import React, { useCallback, useState } from "react";
import { Checkbox } from "primereact/checkbox";
import { useParams } from "react-router-dom";
import { EtapaProps } from "../../etapaProps";
import ProfanoNextStepBar from "../../../../components/ProfanoNextStepBar";
import api from "../../../../services/api";
import { FormProfanoPagePathParams } from "..";
import { useToast } from "../../../../hooks/toast";

const EtapaLeitura: React.FC<EtapaProps> = ({ nextStep, isFormFiliacao }) => {
  const [checked, setChecked] = useState(false);

  const { token } = useParams<FormProfanoPagePathParams>();
  const { showToast } = useToast();

  const updateFormProfano = useCallback(() => {
    const updateUrl = isFormFiliacao ? `formularios/filiando/login/${token}` : `formularios/profano/login/${token}`;
    api
      .put(updateUrl, {})
      .then(() => {
        nextStep();
      })
      .then(() => nextStep())
      .catch(() => {
        showToast({
          type: "error",
          title: "Não foi possível salvar a alteração.",
          description: "Tente novamente ou contate nosso time de suporte para solicitar ajuda.",
        });
      });
  }, [isFormFiliacao, nextStep, showToast, token]);

  return (
    <div className="profano-etapa-leitura">
      <ProfanoNextStepBar buttonDisabled={!checked} onButtonClick={updateFormProfano} />
      <section className="profano-etapa-leitura-section">
        <p className="profano-etapa-leitura-section-title-text">Síntese dos princípios maçônicos</p>
        <p className="profano-etapa-leitura-section-body-text">
          01) A Maçonaria proclama, como sempre fez, desde sua origem, a existência de um Princípio Criador, sob
          denominação de GRANDE ARQUITETO DO UIVERSO;
        </p>
        <p className="profano-etapa-leitura-section-body-text">
          02) É uma instituição filosófica, que proclama a liberdade de consciência como sacratíssimo direto humano;
        </p>
        <p className="profano-etapa-leitura-section-body-text">
          03) Não impõe limite à investigação da Verdade e é para garantir essa liberdade que exige de todos a maior
          tolerância;
        </p>
        <p className="profano-etapa-leitura-section-body-text">
          04) Honra o trabalho em suas formas honestas e o tem por dever, a que nenhuma pessoa válida pode fugir,
        </p>
        <p className="profano-etapa-leitura-section-body-text">
          05) Proscreve qualquer discussão sectária, de natureza política ou religiosa, dentro de seus Templos ou fora
          deles, em nome da Ordem;
        </p>
        <p className="profano-etapa-leitura-section-body-text">
          06) Condena o despotismo e trabalha, incessantemente, para unir a espécie humana pelos laços do amor
          fraternal,
        </p>
        <p className="profano-etapa-leitura-section-body-text">
          07) Impõe o culto à Pátria, exige respeito absoluto à família e não admite a menor ofensa nem a uma nem a
          outra;
        </p>
        <p className="profano-etapa-leitura-section-body-text">
          08) Cada loja é um Templo sagrado, sob cuja abóboda os homens livres e de bons costumes devem reunir-se
          fraternalmente, procurando conseguir o bem da humanidade;
        </p>
        <p className="profano-etapa-leitura-section-body-text">
          09) Todo pensamento maçônico deve ser criador. Essa atitude mental engrandece o espírito e fortifica o
          coração. Cada maçom, parte viva dos irmãos, concorrerá para similar o ideal da Ordem e desenvolvê-lo na
          capacidade de sua inteligência;
        </p>
        <p className="profano-etapa-leitura-section-body-text">
          10) A Maçonaria é acessível aos de todas as classes, crenças religiosas, convicções políticas, com exceção
          daquelas que privam o homem da liberdade de consciência e exijam submissão incondicional a seus chefes;
        </p>
        <p className="profano-etapa-leitura-section-body-text">
          11) Em seus Templos aprende-se a amar e a respeitar tudo o que a virtude e a sabedoria consagram;
        </p>
        <p className="profano-etapa-leitura-section-body-text">
          12) Exige estudo meditado de seus rituais e a prática da solidariedade humana;
        </p>
        <p className="profano-etapa-leitura-section-body-text">
          13) A Maçonaria, por conseguinte, é uma instituição criada para combater tudo o que atende contra a razão e
          contra o espírito de fraternidade universal;
        </p>
        <p className="profano-etapa-leitura-section-body-text">
          14) Os ensinamentos maçônicos, realizados através de símbolos e de alegorias universais, induzem seus adeptos
          a dedicarem-se à felicidade a seus semelhantes, não porque a razão e a justiça lhe imponham esse dever, mas
          porque o sentimento é a qualidade inata, que os faz filhos do Universo e amigos de todos os homens.
        </p>
      </section>
      <section className="profano-etapa-leitura-section">
        <p className="profano-etapa-leitura-section-title-text">Ser maçom</p>
        <p className="profano-etapa-leitura-section-body-text">
          01) Ser Maçom é ser amante da Virtude, da Sabedoria, da Justiça e da Humanidade;
        </p>
        <p className="profano-etapa-leitura-section-body-text">
          02) Ser Maçom é ser amigo dos pobres e desgraçados, dos que sofrem, dos que choram, dos que tem fome de
          justiça; é porque como única norma de conduta o bem de todos e o seu progresso e engrandecimento;
        </p>
        <p className="profano-etapa-leitura-section-body-text">
          03) Ser Maçom é querer a harmonia das famílias, a concórdia dos povos, a paz do gênero humano;{" "}
        </p>
        <p className="profano-etapa-leitura-section-body-text">
          04) Ser Maçom é derramar por todas as partes os esplendores divinos da instrução; educar a inteligência para o
          bem, conceber os mais ideais do Direito, da Moralidade e do Amor, e praticá-los;
        </p>
        <p className="profano-etapa-leitura-section-body-text">
          05) Ser Maçom é levar a prática aquele formosíssimo preceito de todos os lugares e de todos os séculos, que
          diz, com infinita ternura aos seres humanos, indistintamente, do alto de uma Cruz e com os braços aberto ao
          mundo: “Amai-vos uns aos outros, formai uma única família, sede todos irmão”!
        </p>
        <p className="profano-etapa-leitura-section-body-text">
          06) Ser Maçom é olvidar as ofensas que se nos fazem, ser bom, até mesmo para com os nossos adversários e
          inimigos, não odiar a ninguém, praticar a Virtude constantemente, pagar o mal com o bem:
        </p>
        <p className="profano-etapa-leitura-section-body-text">
          07) Ser Maçom é amar a luz e aborrecer as trevas; ser amigo da Ciência e combater a ignorância, render culto a
          razão e à Sabedoria;
        </p>
        <p className="profano-etapa-leitura-section-body-text">
          08) Ser Maçom é praticar a Tolerância, exercer a caridade, sem distinção de raças, crenças ou opiniões, lutar
          contra a hipocrisia e o fanatismo;
        </p>
        <p className="profano-etapa-leitura-section-body-text">
          09) Ser Maçom é realizar, enfim, o sonho áureo da Fraternidade entre os homens.
        </p>
      </section>
      <section className="profano-etapa-leitura-section">
        <p className="profano-etapa-leitura-section-title-text">Nossas finalidades</p>
        <p className="profano-etapa-leitura-section-body-text">
          01) Grandes vultos do passado e do presente pertenceram ou pertencem à Sublime Ordem Maçônica Universal,
          adotando esta filosofia de vida;
        </p>
        <p className="profano-etapa-leitura-section-body-text">
          02) A Maçonaria se reocupa com os problemas nacionais e internacionais, usando de sua influência para minorar
          o sofrimento dos povos, sem distinção de cor, raça ou religião;
        </p>
        <p className="profano-etapa-leitura-section-body-text">
          03) A Maçonaria é parte integrante da História Pátria e Universal, os maçons sempre estiveram presentes nos
          grandes eventos, defendendo a trilogia sagrada LIBERDADE, IGUALDADE E FRATERNIDADE;
        </p>
        <p className="profano-etapa-leitura-section-body-text">
          04) Assim lutaram e venceram os maçons que nos antecederam; assim lutamos hoje; assim lutarão amanhã nossos
          sucessores, empenhando esta mesma bandeira;
        </p>
        <p className="profano-etapa-leitura-section-body-text">
          05) Trabalhando pela felicidade do gênero humano, os maçons se consideram recompensados e realizados, pois
          servindo ao próximo estão observando os ensinamentos da Sublime Instituição;
        </p>
        <p className="profano-etapa-leitura-section-body-text">
          06) Os que satisfizerem estes requisitos e desejarem cerrar fileiras com os Apóstolos da Liberdade e
          Sacerdotes do Direito, deverão se comprometer a trabalhar pelo bem-estar da Pátria e da Humanidade;
        </p>
        <p className="profano-etapa-leitura-section-body-text">
          07) Aquele que for convidado e aceito será considerado nosso irmão, passando a fazer parte da grande Família
          Maçônica Universal;
        </p>
        <p className="profano-etapa-leitura-section-body-text">
          08) Nossa Ordem deseja ardentemente que todos os homens de bem se unam numa cruzada mundial de recuperação dos
          valores morais da humanidade;
        </p>
        <p className="profano-etapa-leitura-section-body-text">
          09) DEUS, a quem denominamos de GRANDE ARQUITETO DO UNIVERSO, conhece muito bem nossas intenções e penetra no
          mais recôndito de nossas almas, razão por que devemos ser sinceros em nossas atitudes.
        </p>
      </section>
      <section className="profano-etapa-leitura-section">
        <div className="profano-etapa-leitura-checkbox-view">
          <Checkbox inputId="binary" checked={checked} onChange={() => setChecked(!checked)} />
          <p className="profano-etapa-leitura-checkbox-view-text" onClickCapture={() => setChecked(!checked)}>
            Li e concordo
          </p>
        </div>
      </section>
    </div>
  );
};

export default EtapaLeitura;
