import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loading from "../Loading";
import { useToast } from "../../hooks/toast";
import publicApi from "../../services/public-api";

interface Params {
  uuid: string;
}

const FilePagePecasArquitetura: React.FC = () => {
  const { uuid } = useParams<Params>();
  const { showToast } = useToast();

  const [loading, setLoading] = useState(true);

  const [pdf, setPdf] = useState<string>("");
  const [mobile, setMobile] = useState<boolean>(false);
  const [base64, setBase64] = useState<string>("");

  const loadData = useCallback(() => {
    if (/Android/i.test(navigator.userAgent)) {
      setMobile(true);
    }

    publicApi
      .get(`files/get/${uuid}`)
      .then(({ data }) => {
        const b64toBlob = (b64Data: string, contentType = "", sliceSize = 512) => {
          const byteCharacters = atob(b64Data);
          const byteArrays = [];

          for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i += 1) {
              byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
          }

          const blob = new Blob(byteArrays, { type: contentType });
          return blob;
        };

        const blob = b64toBlob(data, "application/pdf");
        const blobUrl: any = URL.createObjectURL(blob);

        setPdf(blobUrl);
        setBase64(data);
      })
      .catch(() => {
        showToast({
          title: "Não foi possível carregar este PDF",
          type: "error",
          description: "Tente novamente mais tarde, ou entre em contato com nosso time de suporte para solicitar ajuda",
        });
      })
      .finally(() => setLoading(false));
  }, [showToast, uuid]);

  const celularFunction = useCallback(() => {
    const linkSource = `data:application/pdf;base64,${base64}`;
    const downloadLink = document.createElement("a");
    const fileName = "peca-arquitetura.pdf";
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }, [base64]);

  useEffect(() => {
    loadData();
  }, [loadData, showToast, uuid]);

  const printTrigger = (id: string) => {
    const getMyFrameElement: HTMLElement | null = document.getElementById(id);
    const getMyFrame = getMyFrameElement as unknown as HTMLIFrameElement;

    if (getMyFrame && getMyFrame.contentWindow) {
      getMyFrame.focus();
      getMyFrame.contentWindow.print();
    }
  };

  useEffect(() => {
    const checkPrintKey = (e: KeyboardEvent) => {
      if (e.ctrlKey && e.key === "p") {
        e.preventDefault();
        e.stopPropagation();
        printTrigger("pdf");
      }
    };

    window.removeEventListener("keydown", checkPrintKey);
    window.addEventListener("keydown", checkPrintKey);

    return () => {
      window.removeEventListener("keydown", checkPrintKey);
    };
  }, []);

  return (
    <div className="main" style={{ position: "absolute", top: 0, right: 0, left: 0, bottom: 0 }}>
      {loading && <Loading isLoading />}
      {!loading && !mobile ? window.open(`${pdf}`, "_self") : undefined}
      {mobile && !loading ? celularFunction() : undefined}
    </div>
  );
};

export default FilePagePecasArquitetura;
