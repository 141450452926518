import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { v4 } from "uuid";
import { FormProfanoPagePathParams } from "..";
import {
  AnexoSituacao,
  AnexoTipo,
  situacoesAnexoProfanoMap,
  tiposAnexoProfanoMap,
} from "../../../../components/AnexosProfano";

import InputAnexo from "../../../../components/InputAnexo";
import Loading from "../../../../components/Loading";
import ProfanoNextStepBar from "../../../../components/ProfanoNextStepBar";
import { formProfanoEtapaAnexoErrors } from "../../../../errors/formProfanoEtapaAnexo";
import { useError } from "../../../../hooks/error";
import api from "../../../../services/api";
import publicApi from "../../../../services/public-api";
import { convertFileToBase64, pdfBlob } from "../../../../utils/convertFileToBase64";
import { EtapaProps } from "../../etapaProps";

interface ErrosForm {
  [name: string]: string;
}

interface AnexosResponse {
  id?: number;

  uuid: string;
  observacao?: string;

  tipo: AnexoTipo;
  situacao?: AnexoSituacao;
}

// eslint-disable-next-line no-unused-vars
type AnexoBlobMap = { [name in AnexoTipo]: pdfBlob | undefined };

const EtapaAnexo: React.FC<EtapaProps> = ({ nextStep, isFormFiliacao }) => {
  // const { assinado } = useAppSelector((state) => state.signature);
  const typePdf = "application/pdf";

  const errorMediaType = "É necessário selecionar um arquivo do tipo pdf.";

  const { token } = useParams<FormProfanoPagePathParams>();
  const { handleError } = useError();

  const [errorsForm, setErrorsForm] = useState<ErrosForm>({});
  const [loading, setLoading] = useState(false);
  const [loadingSend, setLoadingSend] = useState(false);

  const [anexosBlobs, setAnexosBlobs] = useState<AnexoBlobMap>({
    QUIT_PLACET: undefined,
    RESUMO_VIDA: undefined,
    ATESTADO_SAUDE: undefined,
    ATESTADO_SANIDADE: undefined,
    CN_CRIMINAL_FEDERAL: undefined,
    CN_CRIMINAL_ESTADUAL: undefined,
    RG_CPF_TITULO_ELEITOR: undefined,
    DECLARACAO_SUBSISTENCIA: undefined,
  });

  const [estrutura, setEstrutura] = useState<AnexosResponse[]>([
    // { tipo: "QUIT_PLACET", uuid: `fake@${v4()}` },
    { tipo: "RESUMO_VIDA", uuid: `fake@${v4()}` },
    { tipo: "ATESTADO_SAUDE", uuid: `fake@${v4()}` },
    { tipo: "ATESTADO_SANIDADE", uuid: `fake@${v4()}` },
    { tipo: "CN_CRIMINAL_FEDERAL", uuid: `fake@${v4()}` },
    { tipo: "CN_CRIMINAL_ESTADUAL", uuid: `fake@${v4()}` },
    { tipo: "DECLARACAO_SUBSISTENCIA", uuid: `fake@${v4()}` },
    { tipo: "RG_CPF_TITULO_ELEITOR", uuid: `fake@${v4()}` },
  ]);

  const validateData = useCallback(() => {
    const assinado = localStorage.getItem("@Assinado");

    let result = true;
    estrutura.forEach((field) => {
      if (!assinado && (!field.situacao || field.situacao === "REJEITADO")) {
        if (_.isEmpty(anexosBlobs[field.tipo])) {
          result = false;
        }
      }
    });
    return result;
  }, [anexosBlobs, estrutura]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleButtonClick = () => {
    window.open(`/assinatura/${token}`, "_blank");
  };

  // filtrando e removendo o anexo do tipo declaração de subsitência
  const getAnexosToSend = useCallback(() => {
    return estrutura
      .filter((field) => field.tipo !== "DECLARACAO_SUBSISTENCIA")
      .map((field) => ({
        id: field.id,
        tipo: field.tipo,
        dados: anexosBlobs[field.tipo],
      }));
  }, [anexosBlobs, estrutura]);

  const updateForm = useCallback(() => {
    if (validateData()) {
      setLoadingSend(true);
      const anexosToSend = getAnexosToSend();
      const updateUrl = isFormFiliacao ? `formularios/filiando/login/${token}` : `formularios/profano/login/${token}`;
      api
        .put(updateUrl, {
          anexos: anexosToSend,
        })
        .then(() => {
          localStorage.removeItem("@Assinado");
          nextStep();
        })
        .catch((err) => {
          handleError({ error: err, action: "salvar anexos", knownErrors: formProfanoEtapaAnexoErrors });
        })
        .finally(() => {
          setLoadingSend(false);
        });
    }
  }, [getAnexosToSend, handleError, isFormFiliacao, nextStep, token, validateData]);

  const onInputAnexoChange = useCallback(
    async (file, tipo: AnexoTipo) => {
      if (file && file.type === typePdf) {
        const fileConverted = await convertFileToBase64(file);
        const blobsClone = _.cloneDeep(anexosBlobs);
        blobsClone[tipo] = fileConverted;
        setAnexosBlobs(blobsClone);

        const errorsLocal = _.cloneDeep(errorsForm);
        delete errorsLocal[tipo];
        setErrorsForm(errorsLocal);
      } else {
        const blobsClone = _.cloneDeep(anexosBlobs);
        blobsClone[tipo] = undefined;
        setAnexosBlobs(blobsClone);

        const errorsLocal = _.cloneDeep(errorsForm);
        errorsLocal[tipo] = errorMediaType;
        setErrorsForm(errorsLocal);
      }
    },
    [anexosBlobs, errorsForm]
  );

  function compareAnexos(a: AnexosResponse, b: AnexosResponse) {
    if (a.situacao && b.situacao) {
      return situacoesAnexoProfanoMap[a.situacao].order - situacoesAnexoProfanoMap[b.situacao].order;
    }
    return 0;
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  // const downloadDeclaracaoSubsistencia = async () => {
  //   const downloadUrl = `/pdf/download/subsistencia/${token}`;

  //   try {
  //     const response = await api.get(downloadUrl, {
  //       responseType: "text",
  //     });

  //     const base64 = response.data;

  //     const link = document.createElement("a");
  //     link.href = `data:application/pdf;base64,${base64}`;
  //     link.download = "declaracao_subsistencia.pdf";

  //     link.click();

  //     return response;
  //   } catch (error) {
  //     return null;
  //   }
  // };

  const loadFields = useCallback(() => {
    const updateUrl = isFormFiliacao
      ? `formularios/filiando/login/anexo/${token}`
      : `formularios/profano/login/anexo/${token}`;
    publicApi
      .get(updateUrl)
      .then(({ data }) => {
        if (!_.isEmpty(data)) {
          data.sort(compareAnexos);
          setEstrutura(data);
        }
      })
      .catch((err) => {
        handleError({ error: err, action: "carregar lista de anexos", knownErrors: formProfanoEtapaAnexoErrors });
        setEstrutura([]);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [handleError, isFormFiliacao, token]);

  const getFieldDescriptionBody = useCallback(
    (tipo: AnexoTipo): React.ReactNode => {
      switch (tipo) {
        case "QUIT_PLACET":
          return (
            <div className="profano-etapa-anexo-sections-subtitle">
              <p className="profano-etapa-anexo-sections-label-text">Expedido pela Loja de Origem.</p>
            </div>
          );
        case "RESUMO_VIDA":
          return (
            <div className="profano-etapa-anexo-sections-subtitle">
              <p className="profano-etapa-anexo-sections-label-text">
                Faça, de próprio punho, um resumo da sua vida, contando passagens importantes, sua situação familiar,
                profissional
                {isFormFiliacao
                  ? " e seus costumes. "
                  : ", seus costumes e as razões que o levaram a querer ingressar na maçonaria. "}
              </p>
              <p className="profano-etapa-anexo-sections-label-text-bold">
                - Não são válidos documentos feitos no computador.
              </p>
              <p className="profano-etapa-anexo-sections-label-text-bold">- O texto deve ter no máximo 1 página.</p>
            </div>
          );
        case "ATESTADO_SAUDE":
          return "";
        case "ATESTADO_SANIDADE":
          return "";
        case "CN_CRIMINAL_FEDERAL":
          return (
            <div className="profano-etapa-anexo-sections-subtitle">
              <p className="profano-etapa-anexo-sections-label-text">
                Você pode conseguir esta certidão através do site do Conselho da Justiça Federal.
                <a
                  className="profano-etapa-anexo-sections-label-link"
                  href="https://www.cjf.jus.br/cjf/certidao-negativa"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://www.cjf.jus.br/cjf/certidao-negativa
                </a>
              </p>
            </div>
          );
        case "CN_CRIMINAL_ESTADUAL":
          return (
            <div className="profano-etapa-anexo-sections-subtitle">
              <p className="profano-etapa-anexo-sections-label-text">
                Você pode conseguir esta certidão através do site do Conselho da Justiça Federal.
                <a
                  className="profano-etapa-anexo-sections-label-link"
                  href="https://consultasaj.tjam.jus.br/sco/abrirCadastro.do"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://consultasaj.tjam.jus.br/sco/abrirCadastro.do
                </a>
              </p>
            </div>
          );
        case "DECLARACAO_SUBSISTENCIA":
          return (
            <div>
              <div
                className="profano-etapa-anexo-sections-subtitle"
                style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
              >
                <p className="profano-etapa-anexo-sections-label-text">
                  para imprimir a declaração, que deve ser assinada à caneta ou utilizando o GOV.BR.
                  {/* <a
                      className="profano-etapa-anexo-sections-label-link"
                      href="https://assinador.iti.br/assinatura/index.xhtml"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://assinador.iti.br/assinatura/index.xhtml
                    </a> */}
                </p>
                <button type="button" onClick={handleButtonClick} style={{ fontSize: "1rem" }}>
                  Clique aqui
                  <i className="fa fa-download" style={{ marginLeft: "5px" }} />
                </button>
              </div>
              <div />
            </div>
          );
        case "RG_CPF_TITULO_ELEITOR":
          return (
            <div className="profano-etapa-anexo-sections-subtitle">
              <p className="profano-etapa-anexo-sections-label-text">
                Os três documentos devem estar no mesmo arquivo PDF
              </p>
            </div>
          );

        default:
          return "";
      }
    },
    [handleButtonClick, isFormFiliacao]
  );

  useEffect(() => {
    setLoading(true);

    loadFields();
  }, [loadFields]);

  useEffect(() => {
    // adicionando anexo FORM_PLACET se for form de filiação e ainda não estiver na lista
    if (isFormFiliacao && !estrutura.find((anexo) => anexo.tipo === "QUIT_PLACET")) {
      const novaEstrutura = _.cloneDeep(estrutura);
      novaEstrutura.unshift({ tipo: "QUIT_PLACET", uuid: `fake@${v4()}` });
      setEstrutura(novaEstrutura);
    }
  }, [estrutura, isFormFiliacao]);

  if (loading) {
    return <Loading isLoading={loading} />;
  }

  return (
    <div className="profano-etapa-anexo">
      <ProfanoNextStepBar buttonDisabled={!validateData()} onButtonClick={() => updateForm()} loading={loadingSend} />
      {estrutura.map((estruturaMaped) => {
        return estruturaMaped.tipo ? (
          <InputAnexo
            key={`key-${estruturaMaped.uuid}`}
            labelField={tiposAnexoProfanoMap[estruturaMaped.tipo].label}
            typeAcceptFileSelect={typePdf}
            onChangeFile={(file) => onInputAnexoChange(file, estruturaMaped.tipo)}
            errorMessage={errorsForm[`${estruturaMaped.tipo}`]}
            revisaoMessage={estruturaMaped.observacao}
            body={getFieldDescriptionBody(estruturaMaped.tipo)}
            situacao={estruturaMaped.situacao}
            hideButton={estruturaMaped.tipo === "DECLARACAO_SUBSISTENCIA"}
          />
        ) : (
          <></>
        );
      })}
    </div>
  );
};

export default EtapaAnexo;
