import { IAppError } from "./AppErrorInterfaces";

export const formProfanoEtapaAnexoErrors: IAppError[] = [
  {
    code: "Formulário não encontrado.",
    title: "Formulário não encontrado.",
    message: "Entre em contato com nosso time de suporte para solicitar ajuda.",
  },
  {
    code: "É obrigatório enviar os anexos.",
    title: "É obrigatório enviar os anexos.",
    message: "Selecione corretamente os arquivos que você deseja enviar.",
  },
  {
    code: "É obrigatório enviar todos os anexos.",
    title: "É obrigatório enviar os anexos.",
    message: "Preencha corretamente todos os campos.",
  },
  {
    code: "Anexo não encontrado.",
    title: "Anexo não encontrado.",
    message: "Entre em contato com nosso time de suporte para solicitar ajuda.",
  },
];
