import React from "react";
import { EtapaFormProfano } from "../../pages/FormProfano/FormProfanoPage";

export interface EtapaElement {
  label: string;
  etapa: EtapaFormProfano;
}
type EtapaMap = {
  // eslint-disable-next-line no-unused-vars
  [name in EtapaFormProfano]: EtapaElement;
};

export const etapasMap: EtapaMap = {
  ANEXO: { etapa: "ANEXO", label: "Envio de anexos" },
  FINALIZACAO: { etapa: "FINALIZACAO", label: "Concluído" },
  FORM: { etapa: "FORM", label: "Preenchimento do formulário" },
  LEITURA: { etapa: "LEITURA", label: "Leitura e aceitação" },
};

interface Props {
  etapaSelecionada: EtapaFormProfano;
}

const FormProfanoEtapaSelecionada: React.FC<Props> = ({ etapaSelecionada }) => {
  const etapas: EtapaFormProfano[] = ["LEITURA", "FORM", "ANEXO", "FINALIZACAO"];

  const estruturaEtapas = etapas.map((etapa) => {
    const index = etapas.findIndex((i) => i === etapa);
    const numeroEtapa = index + 1;

    return (
      <div className="form-profano-etapas-content" key={numeroEtapa}>
        <div
          className={`form-profano-etapas-content-numero${
            etapasMap[etapa].etapa === etapaSelecionada ? " selecionado" : ""
          }`}
        >
          {numeroEtapa}
        </div>
        <div className="form-profano-etapas-content-label">{etapasMap[etapa].label}</div>
        {numeroEtapa !== etapas.length && <hr className="form-profano-etapas-content-barra-divisao" />}
      </div>
    );
  });

  return <div className="form-profano-etapas">{estruturaEtapas}</div>;
};

export default FormProfanoEtapaSelecionada;
