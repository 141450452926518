import { IAppError } from "./AppErrorInterfaces";

export const votacaoEscrutinio: IAppError[] = [
  {
    code: "UUID inválido.",
    message: "Alteração inválida na url.",
  },
  {
    code: "Escrutinio não encontrado.",
    title: "Escrutínio não encontrado.",
    message: "Insira uma url válida.",
  },
  {
    code: "Irmão não encontrado.",
    title: "Irmão não encontrado.",
    message: "Insira um cpf existente em nossa base de dados.",
  },
  {
    code: "Participante não encontrado.",
    title: "Participante não encontrado.",
    message: "Faça login novamente.",
  },
];
