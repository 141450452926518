import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import _ from "lodash";
import { InputText } from "primereact/inputtext";
import { RadioButton } from "primereact/radiobutton";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { InputTextarea } from "primereact/inputtextarea";
import SimpleEntityListPage from "../../../../components/SimpleEntityPage";
import api from "../../../../services/api";
import { useError } from "../../../../hooks/error";
import { podcastErrors } from "../../../../errors/podcast";
import { Podcast, TipoPublicacaoElement, tipoPublicacaoMap } from "../../types";
import FloatingSave from "../../../../components/FloatingSave";
import CustomFormField from "../../../../components/CustomFormField";
import { convertDateToStringBR, convertStringBRToDate } from "../../../../utils/convertDates";
import { GrauElement, grausMap } from "../../../../components/IrmaoComponent";
import { formatDateToStringForSend } from "../../../../utils/formatDateToStringForSend";
import PreviewSelectAvatar from "../../../../components/PreviewSelectAvatar";
import { useToast } from "../../../../hooks/toast";
import MessageErro from "../../../../components/MessageErro";

interface Params {
  id: string;
}

const PodcastFormPage: React.FC = () => {
  const history = useHistory();
  const { id } = useParams<Params>();

  const { showToast } = useToast();
  const { handleError } = useError();

  const [loading, setLoading] = useState(false);
  const [initialPrograma, setInitialPrograma] = useState({} as Podcast);
  const [errors, setErrors] = useState<{ [name: string]: string }>({});

  const [isDirty, setIsDirty] = useState(false);
  const [loadingSalvar, setLoadingSalvar] = useState(false);

  const [nome, setNome] = useState<string>("");
  const [tipo, setTipo] = useState<TipoPublicacaoElement>(tipoPublicacaoMap.LOJA);
  const [grau, setGrau] = useState<GrauElement>(grausMap.APRENDIZ);
  const [descricao, setDescricao] = useState<string>("");
  const [thumbnail, setThumbnail] = useState<string | undefined>("");
  const [publicado, setPublicado] = useState<boolean>(true);
  const [dataCriacao, setDataCriacao] = useState<Date | Date[]>(new Date());
  const [dataCriacaoFormatada, setDataCriacaoFormatada] = useState<string>("");

  const yearNavigatorTemplate = useCallback((e: any) => {
    return (
      <Dropdown
        value={e.value}
        options={e.options}
        onChange={(event) => e.onChange(event.originalEvent, event.value)}
        className="p-ml-2"
        style={{ lineHeight: 1 }}
      />
    );
  }, []);

  const monthNavigatorTemplate = useCallback((e: any) => {
    return (
      <Dropdown
        value={e.value}
        options={e.options}
        onChange={(event) => e.onChange(event.originalEvent, event.value)}
        style={{ lineHeight: 1 }}
      />
    );
  }, []);

  const programaValido = useCallback(() => {
    const errorsLocal: { [campo: string]: string } = {};

    if (_.isEmpty(nome)) errorsLocal.nome = "Este é um campo obrigatório";
    if (_.isEmpty(tipo)) errorsLocal.tipo = "Este é um campo obrigatório";
    if (_.isEmpty(grau)) errorsLocal.grau = "Este é um campo obrigatório";
    if (_.isEmpty(descricao)) errorsLocal.descricao = "Este é um campo obrigatório";
    if (_.isEmpty(thumbnail)) errorsLocal.thumbnail = "Este é um campo obrigatório";
    if (!dataCriacao) errorsLocal.dataCriacao = "Este é um campo obrigatório";
    if (typeof publicado !== "boolean") errorsLocal.publicado = "Este é um campo obrigatório";

    setErrors(errorsLocal);
    return _.isEmpty(errorsLocal);
  }, [dataCriacao, descricao, grau, nome, publicado, thumbnail, tipo]);

  const onThumbChange = useCallback(
    (e, newImage) => {
      const blob = new Blob([`${newImage}`], { type: newImage?.split(";")[0].replace("data:", "") });
      if (blob.type !== "image/png" && blob.type !== "image/jpeg" && blob.type !== "image/jpg") {
        showToast({
          type: "warn",
          title: "Extensão da imagem incorreta",
          description: `A imagem precisa ser do tipo .jpg, ou .jpeg, ou .png`,
        });
        return;
      }

      const errorsLocal = _.cloneDeep(errors);
      delete errorsLocal.thumbnail;
      setErrors(errorsLocal);

      setThumbnail(newImage);
      if (!_.isEqual(initialPrograma.thumbnail, newImage)) {
        setIsDirty(true);
      } else {
        setIsDirty(false);
      }
    },
    [errors, initialPrograma.thumbnail, showToast]
  );

  const onThumbClear = useCallback(() => {
    setThumbnail(undefined);
    if (initialPrograma.thumbnail) {
      setIsDirty(true);
    } else {
      setIsDirty(false);
    }
  }, [initialPrograma]);

  const redefinirPodcast = useCallback(() => {
    setIsDirty(false);

    if (id) {
      setNome(initialPrograma.nome);
      setTipo(tipoPublicacaoMap[initialPrograma.tipo]);
      setGrau(grausMap[initialPrograma.grau]);
      setDescricao(initialPrograma.descricao);
      setThumbnail(initialPrograma.thumbnail);
      setPublicado(initialPrograma.publicado);
      setDataCriacao(convertStringBRToDate(initialPrograma.dataCriacao));
      setDataCriacaoFormatada(initialPrograma.dataCriacao);
    } else {
      setNome("");
      setTipo(tipoPublicacaoMap.LOJA);
      setGrau(grausMap.APRENDIZ);
      setDescricao("");
      setThumbnail("");
      setPublicado(true);
      setDataCriacao(new Date());
      setDataCriacaoFormatada("");
    }
  }, [
    id,
    initialPrograma.dataCriacao,
    initialPrograma.descricao,
    initialPrograma.grau,
    initialPrograma.nome,
    initialPrograma.publicado,
    initialPrograma.thumbnail,
    initialPrograma.tipo,
  ]);

  const salvarPodcast = useCallback(() => {
    if (programaValido()) {
      setLoadingSalvar(true);

      const objectForSend = {
        dataCriacao: dataCriacaoFormatada,
        nome,
        descricao,
        thumbnail,
        publicado,
        tipo: tipo.tipo,
        grau: grau.grau,
      };

      if (id) {
        api
          .put(`podcast/${id}`, objectForSend)
          .then(() => {
            showToast({
              title: "Sucesso!",
              type: "success",
              description: "O programa foi atualizado.",
            });

            history.push(`/podcast`);
          })
          .catch((err) => {
            handleError({ error: err, action: "atualizar programa", knownErrors: podcastErrors });
          })
          .finally(() => setLoadingSalvar(false));
      } else {
        api
          .post("podcast", objectForSend)
          .then(() => {
            showToast({
              title: "Sucesso!",
              type: "success",
              description: "Um novo programa foi criado.",
            });

            history.push(`/podcast`);
          })
          .catch((err) => {
            handleError({ error: err, action: "cadastrar programa", knownErrors: podcastErrors });
          })
          .finally(() => setLoadingSalvar(false));
      }
    }
  }, [
    dataCriacaoFormatada,
    descricao,
    grau.grau,
    handleError,
    history,
    id,
    nome,
    programaValido,
    publicado,
    showToast,
    thumbnail,
    tipo.tipo,
  ]);

  const loadData = useCallback(() => {
    api
      .get<Podcast>(`podcast/${id}`)
      .then(({ data }) => {
        setInitialPrograma(data);

        setNome(data.nome);
        setTipo(tipoPublicacaoMap[data.tipo]);
        setGrau(grausMap[data.grau]);
        setDescricao(data.descricao);
        setThumbnail(data.thumbnail);
        setPublicado(data.publicado);
        setDataCriacao(convertStringBRToDate(data.dataCriacao));
        setDataCriacaoFormatada(data.dataCriacao);
      })
      .catch((err: any) => {
        handleError({ error: err, action: "carregar programa", knownErrors: podcastErrors });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [handleError, id]);

  useEffect(() => {
    if (id) {
      setLoading(true);
      loadData();
    }
  }, [id, loadData]);

  return (
    <SimpleEntityListPage showTopBar routeBack="/podcast" isFormPage loading={loading}>
      <div className="p-grid p-flex-row p-jc-between">
        <div className="p-col-7 p-grid">
          <div className="p-fluid p-col-12">
            <CustomFormField
              htmlForDescription="nome"
              description="Nome"
              iconMaterial="mic"
              labelWidth="3"
              required
              inputBody={
                <InputText
                  id="nome"
                  value={nome}
                  maxLength={200}
                  placeholder="Digite o nome do programa"
                  onChange={(e) => {
                    setNome(e.target.value);

                    const errorsLocal = _.cloneDeep(errors);
                    delete errorsLocal.nome;
                    setErrors(errorsLocal);

                    if (!_.isEqual(initialPrograma.nome, e.target.value)) {
                      setIsDirty(true);
                    } else {
                      setIsDirty(false);
                    }
                  }}
                />
              }
              errorMessage={errors.nome}
            />

            <CustomFormField
              htmlForDescription="descricao"
              description="Descrição"
              iconMaterial="subject"
              labelWidth="3"
              required
              inputBody={
                <InputTextarea
                  id="descricao"
                  value={descricao}
                  placeholder="Digite a descrição do programa"
                  rows={5}
                  autoResize
                  onChange={(e) => {
                    setDescricao(e.target.value);

                    const errorsLocal = _.cloneDeep(errors);
                    delete errorsLocal.descricao;
                    setErrors(errorsLocal);

                    if (!_.isEqual(initialPrograma.descricao, e.target.value)) {
                      setIsDirty(true);
                    } else {
                      setIsDirty(false);
                    }
                  }}
                />
              }
              errorMessage={errors.descricao}
            />

            <CustomFormField
              htmlForDescription="publicar-para"
              description="Publicar para"
              iconMaterial="category"
              labelWidth="3"
              required
              inputBody={
                <Dropdown
                  id="publicar-para"
                  value={tipo}
                  options={[tipoPublicacaoMap.LOJA, tipoPublicacaoMap.POTENCIA]}
                  dataKey="tipo"
                  optionLabel="label"
                  onChange={(e) => {
                    setTipo(e.value);
                    const errorsLocal = _.cloneDeep(errors);
                    delete errorsLocal.tipo;
                    setErrors(errorsLocal);
                    if (!_.isEqual(initialPrograma.tipo, e.target.value.tipo)) {
                      setIsDirty(true);
                    } else {
                      setIsDirty(false);
                    }
                  }}
                  style={{ minWidth: "400px" }}
                />
              }
              errorMessage={errors.tipo}
            />

            <CustomFormField
              htmlForDescription="grau"
              description="Para qual grau?"
              icon="pi pi-angle-double-up"
              labelWidth="3"
              required
              inputBody={
                <Dropdown
                  id="grau"
                  value={grau}
                  options={[grausMap.APRENDIZ, grausMap.COMPANHEIRO, grausMap.MESTRE, grausMap.MESTRE_INSTALADO]}
                  dataKey="grau"
                  optionLabel="label"
                  onChange={(e) => {
                    setGrau(e.target.value);
                    const errorsLocal = _.cloneDeep(errors);
                    delete errorsLocal.grau;
                    setErrors(errorsLocal);
                    if (!_.isEqual(initialPrograma.grau, e.target.value.grau)) {
                      setIsDirty(true);
                    } else {
                      setIsDirty(false);
                    }
                  }}
                  itemTemplate={(item: GrauElement) => `${item.label} (${item.nivelStr})`}
                  valueTemplate={(item: GrauElement) => `${item.label} (${item.nivelStr})`}
                  style={{ minWidth: "400px" }}
                />
              }
              errorMessage={errors.grau}
            />
            <CustomFormField
              iconMaterial="event"
              htmlForDescription="criado-em"
              description="Data da criação"
              inputBody={
                <Calendar
                  id="criado-em"
                  placeholder="DD/MM/AAAA"
                  value={dataCriacao}
                  onChange={(e) => {
                    if (e.target && e.target.value) {
                      setDataCriacaoFormatada(convertDateToStringBR(e.target.value.toLocaleString("en-us")) || "");
                      setDataCriacao(e.target.value);

                      if (id) {
                        if (
                          !_.isEqual(
                            formatDateToStringForSend(
                              convertStringBRToDate(initialPrograma.dataCriacao).toLocaleString("en-US")
                            ),
                            formatDateToStringForSend(e.target.value.toLocaleString("en-us"))
                          )
                        ) {
                          setIsDirty(true);
                        } else {
                          setIsDirty(false);
                        }
                      } else {
                        setIsDirty(true);
                      }
                    }
                    const errorsLocal = _.cloneDeep(errors);
                    delete errorsLocal.dataVencimento;
                    setErrors(errorsLocal);
                  }}
                  monthNavigator
                  yearNavigator
                  yearRange="1800:2050"
                  monthNavigatorTemplate={monthNavigatorTemplate}
                  yearNavigatorTemplate={yearNavigatorTemplate}
                  locale="pt-br"
                  showIcon
                />
              }
              required
              errorMessage={errors.dataCriacao}
            />

            <CustomFormField
              htmlForDescription="publicado"
              description="Deseja publicar este programa?"
              iconMaterial="campaign"
              labelWidth="3"
              required
              inputBody={
                <div className="p-d-flex p-ai-center" style={{ paddingTop: 2, paddingBottom: 2 }}>
                  <RadioButton
                    inputId="publicado"
                    onChange={() => {
                      setPublicado(true);
                      const errorsLocal = _.cloneDeep(errors);
                      delete errorsLocal.publicado;
                      setErrors(errorsLocal);
                      if (!_.isEqual(initialPrograma.publicado, true)) {
                        setIsDirty(true);
                      } else {
                        setIsDirty(false);
                      }
                    }}
                    checked={publicado}
                  />
                  <label htmlFor="publicado" className="p-ml-2">
                    Sim
                  </label>
                  <RadioButton
                    inputId="nao-publicado"
                    onChange={() => {
                      setPublicado(false);
                      const errorsLocal = _.cloneDeep(errors);
                      delete errorsLocal.publicado;
                      setErrors(errorsLocal);
                      if (!_.isEqual(initialPrograma.publicado, false)) {
                        setIsDirty(true);
                      } else {
                        setIsDirty(false);
                      }
                    }}
                    checked={!publicado}
                    className="p-ml-4"
                  />
                  <label htmlFor="nao-publicado" className="p-ml-2">
                    Não
                  </label>
                </div>
              }
              errorMessage={errors.publicado}
            />
          </div>
        </div>
        <div className="p-col-5 form-irmao-image-container">
          <PreviewSelectAvatar
            src={thumbnail}
            onChange={onThumbChange}
            onClear={onThumbClear}
            rounded={false}
            borderRadius={20}
            maxFileSizeMB={10}
            overwriteIcon="pi pi-image"
            onLargeFileSize={(fileSize, maxFileSize) => {
              showToast({
                type: "warn",
                title: "Limite de tamanho do arquivo excedido",
                description: `O arquivo selecionado possui ${fileSize}MB. O tamanho máximo permitido é de ${maxFileSize}MB.`,
              });
            }}
            styleImage={{ objectFit: "fill" }}
          />
          <div className="p-d-flex p-jc-center p-text-center" style={{ width: 380, lineHeight: "16px" }}>
            Imagens com a proporção 1:1 (quadradas), ficam com uma melhor visualização
          </div>
          <div className="p-d-flex p-jc-center p-text-center" style={{ width: 380 }}>
            {errors.thumbnail && <MessageErro message={errors.thumbnail} messageClassName="p-jc-center" />}
          </div>
        </div>
        <div />
        {isDirty ? (
          <FloatingSave
            resetCommand={() => redefinirPodcast()}
            disabled={loadingSalvar || !_.isEmpty(errors)}
            saveCommand={() => salvarPodcast()}
            loadingOnSave={loadingSalvar}
          />
        ) : null}
      </div>
    </SimpleEntityListPage>
  );
};

export default PodcastFormPage;
