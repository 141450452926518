/* eslint-disable no-unused-vars */
import { Button } from "primereact/button";
import React, { CSSProperties } from "react";
import { convertFileToBase64 } from "../../utils/convertFileToBase64";

interface Props {
  src?: string;
  onChange?: (event: any, imageStr?: string) => void;
  onClear?: () => void;
  maxFileSizeMB?: number;
  onLargeFileSize?: (fileSize: number, maxFileSize: number) => void;
  rounded?: boolean;
  width?: string;
  height?: string;
  editing?: boolean;
  className?: string;
  overwriteIcon?: string;
  borderRadius?: number;
  styleImage?: CSSProperties;
}

const PreviewSelectAvatar: React.FC<Props> = ({
  src,
  maxFileSizeMB = 10,
  rounded = true,
  width = "300px",
  height = "300px",
  onChange,
  onClear = () => {},
  onLargeFileSize,
  editing = true,
  className = "",
  overwriteIcon,
  borderRadius,
  styleImage,
}) => {
  const fileSizeLimitExceeds = (file: File) => {
    const fileSize = Number((file.size / 1024 / 1024).toFixed(4));
    const fileSizeExceedsMaxFileSize = fileSize > maxFileSizeMB;
    if (fileSizeExceedsMaxFileSize && onLargeFileSize) {
      onLargeFileSize(fileSize, maxFileSizeMB);
    }
    return fileSizeExceedsMaxFileSize;
  };

  return (
    <div className={`preview-avatar-container ${className}`}>
      <label className="preview-avatar-label">
        <input
          type="file"
          accept=".jpg,.jpeg,.png"
          disabled={!editing}
          onChange={async (event) => {
            if (event.target.files && event.target.files[0]) {
              const file = event.target.files[0];
              if (fileSizeLimitExceeds(file)) {
                return;
              }

              if (onChange) {
                const image = await convertFileToBase64(file);

                onChange(event, image?.toString());
              }
            }
          }}
        />
        <figure className={`preview-avatar-figure `} style={{ width, height }}>
          {src ? (
            <img
              src={src}
              style={{ width, height, borderRadius, ...styleImage }}
              className={`preview-avatar-image ${rounded ? "preview-avatar-rounded" : ""}`}
              alt="avatar"
            />
          ) : (
            <div
              style={{ width, height, borderRadius }}
              className={`preview-avatar-no-image ${rounded ? "preview-avatar-rounded" : ""}`}
            >
              <i className={overwriteIcon || "pi pi-user"} />
            </div>
          )}

          {editing && (
            <figcaption className={`preview-avatar-figcaption ${rounded ? "preview-avatar-rounded" : ""}`}>
              <i className="pi pi-camera" />
            </figcaption>
          )}
        </figure>
      </label>
      {src && editing && (
        <Button
          icon="pi pi-times"
          className="p-button-text p-button-rounded preview-avatar-remove-button"
          onClick={onClear}
          tooltip="Remover foto"
        />
      )}
    </div>
  );
};

PreviewSelectAvatar.defaultProps = {
  src: undefined,
  maxFileSizeMB: 10,
  rounded: true,
  width: "300px",
  height: "300px",
  onChange: undefined,
  onClear: () => {},
  onLargeFileSize: undefined,
  editing: true,
  className: "",
  overwriteIcon: undefined,
  borderRadius: undefined,
  styleImage: undefined,
};

export default PreviewSelectAvatar;
