import React, { useState, useCallback, useRef, useEffect, useMemo } from "react";
import { DataTable } from "primereact/datatable";
import { useHistory } from "react-router-dom";
import { ColumnProps } from "primereact/column";

import { Button } from "primereact/button";
import { confirmDialog } from "primereact/confirmdialog";
import SimpleEntityDatatable from "../../../../components/SimpleEntityDatatable";
import SimpleEntityListPage from "../../../../components/SimpleEntityPage";
import api from "../../../../services/api";
import { Podcast, tipoPublicacaoMap } from "../../types";
import { useToast } from "../../../../hooks/toast";
import { convertStringBRToDate } from "../../../../utils/convertDates";
import { grausMap } from "../../../../components/IrmaoComponent";

const PodcastPage: React.FC = () => {
  const history = useHistory();
  const dt = useRef<DataTable>(null);
  const { showToast } = useToast();

  const [loading, setLoading] = useState(true);
  const [globalFilter, setGlobalFilter] = useState<string>("");

  const [podcasts, setPodcasts] = useState<Podcast[]>([]);

  const loadData = useCallback(() => {
    api
      .get<Podcast[]>("podcast")
      .then(({ data }) => {
        const newData = data.map((p) => {
          return { ...p, criationDate: convertStringBRToDate(p.dataCriacao) };
        });
        setPodcasts(newData);
      })
      .catch(() => {
        showToast({
          type: "error",
          title: "Não foi possível carregar os podcasts",
          description: "Tente novamente ou contate nosso time de suporte para solicitar ajuda.",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [showToast]);

  const excluirPodcast = useCallback(
    (data: Podcast) => {
      api
        .delete(`podcast/${data.uuid}`)
        .then(() => {
          showToast({ title: "Sucesso!", type: "success", description: "O programa foi removido." });
          loadData();
        })
        .catch((err) => {
          showToast({
            title: "Não foi possível remover programa",
            type: "error",
            description: "Tente novamente, ou entre em contato com um administrador",
          });
          // eslint-disable-next-line no-console
          console.log(err.response);
        });
    },
    [loadData, showToast]
  );

  const confirmExclusao = useCallback(
    (data: Podcast) => {
      confirmDialog({
        message: "Após a remoção o item não poderá ser recuperado.",
        header: "Remover programa?",
        icon: "pi pi-question-circle",
        focusOnShow: false,
        rejectLabel: "Manter",
        acceptLabel: "Remover",
        accept: () => excluirPodcast(data),
        reject: () => {},
      });
    },
    [excluirPodcast]
  );

  const nomeEImagemBodyTemplate = useCallback((data: Podcast) => {
    return (
      <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <img
          src={data.thumbnail}
          style={{ width: 40, height: 40, borderRadius: 10, marginRight: 10 }}
          alt={`Imagem do programa ${data.nome}`}
        />
        <span>{data.nome}</span>
      </div>
    );
  }, []);

  const jaPublicadoBodyTemplate = useCallback((rowData: Podcast) => {
    if (rowData.publicado) return <span className="material-symbols-rounded svg-icon">done</span>;
    return <span className="material-symbols-rounded svg-icon">close</span>;
  }, []);

  const grauBodyTemplate = useCallback((rowData: Podcast) => {
    return rowData.grau ? `${grausMap[rowData.grau].nivelStr}` : "";
  }, []);

  const tipoBodyTemplate = useCallback((rowData: Podcast) => {
    return tipoPublicacaoMap[rowData.tipo].label;
  }, []);

  const editDeleteButtonsBodyTemplate = useCallback(
    (data: Podcast) => {
      return (
        <>
          <Button
            type="button"
            className="balandrau-action-button"
            icon="pi pi-eye"
            tooltip="Temporadas"
            tooltipOptions={{ position: "top" }}
            onClick={() => {
              history.push({ pathname: `/podcast/${data.uuid}/temporada` });
            }}
          />
          <Button
            type="button"
            className="p-ml-2 balandrau-action-button"
            icon="pi pi-pencil"
            onClick={() => {
              history.push({ pathname: `/podcast/${data.uuid}` });
            }}
          />
          <Button
            type="button"
            className="p-ml-2 balandrau-exclude-button"
            icon="pi pi-trash"
            onClick={() => {
              confirmExclusao(data);
            }}
          />
        </>
      );
    },
    [history, confirmExclusao]
  );

  const podcastsColumns = useMemo(
    (): ColumnProps[] => [
      {
        field: "nome",
        header: "Nome",
        sortable: true,
        align: "left",
        style: { verticalAlign: "middle" },
        className: "p-text-nowrap p-text-truncate",
        body: nomeEImagemBodyTemplate,
      },
      {
        field: "descricao",
        header: "Descrição",
        sortable: true,
        align: "left",
        style: { verticalAlign: "middle", maxWidth: "200px" },
        className: "p-text-nowrap p-text-truncate",
      },
      {
        field: "dataCriacao",
        header: "Data de criação",
        sortable: true,
        sortField: "criationDate",
        align: "center",
        style: { verticalAlign: "middle", width: 140, maxWidth: 140 },
      },
      {
        field: "publicado",
        header: "Já foi publicado?",
        sortable: false,
        align: "center",
        style: { verticalAlign: "middle", width: 120, maxWidth: 120 },
        body: jaPublicadoBodyTemplate,
      },
      {
        field: "grau",
        header: "Grau",
        sortable: true,
        align: "center",
        style: { verticalAlign: "middle", width: 100, maxWidth: 100 },
        body: grauBodyTemplate,
      },

      {
        field: "tipo",
        header: "Publicado para",
        sortable: true,
        align: "center",
        style: { verticalAlign: "middle", width: 140, maxWidth: 140 },
        body: tipoBodyTemplate,
      },
      {
        align: "center",
        header: "Ações",
        body: editDeleteButtonsBodyTemplate,
        style: { width: "100px", verticalAlign: "middle" },
      },
    ],
    [
      editDeleteButtonsBodyTemplate,
      grauBodyTemplate,
      jaPublicadoBodyTemplate,
      nomeEImagemBodyTemplate,
      tipoBodyTemplate,
    ]
  );

  const onGlobalFilterChange = useCallback((filterValue) => {
    setGlobalFilter(filterValue);
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <SimpleEntityListPage
      showListHeader
      onGlobalFilterChange={(value) => onGlobalFilterChange(value)}
      loading={loading}
      showExportCsvButton
      datatableRef={dt}
      newButtonLabel="Novo programa"
      onNewButtonCLick={() => {
        history.push("/podcast/novo");
      }}
    >
      <SimpleEntityDatatable
        datatableRef={dt}
        value={podcasts}
        columns={podcastsColumns}
        globalFilter={globalFilter}
        sortField="nome"
        sortOrder={1}
      />
    </SimpleEntityListPage>
  );
};

export default PodcastPage;
