/* eslint-disable react/require-default-props */
/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { ColumnProps } from "primereact/column";

import { useHistory } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Tag } from "primereact/tag";
import { Button } from "primereact/button";
import { confirmDialog } from "primereact/confirmdialog";
import _ from "lodash";
import { MultiSelect } from "primereact/multiselect";
import { useToast } from "../../../hooks/toast";
import api from "../../../services/api";
import SimpleEntityDatatable from "../../../components/SimpleEntityDatatable";
import SimpleEntityListPage from "../../../components/SimpleEntityPage";
import { useError } from "../../../hooks/error";
import { publicacaoErrors } from "../../../errors/publicacao";

export interface Publicacao {
  titulo: string;
  dtPublicacao: string;
  tipo: TiposPublicacao;
  uuid: string;
  arquivo: string;
  autor?: string;
  edicao?: string;
}

export interface TipoPublicacaoElement {
  label: string;
  class: string;
  tipo: TiposPublicacao;
}

export type TiposPublicacao = "LIVRO" | "REVISTA" | "OUTROS";

type TiposPublicacoesMap = {
  // eslint-disable-next-line no-unused-vars
  [name in TiposPublicacao]: TipoPublicacaoElement;
};

export const tiposPublicacaoMap: TiposPublicacoesMap = {
  LIVRO: {
    label: "Livro",
    class: "tag-tipo-pub tipo-pub-livro",
    tipo: "LIVRO",
  },
  REVISTA: {
    label: "Revista",
    class: "tag-tipo-pub tipo-pub-revista",
    tipo: "REVISTA",
  },
  OUTROS: {
    label: "Outros",
    class: "tag-tipo-pub tipo-pub-outros",
    tipo: "OUTROS",
  },
};

const PublicacoesPage: React.FC = () => {
  const dt = useRef<DataTable>(null);
  const [loading, setLoading] = useState(false);
  const [forms, setForms] = useState<Publicacao[]>([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const tipoPublicacaoFilterOptions: TipoPublicacaoElement[] = useMemo((): TipoPublicacaoElement[] => {
    return [tiposPublicacaoMap.LIVRO, tiposPublicacaoMap.REVISTA, tiposPublicacaoMap.OUTROS];
  }, []);
  const [tipoPublicacaoFilter, setTipoPublicacaoFilter] = useState<TipoPublicacaoElement[]>([]);
  const { showToast } = useToast();
  const history = useHistory();
  const { handleError } = useError();

  const loadData = useCallback(() => {
    setLoading(true);
    api
      .get<Publicacao[]>(`publicacao`)
      .then(({ data }) => {
        setForms(data);
      })
      .catch((err) => {
        showToast({
          type: "error",
          title: "Não foi possível carregar publicações",
          description: "Tente novamente ou contate nosso time de suporte para solicitar ajuda.",
        });
        // eslint-disable-next-line no-console
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [showToast]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  // const authorBodyTemplate = useCallback((rowData: Publicacao) => {
  //   return (
  //     <>
  //       <IrmaoComponent nome={rowData.author} foto={rowData.authorPhoto} />
  //     </>
  //   );
  // }, []);

  const tipoPublicacaoBodyTemplate = useCallback((rowData: Publicacao) => {
    return <Tag className={tiposPublicacaoMap[rowData.tipo].class}>{tiposPublicacaoMap[rowData.tipo].label}</Tag>;
  }, []);

  const dtPublicacaoBodyTemplate = useCallback(({ dtPublicacao }: Publicacao) => {
    return dtPublicacao
      ? new Date(dtPublicacao).toLocaleDateString("pt-BR", {
          timeZone: "UTC",
        })
      : "";
  }, []);

  // const dtDisponibilizacaoBodyTemplate = useCallback(({ dtDisponibilizacao }: Publicacao) => {
  //   return dtDisponibilizacao
  //     ? new Date(dtDisponibilizacao).toLocaleDateString("pt-BR", {
  //         timeZone: "UTC",
  //       })
  //     : "";
  // }, []);

  const excluirPublicacao = useCallback(
    (data: Publicacao) => {
      api
        .delete(`publicacao/${data.uuid}`)
        .then(() => {
          showToast({ title: "Sucesso!", type: "success", description: "A publicação foi removida." });
          loadData();
        })
        .catch((err) => {
          handleError({ error: err, action: "remover publicação", knownErrors: publicacaoErrors });
          // eslint-disable-next-line no-console
          console.log(err.response);
        });
    },
    [handleError, loadData, showToast]
  );

  const confirmExclusao = useCallback(
    (data: Publicacao) => {
      confirmDialog({
        message: "Após a remoção o item não poderá ser recuperado.",
        header: "Remover publicação?",
        icon: "pi pi-question-circle",
        focusOnShow: false,
        rejectLabel: "Manter",
        acceptLabel: "Remover",
        accept: () => excluirPublicacao(data),
        reject: () => {},
      });
    },
    [excluirPublicacao]
  );

  const editDeleteButtonsBodyTemplate = useCallback(
    (data: Publicacao) => {
      return (
        <>
          <Button
            type="button"
            className="balandrau-action-button"
            icon="pi pi-pencil"
            onClick={() => {
              history.push({ pathname: `/publicacoes/${data.uuid}` });
            }}
          />
          <Button
            type="button"
            className="p-ml-3 balandrau-exclude-button"
            icon="pi pi-trash"
            onClick={() => {
              confirmExclusao(data);
            }}
          />
        </>
      );
    },
    [history, confirmExclusao]
  );

  const filterByTipo = useCallback((value: TiposPublicacao, filter: TipoPublicacaoElement[]) => {
    if (value) return filter == null || _.isEmpty(filter) || filter.filter((f) => f.tipo === value).length > 0;
    return false;
  }, []);

  const columns = useMemo(
    (): ColumnProps[] => [
      {
        field: "tipo",
        header: "Tipo",
        sortable: false,
        align: "center",
        style: { verticalAlign: "middle", width: "90px" },
        body: tipoPublicacaoBodyTemplate,
        filterMatchMode: "custom",
        filterFunction: (value, filter) => filterByTipo(value, filter),
      },
      {
        field: "titulo",
        header: "Título",
        sortable: true,
        style: { textAlign: "left", verticalAlign: "middle", width: "40%" },
        className: "p-text-nowrap p-text-truncate",
        body: ({ arquivo, titulo }: Publicacao) => {
          return (
            <a className="color-link" href={`pdf/publicacao/${arquivo}`} target="_blank" rel="noreferrer">
              {titulo}
            </a>
          );
        },
      },
      {
        field: "autor",
        header: "Autor",
        sortable: true,
        style: { textAlign: "left", verticalAlign: "middle", width: "25%" },
        className: "p-text-nowrap p-text-truncate",
      },
      {
        field: "edicao",
        header: "Edição",
        sortable: true,
        style: { textAlign: "left", verticalAlign: "middle", width: "90px" },
        className: "p-text-nowrap p-text-truncate",
      },
      {
        field: "dtPublicacao",
        header: "Publicação",
        sortable: true,
        style: { textAlign: "left", verticalAlign: "middle", width: "90px" },
        className: "p-text-nowrap p-text-truncate",
        body: dtPublicacaoBodyTemplate,
      },
      {
        align: "center",
        body: editDeleteButtonsBodyTemplate,
        style: { width: "100px", verticalAlign: "middle" },
      },
    ],
    [tipoPublicacaoBodyTemplate, dtPublicacaoBodyTemplate, filterByTipo, editDeleteButtonsBodyTemplate]
  );

  const tiposPublicacaoFilterBodyTemplate = (
    <MultiSelect
      className="p-ml-2"
      showClear
      dataKey="tipo"
      optionLabel="label"
      options={tipoPublicacaoFilterOptions}
      value={tipoPublicacaoFilter}
      onChange={(e) => {
        dt.current?.filter(e.value, "tipo", "custom");
        setTipoPublicacaoFilter(e.target.value);
      }}
      placeholder="Filtrar por tipo de publicação"
      selectedItemsLabel="{0} tipos de publicação selecionados"
      maxSelectedLabels={2}
      emptyFilterMessage="Nenhum tipo encontrado"
      style={{ width: "180px" }}
      scrollHeight="400px"
    />
  );

  return (
    <SimpleEntityListPage
      showListHeader
      listHeaderContentRight={tiposPublicacaoFilterBodyTemplate}
      newButtonLabel="Nova publicação"
      onNewButtonCLick={() => {
        history.push("/publicacoes/novo");
      }}
      onGlobalFilterChange={(value) => setGlobalFilter(value)}
      loading={loading}
      showExportCsvButton
      datatableRef={dt}
    >
      <SimpleEntityDatatable
        datatableRef={dt}
        value={forms}
        columns={columns}
        globalFilter={globalFilter}
        sortField="titulo"
        sortOrder={1}
      />
    </SimpleEntityListPage>
  );
};

export default PublicacoesPage;
