/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

interface IAuthorizationError {
  isError: boolean;
}

const initialState: IAuthorizationError = {
  isError: false,
};

export const errorPageSlice = createSlice({
  name: "errorState",
  initialState,
  reducers: {
    showErrorPage: (state) => {
      state.isError = true;
    },
    hideErrorPage: (state) => {
      state.isError = false;
    },
  },
});
export const { showErrorPage, hideErrorPage } = errorPageSlice.actions;
export default errorPageSlice.reducer;
