/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface CounterState {
  value: number;
}

const initialState: CounterState = {
  value: 50,
};

export const paginatorSlice = createSlice({
  name: "paginator",
  initialState,
  reducers: {
    changePaginatorbyNumber: (state, action: PayloadAction<number>) => {
      state.value = action.payload;
    },
  },
});
export const { changePaginatorbyNumber } = paginatorSlice.actions;
export default paginatorSlice.reducer;
