import React, { useCallback, useEffect, useState } from "react";
import _ from "lodash";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import { RadioButton } from "primereact/radiobutton";
import { useHistory, useParams } from "react-router-dom";

import { Button } from "primereact/button";
import api from "../../../services/api";

import { useError } from "../../../hooks/error";
import { useToast } from "../../../hooks/toast";

import { TurmaDTO, TurmaDTOEdit } from "../utils";
import { turmaErrors } from "../../../errors/turma";
import FloatingSave from "../../../components/FloatingSave";
import CustomFormField from "../../../components/CustomFormField";
import SimpleEntityPage from "../../../components/SimpleEntityPage";
import { formatDateToStringForSend } from "../../../utils/formatDateToStringForSend";
import { convertDateToStringBR, removeOffsetFromDate } from "../../../utils/convertDates";

interface Params {
  id: string;
}

const TurmaFormPage: React.FC = () => {
  const PATH_FICHA_TURMA = "/pdf/turmaProfano/";
  const PATH_FICHA_TESTAMENTO = "/pdf/testamento/";

  const history = useHistory();
  const { id } = useParams<Params>();
  const { showToast } = useToast();

  const [loading, setLoading] = useState(false);
  const [loadingSalvar, setLoadingSalvar] = useState(false);

  const [turmaInicial, setTurmaInicial] = useState<TurmaDTOEdit>();

  const [isDirty, setIsDirty] = useState(false);

  const [nome, setNome] = useState<string | null>(null);
  const [blUsarNome, setBlUsarNome] = useState<"nao-usar-nome" | "sim-usar-nome">("sim-usar-nome");
  const [dtIniciacao, setDtIniciacao] = useState<Date | Date[] | undefined>(new Date(Date.now()));

  const { handleError } = useError();
  const [errors, setErrors] = useState<{ [campo: string]: string }>({});

  const loadTurma = useCallback(() => {
    setLoading(true);
    api
      .get<TurmaDTO>(`turmas/${id}`)
      .then(({ data }) => {
        if (data.nome) setNome(data.nome);
        if (data.dtIniciacao) setDtIniciacao(removeOffsetFromDate(new Date(data.dtIniciacao)));
        setTurmaInicial({ ...data, usarNome: "sim-usar-nome" });
        setBlUsarNome("sim-usar-nome");
      })
      .catch(() => {
        showToast({
          type: "error",
          title: "Não foi possível carregar as turmas",
          description: "Tente novamente ou contate nosso time de suporte para solicitar ajuda.",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id, showToast]);

  const validarCampos = useCallback(() => {
    const errorsLocal: { [campo: string]: string } = {};

    if (_.isEmpty(blUsarNome)) {
      errorsLocal.blUsarNome = "Este é um campo obrigatório";
    }
    if (blUsarNome === "sim-usar-nome" && _.isEmpty(nome)) {
      errorsLocal.nome = "Este é um campo obrigatório";
    }
    if (!dtIniciacao) {
      errorsLocal.dtIniciacao = "Este é um campo obrigatório";
    }

    setErrors(errorsLocal);
    return _.isEmpty(errorsLocal);
  }, [blUsarNome, dtIniciacao, nome]);

  const validacao = useCallback(() => {
    const validarNome = blUsarNome === "sim-usar-nome" ? !_.isEmpty(nome) : true;
    return (
      !!dtIniciacao &&
      !_.isEmpty(blUsarNome) &&
      validarNome &&
      (!_.isEqual(turmaInicial?.nome, nome) || !_.isEqual(turmaInicial?.dtIniciacao, dtIniciacao))
    );
  }, [blUsarNome, dtIniciacao, nome, turmaInicial?.dtIniciacao, turmaInicial?.nome]);

  const salvarTurma = useCallback(() => {
    const valid = validarCampos();
    if (valid) {
      if (id === "novo") {
        setLoadingSalvar(true);
        api
          .post("turmas", {
            nome:
              blUsarNome === "nao-usar-nome"
                ? `Iniciação em ${convertDateToStringBR(dtIniciacao?.toString() || "")}`
                : nome,
            dtIniciacao,
          })
          .then(() => {
            showToast({ title: "Sucesso!", type: "success", description: "A turma foi criada." });
            history.push("/turma");
          })
          .catch((err) => {
            handleError({ error: err, action: "criar turma", knownErrors: turmaErrors });
          })
          .finally(() => setLoadingSalvar(false));
      } else {
        setLoadingSalvar(true);
        api
          .put(`turmas/${id}`, {
            nome:
              blUsarNome === "nao-usar-nome"
                ? `Iniciação em ${convertDateToStringBR(dtIniciacao?.toString() || "")}`
                : nome,
            dtIniciacao,
          })
          .then(() => {
            showToast({ title: "Sucesso!", type: "success", description: "A turma foi editada e salva." });
            history.push("/turma");
          })
          .catch((err) => {
            handleError({ error: err, action: "atualizar turma", knownErrors: turmaErrors });
          })
          .finally(() => setLoadingSalvar(false));
      }
    }
  }, [blUsarNome, dtIniciacao, handleError, history, id, nome, showToast, validarCampos]);

  const redefinirTurma = useCallback(() => {
    setIsDirty(false);
    if (id === "novo") {
      setBlUsarNome("sim-usar-nome");
      setNome(null);
      setDtIniciacao(new Date(Date.now()));
    } else {
      loadTurma();
    }
  }, [id, loadTurma]);

  const yearNavigatorTemplate = (e: any) => {
    return (
      <Dropdown
        value={e.value}
        options={e.options}
        onChange={(event) => e.onChange(event.originalEvent, event.value)}
        className="p-ml-2"
        style={{ lineHeight: 1 }}
      />
    );
  };

  const monthNavigatorTemplate = (e: any) => {
    return (
      <Dropdown
        value={e.value}
        options={e.options}
        onChange={(event) => e.onChange(event.originalEvent, event.value)}
        style={{ lineHeight: 1 }}
      />
    );
  };

  const openUrlNewTab = useCallback((url: string) => {
    const opened = window.open(url, "_blank", "noopener,noreferrer");
    if (opened) {
      opened.opener = null;
    }
  }, []);

  useEffect(() => {
    if (id !== "novo") {
      loadTurma();
    }
  }, [id, loadTurma]);

  return (
    <SimpleEntityPage showTopBar routeBack="/turma" isFormPage loading={loading}>
      <div className="p-grid p-flex-row p-jc-between">
        <div className="p-col-7 p-grid">
          {id !== "novo" && (
            <div className="p-mb-2 p-pt-3 p-pb-3">
              <Button
                className="p-ml-1"
                icon="fa-solid fa-file-pdf"
                label="Lista de Profanos da Iniciação"
                onClick={() => openUrlNewTab(`${PATH_FICHA_TURMA}${id}`)}
              />
              <Button
                className="p-ml-3"
                icon="fa-solid fa-file-pdf"
                label="Testamentos"
                onClick={() => openUrlNewTab(`${PATH_FICHA_TESTAMENTO}${id}`)}
              />
            </div>
          )}

          <div className="p-fluid p-col-12">
            <CustomFormField
              icon="fas fa-flag"
              labelWidth="4"
              htmlForDescription="usar-nome"
              description="Criar nome para turma"
              className="p-mb-4"
              inputBody={
                <div className="p-d-flex p-ai-center" style={{ paddingTop: 2, paddingBottom: 2 }}>
                  <RadioButton
                    value="sim-usar-nome"
                    inputId="sim-usar-nome"
                    onChange={(e) => {
                      setBlUsarNome(e.value);
                      setIsDirty(true);
                    }}
                    checked={blUsarNome === "sim-usar-nome"}
                  />
                  <label htmlFor="sim-usar-nome" className="p-ml-2">
                    Sim
                  </label>
                  <RadioButton
                    value="nao-usar-nome"
                    inputId="nao-usar-nome"
                    onChange={(e) => {
                      setBlUsarNome(e.value);
                      setNome(null);
                      const errorsLocal = _.cloneDeep(errors);
                      delete errorsLocal.nome;
                      setErrors(errorsLocal);
                      setIsDirty(true);
                    }}
                    checked={blUsarNome === "nao-usar-nome"}
                    className="p-ml-4"
                  />
                  <label htmlFor="nao-usar-nome" className="p-ml-2">
                    Não
                  </label>
                </div>
              }
              required
              errorMessage={errors.usarNome}
            />
            {blUsarNome === "sim-usar-nome" && (
              <CustomFormField
                icon="pi pi-users"
                labelWidth="4"
                htmlForDescription="nome"
                description="Nome da turma"
                inputBody={
                  <InputText
                    id="nome"
                    value={nome || ""}
                    type="text"
                    placeholder="Nome da Turma"
                    onChange={(e) => {
                      setNome(e.target.value);
                      const errorsLocal = _.cloneDeep(errors);
                      delete errorsLocal.nome;
                      setErrors(errorsLocal);
                      if (!_.isEqual(turmaInicial?.nome, e.target.value)) {
                        setIsDirty(true);
                      } else {
                        setIsDirty(false);
                      }
                    }}
                    maxLength={50}
                  />
                }
                required
                errorMessage={errors.nome}
              />
            )}
            <CustomFormField
              icon="pi pi-calendar"
              labelWidth="4"
              htmlForDescription="dtIniciacao"
              description="Data de Iniciação"
              inputBody={
                <Calendar
                  id="dtIniciacao"
                  placeholder="DD/MM/AAAA"
                  value={dtIniciacao}
                  onChange={(e) => {
                    setDtIniciacao(e.value);
                    if (!_.isEqual(turmaInicial?.dtIniciacao, formatDateToStringForSend(e.value?.toString()))) {
                      setIsDirty(true);
                    } else {
                      setIsDirty(false);
                    }
                  }}
                  monthNavigator
                  yearNavigator
                  yearRange="1800:2050"
                  monthNavigatorTemplate={monthNavigatorTemplate}
                  yearNavigatorTemplate={yearNavigatorTemplate}
                  locale="pt-br"
                  showIcon
                />
              }
              required
              errorMessage={errors.nascimento}
            />
          </div>
        </div>
      </div>
      {isDirty && (
        <FloatingSave
          saveCommand={salvarTurma}
          resetCommand={redefinirTurma}
          disabled={loadingSalvar || !validacao()}
          loadingOnSave={loadingSalvar}
        />
      )}
    </SimpleEntityPage>
  );
};

export default TurmaFormPage;
