/* eslint-disable no-param-reassign */
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { AutoComplete } from "primereact/autocomplete";
import { ColumnProps } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputMask } from "primereact/inputmask";
import { useHistory, useParams } from "react-router-dom";
import _ from "lodash";

import SimpleEntityDatatable from "../../../components/SimpleEntityDatatable";
import SimpleEntityPage from "../../../components/SimpleEntityPage";
import { nominatasErrors } from "../../../errors/nominatas";
import { useError } from "../../../hooks/error";
import api from "../../../services/api";
import IrmaoComponent from "../../../components/IrmaoComponent";
import CustomFormField from "../../../components/CustomFormField";
import FloatingSave from "../../../components/FloatingSave";
import { useToast } from "../../../hooks/toast";

interface Params {
  id: string;
}

interface IrmaoDTO {
  uuid: string;
  id: number;
  nome: string;
  foto?: string;
}

interface CargoDTO {
  id: number;
  nome: string;
  brasao: string;
  ordem: number;
}

interface CargoIrmaoDTO {
  cargo: CargoDTO;
  irmao: IrmaoDTO;
}

interface Nominata {
  id?: number;
  periodo: string;
  cargos: CargoIrmaoDTO[];
}

const NominataFormPage: React.FC = () => {
  const dt = useRef<DataTable>(null);
  const { handleError } = useError();
  const { id } = useParams<Params>();

  const [loading, setLoading] = useState(false);
  const [loadingDropdown, setLoadingDropdown] = useState(false);

  const [globalFilter, setGlobalFilter] = useState("");

  const [periodo, setPeriodo] = useState<string>("");
  const [cargosIrmaos, setCargosIrmaos] = useState<CargoIrmaoDTO[]>([]);
  const [cargosIrmaosInitial, setCargosIrmaosInitial] = useState<CargoIrmaoDTO[]>([]);
  const [irmaos, setIrmaos] = useState<IrmaoDTO[]>([]);
  const [filteredIrmaos, setFilteredIrmaos] = useState<IrmaoDTO[]>([]);
  const { showToast } = useToast();
  const history = useHistory();

  const loadData = useCallback(() => {
    setLoading(true);
    if (!id) {
      api
        .get<CargoIrmaoDTO[]>(`nominatas/cargos`)
        .then(({ data }) => {
          setCargosIrmaos(data);
          setCargosIrmaosInitial(data);
        })
        .catch((err) => {
          handleError({ error: err, action: "carregar cargos para a nominata", knownErrors: nominatasErrors });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      api
        .get<Nominata>(`nominatas/${id}`)
        .then(({ data }) => {
          setPeriodo(data.periodo);
          setCargosIrmaos(data.cargos);
          setCargosIrmaosInitial(data.cargos);
        })
        .catch((err) => {
          handleError({ error: err, action: "carregar informações da nominata", knownErrors: nominatasErrors });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [handleError, id]);

  const loadIrmaos = useCallback(() => {
    setLoadingDropdown(true);
    api
      .get<IrmaoDTO[]>(`dropdown/irmaos/nominatas`)
      .then(({ data }) => {
        setIrmaos(data);
      })
      .catch((err) => {
        handleError({ error: err, action: "carregar irmãos para nominata", knownErrors: nominatasErrors });
      })
      .finally(() => {
        setLoadingDropdown(false);
      });
  }, [handleError]);

  const cargoBodyTemplate = useCallback(({ cargo }: CargoIrmaoDTO) => {
    if (cargo) {
      return <IrmaoComponent nome={cargo.nome} foto={cargo.brasao} iconePadrao="pi pi-sitemap" />;
    }
    return "";
  }, []);

  const findIrmao = useCallback(
    (event: { query: string }) => {
      let tmpFilteredIrmaos;
      if (!event.query.trim().length) {
        tmpFilteredIrmaos = [...irmaos];
      } else {
        tmpFilteredIrmaos = irmaos.filter((irmao) => {
          return irmao.nome.toLowerCase().startsWith(event.query.toLowerCase());
        });
      }

      setFilteredIrmaos(tmpFilteredIrmaos);
    },
    [irmaos]
  );

  const irmaoBodyTemplate = useCallback(
    (rowData: CargoIrmaoDTO) => {
      return (
        <AutoComplete
          placeholder="Nome completo"
          suggestions={filteredIrmaos}
          field="nome"
          value={rowData.irmao || ""}
          disabled={!periodo}
          completeMethod={findIrmao}
          forceSelection
          onChange={(e) => {
            const cargosIrmaosLocal = _.cloneDeep(cargosIrmaos);
            const cargoFoundIndex = cargosIrmaosLocal.findIndex(
              (cargoIrmao) => cargoIrmao.cargo.id === rowData.cargo.id
            );
            if (cargoFoundIndex >= 0) {
              cargosIrmaosLocal[cargoFoundIndex].irmao = e.target.value;
              setCargosIrmaos(cargosIrmaosLocal);
            }
          }}
        />
      );
    },
    [cargosIrmaos, filteredIrmaos, findIrmao, periodo]
  );

  const columns = useMemo(
    (): ColumnProps[] => [
      {
        field: "cargo.nome",
        header: "Cargo",
        bodyClassName: "p-fluid",
        headerStyle: { width: "30%" },
        body: cargoBodyTemplate,
        sortable: false,
        sortField: "cargo.nome",
        style: { verticalAlign: "middle" },
      },
      {
        field: "irmao.nome",
        header: "Irmão",
        bodyClassName: "p-fluid",
        body: irmaoBodyTemplate,
        style: { verticalAlign: "middle" },
      },
    ],
    [cargoBodyTemplate, irmaoBodyTemplate]
  );

  const isDirty = useCallback(() => {
    return !_.isEmpty(cargosIrmaosInitial) && !_.isEmpty(periodo) && !_.isEqual(cargosIrmaos, cargosIrmaosInitial);
  }, [cargosIrmaos, cargosIrmaosInitial, periodo]);

  useEffect(() => {
    loadData();
    loadIrmaos();
  }, [loadIrmaos, loadData]);

  const saveNominata = useCallback(() => {
    if (id) {
      api
        .put(`/nominatas/${id}`, {
          periodo,
          cargos: cargosIrmaos,
        })
        .then(() => {
          showToast({ title: "Sucesso!", description: "A nominata foi criada e salva.", type: "success" });
          history.push("/nominata");
        })
        .catch((err) => {
          handleError({ error: err, action: "atualizar a nominata", knownErrors: nominatasErrors });
        });
    } else {
      api
        .post("/nominatas", {
          periodo,
          cargos: cargosIrmaos,
        })
        .then(() => {
          showToast({ title: "Sucesso!", description: "A nominata foi criada e salva.", type: "success" });
          history.push("/nominata");
        })
        .catch((err) => {
          handleError({ error: err, action: "salvar a nominata", knownErrors: nominatasErrors });
        });
    }
  }, [cargosIrmaos, handleError, history, id, periodo, showToast]);

  const redefinirNominata = useCallback(() => {
    setPeriodo("");
    loadData();
  }, [loadData]);

  return (
    <SimpleEntityPage
      showTopBar
      routeBack="/nominata"
      onGlobalFilterChange={(value) => setGlobalFilter(value)}
      isFormPage
      loading={loading || loadingDropdown}
    >
      <div className="p-grid flex-row-center p-flex-row p-jc-between">
        <div className="p-col-7 p-grid">
          <div className="p-fluid p-col-12">
            <CustomFormField
              icon="pi pi-calendar"
              htmlForDescription="periodo"
              description="Período"
              labelWidth="4"
              required
              inputBody={
                <InputMask
                  id="periodo"
                  mask="9999 - 9999"
                  value={periodo}
                  onChange={(e) => {
                    setPeriodo(e.target.value);
                  }}
                />
              }
            />
          </div>
        </div>
      </div>
      <SimpleEntityDatatable
        datatableRef={dt}
        value={cargosIrmaos}
        columns={columns}
        globalFilter={globalFilter}
        sortField="cargo.ordem"
        sortOrder={1}
        editMode="row"
        dataKey="id"
        paginator={false}
        style={{ marginBottom: 120 }}
      />
      {isDirty() && (
        <FloatingSave
          saveCommand={() => saveNominata()}
          resetCommand={() => redefinirNominata()}
          disabled={false}
          loadingOnSave={false}
        />
      )}
    </SimpleEntityPage>
  );
};

export default NominataFormPage;
