import React from "react";

type FormHeaderProps = {
  headerMessage?: string;
  className?: string;
};

const FormHeader: React.FC<FormHeaderProps> = ({ headerMessage, className }) => {
  return headerMessage ? (
    <div className={`form-header-container ${className || ""}`}>
      <div className="form-header-message">{headerMessage}</div>
    </div>
  ) : (
    <></>
  );
};

export default FormHeader;
