export type FormaPagamento =
  | "BOLETO"
  | "CREDIT_CARD"
  | "PIX"
  | "UNDEFINED"
  | "DEBIT_CARD"
  | "TRANSFER"
  | "DEPOSIT"
  | "IN_CASH";

export interface FormaPagamentoElement {
  label: string;
  labelAlt: string;
  parcela?: boolean;
  value: FormaPagamento;
}
type FormaPagamentoMap = {
  // eslint-disable-next-line no-unused-vars
  [name in FormaPagamento]: FormaPagamentoElement;
};

export const formaPagamentoMap: FormaPagamentoMap = {
  BOLETO: {
    label: "Boleto",
    labelAlt: "Apenas Pix ou boleto",
    value: "BOLETO",
  },
  CREDIT_CARD: {
    label: "Cartão de crédito",
    labelAlt: "Apenas cartão de crédito",
    parcela: true,
    value: "CREDIT_CARD",
  },
  PIX: {
    label: "Pix",
    labelAlt: "Pix",
    value: "PIX",
  },
  UNDEFINED: {
    label: "Indefinido",
    labelAlt: "Qualquer forma de pagamento",
    value: "UNDEFINED",
  },
  DEBIT_CARD: {
    label: "Cartão de débito",
    labelAlt: "Cartão de débito",
    value: "DEBIT_CARD",
  },
  TRANSFER: {
    label: "Transferência",
    labelAlt: "Transferência",
    value: "TRANSFER",
  },
  DEPOSIT: {
    label: "Depósito",
    labelAlt: "Depósito",
    value: "DEPOSIT",
  },
  IN_CASH: {
    label: "Avulso",
    labelAlt: "Avulso",
    value: "IN_CASH",
  },
};

export const formaPagamentoEParcelasString = (formaPagamento?: FormaPagamento, parcelas?: number): string => {
  if (formaPagamento) {
    const parcelasTxt = parcelas === 1 ? "à vista" : `em ${parcelas}x`;
    const formaTxt =
      formaPagamentoMap[formaPagamento].parcela && parcelas
        ? `${formaPagamentoMap[formaPagamento].label} (${parcelasTxt})`
        : `${formaPagamentoMap[formaPagamento].label}`;
    return formaTxt;
  }
  return "";
};
