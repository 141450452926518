import { removeOffsetFromDate } from "./convertDates";

// converte uma data em uma string (YYYY-MM-DD)
export function formatDateToStringForSend(data: string | undefined): string | undefined {
  if (data) {
    const dataRemoveOffset = removeOffsetFromDate(new Date(data)).toLocaleString("en-us");
    const dataF = new Date(dataRemoveOffset);
    const dia = dataF.getDate().toString();
    const diaF = dia.length === 1 ? `0${dia}` : dia;
    const mes = (dataF.getMonth() + 1).toString(); // +1 pois no getMonth Janeiro começa com zero.
    const mesF = mes.length === 1 ? `0${mes}` : mes;
    const anoF = dataF.getFullYear();
    return `${anoF}-${mesF}-${diaF}`;
  }
  return undefined;
}

export function formatDateToStringToSend(data: Date | undefined): string | undefined {
  if (data) {
    const date = removeOffsetFromDate(data);

    return date.toISOString().split("T")[0];
  }
  return undefined;
}

export function formatDateToStringForSendWithHours(data: Date | undefined): string | undefined {
  if (data) {
    // const date = removeOffsetFromDate(data);
    return data.toISOString();
  }
  return undefined;
}
