import { IAppError } from "./AppErrorInterfaces";

export const livroErrors: IAppError[] = [
  {
    code: "UUID inválido.",
    message: "Alteração inválida na url.",
  },
  {
    code: "Codigo ja cadastradado",
    title: "Código do exemplar já cadastro",
    message: "Modifique o código e tente novamente",
  },
  {
    code: "Livro não encontrado.",
    title: "Não foi possível entrar este livro",
    message: "Tente novamente ou entre em contato com um administrador",
  },
  {
    code: "Exemplar não encontrado.",
    title: "Não foi possível encontrar este exemplar",
    message: "Tente novamente ou entre em contato com um administrador",
  },
];
