import { configureStore } from "@reduxjs/toolkit";
import paginatorSlice from "./paginatorSlice";
import errorStateSlice from "./errorPageSlice";
import decSubsisInfosSlice from "./decSubsisInfosSlice";
import signatureSlice from "./signatureSlice";

export const store = configureStore({
  reducer: {
    paginator: paginatorSlice,
    docInfo: decSubsisInfosSlice,
    errorState: errorStateSlice,
    signature: signatureSlice,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
