import React from "react";

interface MessageErroProps {
  message?: string;
  messageClassName?: string;
}

const MessageErro: React.FC<MessageErroProps> = ({ message = "Este é um campo obrigatório", messageClassName }) => {
  return (
    <div className="p-d-flex p-jc-start p-mt-2 p-ai-center">
      <small className={`p-error p-mt-0 p-ml-1 ${messageClassName || ""}`}>{message}</small>
    </div>
  );
};

export default MessageErro;
