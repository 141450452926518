import React from "react";
import { Tag } from "primereact/tag";
import { Tooltip } from "primereact/tooltip";
import { v4 } from "uuid";

export type SituacaoCobrancaTipo =
  | "APROVADO"
  | "PENDENTE"
  | "PAGO"
  | "VENCIDO"
  | "ANISTIADO"
  | "GERADO"
  | "ERRO"
  | "ESTORNADO";

interface Props {
  situacao?: SituacaoCobrancaTipo;
  visualizado?: boolean;
}

export interface CobrancaSituacaoElement {
  label: string;
  class: string;
  tooltip: string;
  situacao: SituacaoCobrancaTipo;
}
type SituacaoMap = {
  // eslint-disable-next-line no-unused-vars
  [name in SituacaoCobrancaTipo]: CobrancaSituacaoElement;
};

export const situacoesCobrancaMap: SituacaoMap = {
  APROVADO: {
    label: "Aprovado",
    class: "situacao-cobranca-aprovado",
    tooltip: "O pagamento já foi aprovado. Aguardando recebimento do valor pela operadora.",
    situacao: "APROVADO",
  },
  PAGO: { label: "Pago", class: "situacao-cobranca-pago", tooltip: "Pagamento recebido.", situacao: "PAGO" },
  PENDENTE: {
    label: "Pendente",
    class: "situacao-cobranca-pendente",
    tooltip: "Aguardando pagamento.",
    situacao: "PENDENTE",
  },
  GERADO: {
    label: "Gerado",
    class: "situacao-cobranca-gerado",
    tooltip: "Aguardando processamento da cobrança.",
    situacao: "GERADO",
  },
  ERRO: {
    label: "Erro",
    class: "situacao-cobranca-erro",
    tooltip: "Ocorreu um erro durante o processamento da cobrança",
    situacao: "ERRO",
  },
  VENCIDO: {
    label: "Vencido",
    class: "situacao-cobranca-vencido",
    tooltip: "Pagamento atrasado.",
    situacao: "VENCIDO",
  },
  ESTORNADO: {
    label: "Estornado",
    class: "situacao-cobranca-erro",
    tooltip: "O valor da cobrança foi estornado",
    situacao: "ESTORNADO",
  },
  ANISTIADO: {
    label: "Anistiado",
    class: "situacao-cobranca-anistiado",
    tooltip: "O valor da cobrança foi anistiado",
    situacao: "ANISTIADO",
  },
};

const SituacaoCobranca: React.FC<Props> = ({ situacao, visualizado }) => {
  const id = v4();

  return situacao ? (
    <div className="situacao-cobranca-container">
      <div id={`cobranca-tag-${id}`}>
        <Tag
          className={`situacao-cobranca-tag ${situacoesCobrancaMap[situacao].class}`}
          value={situacoesCobrancaMap[situacao].label}
        />
      </div>
      {/** Desabilitando funcionalidade de cobrança visualizada porque a cobrança
       * será criada sob demanda ao selecionar forma de pagamento ou clicando em
       * gerar link de pagamento (tesoureiro) */
      /* {["PENDENTE", "VENCIDO"].includes(situacao) && visualizado !== undefined && (
        <div id={`cobranca-visualizada-${id}`}>
          <Tag
            icon={visualizado ? "pi pi-eye" : "pi pi-eye-slash"}
            className={`p-ml-1 situacao-cobranca-visualizado ${
              visualizado ? "situacao-cobranca-visualizado" : "situacao-cobranca-nao-visualizado"
            }`}
          />
        </div>
      )} */}

      {visualizado !== undefined && (
        <Tooltip
          target={`#cobranca-visualizada-${id}`}
          content={visualizado ? "Esta cobrança já foi visualizada" : "Esta cobrança ainda não foi visualizada"}
        />
      )}

      <Tooltip target={`#cobranca-tag-${id}`} content={situacoesCobrancaMap[situacao].tooltip} />
    </div>
  ) : (
    <></>
  );
};

export default SituacaoCobranca;
