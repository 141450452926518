/* eslint-disable react/require-default-props */
/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { ColumnProps } from "primereact/column";

import { useHistory } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Tag } from "primereact/tag";
import { Button } from "primereact/button";
import { confirmDialog } from "primereact/confirmdialog";
import _ from "lodash";
import { MultiSelect } from "primereact/multiselect";
import { useToast } from "../../../hooks/toast";
import api from "../../../services/api";
import SimpleEntityDatatable from "../../../components/SimpleEntityDatatable";
import SimpleEntityListPage from "../../../components/SimpleEntityPage";
import IrmaoComponent from "../../../components/IrmaoComponent";
import { useError } from "../../../hooks/error";
import { pecasArquiteturaErrors } from "../../../errors/pecas-arquitetura";
import { Grau } from "../../Irmao/IrmaoPage";

export interface PecaArquitetura {
  uuid: string;
  titulo: string;
  loja: string;
  author: string;
  authorPhoto: string;
  authorUuid: string;
  dtPublicacao: Date;
  dtDisponibilizacao: Date;
  tipoPublicacao: TiposPecas;
  arquivo: string;
  grauPeca: Grau;
}

export interface TiposPecasElement {
  label: string;
  class: string;
  tipo: TiposPecas;
}

export type TiposPecas = "NAO_PUBLICADO" | "PUBLICADO_LOJA" | "PUBLICADO_POTENCIA";

type TiposPecasMap = {
  // eslint-disable-next-line no-unused-vars
  [name in TiposPecas]: TiposPecasElement;
};

export const tiposPecasMap: TiposPecasMap = {
  NAO_PUBLICADO: {
    label: "Não publicado",
    class: "tag-tipo-peca tipo-peca-nao-publicado",
    tipo: "NAO_PUBLICADO",
  },
  PUBLICADO_LOJA: {
    label: "Loja",
    class: "tag-tipo-peca tipo-peca-publicado-na-loja",
    tipo: "PUBLICADO_LOJA",
  },
  PUBLICADO_POTENCIA: {
    label: "Potência",
    class: "tag-tipo-peca tipo-peca-publicado-na-potencia",
    tipo: "PUBLICADO_POTENCIA",
  },
};

const PecasArquiteturaPage: React.FC = () => {
  const dt = useRef<DataTable>(null);
  const [loading, setLoading] = useState(false);
  const [forms, setForms] = useState<PecaArquitetura[]>([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const tipoPecaFilterOptions: TiposPecasElement[] = useMemo((): TiposPecasElement[] => {
    return [tiposPecasMap.NAO_PUBLICADO, tiposPecasMap.PUBLICADO_LOJA, tiposPecasMap.PUBLICADO_POTENCIA];
  }, []);
  const [tipoPecaFilter, setTipoPecaFilter] = useState<TiposPecasElement[]>([]);
  const { showToast } = useToast();
  const history = useHistory();
  const { handleError } = useError();

  const loadData = useCallback(() => {
    setLoading(true);
    api
      .get<PecaArquitetura[]>(`pecas-arquitetura`)
      .then(({ data }) => {
        setForms(data);
      })
      .catch((err) => {
        showToast({
          type: "error",
          title: "Não foi possível carregar peças de arquitetura",
          description: "Tente novamente ou contate nosso time de suporte para solicitar ajuda.",
        });
        // eslint-disable-next-line no-console
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [showToast]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const authorBodyTemplate = useCallback((rowData: PecaArquitetura) => {
    return (
      <>
        <IrmaoComponent nome={rowData.author} foto={rowData.authorPhoto} />
      </>
    );
  }, []);

  const tipoPecaBodyTemplate = useCallback((rowData: PecaArquitetura) => {
    return (
      <Tag className={tiposPecasMap[rowData.tipoPublicacao].class}>{tiposPecasMap[rowData.tipoPublicacao].label}</Tag>
    );
  }, []);

  const dtPublicacaoBodyTemplate = useCallback(({ dtPublicacao }: PecaArquitetura) => {
    return dtPublicacao
      ? new Date(dtPublicacao).toLocaleDateString("pt-BR", {
          timeZone: "UTC",
        })
      : "";
  }, []);

  const dtDisponibilizacaoBodyTemplate = useCallback(({ dtDisponibilizacao }: PecaArquitetura) => {
    return dtDisponibilizacao
      ? new Date(dtDisponibilizacao).toLocaleDateString("pt-BR", {
          timeZone: "UTC",
        })
      : "";
  }, []);

  const excluirPecaArquitetura = useCallback(
    (data: PecaArquitetura) => {
      api
        .delete(`pecas-arquitetura/adm/${data.uuid}`)
        .then(() => {
          showToast({ title: "Sucesso!", type: "success", description: "A peça de arquitetura foi removida." });
          loadData();
        })
        .catch((err) => {
          handleError({ error: err, action: "remover peça de arquitetura", knownErrors: pecasArquiteturaErrors });
          // eslint-disable-next-line no-console
          console.log(err.response);
        });
    },
    [handleError, loadData, showToast]
  );

  const confirmExclusao = useCallback(
    (data: PecaArquitetura) => {
      confirmDialog({
        message: "Após a remoção o item não poderá ser recuperado.",
        header: "Remover peça de arquitetura?",
        icon: "pi pi-question-circle",
        focusOnShow: false,
        rejectLabel: "Manter",
        acceptLabel: "Remover",
        accept: () => excluirPecaArquitetura(data),
        reject: () => {},
      });
    },
    [excluirPecaArquitetura]
  );

  const editDeleteButtonsBodyTemplate = useCallback(
    (data: PecaArquitetura) => {
      return (
        <>
          <Button
            type="button"
            className="balandrau-action-button"
            icon="pi pi-pencil"
            onClick={() => {
              history.push({ pathname: `/pecas-arquitetura/${data.uuid}` });
            }}
          />
          <Button
            type="button"
            className="p-ml-3 balandrau-exclude-button"
            icon="pi pi-trash"
            onClick={() => {
              confirmExclusao(data);
            }}
          />
        </>
      );
    },
    [confirmExclusao, history]
  );

  const filterByTipo = useCallback((value: TiposPecas, filter: TiposPecasElement[]) => {
    if (value) return filter == null || _.isEmpty(filter) || filter.filter((f) => f.tipo === value).length > 0;
    return false;
  }, []);

  const columns = useMemo(
    (): ColumnProps[] => [
      {
        field: "tipoPublicacao",
        header: "Tipo",
        sortable: false,
        align: "center",
        style: { verticalAlign: "middle", width: "110px" },
        body: tipoPecaBodyTemplate,
        filterMatchMode: "custom",
        filterFunction: (value, filter) => filterByTipo(value, filter),
      },
      {
        field: "titulo",
        header: "Título",
        sortable: true,
        style: { textAlign: "left", verticalAlign: "middle", width: "25%" },
        className: "p-text-nowrap p-text-truncate",
        body: ({ arquivo, titulo }: PecaArquitetura) => {
          return (
            <a className="color-link" href={`pdf/peca/${arquivo}`} target="_blank" rel="noreferrer">
              {titulo}
            </a>
          );
        },
      },

      {
        field: "author",
        header: "Autor",
        sortable: true,
        style: { textAlign: "left", verticalAlign: "middle", width: "25%" },
        className: "p-text-nowrap p-text-truncate",
        body: authorBodyTemplate,
      },
      {
        field: "dtPublicacao",
        header: "Publicação",
        sortable: true,
        style: { textAlign: "left", verticalAlign: "middle", width: "160px" },
        className: "p-text-nowrap p-text-truncate",
        body: dtPublicacaoBodyTemplate,
      },
      {
        field: "dtDisponibilizacao",
        header: "Disponibilização",
        sortable: true,
        style: { textAlign: "left", verticalAlign: "middle", width: "160px" },
        className: "p-text-nowrap p-text-truncate",
        body: dtDisponibilizacaoBodyTemplate,
      },

      {
        align: "center",
        body: editDeleteButtonsBodyTemplate,
        style: { width: "100px", verticalAlign: "middle" },
      },
    ],
    [
      tipoPecaBodyTemplate,
      authorBodyTemplate,
      dtPublicacaoBodyTemplate,
      dtDisponibilizacaoBodyTemplate,
      editDeleteButtonsBodyTemplate,
      filterByTipo,
    ]
  );

  const tiposPecaFilterBodyTemplate = (
    <MultiSelect
      className="p-ml-2"
      showClear
      dataKey="tipo"
      optionLabel="label"
      options={tipoPecaFilterOptions}
      value={tipoPecaFilter}
      onChange={(e) => {
        dt.current?.filter(e.value, "tipoPublicacao", "custom");
        setTipoPecaFilter(e.target.value);
      }}
      placeholder="Filtrar por tipo de publicação"
      selectedItemsLabel="{0} tipos de publicação selecionados"
      maxSelectedLabels={2}
      emptyFilterMessage="Nenhum tipo encontrado"
      style={{ width: "180px" }}
      scrollHeight="400px"
    />
  );

  return (
    <SimpleEntityListPage
      showListHeader
      listHeaderContentRight={tiposPecaFilterBodyTemplate}
      newButtonLabel="Nova peça de arquitetura"
      onNewButtonCLick={() => {
        history.push("/pecas-arquitetura/novo");
      }}
      onGlobalFilterChange={(value) => setGlobalFilter(value)}
      loading={loading}
      showExportCsvButton
      datatableRef={dt}
    >
      <SimpleEntityDatatable
        datatableRef={dt}
        value={forms}
        columns={columns}
        globalFilter={globalFilter}
        sortField="titulo"
        sortOrder={1}
      />
    </SimpleEntityListPage>
  );
};

export default PecasArquiteturaPage;
