/* eslint-disable no-param-reassign */
import _ from "lodash";
import { Button } from "primereact/button";
import { ColumnEditorOptions, ColumnBodyOptions, ColumnProps } from "primereact/column";
import { confirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Tag } from "primereact/tag";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import SimpleEntityDatatable from "../../../components/SimpleEntityDatatable";
import SimpleEntityPage from "../../../components/SimpleEntityPage";
import { livroErrors } from "../../../errors/livro";
import { useError } from "../../../hooks/error";
import { useToast } from "../../../hooks/toast";
import api from "../../../services/api";
import {
  LivroFormResponse,
  Exemplares,
  conservacaoMap,
  situacaoLivroMap,
  origemMap,
  OrigemElement,
  ConservacaoElement,
} from "../AcervoFormPage";

interface Params {
  id: string;
}

const ExemplaresFormPage: React.FC = () => {
  const dt = useRef<DataTable>(null);
  const { handleError } = useError();
  const { id } = useParams<Params>();
  const { showToast } = useToast();

  const [loading, setLoading] = useState(false);
  const [isbn, setIsbn] = useState<string>("");
  const [titulo, setTitulo] = useState<string>("");
  const [autor, setAutor] = useState<string>("");
  const [edicao, setEdicao] = useState<string>("");
  const [editora, setEditora] = useState<string>("");

  const [globalFilter, setGlobalFilter] = useState("");

  const [exemplares, setExemplares] = useState<Exemplares[]>([]);
  const [exemplaresInitial, setExemplaresInitial] = useState<Exemplares[]>([]);

  const origemOptions = useMemo(
    (): OrigemElement[] => [origemMap.COMPRADO_PELA_BIBLIOTECA, origemMap.DOACAO, origemMap.INDEFINIDO],
    []
  );

  const conservacaoOptions = useMemo(
    (): ConservacaoElement[] => [conservacaoMap.NOVO, conservacaoMap.USADO, conservacaoMap.DANIFICADO],
    []
  );

  const loadLivro = useCallback(() => {
    setLoading(true);
    api
      .get<LivroFormResponse>(`biblioteca/livros/${id}`)
      .then(({ data }) => {
        setIsbn(data.isbn);
        setTitulo(data.titulo);
        setAutor(data.autor);
        setEdicao(data.edicao);
        setEditora(data.editora);

        const exemplaresEdit = data.codigos.map((ex) => {
          ex.situacaoForm = situacaoLivroMap[ex.situacao];
          ex.estadoForm = conservacaoMap[ex.estado];
          ex.origemForm = origemMap[ex.origem];
          ex.codigoForm = ex.codigo;
          return ex;
        });

        setExemplares(exemplaresEdit);
        setExemplaresInitial(exemplaresEdit);
      })
      .catch((err) => {
        handleError({ error: err, action: "carregar informações do exemplar", knownErrors: livroErrors });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [handleError, id]);

  const updateExemplar = useCallback(
    (data: Exemplares, options: ColumnBodyOptions, e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const rowEdited = exemplares.find((ex) => ex.uuid === data.uuid);

      if (rowEdited) {
        api
          .put(`biblioteca/livros/exemplar/${rowEdited.uuid}`, {
            estadoConservacao: rowEdited.estado,
            local: rowEdited.situacao === "LOCAL",
            origem: rowEdited.origem,
            codigo: rowEdited.codigoForm,
          })
          .then(() => {
            options?.rowEditor?.onSaveClick?.(e);
            showToast({ title: "Sucesso!", type: "success", description: "O exemplar foi editado e salvo." });
            const exemplaresCopy = _.cloneDeep(exemplares);
            const exemplaresInitialCopy = _.cloneDeep(exemplaresInitial);
            const indexExemplarEdited = exemplaresCopy.findIndex((ex) => ex.uuid === rowEdited.uuid);
            const indexExemplarInitial = exemplaresInitialCopy.findIndex((ex) => ex.uuid === rowEdited.uuid);

            if (indexExemplarEdited > -1 && indexExemplarInitial > -1) {
              exemplaresCopy[indexExemplarEdited] = rowEdited;
              exemplaresInitialCopy[indexExemplarInitial] = rowEdited;

              setExemplares(exemplaresCopy);
              setExemplaresInitial(exemplaresInitialCopy);
            }
          })
          .catch((err) => {
            handleError({ error: err, action: "editar exemplar", knownErrors: livroErrors });
          });
      }
    },
    [handleError, exemplares, showToast, exemplaresInitial]
  );

  const situacaoBodyTemplate = useCallback((data: Exemplares) => {
    if (data.situacao) {
      return <Tag className={situacaoLivroMap[data.situacao].class}>{situacaoLivroMap[data.situacao].label}</Tag>;
    }
    return ``;
  }, []);

  const conservacaoBodyTemplate = useCallback((data: Exemplares) => {
    return `${conservacaoMap[data.estado].label}`;
  }, []);

  const excluirExemplar = useCallback(
    (data: Exemplares) => {
      api
        .delete(`biblioteca/livros/exemplar/${data.uuid}`)
        .then(() => {
          showToast({ title: "Sucesso!", type: "success", description: "O exemplar foi removido." });
          loadLivro();
        })
        .catch((err) => {
          handleError({ error: err, action: "remover exemplar", knownErrors: livroErrors });
          // eslint-disable-next-line no-console
          console.log(err.response);
        });
    },
    [handleError, loadLivro, showToast]
  );

  const confirmExclusao = useCallback(
    (data: Exemplares) => {
      confirmDialog({
        message: "Após a remoção o item não poderá ser recuperado.",
        header: "Remover exemplar do acervo?",
        icon: "pi pi-question-circle",
        focusOnShow: false,
        rejectLabel: "Manter",
        acceptLabel: "Remover",
        accept: () => excluirExemplar(data),
        reject: () => {},
      });
    },
    [excluirExemplar]
  );

  const origemBodyTemplate = useCallback((data: Exemplares) => {
    if (data.origem) return origemMap[data.origem].label;
    return ``;
  }, []);

  const codigoEditor = useCallback(
    (options: ColumnEditorOptions) => {
      return (
        <InputText
          type="text"
          value={options.value}
          onChange={(e) => {
            return options.editorCallback?.(e.target.value);
          }}
          onBlur={(e) => {
            const exemplaresCopy = _.cloneDeep(exemplares);
            const index = exemplaresCopy.findIndex((ex) => ex.uuid === options.rowData.uuid);
            if (index !== -1) {
              exemplaresCopy[index].codigoForm = e.target.value;
              setExemplares(exemplaresCopy);
            }
          }}
        />
      );
    },
    [exemplares]
  );

  const origemEditor = useCallback(
    (options: ColumnEditorOptions) => {
      return (
        <Dropdown
          placeholder="Selecione a origem do exemplar"
          options={origemOptions}
          value={options.value}
          onChange={(e) => {
            const exemplaresCopy = _.cloneDeep(exemplares);
            const index = exemplaresCopy.findIndex((ex) => ex.uuid === options.rowData.uuid);
            if (index !== -1) {
              exemplaresCopy[index].origem = e.target.value.origem;
              exemplaresCopy[index].origemForm = e.target.value;
              setExemplares(exemplaresCopy);
            }

            return options.editorCallback?.(e.target.value);
          }}
          optionLabel="label"
        />
      );
    },
    [origemOptions, exemplares]
  );

  const conservacaoEditor = useCallback(
    (options: ColumnEditorOptions) => {
      return (
        <Dropdown
          placeholder="Selecione o estado de conservação do exemplar"
          options={conservacaoOptions}
          value={options.value}
          onChange={(e) => {
            const exemplaresCopy = _.cloneDeep(exemplares);
            const index = exemplaresCopy.findIndex((ex) => ex.uuid === options.rowData.uuid);
            if (index !== -1) {
              exemplaresCopy[index].estado = e.target.value.conservacao;
              exemplaresCopy[index].estadoForm = e.target.value;
              setExemplares(exemplaresCopy);
            }

            return options.editorCallback?.(e.target.value);
          }}
          optionLabel="label"
        />
      );
    },
    [conservacaoOptions, exemplares]
  );

  const [editingRows, setEditingRows] = useState({});

  const onRowEditChange = (e: { data: React.SetStateAction<{}> }) => {
    setEditingRows(e.data);
  };

  const setActiveRowIndex = useCallback(
    (index: number) => {
      const editingRowsModified = { ...editingRows, ...{ [`${exemplares[index].uuid}`]: true } };
      setEditingRows(editingRowsModified);
    },
    [editingRows, exemplares]
  );

  const onRowEditComplete = (e: { newData: any; index: any }) => {
    const exemplaresModied = [...exemplares];
    const { newData, index } = e;

    exemplaresModied[index] = newData;

    setExemplares(exemplaresModied);
  };

  const editDeleteButtonsBodyTemplate = useCallback(
    (data: Exemplares, options: ColumnBodyOptions) => {
      if (!options.rowEditor?.editing) {
        return (
          <>
            <Button
              type="button"
              className="balandrau-action-button"
              icon="pi pi-pencil"
              onClick={() => {
                setActiveRowIndex(exemplares.findIndex((ex) => ex.uuid === data.uuid));
              }}
            />
            <Button
              type="button"
              className="p-ml-3 balandrau-exclude-button"
              icon="pi pi-trash"
              onClick={() => {
                confirmExclusao(data);
              }}
            />
          </>
        );
      }
      return (
        <>
          <Button
            className={`${options?.rowEditor?.saveClassName}`}
            icon={`${options?.rowEditor?.saveIconClassName}`}
            onClick={(e) => {
              updateExemplar(data, options, e);
            }}
          />
          <Button
            className="p-ml-2 balandrau-exclude-button"
            icon={`${options?.rowEditor?.cancelIconClassName}`}
            onClick={(e) => {
              const indexExemplaresList = exemplares.findIndex((exList) => exList.uuid === data.uuid);
              const indexExemplaresInitial = exemplaresInitial.findIndex((exInitial) => exInitial.uuid === data.uuid);
              if (indexExemplaresList > -1 && indexExemplaresInitial > -1) {
                exemplares[indexExemplaresList] = exemplaresInitial[indexExemplaresInitial];
              }

              options?.rowEditor?.onCancelClick?.(e);
            }}
          />
        </>
      );
    },
    [confirmExclusao, exemplares, setActiveRowIndex, updateExemplar, exemplaresInitial]
  );

  const columns = useMemo(
    (): ColumnProps[] => [
      {
        field: "codigoForm",
        header: "Código",
        bodyClassName: "p-fluid",
        headerStyle: { width: "15%" },
        editor: codigoEditor,
        sortable: true,
        sortField: "codigo",
      },
      {
        field: "origemForm",
        header: "Origem",
        bodyClassName: "p-fluid",
        body: origemBodyTemplate,
        headerStyle: { width: "20%" },
        editor: origemEditor,
      },
      {
        field: "estadoForm",
        header: "Conservação",
        bodyClassName: "p-fluid",
        body: conservacaoBodyTemplate,
        // headerStyle: { width: "15%" },
        editor: conservacaoEditor,
      },
      {
        field: "situacaoForm",
        header: "Situação",
        bodyClassName: "p-fluid",
        body: situacaoBodyTemplate,
        align: "center",
        headerStyle: { width: "120px", textAlign: "center" },
        // editor: situacaoEditor,
        editor: (options) => situacaoBodyTemplate(options.rowData),
      },
      {
        align: "center",
        rowEditor: true,
        body: editDeleteButtonsBodyTemplate,
        style: { width: "120px", verticalAlign: "middle" },
      },
    ],
    [
      codigoEditor,
      origemBodyTemplate,
      conservacaoBodyTemplate,
      situacaoBodyTemplate,
      editDeleteButtonsBodyTemplate,
      conservacaoEditor,
      origemEditor,
    ]
  );

  useEffect(() => {
    if (id) {
      loadLivro();
    }
  }, [id, loadLivro]);

  return (
    <SimpleEntityPage
      showTopBar
      routeBack="/acervo"
      onGlobalFilterChange={(value) => setGlobalFilter(value)}
      isFormPage
      loading={loading}
    >
      <div className="background-exemplares p-mb-4">
        <div className="info-livro">
          <span>ISBN</span>
          <span>{isbn}</span>
        </div>

        <div className="info-livro">
          <span>Titulo</span>
          <span>{titulo}</span>
        </div>

        <div className="info-livro">
          <span>Autor</span>
          <span>{autor}</span>
        </div>

        <div className="info-livro">
          <span>Edição</span>
          <span>{edicao}</span>
        </div>

        <div className="info-livro">
          <span>Editora</span>
          <span>{editora}</span>
        </div>
      </div>
      <SimpleEntityDatatable
        datatableRef={dt}
        value={exemplares}
        columns={columns}
        globalFilter={globalFilter}
        sortField="codigo"
        sortOrder={1}
        editMode="row"
        dataKey="uuid"
        editingRows={editingRows}
        onRowEditChange={onRowEditChange}
        onRowEditComplete={onRowEditComplete}
      />
    </SimpleEntityPage>
  );
};

export default ExemplaresFormPage;
