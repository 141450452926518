/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";

import background from "../../assets/img/signin-background.jpg";
import CardDashboard from "../../components/CardDashboard";
import api from "../../services/api";
import CardDashboardFaturamento from "../../components/CardDashboardFaturamento";
import { useToast } from "../../hooks/toast";

export type CardProps<T> = { campo: T; total: number; valorTotal?: number; porcentagem?: string };
export type FaturamentoProps = {
  cobrancasPrevistas: number | null;
  previsto: number | null;
  cobrancasPagas: number | null;
  recebido: number | null;
};

export type IrmaoDashboardEnum = "REGULAR" | "IRREGULAR" | "REMIDO" | "DESLIGADO" | "ADORMECIDO";
export type CobrancaDashboardEnum = "PENDENTE" | "VENCIDO" | "PAGO" | "APROVADO";

interface DashboardResponse {
  irmaos: CardProps<IrmaoDashboardEnum>[];
  cobrancas: CardProps<CobrancaDashboardEnum>[];
  faturamentos: FaturamentoProps;
}

interface LojaInfosResponse {
  nomeLoja: string;
  numeroLoja: number;
  tituloLoja: string;
  tituloSiglaLoja: string;
  logoLoja: string;
}

export type IrmaoDashboardType = { [name in IrmaoDashboardEnum]: CardProps<IrmaoDashboardEnum> };
export type CobrancaDashboardType = { [name in CobrancaDashboardEnum]: CardProps<CobrancaDashboardEnum> };

const Dashboard: React.FC = () => {
  const { showToast } = useToast();
  const [lojaInfos, setLojaInfos] = useState<LojaInfosResponse>({
    logoLoja: "",
    nomeLoja: "",
    numeroLoja: 0,
    tituloLoja: "",
    tituloSiglaLoja: "",
  });
  const [faturamentos, setFaturamentos] = useState<FaturamentoProps | null>({} as FaturamentoProps);

  const irmaosInicial: IrmaoDashboardType = useMemo((): IrmaoDashboardType => {
    return {
      REGULAR: {
        campo: "REGULAR",
        total: 0,
        porcentagem: "0%",
      },
      IRREGULAR: {
        campo: "IRREGULAR",
        total: 0,
        porcentagem: "0%",
      },
      REMIDO: {
        campo: "REMIDO",
        total: 0,
        porcentagem: "0%",
      },
      DESLIGADO: {
        campo: "DESLIGADO",
        total: 0,
        porcentagem: "0%",
      },
      ADORMECIDO: {
        campo: "ADORMECIDO",
        total: 0,
        porcentagem: "0%",
      },
    } as IrmaoDashboardType;
  }, []);
  const [irmaosExibicao, setIrmaosExibicao] = useState<IrmaoDashboardType>(irmaosInicial);

  const cobrancasInicial: CobrancaDashboardType = useMemo((): CobrancaDashboardType => {
    return {
      PENDENTE: {
        campo: "PENDENTE",
        total: 0,
        porcentagem: "0%",
      },
      VENCIDO: {
        campo: "VENCIDO",
        total: 0,
        porcentagem: "0%",
      },
      PAGO: {
        campo: "PAGO",
        total: 0,
        porcentagem: "0%",
      },
      APROVADO: {
        campo: "APROVADO",
        total: 0,
        porcentagem: "0%",
      },
    } as CobrancaDashboardType;
  }, []);
  const [cobrancasExibicao, setCobrancasExibicao] = useState<CobrancaDashboardType>(cobrancasInicial);

  const loadDataDashboard = useCallback(() => {
    api
      .get<DashboardResponse>("dashboard")
      .then(({ data }) => {
        const irmaoFilter = data.irmaos.filter((irmao) =>
          ["REGULAR", "IRREGULAR", "REMIDO", "DESLIGADO", "ADORMECIDO"].includes(irmao.campo)
        );

        const cobrancasFilter = data.cobrancas.filter((cobranca) =>
          ["PENDENTE", "VENCIDO", "PAGO", "APROVADO"].includes(cobranca.campo)
        );

        const irmaosToShow: IrmaoDashboardType = irmaosInicial;
        irmaoFilter.forEach((irmao) => {
          irmaosToShow[irmao.campo] = irmao;
        });

        const cobrancasToShow: CobrancaDashboardType = cobrancasInicial;
        cobrancasFilter.forEach((cobranca) => {
          cobrancasToShow[cobranca.campo] = cobranca;
        });

        setIrmaosExibicao(irmaosToShow);
        setCobrancasExibicao(cobrancasToShow);
        setFaturamentos(data.faturamentos);
      })
      .catch(() => {
        showToast({
          type: "error",
          title: "Não foi possível carregar a tela de dashboard.",
          description: "Tente novamente ou contate nosso time de suporte para solicitar ajuda.",
        });
      });
  }, [cobrancasInicial, irmaosInicial, showToast]);

  const getNome = useCallback(() => {
    api
      .get<LojaInfosResponse>("dashboard/infoLoja")
      .then(({ data }) => {
        setLojaInfos(data);
      })
      .catch(() => {
        showToast({
          title: "Não foi possível carregar dados da Loja",
          type: "error",
          description: "Tente novamente ou contate nosso time de suporte para solicitar ajuda.",
        });
      });
  }, [showToast]);

  useEffect(() => {
    loadDataDashboard();
    getNome();
  }, [getNome, loadDataDashboard]);

  return (
    <>
      <div className="main-dashboard">
        <div className="dashboard-container">
          <img src={background} alt="background dashboard" className="background-image" />

          <div className="p-mb-5 dashboard-header-infos">
            {lojaInfos.logoLoja && (
              <img
                src={lojaInfos.logoLoja}
                alt={`Logo da loja ${lojaInfos.nomeLoja}`}
                style={{ width: "176px", marginRight: "16px" }}
              />
            )}
            <div className="dashboard-header-text">
              {!_.isEmpty(lojaInfos.tituloLoja) &&
                !_.isEmpty(lojaInfos.nomeLoja) &&
                lojaInfos.numeroLoja > 0 &&
                `${lojaInfos.tituloLoja} ${lojaInfos.nomeLoja} Nº ${
                  lojaInfos.numeroLoja < 10 ? `0${lojaInfos.numeroLoja}` : lojaInfos.numeroLoja
                }`}
              <br />
              <br />
              {/* Av. Leovegildo Coelho, 294, Centro */}
            </div>
          </div>

          <div className="dashboard-card-list">
            <CardDashboard
              icon="ba ba-usuario"
              title="Irmãos"
              values={[
                irmaosExibicao.REGULAR,
                irmaosExibicao.IRREGULAR,
                irmaosExibicao.REMIDO,
                irmaosExibicao.ADORMECIDO,
                irmaosExibicao.DESLIGADO,
              ]}
            />
            <CardDashboard
              icon="pi pi-wallet"
              title="Cobranças"
              values={[
                cobrancasExibicao.PENDENTE,
                cobrancasExibicao.VENCIDO,
                cobrancasExibicao.PAGO,
                cobrancasExibicao.APROVADO,
              ]}
            />
            <CardDashboardFaturamento icon="ba ba-faturamento" title="Faturamento do mês" value={faturamentos} />
          </div>
          <div className="dashborad-bottom">
            <div>
              Desenvolvido pelo Instituto de Inovação, Pesquisa e Desenvolvimento Científico e Tecnológico do Amazonas
            </div>
            <div>Av. Eduardo Ribeiro, 520, Edifício Manaus Shopping Center, sala 1512, Manaus/AM</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
