import { Grau } from "../Irmao/IrmaoPage";

export type TipoPublicacao = "LOJA" | "POTENCIA";

export interface TipoPublicacaoElement {
  label: string;
  tipo: TipoPublicacao;
}

type TipoPublicacaoMap = {
  // eslint-disable-next-line no-unused-vars
  [name in TipoPublicacao]: TipoPublicacaoElement;
};

export const tipoPublicacaoMap: TipoPublicacaoMap = {
  LOJA: {
    label: "Loja",
    tipo: "LOJA",
  },
  POTENCIA: {
    label: "Potencia",
    tipo: "POTENCIA",
  },
};

export interface Episodio {
  uuid: string;
  dataPublicacao: string;
  nome: string;
  duracao: string;
  descricao: string;
}

export interface Temporada {
  uuid: string;
  temporada: number;
  nome: string;
  descricao: string;
  episodios?: Episodio[];
}

export interface Podcast {
  uuid: string;
  dataCriacao: string;
  nome: string;
  thumbnail: string;
  publicado: boolean;
  tipo: TipoPublicacao;
  descricao: string;
  grau: Grau;
  temporadas?: Temporada[];
  criationDate?: Date;
}
