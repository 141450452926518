import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { confirmDialog } from "primereact/confirmdialog";
import { ColumnProps } from "primereact/column";

import SimpleEntityDatatable from "../../../../components/SimpleEntityDatatable";
import SimpleEntityListPage from "../../../../components/SimpleEntityPage";
import api from "../../../../services/api";
import { useToast } from "../../../../hooks/toast";
import { Podcast, Temporada } from "../../types";

interface Params {
  id: string;
}

const TemporadaPage: React.FC = () => {
  const history = useHistory();
  const { id } = useParams<Params>();
  const { showToast } = useToast();

  const dt = useRef<DataTable>(null);

  const [loading, setLoading] = useState(false);
  const [globalFilter, setGlobalFilter] = useState<string>("");

  const [programa, setPrograma] = useState({} as Podcast);
  const [temporadas, setTemporadas] = useState<Temporada[]>([]);

  const loadData = useCallback(() => {
    api
      .get<Podcast>(`podcast/${id}`)
      .then(({ data }) => {
        setTemporadas(data.temporadas || []);
        const programaFromRequest = { ...data, temporadas: undefined };
        setPrograma(programaFromRequest);
      })
      .catch(() => {
        showToast({
          type: "error",
          title: "Não foi possível carregar as temporadas",
          description: "Tente novamente ou contate nosso time de suporte para solicitar ajuda.",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id, showToast]);

  const onGlobalFilterChange = useCallback((filterValue: string) => {
    setGlobalFilter(filterValue);
  }, []);

  const excluirTemporada = useCallback(
    (data: Temporada) => {
      api
        .delete(`podcast/temporada/${data.uuid}`)
        .then(() => {
          showToast({ title: "Sucesso!", type: "success", description: "A temporada foi removida." });
          loadData();
        })
        .catch((err) => {
          showToast({
            title: "Não foi possível remover temporada",
            type: "error",
            description: "Tente novamente, ou entre em contato com um administrador",
          });
          // eslint-disable-next-line no-console
          console.log(err.response);
        });
    },
    [loadData, showToast]
  );

  const confirmExclusao = useCallback(
    (data: Temporada) => {
      confirmDialog({
        message: "Após a remoção o item não poderá ser recuperado.",
        header: "Remover temporada?",
        icon: "pi pi-question-circle",
        focusOnShow: false,
        rejectLabel: "Manter",
        acceptLabel: "Remover",
        accept: () => excluirTemporada(data),
        reject: () => {},
      });
    },
    [excluirTemporada]
  );

  const editDeleteButtonsBodyTemplate = useCallback(
    (data: Temporada) => {
      return (
        <>
          <Button
            type="button"
            className="balandrau-action-button"
            icon="pi pi-eye"
            tooltip="Episódios"
            tooltipOptions={{ position: "top" }}
            onClick={() => {
              history.push({ pathname: `/podcast/${id}/temporada/${data.uuid}/episodio` });
            }}
          />
          <Button
            type="button"
            className="p-ml-2 balandrau-action-button"
            icon="pi pi-pencil"
            onClick={() => {
              history.push({ pathname: `/podcast/${id}/temporada/${data.uuid}` });
            }}
          />
          <Button
            type="button"
            className="p-ml-2 balandrau-exclude-button"
            icon="pi pi-trash"
            onClick={() => {
              confirmExclusao(data);
            }}
          />
        </>
      );
    },
    [history, id, confirmExclusao]
  );

  const temporadasColumns = useMemo(
    (): ColumnProps[] => [
      {
        field: "temporada",
        header: "Nº da temporada",
        sortable: true,
        align: "center",
        style: { verticalAlign: "middle", width: 150 },
        className: "p-text-nowrap p-text-truncate",
        body: (rowData: { temporada: number }) => `${rowData?.temporada}`.padStart(2, "0"),
      },
      {
        field: "nome",
        header: "Nome",
        sortable: true,
        align: "left",
        style: { verticalAlign: "middle", maxWidth: "200px" },
        className: "p-text-nowrap p-text-truncate",
      },
      {
        field: "descricao",
        header: "Descrição",
        sortable: true,
        align: "left",
        style: { verticalAlign: "middle", maxWidth: "200px" },
        className: "p-text-nowrap p-text-truncate",
      },
      {
        align: "center",
        header: "Ações",
        body: editDeleteButtonsBodyTemplate,
        style: { width: "100px", verticalAlign: "middle" },
      },
    ],
    [editDeleteButtonsBodyTemplate]
  );

  useEffect(() => {
    setLoading(true);
    loadData();
  }, [loadData]);

  return (
    <SimpleEntityListPage
      showListHeader
      onGlobalFilterChange={(value) => onGlobalFilterChange(value)}
      loading={loading}
      showExportCsvButton
      datatableRef={dt}
      newButtonLabel="Nova temporada"
      onNewButtonCLick={() => {
        history.push(`/podcast/${id}/temporada/novo`);
      }}
    >
      <div
        className="p-mb-4 p-grid"
        style={{ margin: 0, borderRadius: 7, minWidth: "100%", backgroundColor: "#404040", padding: 17 }}
      >
        {/* <div className="p-col-2 p-d-flex p-flex-column">
          <span className="p-text-bold" style={{ color: "white" }}>
            Thumbnail do programa
          </span>
          <img
            src={programa.thumbnail}
            style={{ width: 80, height: 80, borderRadius: 10, marginRight: 10 }}
            alt={`Imagem do programa ${programa.nome}`}
            className="p-mt-2"
          />
        </div> */}

        <div className="p-col-2 p-d-flex p-flex-column">
          <span className="p-text-bold" style={{ color: "white" }}>
            Nome do programa
          </span>
          <div className="p-mt-2">
            <span style={{ lineHeight: "16px" }}>{programa.nome}</span>
          </div>
        </div>

        <div className="p-col p-d-flex p-flex-column">
          <span className="p-text-bold" style={{ color: "white" }}>
            Descrição do programa
          </span>
          <div className="p-mt-2">
            <pre className="p-text-wrap" style={{ lineHeight: "16px", whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
              {programa.descricao}
            </pre>
          </div>
        </div>

        <div className="p-col-fixed p-d-flex p-flex-column">
          <span className="p-text-bold" style={{ color: "white" }}>
            Data da criação do programa
          </span>
          <div className="p-mt-2 p-d-flex p-jc-center">
            <span style={{ lineHeight: "16px", textAlign: "center" }}>{programa.dataCriacao}</span>
          </div>
        </div>
      </div>

      <SimpleEntityDatatable
        datatableRef={dt}
        value={temporadas}
        columns={temporadasColumns}
        globalFilter={globalFilter}
        sortField="temporada"
        sortOrder={1}
      />
    </SimpleEntityListPage>
  );
};

export default TemporadaPage;
