import { IAppError } from "./AppErrorInterfaces";

export const enderecoErrors: IAppError[] = [
  {
    code: "Cep deve ter 8 caracteres numericos",
    title: "Não foi possível consultar este CEP",
    message: "O CEP deve conter exatos 10 dígitos.",
  },
  {
    code: "Cep não encontrado!",
    title: "Não foi possível consultar este CEP",
    message: "Este CEP não existe em nosso banco de dados.",
  },
];
