import React, { useCallback, useEffect, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import _ from "lodash";
import { useHistory } from "react-router-dom";

import SimpleEntityPage from "../../../components/SimpleEntityPage";
import CustomFormField from "../../../components/CustomFormField";
import FloatingSave from "../../../components/FloatingSave";
import api from "../../../services/api";
import { useToast } from "../../../hooks/toast";

interface ProfanoDropdown {
  id: number;
  nome: string;
  uuid: string;
}

const EscrutinioFormPage: React.FC = () => {
  const { showToast } = useToast();
  const history = useHistory();

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingSalvar, setLoadingSalvar] = useState<boolean>(false);

  const [errors, setErrors] = useState<{ [name: string]: string }>({});

  const [profano, setProfano] = useState({} as ProfanoDropdown);
  // const [totalEscrutinadores, setTotalEscrutinadores] = useState<number>(0);
  const [dropdownOptions, setDropdownOptions] = useState<ProfanoDropdown[]>([]);

  const redefinir = useCallback(() => {
    setProfano({} as ProfanoDropdown);
  }, []);

  const validacao = useCallback(() => {
    return !_.isEmpty(profano);
  }, [profano]);

  const isDirty = useCallback((): boolean => {
    return !_.isEmpty(profano);
  }, [profano]);

  const salvarNovoEstrunio = useCallback(() => {
    if (validacao()) {
      setLoadingSalvar(true);
      api
        .post("escrutinio", {
          idProfano: profano.id,
          // totalParticipantes: totalEscrutinadores,
        })
        .then(() => {
          showToast({ title: "Sucesso!", type: "success", description: "O escrutínio foi criado." });
          history.push("/escrutinio");
        })
        .catch(() => {
          showToast({
            type: "error",
            title: "Não foi possível salvar o novo escrutínio",
            description: "Tente novamente ou contate nosso time de suporte para solicitar ajuda.",
          });
        })
        .finally(() => {
          setLoadingSalvar(false);
        });
    }
  }, [history, profano.id, showToast, validacao]);

  const loadDropdown = useCallback(() => {
    setLoading(true);
    api
      .get<ProfanoDropdown[]>("escrutinio/profanos")
      .then((res) => {
        setDropdownOptions(res.data);
      })
      .catch(() => {})
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    loadDropdown();
  }, [loadDropdown]);

  return (
    <SimpleEntityPage showTopBar routeBack="/escrutinio" isFormPage loading={loading}>
      <div className="escrutinio-form-page">
        <div className="p-grid p-flex-row p-jc-between">
          <div className="p-col-7 p-grid">
            <div className="p-fluid p-col-12">
              <CustomFormField
                icon="pi pi-angle-double-up"
                htmlForDescription="profanos"
                description="Profanos"
                inputBody={
                  <Dropdown
                    id="profanos"
                    value={profano}
                    emptyMessage="Nenhum profano encontrado."
                    options={dropdownOptions}
                    onChange={(e) => {
                      setProfano(e.target.value);
                      const errorsLocal = _.cloneDeep(errors);
                      delete errorsLocal.profano;
                      setErrors(errorsLocal);
                      // if (!_.isEqual(irmaoInicial.grau, e.target.value.grau)) {
                      //   setIsDirty(true);
                      // } else {
                      //   setIsDirty(false);
                      // }
                    }}
                    optionLabel="nome"
                    // itemTemplate={(item: GrauElement) => `${item.label} (${item.nivelStr})`}
                    // valueTemplate={(item: GrauElement) => `${item.label} (${item.nivelStr})`}
                  />
                }
                required
                errorMessage={errors.grau}
              />
              {/* <CustomFormField
                icon="pi pi-users"
                htmlForDescription="totalEscrutinadores"
                description="Total de Escrutinadores"
                inputBody={
                  <InputNumber
                    inputId="totalEscrutinadores"
                    style={{ width: "150px" }}
                    value={totalEscrutinadores}
                    onValueChange={(e) => {
                      setTotalEscrutinadores(e.value);
                      const errorsLocal = _.cloneDeep(errors);
                      delete errorsLocal.totalEscrutinadores;
                      setErrors(errorsLocal);
                    }}
                    showButtons
                    buttonLayout="horizontal"
                    step={1}
                    // decrementButtonClassName="p-button-danger"
                    // incrementButtonClassName="p-button-success"
                    inputClassName="p-text-center"
                    incrementButtonIcon="pi pi-plus"
                    decrementButtonIcon="pi pi-minus"
                    min={0}
                  />
                }
                required
                errorMessage={errors.totalEscrutinadores}
              /> */}
            </div>
          </div>
        </div>
      </div>
      {isDirty() && (
        <FloatingSave
          resetCommand={() => redefinir()}
          disabled={!validacao()}
          saveCommand={() => salvarNovoEstrunio()}
          loadingOnSave={loadingSalvar}
        />
      )}
    </SimpleEntityPage>
  );
};

export default EscrutinioFormPage;
