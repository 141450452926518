import { IAppError } from "./AppErrorInterfaces";

export const podcastErrors: IAppError[] = [
  {
    code: "UUID inválido.",
    message: "Alteração inválida na url.",
  },
  {
    code: "Irmão não encontrado",
    title: "Irmão não encontrado.",
    message: "Tente novamente ou entre em contato com um administrador",
  },
  {
    code: "Programa não encontrado.",
    title: "Não foi possível encontrar este programa",
    message: "Você não tem permissão ou programa não existe.",
  },
];
