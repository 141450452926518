import { IAppError } from "./AppErrorInterfaces";

export const turmaErrors: IAppError[] = [
  {
    code: "UUID inválido.",
    message: "Alteração inválida na url.",
  },
  {
    code: "Irmão não encontrado.",
    title: "Irmão não encontrado.",
    message: "Entre em contato com nosso time de suporte para solicitar ajuda.",
  },
  {
    code: "Turma está em uso por um profano.",
    title: "Existem profanos nesta turma.",
    message: "Remova os profanos e tente novamente.",
  },
];
