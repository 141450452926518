/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
import { Column, ColumnProps } from "primereact/column";
import { DataTable, DataTableProps } from "primereact/datatable";
import React, { RefObject } from "react";
import { paginatorTemplate } from "../../utils/paginatorTemplate";

export interface SimpleEntityDatatableProps {
  columns?: ColumnProps[];
  datatableRef?: RefObject<DataTable>;
}

const SimpleEntityDatatable: React.FC<SimpleEntityDatatableProps & DataTableProps> = ({
  columns = [],
  datatableRef,
  children,
  ...otherProps
}) => {
  return (
    <DataTable
      ref={datatableRef}
      emptyMessage={<p>Nenhum registro encontrado</p>}
      stripedRows
      paginator
      paginatorTemplate={paginatorTemplate}
      currentPageReportTemplate="{first} - {last} de {totalRecords}"
      paginatorClassName="p-col-12 p-jc-center"
      rows={50}
      rowsPerPageOptions={[10, 20, 50]}
      showGridlines
      resizableColumns
      csvSeparator=";"
      {...otherProps}
    >
      {columns.map((column, i) => (
        <Column key={`column${i}`} {...column} />
      ))}
      {children}
    </DataTable>
  );
};

export default SimpleEntityDatatable;
