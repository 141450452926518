// converte um arquivo para o formato de base64
export type pdfBlob = string | ArrayBuffer | null;

export function convertFileToBase64(file: File) {
  return new Promise<pdfBlob>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
