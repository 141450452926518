import React from "react";
import { CardProps, CobrancaDashboardEnum, IrmaoDashboardEnum } from "../../pages/DashboardPage";

export type CardDashboardTipo = IrmaoDashboardEnum | CobrancaDashboardEnum;

interface Props {
  title: string;
  values?: CardProps<CardDashboardTipo>[];
  icon: string;
}

export interface CardDashboardElement {
  label: string;
  class: string;
  situacao: CardDashboardTipo;
}

type CardDashboardMap = {
  // eslint-disable-next-line no-unused-vars
  [name in CardDashboardTipo]: CardDashboardElement;
};

const cardDashboardMap: CardDashboardMap = {
  DESLIGADO: {
    label: "Desligados",
    class: "dashboard-color-desligado",
    situacao: "DESLIGADO",
  },
  REGULAR: {
    label: "Regulares",
    class: "dashboard-color-regular",
    situacao: "REGULAR",
  },
  IRREGULAR: {
    label: "Irregulares",
    class: "dashboard-color-irregular",
    situacao: "IRREGULAR",
  },
  REMIDO: {
    label: "Remidos",
    class: "dashboard-color-remido",
    situacao: "REMIDO",
  },
  ADORMECIDO: {
    label: "Adormecidos",
    class: "dashboard-color-adormecido",
    situacao: "ADORMECIDO",
  },
  PENDENTE: {
    label: "Pendentes",
    class: "dashboard-color-pendente",
    situacao: "PENDENTE",
  },
  VENCIDO: {
    label: "Vencidas",
    class: "dashboard-color-vencido",
    situacao: "VENCIDO",
  },
  PAGO: {
    label: "Pagas",
    class: "dashboard-color-pago",
    situacao: "PAGO",
  },
  APROVADO: {
    label: "Aprovadas",
    class: "dashboard-color-aprovado",
    situacao: "APROVADO",
  },
};

const CardDashboard: React.FC<Props> = ({ icon, title, values }) => {
  return (
    <div>
      <div className="p-mb-1 title-dashboard">
        <i className={`p-mr-2 ${icon}`} />
        {title}
      </div>
      <div className="dashboard-card-container">
        <div className="p-text-nowrap p-text-truncate">
          {values &&
            values.map(({ total, campo, porcentagem }) => {
              return (
                <div key={`${campo}`} className={`p-d-flex p-ai-center linha-card ${cardDashboardMap[campo].class}`}>
                  <div className="p-pb-1 dashboard-text-value">{total}</div>
                  <div className="p-pb-1 dashboard-text-porcentage p-mr-2">{porcentagem}</div>
                  <div className="p-pb-1 dashboard-text-campo">{cardDashboardMap[campo].label}</div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default CardDashboard;
