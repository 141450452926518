import React from "react";
import InfoComponent, { InfoComponentProps } from "../InfoComponent";
import MessageErro from "../MessageErro";

interface Props {
  icon?: string;
  iconMaterial?: string;
  info?: InfoComponentProps;
  description: string;
  htmlForDescription: string;
  required?: boolean;
  inputBody?: React.ReactNode;
  labelWidth?: "2" | "3" | "4" | "5" | "6" | "7" | "8" | "9";
  errorMessage?: string;
  className?: string;
}

const CustomFormField: React.FC<Props> = ({
  icon = "pi pi-question",
  iconMaterial = undefined,
  info,
  description,
  inputBody,
  required = false,
  htmlForDescription,
  labelWidth = "3",
  errorMessage,
  className = "",
}) => {
  return (
    <div>
      <div className={`p-field p-grid p-ai-center ${className}`}>
        <div className={`p-col-12 p-md-${labelWidth}`}>
          <div className="flex-row-center">
            {!iconMaterial ? (
              <i className={`${icon} svg-icon`} />
            ) : (
              <span className="material-symbols-rounded svg-icon">{iconMaterial}</span>
            )}
            <label htmlFor={htmlForDescription}>
              {description}
              {required && " *"}
            </label>
          </div>
        </div>
        <div className={`p-col-12 p-md-${12 - Number(labelWidth) - 1}`}>
          {inputBody}
          {errorMessage && <MessageErro message={errorMessage} />}
        </div>
        <InfoComponent className="p-col-12 p-md-1 " {...info} />
      </div>
    </div>
  );
};

export default CustomFormField;
