import React, { useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { Dropdown } from "primereact/dropdown";
import { useHistory, useParams } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { RadioButton } from "primereact/radiobutton";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { useToast } from "../../../hooks/toast";
import { useError } from "../../../hooks/error";
import api from "../../../services/api";
import CustomVerticalFormField from "../../../components/CustomVerticalFormField";
import SimpleEntityPage from "../../../components/SimpleEntityPage";
import { livroErrors } from "../../../errors/livro";
import { Livro } from "../AcervoPage";

interface Params {
  id: string;
}

export interface SituacaoLivroElement {
  label: string;
  class: string;
  situacao: SituacaoLivro;
}

export type SituacaoLivro = "DISPONIVEL" | "PERDIDO" | "LOCAL" | "EMPRESTADO" | "REPARO";

type SituacaoLivroMap = {
  // eslint-disable-next-line no-unused-vars
  [name in SituacaoLivro]: SituacaoLivroElement;
};

export const situacaoLivroMap: SituacaoLivroMap = {
  DISPONIVEL: {
    label: "Disponível",
    class: "tag-situacao-livro situacao-livro-dis",
    situacao: "DISPONIVEL",
  },
  PERDIDO: {
    label: "Perdido",
    class: "tag-situacao-livro situacao-livro-per",
    situacao: "PERDIDO",
  },
  LOCAL: {
    label: "Local",
    class: "tag-situacao-livro situacao-livro-loc",
    situacao: "LOCAL",
  },
  EMPRESTADO: {
    label: "Emprestado",
    class: "tag-situacao-livro situacao-livro-emp",
    situacao: "EMPRESTADO",
  },
  REPARO: {
    label: "Em reparo",
    class: "tag-situacao-livro situacao-livro-rep",
    situacao: "REPARO",
  },
};

export interface LivroFormResponse {
  uuid: string;
  isbn: string;
  titulo: string;
  autor: string;
  editora: string;
  edicao: string;
  ano: string;
  idioma: Idioma;
  categoria: string;
  codigos: Exemplares[];
}

export interface Exemplares {
  uuid: string;
  codigo: string;
  codigoForm: string;
  estado: Conservacao;
  estadoForm: ConservacaoElement;
  situacao: SituacaoLivro;
  situacaoForm: SituacaoLivroElement;
  origem: Origem;
  origemForm: OrigemElement;
}

interface IdiomaElement {
  label: string;
  idioma: Idioma;
}

type Idioma = "portugues" | "ingles" | "espanhol" | "outro";

type IdiomaMap = {
  // eslint-disable-next-line no-unused-vars
  [name in Idioma]: IdiomaElement;
};

export const idiomaMap: IdiomaMap = {
  portugues: {
    label: "Português",
    idioma: "portugues",
  },
  ingles: {
    label: "Inglês",
    idioma: "ingles",
  },
  espanhol: {
    label: "Espanhol",
    idioma: "espanhol",
  },
  outro: {
    label: "Outro",
    idioma: "outro",
  },
};

interface ResponseDropdowns {
  autores: string[];
  categorias: string[];
  editoras: string[];
  titulos: string[];
}

export interface ConservacaoElement {
  label: string;
  conservacao: Conservacao;
}

export type Conservacao = "NOVO" | "USADO" | "DANIFICADO";

type ConservacaoMap = {
  // eslint-disable-next-line no-unused-vars
  [name in Conservacao]: ConservacaoElement;
};

export const conservacaoMap: ConservacaoMap = {
  NOVO: {
    label: "Novo",
    conservacao: "NOVO",
  },
  USADO: {
    label: "Usado",
    conservacao: "USADO",
  },
  DANIFICADO: {
    label: "Danificado",
    conservacao: "DANIFICADO",
  },
};

export interface OrigemElement {
  label: string;
  origem: Origem;
}
export type Origem = "DOACAO" | "COMPRADO_PELA_BIBLIOTECA" | "INDEFINIDO";
type OrigemMap = {
  // eslint-disable-next-line no-unused-vars
  [name in Origem]: OrigemElement;
};

export const origemMap: OrigemMap = {
  DOACAO: {
    label: "Doação",
    origem: "DOACAO",
  },
  COMPRADO_PELA_BIBLIOTECA: {
    label: "Comprado pela Biblioteca",
    origem: "COMPRADO_PELA_BIBLIOTECA",
  },
  INDEFINIDO: {
    label: "Indefinido",
    origem: "INDEFINIDO",
  },
};

type Expansible = "DADOS_BASICOS" | "PUBLICACAO" | "EXEMPLAR";

interface ExpansibleProps {
  open: boolean;
}

const AcervoFormPage: React.FC = () => {
  const { handleError } = useError();
  const { id } = useParams<Params>();

  const [errors, setErrors] = useState<{ [campo: string]: string }>({});

  const history = useHistory();
  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [loadingSalvar, setLoadingSalvar] = useState(false);

  // estados dos campos do formulário
  const [isbn, setIsbn] = useState<string>("");
  const [titulo, setTitulo] = useState<string>("");
  const [autor, setAutor] = useState<string>("");
  const [edicao, setEdicao] = useState<string>("");

  const [editora, setEditora] = useState<string>("");
  const [anoPublicacao, setAnoPublicacao] = useState<string>("");
  const [idioma, setIdioma] = useState<IdiomaElement>();
  const [categoria, setCategoria] = useState<string>("");

  const [origem, setOrigem] = useState<OrigemElement>(origemMap.INDEFINIDO);
  const [conservacao, setConservacao] = useState<ConservacaoElement>(conservacaoMap.NOVO);
  const [livroLocal, setLivroLocal] = useState<"livro-local-nao" | "livro-local-sim">("livro-local-nao");
  const [codigoLivro, setCodigoLivro] = useState<string>("");

  const [uuidUltimoLivro, setUuidUltimoLivro] = useState(localStorage.getItem("@Balandrau: ultimoLivroCad"));
  const [addLivroAnterior, setAddLivroAnterior] = useState<boolean>(false);

  const [livroAnterior, setLivroAnterior] = useState<Livro>();
  const [livrosOptions, setLivrosOptions] = useState<Livro[]>([]);

  const [autoresOptions, setAutoresOptions] = useState<string[]>([]);
  const [categoriasOptions, setCategoriasOptions] = useState<string[]>([]);
  const [editorasOptions, setEditorasOptions] = useState<string[]>([]);
  const [titulosOptions, setTitulosOptions] = useState<string[]>([]);

  // eslint-disable-next-line no-unused-vars
  const [expanded, setExpanded] = useState<{ [expanded in Expansible]: ExpansibleProps }>({
    PUBLICACAO: { open: true },
    DADOS_BASICOS: { open: true },
    EXEMPLAR: { open: true },
  });

  const loadOptions = useCallback(() => {
    api
      .get(`biblioteca/livros`)
      .then(({ data }) => {
        setLivrosOptions(data);
      })
      .catch((err) => {
        showToast({
          type: "error",
          title: "Não foi possível carregar o acervo",
          description: "Tente novamente ou contate nosso time de suporte para solicitar ajuda.",
        });
        // eslint-disable-next-line no-console
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [showToast]);

  const idiomaOptions = useMemo(
    (): IdiomaElement[] => [idiomaMap.portugues, idiomaMap.ingles, idiomaMap.espanhol, idiomaMap.outro],
    []
  );

  const conservacaoOptions = useMemo(
    (): ConservacaoElement[] => [conservacaoMap.NOVO, conservacaoMap.USADO, conservacaoMap.DANIFICADO],
    []
  );

  const origemOptions = useMemo(
    (): OrigemElement[] => [origemMap.COMPRADO_PELA_BIBLIOTECA, origemMap.DOACAO, origemMap.INDEFINIDO],
    []
  );

  const isValidForm = useCallback(() => {
    const localErrors = {} as { [campo: string]: string };

    if (!isbn || _.isEmpty(isbn.trim())) {
      localErrors.isbn = "O campo ISBN é obrigatório";
    }
    if (!titulo || _.isEmpty(titulo.trim())) {
      localErrors.titulo = "O campo título é obrigatório.";
    }
    if (!autor || _.isEmpty(autor.trim())) {
      localErrors.autor = "O campo autor é obrigatório";
    }
    if (!edicao || _.isEmpty(edicao.trim())) {
      localErrors.edicao = "O campo edição é obrigatório";
    }
    if (!editora || _.isEmpty(editora.trim())) {
      localErrors.editora = "O campo editora é obrigatório";
    }
    if (Number.isNaN(parseInt(anoPublicacao, 10))) {
      localErrors.anoPublicacao = "O campo ano de publicação necessita de uma data válida";
    }
    if (!idioma || _.isEmpty(idioma)) {
      localErrors.idioma = "O campo idioma é obrigatório";
    }

    if (!categoria || _.isEmpty(categoria.trim())) {
      localErrors.categoria = "O campo categoria é obrigatório";
    }

    if (!id) {
      if (!conservacao || _.isEmpty(conservacao)) {
        localErrors.conservacao = "O campo conservação é obrigatório";
      }
      if (!livroLocal || _.isEmpty(livroLocal)) {
        localErrors.livroLocal = "O campo livro local é obrigatório";
      }
      if (!codigoLivro || _.isEmpty(codigoLivro.trim())) {
        localErrors.codigoLivro = "O campo código do livro é obrigatório";
      }
    }

    setErrors(localErrors);
    return _.isEmpty(localErrors);
  }, [
    anoPublicacao,
    autor,
    categoria,
    codigoLivro,
    conservacao,
    edicao,
    editora,
    idioma,
    isbn,
    livroLocal,
    titulo,
    id,
  ]);

  const confirmarCadastro = useCallback(() => {
    if (isValidForm()) {
      setLoadingSalvar(true);

      if (!id) {
        api
          .post<LivroFormResponse>("biblioteca/livros", {
            isbn,
            titulo,
            autor,
            edicao,
            editora,
            ano: anoPublicacao,
            idioma: idioma?.idioma,
            categoria,
            estadoConservacao: conservacao.conservacao,
            local: livroLocal === "livro-local-sim",
            codigo: codigoLivro,
            origem: origem.origem,
          })
          .then(({ data }) => {
            showToast({
              title: "Sucesso!",
              type: "success",
              description: "Um novo livro foi adicionado no acervo.",
            });
            localStorage.setItem("@Balandrau: ultimoLivroCad", data.uuid);
            setUuidUltimoLivro(data.uuid);
            history.push("/acervo");
          })
          .catch((err: any) => {
            handleError({ error: err, action: "cadastrar livro", knownErrors: livroErrors });
          })
          .finally(() => setLoadingSalvar(false));
      } else {
        api
          .put(`biblioteca/livros/${id}`, {
            isbn,
            titulo,
            autor,
            edicao,
            editora,
            ano: anoPublicacao,
            idioma: idioma?.idioma,
            categoria,
          })
          .then(() => {
            showToast({
              title: "Sucesso!",
              type: "success",
              description: "O Livro foi editado.",
            });
            history.push("/acervo");
          })
          .catch((err: any) => {
            handleError({ error: err, action: "alterar livro", knownErrors: livroErrors });
          })
          .finally(() => setLoadingSalvar(false));
      }
    }
  }, [
    origem.origem,
    isValidForm,
    anoPublicacao,
    autor,
    categoria,
    codigoLivro,
    conservacao,
    edicao,
    editora,
    handleError,
    history,
    id,
    idioma,
    isbn,
    livroLocal,
    showToast,
    titulo,
  ]);

  const resetFieldsExemplar = useCallback(() => {
    setConservacao(conservacaoMap.NOVO);
    setLivroLocal("livro-local-nao");
    setCodigoLivro("");
  }, []);

  const resetFields = useCallback(() => {
    setIsbn("");
    setTitulo("");
    setAutor("");
    setEdicao("");
    setEditora("");
    setAnoPublicacao("");
    setIdioma(undefined);
    setCategoria("");
    resetFieldsExemplar();
  }, [resetFieldsExemplar]);

  const cadastrarProximo = useCallback(() => {
    if (isValidForm()) {
      setLoadingSalvar(true);

      api
        .post<LivroFormResponse>("biblioteca/livros", {
          isbn,
          titulo,
          autor,
          edicao,
          editora,
          ano: anoPublicacao,
          idioma: idioma?.idioma,
          categoria,
          estadoConservacao: conservacao.conservacao,
          local: livroLocal === "livro-local-sim",
          codigo: codigoLivro,
          origem: origem.origem,
        })
        .then(({ data }) => {
          showToast({
            title: "Sucesso!",
            type: "success",
            description: "Um novo livro foi adicionado no acervo.",
          });
          localStorage.setItem("@Balandrau: ultimoLivroCad", data.uuid);
          setUuidUltimoLivro(data.uuid);
          window.scrollTo(0, 0);

          resetFieldsExemplar();
        })
        .catch((err: any) => {
          handleError({ error: err, action: "cadastrar livro", knownErrors: livroErrors });
        })
        .finally(() => setLoadingSalvar(false));
    }
  }, [
    origem.origem,
    anoPublicacao,
    autor,
    categoria,
    codigoLivro,
    conservacao.conservacao,
    edicao,
    editora,
    handleError,
    idioma?.idioma,
    isValidForm,
    isbn,
    livroLocal,
    showToast,
    titulo,
    resetFieldsExemplar,
  ]);

  const loadLivro = useCallback(
    (uuidLivroSearch) => {
      setLoading(true);
      api
        .get<LivroFormResponse>(`biblioteca/livros/${uuidLivroSearch}`)
        .then(({ data }) => {
          setIsbn(data.isbn);
          setTitulo(data.titulo);
          setAutor(data.autor);
          setEdicao(data.edicao);
          setEditora(data.editora);
          setAnoPublicacao(data.ano);
          setIdioma(idiomaMap[data.idioma]);
          setCategoria(data.categoria);
        })
        .catch((err) => {
          handleError({ error: err, action: "carregar o livro", knownErrors: livroErrors });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [handleError]
  );

  const loadDropdowns = useCallback(() => {
    api
      .get<ResponseDropdowns>(`biblioteca/livros/dropdowns`)
      .then(({ data }) => {
        setAutoresOptions(data.autores);
        setCategoriasOptions(data.categorias);
        setEditorasOptions(data.editoras);
        setTitulosOptions(data.titulos);
      })
      .catch((err) => {
        showToast({
          type: "error",
          title: "Não foi possível carregar informações dos dropdowns",
          description: "Tente novamente ou contate nosso time de suporte para solicitar ajuda.",
        });
        // eslint-disable-next-line no-console
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [showToast]);

  useEffect(() => {
    if (id) {
      loadLivro(id);
    }
  }, [id, loadLivro]);

  useEffect(() => {
    loadOptions();
    loadDropdowns();
  }, [loadOptions, loadDropdowns]);

  const disabledField = useCallback(() => {
    return !_.isEmpty(livroAnterior);
  }, [livroAnterior]);

  return (
    <SimpleEntityPage showTopBar routeBack="/acervo" isFormPage loading={loading}>
      <div className="acervo-form-container p-grid p-flex-column p-jc-between">
        {uuidUltimoLivro !== null && (
          <div className="p-d-flex p-ai-center p-mb-3">
            <Checkbox
              checked={addLivroAnterior}
              onChange={(e) => {
                setAddLivroAnterior(e.checked);
                setLivroAnterior(undefined);
                if (e.checked === true) {
                  loadLivro(uuidUltimoLivro);
                } else {
                  resetFields();
                }
              }}
            />
            <span className="color-branco p-pl-2">Adicionar exemplar igual ao último cadastrado</span>
          </div>
        )}

        <div className="p-col-7 p-grid">
          <div className="p-fluid p-col-12">
            <CustomVerticalFormField
              className="color-branco"
              iconMaterial=" "
              htmlForDescription="addCopiaLivro"
              description="Adicionar cópia de exemplar já existente na biblioteca"
              inputBody={
                <Dropdown
                  value={livroAnterior}
                  options={livrosOptions}
                  dataKey="uuid"
                  optionLabel="titulo"
                  placeholder="Digite ou selecione o exemplar"
                  onChange={(e: { value: Livro }) => {
                    setLivroAnterior(e.value);
                    loadLivro(e.value.uuid);
                    setAddLivroAnterior(false);
                    setExpanded({
                      DADOS_BASICOS: { open: false },
                      PUBLICACAO: { open: false },
                      EXEMPLAR: { open: true },
                    });
                  }}
                  style={{ minWidth: "400px" }}
                  itemTemplate={(o: Livro) => `${o.titulo} - ${o.autor} - Edição ${o.edicao}`}
                  filter
                  filterBy="titulo"
                  emptyMessage="Nenhum livro encontrado"
                  emptyFilterMessage="Nenhum livro encontrado"
                />
              }
              errorMessage={errors.isbn}
            />
          </div>
        </div>

        <section id="dados-basicos" className="p-d-flex p-flex-column acervo-form">
          <div className="header-section dados-basicos p-d-flex p-ai-center p-jc-between">
            <span>Dados básicos</span>
            <button
              type="button"
              onClick={() => {
                setExpanded({ ...expanded, DADOS_BASICOS: { open: !expanded.DADOS_BASICOS.open } });
              }}
            >
              {expanded.DADOS_BASICOS.open ? (
                <span className="material-symbols-rounded">expand_less</span>
              ) : (
                <span className="material-symbols-rounded">expand_more</span>
              )}
            </button>
          </div>

          {expanded.DADOS_BASICOS.open && (
            <div className="p-mt-1 p-col-7 p-grid">
              <div className="p-fluid p-col-12">
                <CustomVerticalFormField
                  className="color-branco"
                  iconMaterial="key"
                  htmlForDescription="isbn"
                  description="ISBN"
                  inputBody={
                    <InputText
                      id="isbn"
                      value={isbn}
                      disabled={disabledField()}
                      placeholder="Digite o ISBN do livro"
                      onChange={(e) => {
                        setIsbn(e.target.value);

                        const errorsLocal = _.cloneDeep(errors);
                        delete errorsLocal.isbn;
                        setErrors(errorsLocal);
                      }}
                    />
                  }
                  errorMessage={errors.isbn}
                />
                <CustomVerticalFormField
                  className="color-branco"
                  iconMaterial="star"
                  htmlForDescription="titulo"
                  description="Título"
                  inputBody={
                    <Dropdown
                      placeholder="Digite o título do livro"
                      emptyMessage="Nenhum livro encontrado"
                      emptyFilterMessage="Nenhum livro encontrado"
                      options={titulosOptions}
                      value={titulo}
                      disabled={disabledField()}
                      onChange={(e) => {
                        setTitulo(e.value);

                        const errorsLocal = _.cloneDeep(errors);
                        delete errorsLocal.titulo;
                        setErrors(errorsLocal);
                      }}
                      editable
                    />
                  }
                  errorMessage={errors.titulo}
                />
                <CustomVerticalFormField
                  className="color-branco"
                  iconMaterial="person"
                  htmlForDescription="autor"
                  description="Autor"
                  inputBody={
                    <Dropdown
                      placeholder="Digite o nome do autor"
                      options={autoresOptions}
                      value={autor}
                      disabled={disabledField()}
                      onChange={(e) => {
                        setAutor(e.value);

                        const errorsLocal = _.cloneDeep(errors);
                        delete errorsLocal.autor;
                        setErrors(errorsLocal);
                      }}
                      editable
                      emptyMessage="Nenhum autor encontrado"
                      emptyFilterMessage="Nenhum autor encontrado"
                    />
                  }
                  errorMessage={errors.autor}
                />
                <CustomVerticalFormField
                  className="color-branco"
                  iconMaterial="numbers"
                  htmlForDescription="edicao"
                  description="Edição"
                  inputBody={
                    <InputText
                      id="edicao"
                      value={edicao}
                      disabled={disabledField()}
                      placeholder="Digite o número da edição"
                      onChange={(e) => {
                        setEdicao(e.target.value);

                        const errorsLocal = _.cloneDeep(errors);
                        delete errorsLocal.edicao;
                        setErrors(errorsLocal);
                      }}
                    />
                  }
                  errorMessage={errors.edicao}
                />
              </div>
            </div>
          )}
        </section>

        <section id="publicacao" className="p-d-flex p-flex-column acervo-form">
          <div className="header-section publicacao p-d-flex p-ai-center p-jc-between">
            <span>Publicação</span>
            <button
              type="button"
              onClick={() => {
                setExpanded({ ...expanded, PUBLICACAO: { open: !expanded.PUBLICACAO.open } });
              }}
            >
              {expanded.PUBLICACAO.open ? (
                <span className="material-symbols-rounded">expand_less</span>
              ) : (
                <span className="material-symbols-rounded">expand_more</span>
              )}
            </button>
          </div>

          {expanded.PUBLICACAO.open && (
            <div className="p-mt-1 p-col-7 p-grid">
              <div className="p-fluid p-col-12">
                <CustomVerticalFormField
                  className="color-branco"
                  iconMaterial="account_balance"
                  htmlForDescription="editora"
                  description="Editora"
                  inputBody={
                    <Dropdown
                      placeholder="Digite a categoria"
                      options={editorasOptions}
                      value={editora}
                      disabled={disabledField()}
                      onChange={(e) => {
                        setEditora(e.value);

                        const errorsLocal = _.cloneDeep(errors);
                        delete errorsLocal.editora;
                        setErrors(errorsLocal);
                      }}
                      editable
                      emptyMessage="Nenhuma editora encontrada"
                      emptyFilterMessage="Nenhuma editora encontrada"
                    />
                  }
                  errorMessage={errors.editora}
                />
                <CustomVerticalFormField
                  className="color-branco"
                  iconMaterial="event"
                  htmlForDescription="anoPublicacao"
                  description="Ano de publicação"
                  inputBody={
                    <InputText
                      id="anoPublicacao"
                      value={anoPublicacao}
                      placeholder="Digite o ano de publicação"
                      disabled={disabledField()}
                      onChange={(e) => {
                        setAnoPublicacao(e.target.value);

                        const errorsLocal = _.cloneDeep(errors);
                        delete errorsLocal.anoPublicacao;
                        setErrors(errorsLocal);
                      }}
                    />
                  }
                  errorMessage={errors.anoPublicacao}
                />
                <CustomVerticalFormField
                  className="color-branco"
                  iconMaterial="flag"
                  htmlForDescription="idioma"
                  description="Idioma"
                  inputBody={
                    <Dropdown
                      placeholder="Selecione o idioma do livro"
                      options={idiomaOptions}
                      value={idioma}
                      disabled={disabledField()}
                      onChange={(e) => {
                        setIdioma(e.value);

                        const errorsLocal = _.cloneDeep(errors);
                        delete errorsLocal.idioma;
                        setErrors(errorsLocal);
                      }}
                      emptyMessage="Nenhum idioma encontrado"
                      emptyFilterMessage="Nenhum idioma encontrado"
                    />
                  }
                  errorMessage={errors.idioma}
                />
                <CustomVerticalFormField
                  className="color-branco"
                  iconMaterial="category"
                  htmlForDescription="categoria"
                  description="Categoria"
                  inputBody={
                    <Dropdown
                      placeholder="Digite a categoria"
                      options={categoriasOptions}
                      value={categoria}
                      disabled={disabledField()}
                      onChange={(e) => {
                        setCategoria(e.value);

                        const errorsLocal = _.cloneDeep(errors);
                        delete errorsLocal.categoria;
                        setErrors(errorsLocal);
                      }}
                      editable
                      emptyMessage="Nenhuma categoria encontrada"
                      emptyFilterMessage="Nenhuma categoria encontrada"
                    />
                  }
                  errorMessage={errors.categoria}
                />
              </div>
            </div>
          )}
        </section>

        {!id && (
          <section id="exemplar" className="p-d-flex p-flex-column acervo-form">
            <div className="header-section exemplar p-d-flex p-ai-center p-jc-between">
              <span>Exemplar</span>
              <button
                type="button"
                onClick={() => {
                  setExpanded({ ...expanded, EXEMPLAR: { open: !expanded.EXEMPLAR.open } });
                }}
              >
                {expanded.EXEMPLAR.open ? (
                  <span className="material-symbols-rounded">expand_less</span>
                ) : (
                  <span className="material-symbols-rounded">expand_more</span>
                )}
              </button>
            </div>

            {expanded.EXEMPLAR.open && (
              <div className="p-mt-1 p-col-7 p-grid">
                <div className="p-fluid p-col-12">
                  <CustomVerticalFormField
                    className="color-branco"
                    iconMaterial="explore"
                    htmlForDescription="origem"
                    description="Origem"
                    inputBody={
                      <Dropdown
                        placeholder="Selecione a origem do exemplar"
                        options={origemOptions}
                        value={origem}
                        onChange={(e) => {
                          setOrigem(e.value);

                          const errorsLocal = _.cloneDeep(errors);
                          delete errorsLocal.origem;
                          setErrors(errorsLocal);
                        }}
                        emptyMessage="Nenhuma origem encontrada"
                        emptyFilterMessage="Nenhuma origem encontrada"
                      />
                    }
                    errorMessage={errors.origem}
                  />
                  <CustomVerticalFormField
                    className="color-branco"
                    iconMaterial="label"
                    htmlForDescription="conservacao"
                    description="Conservação"
                    inputBody={
                      <Dropdown
                        placeholder="Selecione o estado de conservação do exemplar"
                        options={conservacaoOptions}
                        value={conservacao}
                        onChange={(e) => {
                          setConservacao(e.value);

                          const errorsLocal = _.cloneDeep(errors);
                          delete errorsLocal.conservacao;
                          setErrors(errorsLocal);
                        }}
                        emptyMessage="Nenhum estado de conservação encontrado"
                        emptyFilterMessage="Nenhum estado de conservação encontrado"
                      />
                    }
                    errorMessage={errors.conservacao}
                  />
                  <CustomVerticalFormField
                    className="color-branco"
                    iconMaterial="label"
                    htmlForDescription="livroLocal"
                    description="Livro local?"
                    inputBody={
                      <div className="p-d-flex p-ai-center" style={{ paddingTop: 2, paddingBottom: 2 }}>
                        <RadioButton
                          value="livro-local-nao"
                          inputId="livro-local-nao"
                          onChange={(e) => {
                            setLivroLocal(e.value);

                            const errorsLocal = _.cloneDeep(errors);
                            delete errorsLocal.livroLocal;
                            setErrors(errorsLocal);
                          }}
                          checked={livroLocal === "livro-local-nao"}
                        />
                        <label htmlFor="livro-local-nao" className="p-ml-2">
                          Não
                        </label>
                        <RadioButton
                          value="livro-local-sim"
                          inputId="livro-local-sim"
                          onChange={(e) => {
                            setLivroLocal(e.value);

                            const errorsLocal = _.cloneDeep(errors);
                            delete errorsLocal.livroLocal;
                            setErrors(errorsLocal);
                          }}
                          checked={livroLocal === "livro-local-sim"}
                          className="p-ml-4"
                        />
                        <label htmlFor="livro-local-sim" className="p-ml-2">
                          Sim
                        </label>
                      </div>
                    }
                    errorMessage={errors.livroLocal}
                  />
                  <CustomVerticalFormField
                    className="color-branco"
                    iconMaterial="qr_code"
                    htmlForDescription="codigoLivro"
                    description="Código do livro"
                    inputBody={
                      <InputText
                        id="codigoLivro"
                        value={codigoLivro}
                        placeholder="Digite o código do livro"
                        onChange={(e) => {
                          setCodigoLivro(e.target.value);

                          const errorsLocal = _.cloneDeep(errors);
                          delete errorsLocal.codigoLivro;
                          setErrors(errorsLocal);
                        }}
                      />
                    }
                    errorMessage={errors.codigoLivro}
                  />
                </div>
              </div>
            )}
          </section>
        )}

        {expanded.EXEMPLAR.open && (
          <div className="p-col-7 p-grid">
            <div className="p-fluid p-col-12">
              <div className="p-d-flex p-jc-between">
                <Button
                  style={!id ? { minWidth: "250px", maxWidth: "250px" } : { minWidth: "175px", maxWidth: "175px" }}
                  onClick={() => confirmarCadastro()}
                  disabled={loadingSalvar}
                >
                  <span className="p-input-icon-left p-d-flex p-ai-center">
                    <span className="material-symbols-rounded">close</span>
                    <span>{!id ? "Salvar livro e finalizar cadastro" : "Concluir alterações"}</span>
                  </span>
                </Button>
                {!id && (
                  <Button
                    style={{ minWidth: "250px", maxWidth: "250px" }}
                    onClick={() => cadastrarProximo()}
                    disabled={loadingSalvar}
                  >
                    <span className="p-input-icon-left p-d-flex p-ai-center">
                      <span className="material-symbols-rounded">arrow_forward</span>
                      <span>Salvar livro e cadastrar próximo</span>
                    </span>
                  </Button>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </SimpleEntityPage>
  );
};

export default AcervoFormPage;
