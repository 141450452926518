import { IAppError } from "./AppErrorInterfaces";

export const temporadaErrors: IAppError[] = [
  {
    code: "Temporada não encontrada",
    title: "A temporada não foi encontrada.",
    message: "Entre em contato com nosso time de suporte para solicitar ajuda.",
  },
  {
    code: "Programa não encontrado",
    title: "O programa não foi encontrado.",
    message: "Entre em contato com nosso time de suporte para solicitar ajuda.",
  },
  {
    code: "Antes de apagar a temporada é necessário apagar os episódios",
    title: "Apague os episódios antes de excluir a temporada.",
    message: "Não foi possível apagar a temporada pois ainda existem episódios cadastrados",
  },
];
