import _ from "lodash";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { Messages } from "primereact/messages";
import { RadioButton } from "primereact/radiobutton";
import { InputText } from "primereact/inputtext";
import { TabPanel, TabView } from "primereact/tabview";
import { confirmDialog } from "primereact/confirmdialog";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { InputMask } from "primereact/inputmask";
import { Button } from "primereact/button";
import { validate } from "email-validator";
import { useParams } from "react-router-dom";

import { InputNumber } from "primereact/inputnumber";
import CustomVerticalFormField from "../../../../components/CustomVerticalFormField";
import FormHeader from "../../../../components/FormHeader";
import { useToast } from "../../../../hooks/toast";
import { useError } from "../../../../hooks/error";
import { enderecoErrors } from "../../../../errors/endereco";
import ProfanoDependentes, { Dependente } from "../../../../components/ProfanoDependentes";
import { EtapaProps } from "../../etapaProps";
import ProfanoNextStepBar from "../../../../components/ProfanoNextStepBar";
import { useProfano } from "../../../../hooks/profano";
import publicApi from "../../../../services/public-api";
import api from "../../../../services/api";
import { FormProfanoPagePathParams } from "..";
import PreviewSelectAvatar from "../../../../components/PreviewSelectAvatar";
import { store } from "../../../../redux/configStore";
import { saveCpf, saveIdentidade, saveNome } from "../../../../redux/decSubsisInfosSlice";

interface Errors {
  [campo: string]: string;
}

type TipoSanguineo = "A+" | "A-" | "B+" | "B-" | "AB+" | "AB-" | "O+" | "O-";
type Escolaridade =
  | "Fundamental"
  | "Médio"
  | "Graduação (cursando)"
  | "Graduação"
  | "Especialização"
  | "Mestrado (cursando)"
  | "Mestrado"
  | "Doutorado(cursando)"
  | "Doutorado"
  | "Pós-doutorado (cursando)"
  | "Pós-doutorado";

type EstadoCivil = "Casado" | "Solteiro" | "Divorciado" | "União estável" | "Viúvo";
export type UF =
  | "AC"
  | "AL"
  | "AP"
  | "AM"
  | "BA"
  | "CE"
  | "DF"
  | "ES"
  | "GO"
  | "MA"
  | "MT"
  | "MS"
  | "MG"
  | "PA"
  | "PB"
  | "PR"
  | "PE"
  | "PI"
  | "RJ"
  | "RN"
  | "RS"
  | "RO"
  | "RR"
  | "SC"
  | "SP"
  | "SE"
  | "TO";

const EtapaForm: React.FC<EtapaProps> = ({ nextStep, isFormFiliacao }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [errors, setErrors] = useState<Errors>({});
  const [globalErrors, setGlobalErrors] = useState<string[]>([]);
  const msgs = useRef<Messages>(null);
  const { token } = useParams<FormProfanoPagePathParams>();

  // campos da aba de dados pessoais
  const [foto, setFoto] = useState<string>();
  const [nome, setNome] = useState<string>("");
  const [dtNascimento, setDtNascimento] = useState<Date | Date[] | undefined>(undefined);
  const [tipoSanguineo, setTipoSanguineo] = useState("");
  const [nacionalidade, setNacionalidade] = useState("");
  const [naturalidade, setNaturalidade] = useState("");
  const [naturalidadeUf, setNaturalidadeUf] = useState("");
  const [nacionalidadePais, setNacionalidadePais] = useState("");
  const [estadoCivil, setEstadoCivil] = useState("");
  const [escolaridade, setEscolaridade] = useState("");
  const [nomeMae, setNomeMae] = useState("");
  const [nomePai, setNomePai] = useState("");
  const [cpf, setCpf] = useState("");
  const [identidade, setIdentidade] = useState("");
  const [identidadeOrgaoExp, setIdentidadeOrgaoExp] = useState("");
  const [identidadeUf, setIdentidadeUf] = useState("");
  const [identidadeDtEmissao, setIdentidadeDtEmissao] = useState<Date | Date[] | undefined>(undefined);
  const [email, setEmail] = useState("");
  const [celular, setCelular] = useState("");
  const [telefone, setTelefone] = useState("");
  const [cep, setCep] = useState("");
  const [logradouro, setLogradouro] = useState("");
  const [numeroLogradouro, setNumeroLogradouro] = useState("");
  const [complemento, setComplemento] = useState("");
  const [bairro, setBairro] = useState("");
  const [enderecoCidade, setEnderecoCidade] = useState("");
  const [enderecoUf, setEnderecoUf] = useState("");

  // campos da aba de dados profissionais
  const [empresa, setEmpresa] = useState<string>("");
  const [telefoneEmpresa, setTelefoneEmpresa] = useState<string>("");
  const [ramalEmpresa, setRamalEmpresa] = useState<string>("");
  const [cargo, setCargo] = useState<string>("");
  const [tempoEmpresa, setTempoEmpresa] = useState<string>("0");
  const [profissao, setProfissao] = useState<string>("");
  const [rendaMensal, setRendaMensal] = useState<number | null>(null);
  const [cepEmpresa, setCepEmpresa] = useState<string>("");
  const [logradouroEmpresa, setLogradouroEmpresa] = useState<string>("");
  const [numeroLogradouroEmpresa, setNumeroLogradouroEmpresa] = useState<string>("");
  const [complementoEmpresa, setComplementoEmpresa] = useState<string>("");
  const [bairroEmpresa, setBairroEmpresa] = useState<string>("");
  const [cidadeEmpresa, setCidadeEmpresa] = useState<string>("");
  const [ufEmpresa, setUfEmpresa] = useState<string>("");

  // campos da aba de outras informações
  const [tempoCidade, setTempoCidade] = useState<string>("0");
  const [religiao, setReligiao] = useState<string>("");
  const [principioCriador, setPrincipioCriador] = useState<string>("");
  const [sobrevivenciaAlma, setSobrevivenciaAlma] = useState<string>("");
  const [compativelMaconaria, setCompativelMaconaria] = useState<string>("");
  const [conheceObrigacoes, setConheceObrigacoes] = useState<string>("");
  const [esposaConcorda, setEsposaConcorda] = useState<string>("");
  const [tempoParaFrequentar, setTempoParaFrequentar] = useState<string>("");
  const [submeteuAntes, setSubmeteuAntes] = useState<string>("");
  const [lojaOrigem, setLojaOrigem] = useState(""); // Campo especifico do form de filiacao
  const [potenciaOrigem, setPotenciaOrigem] = useState(""); // Campo especifico do form de filiacao

  // campos da aba de dependentes
  const [dependentes, setDependentes] = useState<Dependente[]>([]);

  const tipoSanguineoOptions: TipoSanguineo[] = useMemo(() => {
    return ["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"];
  }, []);

  const estadoCivilOptions: EstadoCivil[] = useMemo(() => {
    return ["Casado", "Solteiro", "Divorciado", "União estável", "Viúvo"];
  }, []);

  const escolaridadeOptions: Escolaridade[] = useMemo(() => {
    return [
      "Fundamental",
      "Médio",
      "Graduação (cursando)",
      "Graduação",
      "Especialização",
      "Mestrado (cursando)",
      "Mestrado",
      "Doutorado(cursando)",
      "Doutorado",
      "Pós-doutorado (cursando)",
      "Pós-doutorado",
    ];
  }, []);

  const ufOptions: UF[] = useMemo(() => {
    return [
      "AC",
      "AL",
      "AP",
      "AM",
      "BA",
      "CE",
      "DF",
      "ES",
      "GO",
      "MA",
      "MT",
      "MS",
      "MG",
      "PA",
      "PB",
      "PR",
      "PE",
      "PI",
      "RJ",
      "RN",
      "RS",
      "RO",
      "RR",
      "SC",
      "SP",
      "SE",
      "TO",
    ];
  }, []);

  const { showToast } = useToast();
  const { handleError } = useError();
  const { loadProfanoFromStorage, saveField, removeProfanoFromStorage } = useProfano();

  const onNewDependente = useCallback(
    (dependente: Dependente) => {
      const newDependentes = _.cloneDeep(dependentes);
      newDependentes.unshift(dependente);
      setDependentes(newDependentes);
      saveField("dependentes", JSON.stringify(newDependentes));
    },
    [dependentes, saveField]
  );

  const onDeleteDependente = useCallback(
    (dependenteId: string) => {
      const newDependentes = _.cloneDeep(dependentes);
      const indexFound = newDependentes.findIndex((dependente) => dependente.id === dependenteId);
      if (indexFound >= 0) {
        newDependentes.splice(indexFound, 1);
        setDependentes(newDependentes);
        saveField("dependentes", JSON.stringify(newDependentes));
      }
    },
    [dependentes, saveField]
  );

  const onUpdateDependente = useCallback(
    (dependenteId: string, dependente: Dependente) => {
      const newDependentes = _.cloneDeep(dependentes);
      const indexFound = newDependentes.findIndex((dep) => dep.id === dependenteId);
      if (indexFound >= 0) {
        newDependentes[indexFound] = dependente;
        setDependentes(newDependentes);
        saveField("dependentes", JSON.stringify(newDependentes));
      }
    },
    [dependentes, saveField]
  );

  const yearNavigatorTemplate = (e: any) => {
    return (
      <Dropdown
        value={e.value}
        options={e.options}
        onChange={(event) => e.onChange(event.originalEvent, event.value)}
        className="p-ml-2"
        style={{ lineHeight: 1 }}
      />
    );
  };

  const monthNavigatorTemplate = (e: any) => {
    return (
      <Dropdown
        value={e.value}
        options={e.options}
        onChange={(event) => e.onChange(event.originalEvent, event.value)}
        style={{ lineHeight: 1 }}
      />
    );
  };

  const replaceCep = useCallback((cepString: string | undefined) => {
    if (cepString) return cepString.replace(".", "").replace("-", "").replaceAll("_", "");
    return "";
  }, []);

  const checkRequiredFields = useCallback(() => {
    if (
      _.isEmpty(nome) ||
      !dtNascimento ||
      _.isEmpty(tipoSanguineo) ||
      _.isEmpty(nacionalidade) ||
      (nacionalidade === "brasileiro" && _.isEmpty(naturalidade)) ||
      (nacionalidade === "brasileiro" && _.isEmpty(naturalidadeUf)) ||
      (nacionalidade === "estrangeiro" && _.isEmpty(nacionalidadePais)) ||
      _.isEmpty(estadoCivil) ||
      _.isEmpty(escolaridade) ||
      _.isEmpty(nomeMae) ||
      _.isEmpty(foto) ||
      _.isEmpty(cpf) ||
      _.isEmpty(identidade) ||
      _.isEmpty(identidadeOrgaoExp) ||
      _.isEmpty(identidadeUf) ||
      !identidadeDtEmissao ||
      _.isEmpty(email) ||
      _.isEmpty(celular) ||
      // _.isEmpty(telefone) ||
      _.isEmpty(cep) ||
      _.isEmpty(logradouro) ||
      _.isEmpty(numeroLogradouro) ||
      _.isEmpty(bairro) ||
      _.isEmpty(enderecoCidade) ||
      _.isEmpty(enderecoUf) ||
      _.isEmpty(empresa) ||
      _.isEmpty(telefoneEmpresa) ||
      _.isEmpty(cargo) ||
      _.isEmpty(tempoEmpresa) ||
      _.isEmpty(profissao) ||
      (rendaMensal !== 0 && !rendaMensal) ||
      rendaMensal < 0 ||
      _.isEmpty(cepEmpresa) ||
      _.isEmpty(logradouroEmpresa) ||
      _.isEmpty(numeroLogradouroEmpresa) ||
      _.isEmpty(bairroEmpresa) ||
      _.isEmpty(cidadeEmpresa) ||
      _.isEmpty(ufEmpresa) ||
      _.isEmpty(tempoCidade) ||
      _.isEmpty(religiao) ||
      (_.isEmpty(principioCriador) && !isFormFiliacao) ||
      (_.isEmpty(sobrevivenciaAlma) && !isFormFiliacao) ||
      (_.isEmpty(compativelMaconaria) && !isFormFiliacao) ||
      _.isEmpty(conheceObrigacoes) ||
      (_.isEmpty(esposaConcorda) && !isFormFiliacao) ||
      _.isEmpty(tempoParaFrequentar) ||
      (_.isEmpty(submeteuAntes) && !isFormFiliacao) ||
      (_.isEmpty(lojaOrigem) && isFormFiliacao) ||
      (_.isEmpty(potenciaOrigem) && isFormFiliacao)
    ) {
      return false;
    }
    return true;
  }, [
    isFormFiliacao,
    nome,
    dtNascimento,
    tipoSanguineo,
    nacionalidade,
    naturalidade,
    naturalidadeUf,
    nacionalidadePais,
    estadoCivil,
    escolaridade,
    nomeMae,
    foto,
    cpf,
    identidade,
    identidadeOrgaoExp,
    identidadeUf,
    identidadeDtEmissao,
    email,
    celular,
    cep,
    logradouro,
    numeroLogradouro,
    bairro,
    enderecoCidade,
    enderecoUf,
    empresa,
    telefoneEmpresa,
    cargo,
    tempoEmpresa,
    profissao,
    rendaMensal,
    cepEmpresa,
    logradouroEmpresa,
    numeroLogradouroEmpresa,
    bairroEmpresa,
    cidadeEmpresa,
    ufEmpresa,
    tempoCidade,
    religiao,
    principioCriador,
    sobrevivenciaAlma,
    compativelMaconaria,
    conheceObrigacoes,
    esposaConcorda,
    tempoParaFrequentar,
    submeteuAntes,
    lojaOrigem,
    potenciaOrigem,
  ]);

  const validFormData = useCallback(() => {
    msgs.current?.clear();
    const globalErrorsLocal = [];
    if (!checkRequiredFields()) {
      // Esse erro não deve ocorrer uma vez que o botão de avancar fica desabilitado
      // enquanto todos os campos obrigatórios não estiverem preenchidos
      globalErrorsLocal.push("Falta preencher algum campo obrigatório");
    }

    if (!validate(email)) {
      globalErrorsLocal.push("O e-mail informado é inválido");
      const localErrors = _.cloneDeep(errors);
      localErrors.email = "O e-mail informado é inválido";
      setErrors(localErrors);
    }

    if (dependentes && dependentes.length > 0) {
      dependentes.forEach((dependente, index) => {
        if (!dependente.dtNascimento || !dependente.nome || !dependente.parentesco) {
          globalErrorsLocal.push(`O dependente da linha ${index + 1} não foi preenchido completamente`);
        }
      });
    }

    setGlobalErrors(globalErrorsLocal);
    return globalErrorsLocal.length === 0;
  }, [checkRequiredFields, dependentes, email, errors]);

  const parseTipoSanguineo = useCallback(() => {
    switch (tipoSanguineo) {
      case "O+":
        return "O_POSITIVO";

      case "O-":
        return "O_NEGATIVO";

      case "A+":
        return "A_POSITIVO";

      case "A-":
        return "A_NEGATIVO";

      case "B+":
        return "B_POSITIVO";

      case "B-":
        return "B_NEGATIVO";

      case "AB+":
        return "AB_POSITIVO";

      default:
        return "AB_NEGATIVO";
    }
  }, [tipoSanguineo]);

  const parseEstadoCivil = useCallback(() => {
    switch (estadoCivil) {
      case "Casado":
        return "CASADO";
      case "Solteiro":
        return "SOLTEIRO";
      case "Divorciado":
        return "DIVORCIADO";
      case "União estável":
        return "UNIAO_ESTAVEL";
      default:
        return "VIUVO";
    }
  }, [estadoCivil]);

  const parseEscolaridade = useCallback(() => {
    switch (escolaridade) {
      case "Fundamental":
        return "FUNDAMENTAL";
      case "Médio":
        return "MEDIO";
      case "Graduação (cursando)":
        return "GRADUACAO_CURSANDO";
      case "Graduação":
        return "GRADUACAO";
      case "Especialização":
        return "ESPECIALIZACAO";
      case "Mestrado (cursando)":
        return "MESTRADO_CURSANDO";
      case "Mestrado":
        return "MESTRADO";
      case "Doutorado(cursando)":
        return "DOUTORADO_CURSANDO";
      case "Doutorado":
        return "DOUTORADO";
      case "Pós-doutorado (cursando)":
        return "POS_DOUTORADO_CURSANDO";

      default:
        return "POS_DOUTORADO";
    }
  }, [escolaridade]);

  const updateForm = useCallback(() => {
    const tipoSanguineoParsed = parseTipoSanguineo();
    const estadoCivilParsed = parseEstadoCivil();
    const escolaridadeParsed = parseEscolaridade();

    if (validFormData()) {
      const updateUrl = isFormFiliacao ? `formularios/filiando/login/${token}` : `formularios/profano/login/${token}`;
      api
        .put(updateUrl, {
          formulario: {
            nome,
            dtNascimento,
            tipoSanguineo: tipoSanguineoParsed,
            naturalidade: nacionalidade === "brasileiro" ? naturalidade : null,
            uf: nacionalidade === "brasileiro" ? naturalidadeUf : null,
            pais: nacionalidade === "brasileiro" ? "Brasil" : nacionalidadePais,
            estadoCivil: estadoCivilParsed,
            escolaridade: escolaridadeParsed,
            foto,
            rg: identidade,
            rgOrgaoExpedidor: identidadeOrgaoExp,
            rgUf: identidadeUf,
            rgDtEmissao: identidadeDtEmissao,
            cpf,
            celular1: celular,
            telefone1: telefone,
            email,
            nomeMae,
            nomePai,
            trabEmpresaOrgao: empresa,
            trabTelefone: telefoneEmpresa,
            trabRamal: ramalEmpresa,
            trabCargoFuncao: cargo,
            trabTempoTrabalho: tempoEmpresa,
            trabProfissao: profissao,
            trabRendaMensal: rendaMensal ? `${rendaMensal}` : "0",
            anosResidenciaCidade: tempoCidade,
            religiao,
            creExistenciaCriadorDoUniverso: sobrevivenciaAlma === "sim",
            creSobrevivenciaAlma: sobrevivenciaAlma === "sim",
            principiosFilosoficosCompativeis: compativelMaconaria === "sim",
            plenoConhecimentoObrigacoesPecuniarias: conheceObrigacoes === "sim",
            esposaDeAcordoComIngresso: esposaConcorda === "sim",
            dispoeTempoParticiparReunioes: tempoParaFrequentar === "sim",
            jaFezIdenticoRequerimento: submeteuAntes === "sim",
            lojaOrigem: isFormFiliacao ? lojaOrigem : undefined,
            potenciaOrigem: isFormFiliacao ? potenciaOrigem : undefined,
            dependentes,
            enderecos: [
              {
                cep: replaceCep(cep),
                logradouro,
                numeroLogradouro,
                complemento,
                bairro,
                cidade: enderecoCidade,
                estado: enderecoUf,
                descricao: "",
                tipo: "RESIDENCIAL",
              },
              {
                cep: replaceCep(cepEmpresa),
                logradouro: logradouroEmpresa,
                numeroLogradouro: numeroLogradouroEmpresa,
                complemento: complementoEmpresa,
                bairro: bairroEmpresa,
                cidade: cidadeEmpresa,
                estado: ufEmpresa,
                descricao: "",
                tipo: "COMERCIAL",
              },
            ],
          },
        })
        .then(() => {
          removeProfanoFromStorage();
          store.dispatch(saveCpf(cpf));
          store.dispatch(saveNome(nome));
          store.dispatch(saveIdentidade(identidade));
          nextStep();
        })
        .catch(() => {
          showToast({
            type: "error",
            title: "Não foi possível salvar as alterações.",
            description: "Tente novamente ou contate nosso time de suporte para solicitar ajuda.",
          });
        });
    }
  }, [
    bairro,
    bairroEmpresa,
    cargo,
    celular,
    cep,
    cepEmpresa,
    cidadeEmpresa,
    compativelMaconaria,
    complemento,
    complementoEmpresa,
    conheceObrigacoes,
    cpf,
    dependentes,
    dtNascimento,
    email,
    empresa,
    enderecoCidade,
    enderecoUf,
    esposaConcorda,
    foto,
    identidade,
    identidadeDtEmissao,
    identidadeOrgaoExp,
    identidadeUf,
    logradouro,
    logradouroEmpresa,
    nacionalidade,
    nacionalidadePais,
    naturalidade,
    naturalidadeUf,
    nextStep,
    nome,
    nomeMae,
    nomePai,
    numeroLogradouro,
    numeroLogradouroEmpresa,
    parseEscolaridade,
    parseEstadoCivil,
    parseTipoSanguineo,
    profissao,
    ramalEmpresa,
    religiao,
    removeProfanoFromStorage,
    rendaMensal,
    replaceCep,
    showToast,
    sobrevivenciaAlma,
    submeteuAntes,
    telefone,
    telefoneEmpresa,
    tempoCidade,
    tempoEmpresa,
    tempoParaFrequentar,
    token,
    ufEmpresa,
    validFormData,
    isFormFiliacao,
    lojaOrigem,
    potenciaOrigem,
  ]);

  const confirmData = useCallback(() => {
    confirmDialog({
      message: () => {
        return (
          <p className="text-confirm-dialog">
            Para avançar para a próxima etapa, certifique-se de que todos os campos da etapa atual estão preenchidos
            corretamente. <br />
            <strong>Não será possível retornar para essa etapa</strong>.
          </p>
        );
      },
      header: "Deseja mesmo avançar?",
      icon: "pi pi-question-circle",
      acceptLabel: "Sim, avançar.",
      rejectLabel: "Não, vou revisar.",
      accept: updateForm,
      reject: () => {},
      style: { maxWidth: "500px" },
    });
  }, [updateForm]);

  useEffect(() => {
    const profano = loadProfanoFromStorage();

    setFoto(profano.foto || undefined);
    setCpf(profano.cpf || "");
    setNome(profano.nome || "");
    if (profano.dtNascimento) {
      setDtNascimento(new Date(Date.parse(profano.dtNascimento)));
    }
    setTipoSanguineo(profano.tipoSanguineo || "");
    setNacionalidade(profano.nacionalidade || "");
    setNaturalidade(profano.naturalidade || "");
    setNaturalidadeUf(profano.naturalidadeUf || "");
    setNacionalidadePais(profano.nacionalidadePais || "");
    setEstadoCivil(profano.estadoCivil || "");
    setEscolaridade(profano.escolaridade || "");
    setNomeMae(profano.nomeMae || "");
    setNomePai(profano.nomePai || "");
    setCpf(profano.cpf || "");
    setIdentidade(profano.identidade || "");
    setIdentidadeOrgaoExp(profano.identidadeOrgaoExp || "");
    setIdentidadeUf(profano.identidadeUf || "");
    if (profano.identidadeDtEmissao) {
      setIdentidadeDtEmissao(new Date(Date.parse(profano.identidadeDtEmissao)));
    }
    setEmail(profano.email || "");
    setCelular(profano.celular || "");
    setTelefone(profano.telefone || "");
    setCep(profano.cep || "");
    setLogradouro(profano.logradouro || "");
    setNumeroLogradouro(profano.numeroLogradouro || "");
    setComplemento(profano.complemento || "");
    setBairro(profano.bairro || "");
    setEnderecoCidade(profano.enderecoCidade || "");
    setEnderecoUf(profano.enderecoUf || "");

    // campos da aba de dados profissionais
    setEmpresa(profano.empresa || "");
    setTelefoneEmpresa(profano.telefoneEmpresa || "");
    setRamalEmpresa(profano.ramalEmpresa || "");
    setCargo(profano.cargo || "");
    setTempoEmpresa(profano.tempoEmpresa || "0");
    setProfissao(profano.profissao || "");
    setRendaMensal(profano.rendaMensal || null);
    setCepEmpresa(profano.cepEmpresa || "");
    setLogradouroEmpresa(profano.logradouroEmpresa || "");
    setNumeroLogradouroEmpresa(profano.numeroLogradouroEmpresa || "");
    setComplementoEmpresa(profano.complementoEmpresa || "");
    setBairroEmpresa(profano.bairroEmpresa || "");
    setCidadeEmpresa(profano.cidadeEmpresa || "");
    setUfEmpresa(profano.ufEmpresa || "");

    // campos da aba de outras informações
    setTempoCidade(profano.tempoCidade || "0");
    setReligiao(profano.religiao || "");
    setPrincipioCriador(profano.principioCriador || "");
    setSobrevivenciaAlma(profano.sobrevivenciaAlma || "");
    setCompativelMaconaria(profano.compativelMaconaria || "");
    setConheceObrigacoes(profano.conheceObrigacoes || "");
    setEsposaConcorda(profano.esposaConcorda || "");
    setTempoParaFrequentar(profano.tempoParaFrequentar || "");
    setSubmeteuAntes(profano.submeteuAntes || "");
    setLojaOrigem(profano.lojaOrigem || "");
    setPotenciaOrigem(profano.potenciaOrigem || "");

    // campos da aba de dependentes
    setDependentes(profano.dependentes || []);
  }, [loadProfanoFromStorage]);

  useEffect(() => {
    const msgsToShow = globalErrors.map((erro) => {
      return { severity: "warn", detail: erro, sticky: true };
    });
    msgs.current?.show(msgsToShow);
  }, [globalErrors]);

  return (
    <div className="profano-etapa-form-container ">
      <ProfanoNextStepBar buttonDisabled={!checkRequiredFields()} onButtonClick={confirmData} />
      <Messages ref={msgs} />
      <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} style={{ fontSize: 14 }}>
        <TabPanel header="Dados Pessoais">
          <div className="p-d-flex p-flex-column">
            <CustomVerticalFormField
              icon="fas fa-user"
              htmlForDescription="nome"
              description="Nome completo"
              className="p-mb-4"
              inputBody={
                <InputText
                  id="nome"
                  className="profano-input-max-width"
                  value={nome}
                  type="text"
                  placeholder="Nome completo"
                  onChange={(e) => {
                    setNome(e.target.value);
                    const errorsLocal = _.cloneDeep(errors);
                    delete errorsLocal.nome;
                    setErrors(errorsLocal);
                  }}
                  onBlur={() => {
                    saveField("nome", nome);
                  }}
                  maxLength={100}
                />
              }
              required
              errorMessage={errors.nome}
            />
            <CustomVerticalFormField
              icon="fas fa-birthday-cake"
              htmlForDescription="dtNascimento"
              description="Data de Nascimento"
              className="p-mb-4"
              inputBody={
                <Calendar
                  id="dtNascimento"
                  placeholder="Selecione ou digite"
                  value={dtNascimento}
                  onChange={(e) => {
                    setDtNascimento(e.value);
                    const errorsLocal = _.cloneDeep(errors);
                    delete errorsLocal.dtNascimento;
                    setErrors(errorsLocal);
                  }}
                  onSelect={(e) => {
                    if (e.value) {
                      saveField("dtNascimento", e.value.toString());
                    }
                  }}
                  monthNavigator
                  yearNavigator
                  yearRange="1800:2050"
                  monthNavigatorTemplate={monthNavigatorTemplate}
                  yearNavigatorTemplate={yearNavigatorTemplate}
                  locale="pt-br"
                  showIcon
                  maxDate={new Date(Date.now())}
                  mask="99/99/9999"
                />
              }
              required
              errorMessage={errors.dtNascimento}
            />
            <CustomVerticalFormField
              icon="fas fa-tint"
              htmlForDescription="tipoSanguineo"
              description="Tipo sanguíneo"
              className="p-mb-4"
              inputBody={
                <Dropdown
                  id="tipoSanguineo"
                  value={tipoSanguineo}
                  emptyMessage="Nenhum tipo sanguíneo encontrado."
                  placeholder="Selecione"
                  options={tipoSanguineoOptions}
                  onChange={(e) => {
                    setTipoSanguineo(e.target.value);
                    saveField("tipoSanguineo", e.target.value);
                    const errorsLocal = _.cloneDeep(errors);
                    delete errorsLocal.tipoSanguineo;
                    setErrors(errorsLocal);
                  }}
                />
              }
              required
              errorMessage={errors.tipoSanguineo}
            />
            <CustomVerticalFormField
              icon="fas fa-flag"
              htmlForDescription="nacionalidade"
              description="Nacionalidade"
              className="p-mb-4"
              inputBody={
                <div className="p-d-flex p-ai-center" style={{ paddingTop: 2, paddingBottom: 2 }}>
                  <RadioButton
                    value="brasileiro"
                    inputId="brasileiro"
                    onChange={(e) => {
                      setNacionalidade(e.value);
                      saveField("nacionalidade", e.value);
                    }}
                    checked={nacionalidade === "brasileiro"}
                  />
                  <label htmlFor="brasileiro" className="p-ml-2">
                    Sou brasileiro
                  </label>
                  <RadioButton
                    value="estrangeiro"
                    inputId="estrangeiro"
                    onChange={(e) => {
                      setNacionalidade(e.value);
                      saveField("nacionalidade", e.value);
                    }}
                    checked={nacionalidade === "estrangeiro"}
                    className="p-ml-4"
                  />
                  <label htmlFor="brasileiro" className="p-ml-2">
                    Sou estrangeiro
                  </label>
                </div>
              }
              required
              errorMessage={errors.nacionalidade}
            />
            {nacionalidade === "brasileiro" && (
              <CustomVerticalFormField
                icon="fas fa-map-marker-alt"
                htmlForDescription="naturalidade"
                description="Naturalidade"
                className="p-mb-4"
                inputBody={
                  <InputText
                    id="naturalidade"
                    className="profano-input-max-width"
                    value={naturalidade}
                    type="text"
                    placeholder="Sua cidade natal"
                    onChange={(e) => {
                      setNaturalidade(e.target.value);

                      const errorsLocal = _.cloneDeep(errors);
                      delete errorsLocal.naturalidade;
                      setErrors(errorsLocal);
                    }}
                    onBlur={(e) => {
                      saveField("naturalidade", e.target.value);
                    }}
                    maxLength={50}
                  />
                }
                required
                errorMessage={errors.naturalidade}
              />
            )}

            {nacionalidade === "brasileiro" && (
              <CustomVerticalFormField
                icon="fas fa-map-marked-alt"
                htmlForDescription="naturalidadeUf"
                description="UF"
                className="p-mb-4"
                inputBody={
                  <Dropdown
                    id="naturalidadeUf"
                    value={naturalidadeUf}
                    emptyMessage="Nenhuma UF encontrada."
                    placeholder="Selecione"
                    options={ufOptions}
                    onChange={(e) => {
                      setNaturalidadeUf(e.target.value);
                      saveField("naturalidadeUf", e.target.value);
                      const errorsLocal = _.cloneDeep(errors);
                      delete errorsLocal.naturalidadeUf;
                      setErrors(errorsLocal);
                    }}
                  />
                }
                required
                errorMessage={errors.naturalidadeUf}
              />
            )}
            {nacionalidade === "estrangeiro" && (
              <CustomVerticalFormField
                icon="fas fa-flag"
                htmlForDescription="nacionalidadePais"
                description="País"
                className="p-mb-4"
                inputBody={
                  <InputText
                    id="nacionalidadePais"
                    className="profano-input-max-width"
                    value={nacionalidadePais}
                    type="text"
                    placeholder="Seu país natal"
                    onChange={(e) => {
                      setNacionalidadePais(e.target.value);
                      const errorsLocal = _.cloneDeep(errors);
                      delete errorsLocal.nacionalidadePais;
                      setErrors(errorsLocal);
                    }}
                    onBlur={(e) => {
                      saveField("nacionalidadePais", e.target.value);
                    }}
                    maxLength={50}
                  />
                }
                required
                errorMessage={errors.nacionalidadePais}
              />
            )}
            <CustomVerticalFormField
              icon="fas fa-ring"
              htmlForDescription="estadoCivil"
              description="Estado civil"
              className="p-mb-4"
              inputBody={
                <Dropdown
                  id="estadoCivil"
                  className="profano-input-max-width"
                  value={estadoCivil}
                  emptyMessage="Nenhuma estado civil encontrada."
                  placeholder="Selecione"
                  options={estadoCivilOptions}
                  onChange={(e) => {
                    setEstadoCivil(e.target.value);
                    saveField("estadoCivil", e.target.value);
                    const errorsLocal = _.cloneDeep(errors);
                    delete errorsLocal.estadoCivil;
                    setErrors(errorsLocal);
                  }}
                />
              }
              required
              errorMessage={errors.estadoCivil}
            />
            <CustomVerticalFormField
              icon="fas fa-graduation-cap"
              htmlForDescription="escolaridade"
              description="Escolaridade"
              className="p-mb-4"
              inputBody={
                <Dropdown
                  id="escolaridade"
                  className="profano-input-max-width"
                  value={escolaridade}
                  emptyMessage="Nenhuma escolaridade encontrada."
                  placeholder="Selecione"
                  options={escolaridadeOptions}
                  onChange={(e) => {
                    setEscolaridade(e.target.value);
                    saveField("escolaridade", e.target.value);
                    const errorsLocal = _.cloneDeep(errors);
                    delete errorsLocal.escolaridade;
                    setErrors(errorsLocal);
                  }}
                />
              }
              required
              errorMessage={errors.escolaridade}
            />
            <CustomVerticalFormField
              icon="fas fa-female"
              htmlForDescription="nomeMae"
              description="Nome da mãe"
              className="p-mb-4"
              inputBody={
                <InputText
                  id="nomeMae"
                  className="profano-input-max-width"
                  value={nomeMae}
                  type="text"
                  placeholder="Nome completo"
                  onChange={(e) => {
                    setNomeMae(e.target.value);
                    const errorsLocal = _.cloneDeep(errors);
                    delete errorsLocal.nomeMae;
                    setErrors(errorsLocal);
                  }}
                  onBlur={(e) => {
                    saveField("nomeMae", e.target.value);
                  }}
                  maxLength={100}
                />
              }
              required
              errorMessage={errors.nomeMae}
            />
            <CustomVerticalFormField
              icon="fas fa-male"
              htmlForDescription="nomePai"
              description="Nome do pai"
              className="p-mb-4"
              inputBody={
                <InputText
                  id="nomePai"
                  className="profano-input-max-width"
                  value={nomePai}
                  type="text"
                  placeholder="Nome completo"
                  onChange={(e) => {
                    setNomePai(e.target.value);
                    const errorsLocal = _.cloneDeep(errors);
                    delete errorsLocal.nomePai;
                    setErrors(errorsLocal);
                  }}
                  onBlur={(e) => {
                    saveField("nomePai", e.target.value);
                  }}
                  maxLength={100}
                />
              }
              errorMessage={errors.nomePai}
            />
            <FormHeader headerMessage="Foto" />
            <CustomVerticalFormField
              icon="fas fa-camera"
              htmlForDescription="foto"
              description="Foto 3x4 (paletó e gravata pretos, camisa branca)"
              className="p-mb-4"
              inputBody={
                <PreviewSelectAvatar
                  src={foto}
                  rounded={false}
                  width="225px"
                  height="300px"
                  onChange={(e, newImage) => {
                    const blob = new Blob([`${newImage}`], { type: newImage?.split(";")[0].replace("data:", "") });
                    if (blob.type !== "image/png" && blob.type !== "image/jpeg" && blob.type !== "image/jpg") {
                      const errorsLocal = _.cloneDeep(errors);
                      errorsLocal.foto = "A imagem precisa ser do tipo .jpg, ou .jpeg, ou .png";
                      setErrors(errorsLocal);
                      return;
                    }

                    setFoto(newImage);
                    const errorsLocal = _.cloneDeep(errors);
                    delete errorsLocal.foto;
                    setErrors(errorsLocal);

                    if (newImage) {
                      saveField("foto", newImage);
                    }
                  }}
                  onClear={() => {
                    setFoto(undefined);
                    saveField("foto", "");
                  }}
                  maxFileSizeMB={4}
                  onLargeFileSize={(fileSize, maxFileSize) => {
                    setErrors({
                      ...errors,
                      foto: `O arquivo selecionado possui ${fileSize}MB. O tamanho máximo permitido é de ${maxFileSize}MB.`,
                    });
                  }}
                />
              }
              required
              errorMessage={errors.foto}
            />
            <FormHeader headerMessage="Documentos" />
            <CustomVerticalFormField
              description="CPF"
              htmlForDescription="cpf"
              icon="fas fa-id-card"
              className="p-mb-4"
              inputBody={
                <InputMask
                  id="cpf"
                  value={cpf}
                  // onChange={(e) => {
                  //   setCpf(e.target.value);
                  //   const errorsLocal = _.cloneDeep(errors);
                  //   delete errorsLocal.cpf;
                  //   setErrors(errorsLocal);
                  // }}
                  placeholder="XXX.XXX.XXX-XX"
                  mask="999.999.999-99"
                  disabled
                />
              }
              errorMessage={errors.cpf}
              required
            />
            <CustomVerticalFormField
              icon="fas fa-id-card"
              htmlForDescription="identidade"
              description="Identidade"
              className="p-mb-4"
              inputBody={
                <InputText
                  id="identidade"
                  value={identidade}
                  type="text"
                  placeholder=""
                  onChange={(e) => {
                    setIdentidade(e.target.value);
                    const errorsLocal = _.cloneDeep(errors);
                    delete errorsLocal.identidade;
                    setErrors(errorsLocal);
                  }}
                  onBlur={(e) => {
                    saveField("identidade", e.target.value);
                  }}
                  maxLength={20}
                />
              }
              required
              errorMessage={errors.identidade}
            />
            <CustomVerticalFormField
              icon="fas fa-university"
              htmlForDescription="identidadeOrgaoExp"
              description="Orgão expedidor"
              className="p-mb-4"
              inputBody={
                <InputText
                  id="identidadeOrgaoExp"
                  value={identidadeOrgaoExp}
                  type="text"
                  placeholder=""
                  onChange={(e) => {
                    setIdentidadeOrgaoExp(e.target.value);
                    const errorsLocal = _.cloneDeep(errors);
                    delete errorsLocal.identidadeOrgaoExp;
                    setErrors(errorsLocal);
                  }}
                  onBlur={(e) => {
                    saveField("identidadeOrgaoExp", e.target.value);
                  }}
                  maxLength={50}
                />
              }
              required
              errorMessage={errors.identidadeOrgaoExp}
            />

            <CustomVerticalFormField
              icon="fas fa-map-marked-alt"
              htmlForDescription="identidadeUf"
              description="UF"
              className="p-mb-4"
              inputBody={
                <Dropdown
                  id="identidadeUf"
                  value={identidadeUf}
                  emptyMessage="Nenhuma UF encontrada."
                  placeholder="Selecione"
                  options={ufOptions}
                  onChange={(e) => {
                    setIdentidadeUf(e.target.value);
                    saveField("identidadeUf", e.target.value);
                    const errorsLocal = _.cloneDeep(errors);
                    delete errorsLocal.identidadeUf;
                    setErrors(errorsLocal);
                  }}
                />
              }
              required
              errorMessage={errors.identidadeUf}
            />

            <CustomVerticalFormField
              icon="fas fa-calendar"
              htmlForDescription="identidadeDtEmissao"
              description="Data de Emissão"
              className="p-mb-4"
              inputBody={
                <Calendar
                  id="identidadeDtEmissao"
                  placeholder="Selecione ou digite"
                  value={identidadeDtEmissao}
                  onChange={(e) => {
                    setIdentidadeDtEmissao(e.value);
                    const errorsLocal = _.cloneDeep(errors);
                    delete errorsLocal.identidadeDtEmissao;
                    setErrors(errorsLocal);
                  }}
                  onSelect={(e) => {
                    if (e.value) {
                      saveField("identidadeDtEmissao", e.value.toString());
                    }
                  }}
                  monthNavigator
                  yearNavigator
                  yearRange="1800:2050"
                  monthNavigatorTemplate={monthNavigatorTemplate}
                  yearNavigatorTemplate={yearNavigatorTemplate}
                  locale="pt-br"
                  showIcon
                  maxDate={new Date(Date.now())}
                  mask="99/99/9999"
                />
              }
              required
              errorMessage={errors.identidadeDtEmissao}
            />
            <FormHeader headerMessage="Informações de Contato" />
            <CustomVerticalFormField
              icon="fas fa-envelope"
              htmlForDescription="email"
              description="E-mail"
              className="p-mb-4"
              inputBody={
                <InputText
                  id="email"
                  className="profano-input-max-width"
                  value={email}
                  type="text"
                  placeholder="usuario@dominio.com"
                  onChange={(e) => {
                    setEmail(e.target.value);
                    const errorsLocal = _.cloneDeep(errors);
                    delete errorsLocal.email;
                    setErrors(errorsLocal);
                  }}
                  onBlur={(e) => {
                    saveField("email", e.target.value);
                  }}
                  maxLength={100}
                />
              }
              required
              errorMessage={errors.email}
            />
            <CustomVerticalFormField
              icon="fas fa-phone-alt"
              htmlForDescription="celular"
              description="Celular"
              className="p-mb-4"
              inputBody={
                <InputMask
                  id="celular"
                  className="profano-input-max-width"
                  placeholder="(XX) XXXXX-XXXX"
                  mask="(99) 99999-9999"
                  value={celular}
                  onChange={(e) => {
                    setCelular(e.target.value);
                    const errorsLocal = _.cloneDeep(errors);
                    delete errorsLocal.celular;
                    setErrors(errorsLocal);
                  }}
                  onBlur={(e) => {
                    saveField("celular", e.target.value);
                  }}
                  autoClear={false}
                />
              }
              required
              errorMessage={errors.celular}
            />
            <CustomVerticalFormField
              icon="fas fa-phone-alt"
              htmlForDescription="telefone"
              description="Telefone Residencial"
              className="p-mb-4"
              inputBody={
                <InputMask
                  id="telefone"
                  className="profano-input-max-width"
                  placeholder="(XX) XXXX-XXXX"
                  mask="(99) 9999-9999"
                  value={telefone}
                  onChange={(e) => {
                    setTelefone(e.target.value);
                    const errorsLocal = _.cloneDeep(errors);
                    delete errorsLocal.telefone;
                    setErrors(errorsLocal);
                  }}
                  onBlur={(e) => {
                    saveField("telefone", e.target.value);
                  }}
                  autoClear={false}
                />
              }
              required={false}
              errorMessage={errors.telefone}
            />
            <FormHeader headerMessage="Endereço" />
            <CustomVerticalFormField
              icon="fas fa-location-arrow"
              htmlForDescription="cep"
              description="CEP"
              className="p-mb-4"
              inputBody={
                <div className="">
                  <InputMask
                    style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                    id="cep"
                    placeholder="XX.XXX-XXX"
                    value={cep}
                    type="text"
                    onChange={(e) => {
                      setCep(e.target.value);
                      const errorsLocal = _.cloneDeep(errors);
                      delete errorsLocal.cep;
                      setErrors(errorsLocal);
                    }}
                    onBlur={(e) => {
                      saveField("cep", e.target.value);
                    }}
                    mask="99.999-999"
                    autoClear={false}
                  />
                  <Button
                    style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                    icon="pi pi-search"
                    onClick={() => {
                      if (replaceCep(cep).length !== 8) {
                        const errorsLocal: { [campo: string]: string } = {};
                        errorsLocal.cep = "O campo cep deve conter 8 dígitos";
                        setErrors(errorsLocal);
                        return;
                      }

                      publicApi
                        .post(`enderecos/consultar-cep`, { cep: replaceCep(cep) })
                        .then((res) => {
                          showToast({
                            title: "A consulta foi feita com sucesso.",
                            type: "success",
                            description: "Prossiga com o preenchimento dos dados.",
                          });
                          setLogradouro(res.data.logradouro);
                          saveField("logradouro", res.data.logradouro);
                          setNumeroLogradouro("");
                          saveField("numeroLogradouro", "");
                          setComplemento(res.data.complemento);
                          saveField("complemento", res.data.complemento);
                          setBairro(res.data.bairro);
                          saveField("bairro", res.data.bairro);
                          setEnderecoCidade(res.data.cidade);
                          saveField("enderecoCidade", res.data.cidade);
                          setEnderecoUf(res.data.estado);
                          saveField("enderecoUf", res.data.estado);
                        })
                        .catch((err) => {
                          handleError({ error: err, action: "consultar cep", knownErrors: enderecoErrors });
                        });
                    }}
                  />
                </div>
              }
              required
              errorMessage={errors.cep}
            />
            <CustomVerticalFormField
              icon="fas fa-house-user"
              htmlForDescription="logradouro"
              description="Logradouro"
              className="p-mb-4"
              inputBody={
                <InputText
                  id="logradouro"
                  className="profano-input-max-width"
                  placeholder=""
                  value={logradouro}
                  type="text"
                  onChange={(e) => {
                    setLogradouro(e.target.value);
                    const errorsLocal = _.cloneDeep(errors);
                    delete errorsLocal.logradouro;
                    setErrors(errorsLocal);
                  }}
                  onBlur={(e) => {
                    saveField("logradouro", e.target.value);
                  }}
                  maxLength={120}
                />
              }
              required
              errorMessage={errors.logradouro}
            />
            <CustomVerticalFormField
              icon="fas fa-house-user"
              htmlForDescription="numeroLogradouro"
              description="Número"
              className="p-mb-4"
              inputBody={
                <InputText
                  id="numeroLogradouro"
                  className="profano-input-max-width"
                  placeholder=""
                  value={numeroLogradouro}
                  type="text"
                  onChange={(e) => {
                    setNumeroLogradouro(e.target.value);
                    const errorsLocal = _.cloneDeep(errors);
                    delete errorsLocal.numeroLogradouro;
                    setErrors(errorsLocal);
                  }}
                  onBlur={(e) => {
                    saveField("numeroLogradouro", e.target.value);
                  }}
                  maxLength={12}
                />
              }
              required
              errorMessage={errors.numeroLogradouro}
            />
            <CustomVerticalFormField
              icon="fas fa-house-user"
              htmlForDescription="complemento"
              description="Complemento"
              className="p-mb-4"
              inputBody={
                <InputText
                  id="complemento"
                  className="profano-input-max-width"
                  placeholder=""
                  value={complemento}
                  type="text"
                  onChange={(e) => {
                    setComplemento(e.target.value);
                    const errorsLocal = _.cloneDeep(errors);
                    delete errorsLocal.complemento;
                    setErrors(errorsLocal);
                  }}
                  onBlur={(e) => {
                    saveField("complemento", e.target.value);
                  }}
                  maxLength={120}
                />
              }
              errorMessage={errors.complemento}
            />

            <CustomVerticalFormField
              icon="fas fa-route"
              htmlForDescription="bairro"
              description="Bairro"
              className="p-mb-4"
              inputBody={
                <InputText
                  id="bairro"
                  className="profano-input-max-width"
                  placeholder=""
                  value={bairro}
                  type="text"
                  onChange={(e) => {
                    setBairro(e.target.value);
                    const errorsLocal = _.cloneDeep(errors);
                    delete errorsLocal.bairro;
                    setErrors(errorsLocal);
                  }}
                  onBlur={(e) => {
                    saveField("bairro", e.target.value);
                  }}
                  maxLength={120}
                />
              }
              required
              errorMessage={errors.bairro}
            />
            <CustomVerticalFormField
              icon="fas fa-map-marker-alt"
              htmlForDescription="enderecoCidade"
              description="Cidade"
              className="p-mb-4"
              inputBody={
                <InputText
                  id="enderecoCidade"
                  className="profano-input-max-width"
                  placeholder=""
                  value={enderecoCidade}
                  type="text"
                  onChange={(e) => {
                    setEnderecoCidade(e.target.value);
                    const errorsLocal = _.cloneDeep(errors);
                    delete errorsLocal.enderecoCidade;
                    setErrors(errorsLocal);
                  }}
                  onBlur={(e) => {
                    saveField("enderecoCidade", e.target.value);
                  }}
                  maxLength={120}
                />
              }
              required
              errorMessage={errors.enderecoCidade}
            />
            <CustomVerticalFormField
              icon="fas fa-map-marked-alt"
              htmlForDescription="enderecoUf"
              description="UF"
              className="p-mb-4"
              inputBody={
                <Dropdown
                  id="enderecoUf"
                  value={enderecoUf}
                  emptyMessage="Nenhuma UF encontrada."
                  placeholder="Selecione"
                  options={ufOptions}
                  onChange={(e) => {
                    setEnderecoUf(e.target.value);
                    saveField("enderecoUf", e.target.value);
                    const errorsLocal = _.cloneDeep(errors);
                    delete errorsLocal.enderecoUf;
                    setErrors(errorsLocal);
                  }}
                />
              }
              required
              errorMessage={errors.enderecoUf}
            />
          </div>
        </TabPanel>
        <TabPanel header="Dados Profissionais">
          <div className="p-d-flex p-flex-column">
            <CustomVerticalFormField
              icon="fas fa-industry"
              htmlForDescription="empresa"
              description="Empresa/Órgão"
              className="p-mb-4"
              inputBody={
                <InputText
                  id="empresa"
                  className="profano-input-max-width"
                  placeholder=""
                  value={empresa}
                  type="text"
                  onChange={(e) => {
                    setEmpresa(e.target.value);
                    const errorsLocal = _.cloneDeep(errors);
                    delete errorsLocal.empresa;
                    setErrors(errorsLocal);
                  }}
                  onBlur={(e) => {
                    saveField("empresa", e.target.value);
                  }}
                  maxLength={100}
                />
              }
              required
              errorMessage={errors.empresa}
            />
            <CustomVerticalFormField
              icon="fas fa-phone-alt"
              htmlForDescription="telefoneEmpresa"
              description="Telefone"
              className="p-mb-4"
              inputBody={
                <InputMask
                  id="telefoneEmpresa"
                  className="profano-input-max-width"
                  placeholder="(XX) XXXX-XXXX"
                  mask="(99) 9999-9999"
                  value={telefoneEmpresa}
                  onChange={(e) => {
                    setTelefoneEmpresa(e.target.value);
                    const errorsLocal = _.cloneDeep(errors);
                    delete errorsLocal.telefoneEmpresa;
                    setErrors(errorsLocal);
                  }}
                  onBlur={(e) => {
                    saveField("telefoneEmpresa", e.target.value);
                  }}
                  autoClear={false}
                />
              }
              required
              errorMessage={errors.telefoneEmpresa}
            />
            <CustomVerticalFormField
              icon="fas fa-network-wired"
              htmlForDescription="ramalEmpresa"
              description="Ramal"
              className="p-mb-4"
              inputBody={
                <InputText
                  id="ramalEmpresa"
                  className="profano-input-max-width"
                  placeholder=""
                  value={ramalEmpresa}
                  type="text"
                  onChange={(e) => {
                    setRamalEmpresa(e.target.value);
                    const errorsLocal = _.cloneDeep(errors);
                    delete errorsLocal.ramalEmpresa;
                    setErrors(errorsLocal);
                  }}
                  onBlur={(e) => {
                    saveField("ramalEmpresa", e.target.value);
                  }}
                  maxLength={100}
                />
              }
              errorMessage={errors.ramalEmpresa}
            />
            <CustomVerticalFormField
              icon="fas fa-hard-hat"
              htmlForDescription="cargo"
              description="Cargo"
              className="p-mb-4"
              inputBody={
                <InputText
                  id="cargo"
                  className="profano-input-max-width"
                  placeholder=""
                  value={cargo}
                  type="text"
                  onChange={(e) => {
                    setCargo(e.target.value);
                    const errorsLocal = _.cloneDeep(errors);
                    delete errorsLocal.cargo;
                    setErrors(errorsLocal);
                  }}
                  onBlur={(e) => {
                    saveField("cargo", e.target.value);
                  }}
                  maxLength={100}
                />
              }
              required
              errorMessage={errors.cargo}
            />
            <CustomVerticalFormField
              icon="fas fa-history"
              htmlForDescription="tempoEmpresa"
              description="Tempo"
              className="p-mb-4"
              inputBody={
                <div>
                  <InputText
                    id="tempoEmpresa"
                    placeholder=""
                    value={tempoEmpresa}
                    type="number"
                    onChange={(e) => {
                      setTempoEmpresa(e.target.value);
                      const errorsLocal = _.cloneDeep(errors);
                      delete errorsLocal.tempoEmpresa;
                      setErrors(errorsLocal);
                    }}
                    onBlur={(e) => {
                      saveField("tempoEmpresa", e.target.value);
                    }}
                    min="0"
                    maxLength={100}
                  />
                  <span className="p-ml-2">anos</span>
                </div>
              }
              required
              errorMessage={errors.tempoEmpresa}
            />
            <CustomVerticalFormField
              icon="fas fa-briefcase"
              htmlForDescription="profissao"
              description="Profissão"
              className="p-mb-4"
              inputBody={
                <InputText
                  id="profissao"
                  className="profano-input-max-width"
                  placeholder=""
                  value={profissao}
                  type="text"
                  onChange={(e) => {
                    setProfissao(e.target.value);
                    const errorsLocal = _.cloneDeep(errors);
                    delete errorsLocal.profissao;
                    setErrors(errorsLocal);
                  }}
                  onBlur={(e) => {
                    saveField("profissao", e.target.value);
                  }}
                  maxLength={50}
                />
              }
              required
              errorMessage={errors.profissao}
            />
            <CustomVerticalFormField
              icon="fas fa-money-bill-wave"
              htmlForDescription="rendaMensal"
              description="Renda Mensal"
              className="p-mb-4"
              inputBody={
                <div>
                  {/* <span className="p-mr-2">R$</span> */}
                  <InputNumber
                    id="rendaMensal"
                    placeholder=""
                    value={rendaMensal}
                    onValueChange={(e) => {
                      setRendaMensal(e.value);

                      const errorsLocal = _.cloneDeep(errors);
                      delete errorsLocal.rendaMensal;
                      setErrors(errorsLocal);

                      saveField("rendaMensal", `${e.value}`);
                    }}
                    mode="currency"
                    currency="BRL"
                    locale="pt-BR"
                    min={0}
                  />
                  {/* <InputText
                    // type="number"
                    onChange={(e) => {
                      setRendaMensal(e.target.value);
                      const errorsLocal = _.cloneDeep(errors);
                      delete errorsLocal.rendaMensal;
                      setErrors(errorsLocal);
                    }}
                    onBlur={(e) => {
                      saveField("rendaMensal", e.target.value);
                    }}
                    min="0"
                    maxLength={50}
                  /> */}
                </div>
              }
              required
              errorMessage={errors.rendaMensal}
            />
            <FormHeader headerMessage="Endereço Profissional" />
            <CustomVerticalFormField
              icon="fas fa-location-arrow"
              htmlForDescription="cepEmpresa"
              description="CEP"
              className="p-mb-4"
              inputBody={
                <div className="">
                  <InputMask
                    style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                    id="cepEmpresa"
                    placeholder="XX.XXX-XXX"
                    value={cepEmpresa}
                    type="text"
                    onChange={(e) => {
                      setCepEmpresa(e.target.value);
                      const errorsLocal = _.cloneDeep(errors);
                      delete errorsLocal.cepEmpresa;
                      setErrors(errorsLocal);
                    }}
                    onBlur={(e) => {
                      saveField("cepEmpresa", e.target.value);
                    }}
                    mask="99.999-999"
                    autoClear={false}
                  />
                  <Button
                    style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                    icon="pi pi-search"
                    onClick={() => {
                      if (replaceCep(cepEmpresa).length !== 8) {
                        const errorsLocal: { [campo: string]: string } = {};
                        errorsLocal.cepEmpresa = "O campo cep deve conter 8 dígitos";
                        setErrors(errorsLocal);
                        return;
                      }

                      publicApi
                        .post(`enderecos/consultar-cep`, { cep: replaceCep(cepEmpresa) })
                        .then((res) => {
                          showToast({
                            title: "A consulta foi feita com sucesso.",
                            type: "success",
                            description: "Prossiga com o preenchimento dos dados.",
                          });
                          setLogradouroEmpresa(res.data.logradouro);
                          saveField("logradouroEmpresa", res.data.logradouro);
                          setNumeroLogradouroEmpresa("");
                          saveField("numeroLogradouroEmpresa", "");
                          setComplementoEmpresa(res.data.complemento);
                          saveField("complementoEmpresa", res.data.complemento);
                          setBairroEmpresa(res.data.bairro);
                          saveField("bairroEmpresa", res.data.bairro);
                          setCidadeEmpresa(res.data.cidade);
                          saveField("cidadeEmpresa", res.data.cidade);
                          setUfEmpresa(res.data.estado);
                          saveField("ufEmpresa", res.data.estado);
                        })
                        .catch((err) => {
                          handleError({ error: err, action: "consultar cep", knownErrors: enderecoErrors });
                        });
                    }}
                  />
                </div>
              }
              required
              errorMessage={errors.cepEmpresa}
            />
            <CustomVerticalFormField
              icon="fas fa-industry"
              htmlForDescription="logradouroEmpresa"
              description="Logradouro"
              className="p-mb-4"
              inputBody={
                <InputText
                  id="logradouroEmpresa"
                  className="profano-input-max-width"
                  placeholder=""
                  value={logradouroEmpresa}
                  type="text"
                  onChange={(e) => {
                    setLogradouroEmpresa(e.target.value);
                    const errorsLocal = _.cloneDeep(errors);
                    delete errorsLocal.logradouroEmpresa;
                    setErrors(errorsLocal);
                  }}
                  onBlur={(e) => {
                    saveField("logradouroEmpresa", e.target.value);
                  }}
                  maxLength={120}
                />
              }
              required
              errorMessage={errors.logradouroEmpresa}
            />
            <CustomVerticalFormField
              icon="fas fa-industry"
              htmlForDescription="numeroLogradouroEmpresa"
              description="Número"
              className="p-mb-4"
              inputBody={
                <InputText
                  id="numeroLogradouroEmpresa"
                  className="profano-input-max-width"
                  placeholder=""
                  value={numeroLogradouroEmpresa}
                  type="text"
                  onChange={(e) => {
                    setNumeroLogradouroEmpresa(e.target.value);
                    const errorsLocal = _.cloneDeep(errors);
                    delete errorsLocal.numeroLogradouroEmpresa;
                    setErrors(errorsLocal);
                  }}
                  onBlur={(e) => {
                    saveField("numeroLogradouroEmpresa", e.target.value);
                  }}
                  maxLength={12}
                />
              }
              required
              errorMessage={errors.numeroLogradouroEmpresa}
            />
            <CustomVerticalFormField
              icon="fas fa-industry"
              htmlForDescription="complementoEmpresa"
              description="Complemento"
              className="p-mb-4"
              inputBody={
                <InputText
                  id="complementoEmpresa"
                  className="profano-input-max-width"
                  placeholder=""
                  value={complementoEmpresa}
                  type="text"
                  onChange={(e) => {
                    setComplementoEmpresa(e.target.value);
                    const errorsLocal = _.cloneDeep(errors);
                    delete errorsLocal.complementoEmpresa;
                    setErrors(errorsLocal);
                  }}
                  onBlur={(e) => {
                    saveField("complementoEmpresa", e.target.value);
                  }}
                  maxLength={120}
                />
              }
              errorMessage={errors.complementoEmpresa}
            />

            <CustomVerticalFormField
              icon="fas fa-route"
              htmlForDescription="bairroEmpresa"
              description="Bairro"
              className="p-mb-4"
              inputBody={
                <InputText
                  id="bairroEmpresa"
                  className="profano-input-max-width"
                  placeholder=""
                  value={bairroEmpresa}
                  type="text"
                  onChange={(e) => {
                    setBairroEmpresa(e.target.value);
                    const errorsLocal = _.cloneDeep(errors);
                    delete errorsLocal.bairroEmpresa;
                    setErrors(errorsLocal);
                  }}
                  onBlur={(e) => {
                    saveField("bairroEmpresa", e.target.value);
                  }}
                  maxLength={120}
                />
              }
              required
              errorMessage={errors.bairroEmpresa}
            />
            <CustomVerticalFormField
              icon="fas fa-map-marker-alt"
              htmlForDescription="cidadeEmpresa"
              description="Cidade"
              className="p-mb-4"
              inputBody={
                <InputText
                  id="cidadeEmpresa"
                  className="profano-input-max-width"
                  placeholder=""
                  value={cidadeEmpresa}
                  type="text"
                  onChange={(e) => {
                    setCidadeEmpresa(e.target.value);
                    const errorsLocal = _.cloneDeep(errors);
                    delete errorsLocal.cidadeEmpresa;
                    setErrors(errorsLocal);
                  }}
                  onBlur={(e) => {
                    saveField("cidadeEmpresa", e.target.value);
                  }}
                  maxLength={120}
                />
              }
              required
              errorMessage={errors.cidadeEmpresa}
            />
            <CustomVerticalFormField
              icon="fas fa-map-marked-alt"
              htmlForDescription="ufEmpresa"
              description="UF"
              className="p-mb-4"
              inputBody={
                <Dropdown
                  id="ufEmpresa"
                  value={ufEmpresa}
                  emptyMessage="Nenhuma UF encontrada."
                  placeholder="Selecione"
                  options={ufOptions}
                  onChange={(e) => {
                    setUfEmpresa(e.target.value);
                    saveField("ufEmpresa", e.target.value);
                    const errorsLocal = _.cloneDeep(errors);
                    delete errorsLocal.ufEmpresa;
                    setErrors(errorsLocal);
                  }}
                />
              }
              required
              errorMessage={errors.ufEmpresa}
            />
          </div>
        </TabPanel>
        <TabPanel header="Dependentes">
          <div className="p-d-flex p-flex-column">
            <ProfanoDependentes
              dependentes={dependentes}
              onAdd={onNewDependente}
              onRemove={onDeleteDependente}
              onUpdate={onUpdateDependente}
            />
          </div>
        </TabPanel>
        <TabPanel header="Outras Informações">
          <div className="p-d-flex p-flex-column">
            <CustomVerticalFormField
              icon="fas fa-clock"
              htmlForDescription="tempoCidade"
              description="Há quanto tempo reside nesta cidade?"
              className="p-mb-4"
              inputBody={
                <div>
                  <InputText
                    id="tempoCidade"
                    placeholder=""
                    value={tempoCidade}
                    type="number"
                    onChange={(e) => {
                      setTempoCidade(e.target.value);
                      const errorsLocal = _.cloneDeep(errors);
                      delete errorsLocal.tempoCidade;
                      setErrors(errorsLocal);
                    }}
                    onBlur={(e) => {
                      saveField("tempoCidade", e.target.value);
                    }}
                    min="0"
                    maxLength={50}
                  />
                  <span className="p-ml-2">anos</span>
                </div>
              }
              required
              errorMessage={errors.tempoCidade}
            />
            <CustomVerticalFormField
              icon="fas fa-praying-hands"
              htmlForDescription="religiao"
              description="Qual sua religião?"
              className="p-mb-4"
              inputBody={
                <InputText
                  id="religiao"
                  className="profano-input-max-width"
                  placeholder=""
                  value={religiao}
                  type="text"
                  onChange={(e) => {
                    setReligiao(e.target.value);
                    const errorsLocal = _.cloneDeep(errors);
                    delete errorsLocal.religiao;
                    setErrors(errorsLocal);
                  }}
                  onBlur={(e) => {
                    saveField("religiao", e.target.value);
                  }}
                  maxLength={50}
                />
              }
              required
              errorMessage={errors.religiao}
            />
            {!isFormFiliacao && (
              <>
                <CustomVerticalFormField
                  icon="fas fa-star"
                  htmlForDescription="principioCriador"
                  description="Crê na existência de um princípio criador do Universo?"
                  className="p-mb-4"
                  inputBody={
                    <div
                      className="p-d-flex p-ai-center"
                      style={{ paddingTop: 2, paddingBottom: 2 }}
                      id="principioCriador"
                    >
                      <RadioButton
                        value="sim"
                        inputId="principioCriadorSIM"
                        onChange={(e) => {
                          setPrincipioCriador(e.value);
                          saveField("principioCriador", e.value);
                        }}
                        checked={principioCriador === "sim"}
                      />
                      <label htmlFor="principioCriadorSIM" className="p-ml-2">
                        Sim
                      </label>
                      <RadioButton
                        value="nao"
                        inputId="principioCriadorNAO"
                        onChange={(e) => {
                          setPrincipioCriador(e.value);
                          saveField("principioCriador", e.value);
                        }}
                        checked={principioCriador === "nao"}
                        className="p-ml-4"
                      />
                      <label htmlFor="principioCriadorNAO" className="p-ml-2">
                        Não
                      </label>
                    </div>
                  }
                  required
                  errorMessage={errors.principioCriador}
                />

                <CustomVerticalFormField
                  icon="fas fa-star"
                  htmlForDescription="sobrevivenciaAlma"
                  description="Crê na sobrevivência da alma ou espírito após a morte?"
                  className="p-mb-4"
                  inputBody={
                    <div
                      className="p-d-flex p-ai-center"
                      style={{ paddingTop: 2, paddingBottom: 2 }}
                      id="sobrevivenciaAlma"
                    >
                      <RadioButton
                        value="sim"
                        inputId="sobrevivenciaAlmaSIM"
                        onChange={(e) => {
                          setSobrevivenciaAlma(e.value);
                          saveField("sobrevivenciaAlma", e.value);
                        }}
                        checked={sobrevivenciaAlma === "sim"}
                      />
                      <label htmlFor="sobrevivenciaAlmaSIM" className="p-ml-2">
                        Sim
                      </label>
                      <RadioButton
                        value="nao"
                        inputId="sobrevivenciaAlmaNAO"
                        onChange={(e) => {
                          setSobrevivenciaAlma(e.value);
                          saveField("sobrevivenciaAlma", e.value);
                        }}
                        checked={sobrevivenciaAlma === "nao"}
                        className="p-ml-4"
                      />
                      <label htmlFor="sobrevivenciaAlmaNAO" className="p-ml-2">
                        Não
                      </label>
                    </div>
                  }
                  required
                  errorMessage={errors.sobrevivenciaAlma}
                />
                <CustomVerticalFormField
                  icon="fas fa-star"
                  htmlForDescription="compativelMaconaria"
                  description="A sua formação moral, os seus princípios filosóficos ou sua crença religiosa são compatíveis com os postulados e princípios maçônicos que lhes foram dados a conhecer?"
                  className="p-mb-4"
                  inputBody={
                    <div
                      className="p-d-flex p-ai-center"
                      style={{ paddingTop: 2, paddingBottom: 2 }}
                      id="compativelMaconaria"
                    >
                      <RadioButton
                        value="sim"
                        inputId="compativelMaconariaSIM"
                        onChange={(e) => {
                          setCompativelMaconaria(e.value);
                          saveField("compativelMaconaria", e.value);
                        }}
                        checked={compativelMaconaria === "sim"}
                      />
                      <label htmlFor="compativelMaconariaSIM" className="p-ml-2">
                        Sim
                      </label>
                      <RadioButton
                        value="nao"
                        inputId="compativelMaconariaNAO"
                        onChange={(e) => {
                          setCompativelMaconaria(e.value);
                          saveField("compativelMaconaria", e.value);
                        }}
                        checked={compativelMaconaria === "nao"}
                        className="p-ml-4"
                      />
                      <label htmlFor="compativelMaconariaNAO" className="p-ml-2">
                        Não
                      </label>
                    </div>
                  }
                  required
                  errorMessage={errors.compativelMaconaria}
                />
              </>
            )}

            <CustomVerticalFormField
              icon="fas fa-brain"
              htmlForDescription="conheceObrigacoes"
              description="Tem pleno conhecimento das obrigações pecuniárias que assumirá?"
              className="p-mb-4"
              inputBody={
                <div
                  className="p-d-flex p-ai-center"
                  style={{ paddingTop: 2, paddingBottom: 2 }}
                  id="conheceObrigacoes"
                >
                  <RadioButton
                    value="sim"
                    inputId="conheceObrigacoesSIM"
                    onChange={(e) => {
                      setConheceObrigacoes(e.value);
                      saveField("conheceObrigacoes", e.value);
                    }}
                    checked={conheceObrigacoes === "sim"}
                  />
                  <label htmlFor="conheceObrigacoesSIM" className="p-ml-2">
                    Sim
                  </label>
                  <RadioButton
                    value="nao"
                    inputId="conheceObrigacoesNAO"
                    onChange={(e) => {
                      setConheceObrigacoes(e.value);
                      saveField("conheceObrigacoes", e.value);
                    }}
                    checked={conheceObrigacoes === "nao"}
                    className="p-ml-4"
                  />
                  <label htmlFor="conheceObrigacoesNAO" className="p-ml-2">
                    Não
                  </label>
                </div>
              }
              required
              errorMessage={errors.conheceObrigacoes}
            />
            {!isFormFiliacao && (
              <>
                <CustomVerticalFormField
                  icon="fas fa-thumbs-up"
                  htmlForDescription="esposaConcorda"
                  description="Sua esposa está de acordo com seu ingresso na maçonaria?"
                  className="p-mb-4"
                  inputBody={
                    <div
                      className="p-d-flex p-ai-center"
                      style={{ paddingTop: 2, paddingBottom: 2 }}
                      id="esposaConcorda"
                    >
                      <RadioButton
                        value="sim"
                        inputId="esposaConcordaSIM"
                        onChange={(e) => {
                          setEsposaConcorda(e.value);
                          saveField("esposaConcorda", e.value);
                        }}
                        checked={esposaConcorda === "sim"}
                      />
                      <label htmlFor="esposaConcordaSIM" className="p-ml-2">
                        Sim
                      </label>
                      <RadioButton
                        value="nao"
                        inputId="esposaConcordaNAO"
                        onChange={(e) => {
                          setEsposaConcorda(e.value);
                          saveField("esposaConcorda", e.value);
                        }}
                        checked={esposaConcorda === "nao"}
                        className="p-ml-4"
                      />
                      <label htmlFor="esposaConcordaNAO" className="p-ml-2">
                        Não
                      </label>
                    </div>
                  }
                  required
                  errorMessage={errors.esposaConcorda}
                />
              </>
            )}
            <CustomVerticalFormField
              icon="fas fa-hourglass-half"
              htmlForDescription="tempoParaFrequentar"
              description="Dispõe de tempo para frequentar assiduamente as reuniões?"
              className="p-mb-4"
              inputBody={
                <div
                  className="p-d-flex p-ai-center"
                  style={{ paddingTop: 2, paddingBottom: 2 }}
                  id="tempoParaFrequentar"
                >
                  <RadioButton
                    value="sim"
                    inputId="tempoParaFrequentarSIM"
                    onChange={(e) => {
                      setTempoParaFrequentar(e.value);
                      saveField("tempoParaFrequentar", e.value);
                    }}
                    checked={tempoParaFrequentar === "sim"}
                  />
                  <label htmlFor="tempoParaFrequentarSIM" className="p-ml-2">
                    Sim
                  </label>
                  <RadioButton
                    value="nao"
                    inputId="tempoParaFrequentarNAO"
                    onChange={(e) => {
                      setTempoParaFrequentar(e.value);
                      saveField("tempoParaFrequentar", e.value);
                    }}
                    checked={tempoParaFrequentar === "nao"}
                    className="p-ml-4"
                  />
                  <label htmlFor="tempoParaFrequentarNAO" className="p-ml-2">
                    Não
                  </label>
                </div>
              }
              required
              errorMessage={errors.tempoParaFrequentar}
            />
            {!isFormFiliacao && (
              <>
                <CustomVerticalFormField
                  icon="fas fa-file-alt"
                  htmlForDescription="submeteuAntes"
                  description="Já fez idêntico requerimento à Maçonaria? Caso positivo, citar na declaração de próprio punho o nome da loja, a cidade, o estado e as razões deste novo pedido."
                  className="p-mb-4"
                  inputBody={
                    <div
                      className="p-d-flex p-ai-center"
                      style={{ paddingTop: 2, paddingBottom: 2 }}
                      id="submeteuAntes"
                    >
                      <RadioButton
                        value="sim"
                        inputId="submeteuAntesSIM"
                        onChange={(e) => {
                          setSubmeteuAntes(e.value);
                          saveField("submeteuAntes", e.value);
                        }}
                        checked={submeteuAntes === "sim"}
                      />
                      <label htmlFor="submeteuAntesSIM" className="p-ml-2">
                        Sim
                      </label>
                      <RadioButton
                        value="nao"
                        inputId="submeteuAntesNAO"
                        onChange={(e) => {
                          setSubmeteuAntes(e.value);
                          saveField("submeteuAntes", e.value);
                        }}
                        checked={submeteuAntes === "nao"}
                        className="p-ml-4"
                      />
                      <label htmlFor="submeteuAntesNAO" className="p-ml-2">
                        Não
                      </label>
                    </div>
                  }
                  required
                  errorMessage={errors.submeteuAntes}
                />
              </>
            )}
            {isFormFiliacao && (
              <CustomVerticalFormField
                icon="fa-solid fa-building"
                htmlForDescription="lojaOrigem"
                description="Loja de Origem"
                className="p-mb-4"
                inputBody={
                  <InputText
                    id="lojaOrigem"
                    className="profano-input-max-width"
                    value={lojaOrigem}
                    onChange={(e) => {
                      setLojaOrigem(e.target.value);
                      const errorsLocal = _.cloneDeep(errors);
                      delete errorsLocal.lojaOrigem;
                      setErrors(errorsLocal);
                    }}
                    onBlur={(e) => {
                      saveField("lojaOrigem", e.target.value);
                    }}
                  />
                }
                required
                errorMessage={errors.lojaOrigem}
              />
            )}
            {isFormFiliacao && (
              <CustomVerticalFormField
                icon="fa-solid fa-city"
                htmlForDescription="potenciaOrigem"
                description="Potência de Origem"
                className="p-mb-4"
                inputBody={
                  <InputText
                    id="potenciaOrigem"
                    className="profano-input-max-width"
                    value={potenciaOrigem}
                    onChange={(e) => {
                      setPotenciaOrigem(e.target.value);
                      const errorsLocal = _.cloneDeep(errors);
                      delete errorsLocal.potenciaOrigem;
                      setErrors(errorsLocal);
                    }}
                    onBlur={(e) => {
                      saveField("potenciaOrigem", e.target.value);
                    }}
                  />
                }
                required
                errorMessage={errors.potenciaOrigem}
              />
            )}
          </div>
        </TabPanel>
      </TabView>
    </div>
  );
};

export default EtapaForm;
