import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";

import { useAuth } from "../../hooks/auth";

import { store } from "../../redux/configStore";
import { hideErrorPage } from "../../redux/errorPageSlice";
import ErrorPageComponent from "../ErrorPageComponent";

const NotAuthorizedContainer: React.FC = () => {
  const { signOut } = useAuth();
  const history = useHistory();

  const handleSignOut = useCallback(() => {
    store.dispatch(hideErrorPage());
    signOut();

    history.push("/");
  }, [history, signOut]);

  return (
    <ErrorPageComponent
      code="401"
      message="Desculpe, sua sessão expirou. :("
      backgroundImg=""
      showActionButton
      actionButtonLabel="Fazer login novamente"
      actionButtionCallback={handleSignOut}
    />
  );
};

export default NotAuthorizedContainer;
