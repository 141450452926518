import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { ColumnProps } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Tag } from "primereact/tag";
import { TabPanel, TabView } from "primereact/tabview";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

import _ from "lodash";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { useToast } from "../../../../hooks/toast";
import api from "../../../../services/api";
import SimpleEntityListPage from "../../../../components/SimpleEntityPage";
import SimpleEntityDatatable from "../../../../components/SimpleEntityDatatable";
import CustomVerticalFormField from "../../../../components/CustomVerticalFormField";
import Loading from "../../../../components/Loading";
import { useError } from "../../../../hooks/error";
import { emprestimoErrors } from "../../../../errors/emprestimo";
// import { Exemplares } from "../../../Acervo/AcervoFormPage";

export interface SituacaoEmprestimoElement {
  label: string;
  class: string;
  situacaoEmprestimo: SituacaoEmprestimo;
}
export type SituacaoEmprestimo = "NO_PRAZO" | "ATRASADO" | "DEVOLVIDO" | "PERDIDO";
type SituacaoEmprestimoMap = {
  // eslint-disable-next-line no-unused-vars
  [name in SituacaoEmprestimo]: SituacaoEmprestimoElement;
};

export const situacaoEmprestimoMap: SituacaoEmprestimoMap = {
  NO_PRAZO: {
    label: "No prazo",
    class: "tag-situacao-emprestimo situacao-emprestimo-no-prazo",
    situacaoEmprestimo: "NO_PRAZO",
  },
  ATRASADO: {
    label: "Atrasado",
    class: "tag-situacao-emprestimo situacao-emprestimo-atrasado",
    situacaoEmprestimo: "ATRASADO",
  },
  DEVOLVIDO: {
    label: "Devolvido",
    class: "tag-situacao-emprestimo situacao-emprestimo-devolvido",
    situacaoEmprestimo: "DEVOLVIDO",
  },
  PERDIDO: {
    label: "Perdido",
    class: "tag-situacao-emprestimo situacao-emprestimo-perdido",
    situacaoEmprestimo: "PERDIDO",
  },
};

interface ListEmpretimo {
  uuid: string;
  codigoExemplar: string;
  tituloLivro: string;
  author: string;
  nomeLeitor: string;
  bibliotecario: string;
  dtEmprestimo: string;
  prazo: string;
  situacao: SituacaoEmprestimo;
  dtDevolucao: string;
}

export interface Emprestimo {
  correntes: ListEmpretimo[];
  historico: ListEmpretimo[];
}

interface Leitor {
  codigo: string;
  nome: string;
  uuid: string;
}

interface Exemplar {
  codigo: string;
  estado: string;
  livro: {
    ano: number;
    autor: string;
    edicao: string;
    editora: string;
    exemplares: number;
    isbn: string;
    titulo: string;
    uuid: string;
  };
  situacao: string;
  uuid: string;
}

const EmprestimoPage: React.FC = () => {
  const dt = useRef<DataTable>(null);
  const [loading, setLoading] = useState(false);
  const [loadingExemplar, setLoadingExemplar] = useState<boolean>(false);
  const [livrosEmprestados, setLivrosEmprestados] = useState<ListEmpretimo[]>([]);
  const [livrosDevolvidos, setLivrosDevolvidos] = useState<ListEmpretimo[]>([]);
  const [globalFilter, setGlobalFilter] = useState<string>("");
  const { handleError } = useError();

  const [activeIndex, setActiveIndex] = useState(0);
  const { showToast } = useToast();

  const [leitor, setLeitor] = useState({} as Leitor);
  const [leitorOptions, setLeitorOptions] = useState<Leitor[]>([]);
  const [devolucao, setDevolucao] = useState<number | null>(15);
  const [codigoLivro, setCodigoLivro] = useState<string>("");
  const [exemplar, setExemplar] = useState({} as Exemplar);
  const [errors, setErrors] = useState<{ [campo: string]: string }>({});
  const [showModalConfirmEmprestimo, setShowModalConfirmEmprestimo] = useState<boolean>(false);
  const [showModalDevolucao, setShowModalDevolucao] = useState<boolean>(false);

  const resetFields = useCallback(() => {
    setLeitor({} as Leitor);
    setDevolucao(15);
    setCodigoLivro("");
    setExemplar({} as Exemplar);
    setErrors({});
  }, []);

  const resetFieldsDevolucao = useCallback(() => {
    setCodigoLivro("");
    setExemplar({} as Exemplar);
    setErrors({});
  }, []);

  const loadData = useCallback(() => {
    setLoading(true);
    api
      .get<Emprestimo>(`biblioteca/emprestimos`)
      .then(({ data }) => {
        setLivrosEmprestados(data.correntes);
        setLivrosDevolvidos(data.historico);
      })
      .catch((err) => {
        showToast({
          type: "error",
          title: "Não foi possível carregar os livros emprestados/devolvidos",
          description: "Tente novamente ou contate nosso time de suporte para solicitar ajuda.",
        });
        // eslint-disable-next-line no-console
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [showToast]);

  const loadDropdownLeitor = useCallback(() => {
    api
      .get<Leitor[]>(`biblioteca/emprestimos/dropdown/leitor`)
      .then(({ data }) => {
        setLeitorOptions(data);
      })
      .catch((err) => {
        showToast({
          type: "error",
          title: "Não foi possível carregar os leitores cadastrados",
          description: "Tente novamente ou contate nosso time de suporte para solicitar ajuda.",
        });
        // eslint-disable-next-line no-console
        console.error(err);
      });
  }, [showToast]);

  const findExemplar = useCallback((codigo) => {
    setLoadingExemplar(true);
    api
      .get<Exemplar>(`biblioteca/livros/exemplar/${codigo}`)
      .then(({ data }) => {
        setExemplar(data);
      })
      .catch(() => {
        setExemplar({} as Exemplar);
      })
      .finally(() => setLoadingExemplar(false));
  }, []);

  const isValid = useCallback(() => {
    const errorsLocal: { [name: string]: string } = {};

    if (_.isEmpty(leitor)) {
      errorsLocal.leitor = "O campo leitor é obrigatório";
    }

    if (leitor && !leitor.codigo) {
      errorsLocal.leitor = "Selecione um leitor válido (com código)";
    }

    if (_.isEmpty(codigoLivro)) {
      errorsLocal.codigo = "Digite o código de um exemplar";
    }

    if (_.isEmpty(devolucao?.toString())) {
      errorsLocal.devolucao = "Prazo para devolução é obrigatório";
    }

    if (typeof devolucao !== "number") {
      errorsLocal.devolucao = "Digite uma data de devolução válida";
    }

    setErrors(errorsLocal);
    return _.isEmpty(errorsLocal);
  }, [codigoLivro, devolucao, leitor]);

  const fieldsDevolucaoIsValid = useCallback(() => {
    const errorsLocal: { [name: string]: string } = {};

    if (_.isEmpty(codigoLivro)) {
      errorsLocal.codigo = "Digite o código de um exemplar";
    }

    setErrors(errorsLocal);
    return _.isEmpty(errorsLocal);
  }, [codigoLivro]);

  const newEmprestimo = useCallback(() => {
    if (isValid()) {
      api
        .post("biblioteca/emprestimos", {
          codigoOuNomeLeitor: leitor.codigo,
          codigoLivro,
          prazoDias: devolucao,
        })
        .then(() => {
          showToast({ title: "Sucesso!", description: "Empréstimo confirmado.", type: "success" });
          resetFields();
          setShowModalConfirmEmprestimo(false);
          loadData();
        })
        .catch((err) => {
          handleError({ error: err, action: "confirmar empréstimo", knownErrors: emprestimoErrors });
        });
    }
  }, [codigoLivro, devolucao, handleError, leitor.codigo, showToast, isValid, resetFields, loadData]);

  const newDevoculao = useCallback(() => {
    if (fieldsDevolucaoIsValid()) {
      api
        .put(`biblioteca/emprestimos/${codigoLivro}`)
        .then(() => {
          showToast({ title: "Sucesso!", description: "Devolução do livro confirmada.", type: "success" });
          resetFieldsDevolucao();
          setShowModalDevolucao(false);
          loadData();
        })
        .catch((err) => {
          handleError({ error: err, action: "confirmar devolução", knownErrors: emprestimoErrors });
        });
    }
  }, [codigoLivro, handleError, fieldsDevolucaoIsValid, loadData, resetFieldsDevolucao, showToast]);

  useEffect(() => {
    loadData();
    loadDropdownLeitor();
  }, [loadData, loadDropdownLeitor]);

  const situacaoLivroBodyTemplate = useCallback((data: ListEmpretimo) => {
    if (data.situacao) {
      return (
        <Tag className={situacaoEmprestimoMap[data.situacao].class}>{situacaoEmprestimoMap[data.situacao].label}</Tag>
      );
    }
    return ``;
  }, []);

  const columnsLivrosEmprestados = useMemo(
    (): ColumnProps[] => [
      {
        field: "codigoExemplar",
        header: "Código",
        align: "left",
        sortable: true,
        style: { verticalAlign: "middle", width: "115px" },
      },
      {
        field: "tituloLivro",
        header: "Título",
        sortable: true,
        style: { textAlign: "left", verticalAlign: "middle" },
        className: "p-text-nowrap p-text-truncate",
      },
      {
        field: "author",
        header: "Autor",
        sortable: true,
        style: { textAlign: "left", verticalAlign: "middle", width: "18%" },
        className: "p-text-nowrap p-text-truncate",
      },
      {
        field: "nomeLeitor",
        header: "Leitor",
        sortable: true,
        style: { textAlign: "left", verticalAlign: "middle", width: "20%" },
        className: "p-text-nowrap p-text-truncate",
      },
      {
        field: "bibliotecario",
        header: "Bibliotecário",
        sortable: true,
        style: { textAlign: "left", verticalAlign: "middle", width: "130px" },
        className: "p-text-nowrap p-text-truncate",
      },
      {
        field: "prazo",
        header: "Prazo",
        sortable: false,
        style: { textAlign: "left", verticalAlign: "middle", width: "130px" },
        className: "p-text-nowrap p-text-truncate",
      },
      {
        field: "situacao",
        header: "Situação",
        sortable: false,
        align: "center",
        style: { verticalAlign: "middle", textAlign: "center", width: "120px" },
        body: situacaoLivroBodyTemplate,
      },
    ],
    [situacaoLivroBodyTemplate]
  );

  const columnsLivrosDevolvidos = useMemo(
    (): ColumnProps[] => [
      {
        field: "codigoExemplar",
        header: "Código",
        align: "left",
        sortable: true,
        style: { verticalAlign: "middle", width: "115px" },
      },
      {
        field: "tituloLivro",
        header: "Título",
        sortable: true,
        style: { textAlign: "left", verticalAlign: "middle" },
        className: "p-text-nowrap p-text-truncate",
      },
      {
        field: "nomeLeitor",
        header: "Leitor",
        sortable: true,
        style: { textAlign: "left", verticalAlign: "middle", width: "20%" },
        className: "p-text-nowrap p-text-truncate",
      },
      {
        field: "bibliotecario",
        header: "Bibliotecário",
        sortable: true,
        style: { textAlign: "left", verticalAlign: "middle", width: "130px" },
        className: "p-text-nowrap p-text-truncate",
      },
      {
        field: "dtEmprestimo",
        header: "Retirada",
        sortable: false,
        style: { textAlign: "left", verticalAlign: "middle", width: "130px" },
        className: "p-text-nowrap p-text-truncate",
      },
      {
        field: "prazo",
        header: "Prazo",
        sortable: false,
        style: { textAlign: "left", verticalAlign: "middle", width: "130px" },
        className: "p-text-nowrap p-text-truncate",
      },
      {
        field: "dtDevolucao",
        header: "Devolução",
        sortable: false,
        style: { textAlign: "left", verticalAlign: "middle", width: "130px" },
        className: "p-text-nowrap p-text-truncate",
      },
      {
        field: "situacao",
        header: "Situação",
        sortable: false,
        align: "center",
        style: { verticalAlign: "middle", textAlign: "center", width: "120px" },
        body: situacaoLivroBodyTemplate,
      },
    ],
    [situacaoLivroBodyTemplate]
  );

  return (
    <SimpleEntityListPage
      showListHeader
      newButtonIconFromMaterial="unarchive"
      newButtonLabel="Novo empréstimo"
      onNewButtonCLick={() => setShowModalConfirmEmprestimo(true)}
      onGlobalFilterChange={(value) => setGlobalFilter(value)}
      loading={loading}
      showExportCsvButton
      datatableRef={dt}
      enabledSecondLeftButton
      secondButtonIconFromMaterial="archive"
      secondButtonLabel="Nova devolução"
      secondButtonOnCLick={() => setShowModalDevolucao(true)}
    >
      <Dialog
        header="Novo Empréstimo"
        visible={showModalConfirmEmprestimo}
        modal
        footer={() => (
          <div className="p-mt-3">
            <Button
              label="Confirmar Empréstimo"
              // icon="pi pi-check"
              onClick={() => newEmprestimo()}
              style={{ width: "170px" }}
              autoFocus
            />
          </div>
        )}
        onHide={() => {
          resetFields();
          setShowModalConfirmEmprestimo(false);
        }}
        className="confirm-dialog-container"
        closable
        style={{ minWidth: "430px" }}
      >
        <div className="p-mt-1 p-col p-grid">
          <div className="p-fluid p-col-12">
            <CustomVerticalFormField
              className="color-branco"
              iconMaterial="person"
              htmlForDescription="leitor"
              description="Identifique o leitor"
              inputBody={
                <Dropdown
                  id="leitor"
                  value={leitor}
                  options={leitorOptions}
                  dataKey="uuid"
                  optionLabel="nome"
                  placeholder="Buscar leitor por identificação ou nome"
                  onChange={(e: { value: Leitor }) => {
                    setLeitor(e.value);

                    const errorsLocal = _.cloneDeep(errors);
                    delete errorsLocal.leitor;
                    setErrors(errorsLocal);
                  }}
                  style={{ minWidth: "400px" }}
                  itemTemplate={(l: Leitor) => `${l.codigo} - ${l.nome}`}
                  filter
                  filterBy="nome"
                  emptyMessage="Nenhum leitor encontrado"
                  emptyFilterMessage="Nenhum leitor encontrado"
                />
              }
              errorMessage={errors.leitor}
            />

            <CustomVerticalFormField
              className="color-branco"
              iconMaterial="qr_code"
              htmlForDescription="codigo"
              description="Código do livro"
              inputBody={
                <>
                  <InputText
                    id="codigo"
                    value={codigoLivro}
                    placeholder="Digite o código do livro"
                    onChange={(e) => {
                      setCodigoLivro(e.target.value);
                      findExemplar(e.target.value);

                      const errorsLocal = _.cloneDeep(errors);
                      delete errorsLocal.codigo;
                      setErrors(errorsLocal);
                    }}
                  />
                  <div className="emprestimo-info-leitor">
                    <Loading isLoading={loadingExemplar} />
                    {!loadingExemplar && (
                      <>
                        <div className="container-info-leitor">
                          <span className="info-leitor">Título</span>
                          <span>{exemplar.livro?.titulo}</span>
                        </div>
                        <div className="container-info-leitor">
                          <span className="info-leitor">Autor</span>
                          <span>{exemplar.livro?.autor}</span>
                        </div>
                        <div className="container-info-leitor">
                          <span className="info-leitor">Edição</span>
                          <span>{exemplar.livro?.edicao}</span>
                        </div>
                      </>
                    )}
                  </div>
                </>
              }
              errorMessage={errors.codigo}
            />

            <CustomVerticalFormField
              iconMaterial="event"
              htmlForDescription="devolucao"
              description="Prazo para devolução"
              className="p-mb-4"
              inputBody={
                <InputNumber
                  id="devolucao"
                  value={devolucao}
                  placeholder="Digite o prazo de devolução"
                  className="without-arrows"
                  onChange={(e) => {
                    setDevolucao(e.value);

                    const errorsLocal = _.cloneDeep(errors);
                    delete errorsLocal.devolucao;
                    setErrors(errorsLocal);
                  }}
                  suffix=" dias corridos"
                />
              }
              required
              errorMessage={errors.devolucao}
            />
          </div>
        </div>
      </Dialog>

      <Dialog
        header="Novo Empréstimo"
        visible={showModalDevolucao}
        modal
        footer={() => (
          <div className="p-mt-3">
            <Button
              label="Nova Devolução"
              // icon="pi pi-check"
              onClick={() => newDevoculao()}
              style={{ width: "170px" }}
              autoFocus
            />
          </div>
        )}
        onHide={() => {
          resetFieldsDevolucao();
          setShowModalDevolucao(false);
        }}
        className="confirm-dialog-container"
        closable
        style={{ minWidth: "430px" }}
      >
        <div className="p-mt-1 p-col p-grid">
          <div className="p-fluid p-col-12">
            <CustomVerticalFormField
              className="color-branco"
              iconMaterial="qr_code"
              htmlForDescription="codigo"
              description="Código do livro"
              inputBody={
                <>
                  <InputText
                    id="codigo"
                    value={codigoLivro}
                    placeholder="Digite o código do livro"
                    onChange={(e) => {
                      setCodigoLivro(e.target.value);
                      findExemplar(e.target.value);

                      const errorsLocal = _.cloneDeep(errors);
                      delete errorsLocal.codigo;
                      setErrors(errorsLocal);
                    }}
                  />
                  <div className="emprestimo-info-leitor">
                    <Loading isLoading={loadingExemplar} />
                    {!loadingExemplar && (
                      <>
                        <div className="container-info-leitor">
                          <span className="info-leitor">Título</span>
                          <span>{exemplar.livro?.titulo}</span>
                        </div>
                        <div className="container-info-leitor">
                          <span className="info-leitor">Autor</span>
                          <span>{exemplar.livro?.autor}</span>
                        </div>
                        <div className="container-info-leitor">
                          <span className="info-leitor">Edição</span>
                          <span>{exemplar.livro?.edicao}</span>
                        </div>
                      </>
                    )}
                  </div>
                </>
              }
              errorMessage={errors.codigo}
            />
          </div>
        </div>
      </Dialog>

      <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} className="modelo-container">
        <TabPanel header="Correntes">
          <SimpleEntityDatatable
            datatableRef={dt}
            value={livrosEmprestados}
            columns={columnsLivrosEmprestados}
            globalFilter={globalFilter}
            sortField="tituloLivro"
            sortOrder={1}
          />
        </TabPanel>
        <TabPanel header="Histórico">
          <SimpleEntityDatatable
            datatableRef={dt}
            value={livrosDevolvidos}
            columns={columnsLivrosDevolvidos}
            globalFilter={globalFilter}
            sortField="tituloLivro"
            sortOrder={1}
          />
        </TabPanel>
      </TabView>
    </SimpleEntityListPage>
  );
};

export default EmprestimoPage;
//
