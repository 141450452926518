/* eslint-disable no-unused-vars */
import _ from "lodash";
import { Avatar } from "primereact/avatar";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { ColumnProps } from "primereact/column";
import { confirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { Tag } from "primereact/tag";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { v4 } from "uuid";
import CustomVerticalFormField from "../CustomVerticalFormField";
import IrmaoComponent from "../IrmaoComponent";
import Loading from "../Loading";
import SimpleEntityDatatable from "../SimpleEntityDatatable";
import SimpleEntityPage from "../SimpleEntityPage";
import { openUrlNewTab } from "../../utils/url";

export type SituacaoSindicancia = "PENDENTE" | "REALIZADO";

export interface IrmaoDTO {
  id: number;
  uuid: string;
  nome: string;
  foto?: string;
  sindicante?: boolean;
}

export interface SindicanciaProfano {
  id: number | null;
  uuid: string;
  sindicante?: IrmaoDTO;
  situacaoSindicancia?: SituacaoSindicancia;
  dtLimite?: Date | Date[];
  favoravel?: boolean;
}

interface SindicanciasProfanoProps {
  profanoUuid: string;
  sindicancias?: SindicanciaProfano[];
  loading?: boolean;
  onAdd: (sindicancia: SindicanciaProfano) => void;
  onRemove: (sindicanciaId: string) => void;
  onUpdate: (sindicanciaId: string, sindicancia: SindicanciaProfano) => void;
  sindicantesOptions?: IrmaoDTO[];
  loadingSindicantesOptions?: boolean;
}

const SindicanciasProfano: React.FC<SindicanciasProfanoProps> = ({
  profanoUuid,
  sindicancias = [],
  loading = false,
  onAdd,
  onRemove,
  onUpdate,
  sindicantesOptions = [],
  loadingSindicantesOptions = false,
}) => {
  const PATH_FICHA_SINDICANCIA = "/pdf/fichaSindicancia/";
  const PATH_FICHA_SINDICANCIA_PREENCHIDA = "/pdf/fichaPreenchidaSindicancia/";
  const PATH_FICHAS_SINDICANCIAS_MERGE = `/profanos/detalhes/${profanoUuid}/sindicanciasMerge/`;

  const [dtLimite, setDtLimite] = useState<Date | Date[] | undefined>(new Date());
  const [sindicante, setSindicante] = useState<IrmaoDTO>();

  const [displayNewSindicanciaDialog, setDisplayNewSindicanciaDialog] = useState(false);
  const [dialogErrors, setDialogErrors] = useState<{ [name: string]: string }>({});

  const MAX_NUMBER_SINDICANCIAS = 3;
  const dt = useRef<DataTable>(null);

  const handleAddSindicancia = useCallback((sindicancia: SindicanciaProfano) => onAdd(sindicancia), [onAdd]);

  const handleRemoveSindicancia = useCallback((sindicanciaId: string) => onRemove(sindicanciaId), [onRemove]);

  const handleUpdateSindicancia = useCallback(
    (sindicanciaId: string, sindicancia: SindicanciaProfano) => onUpdate(sindicanciaId, sindicancia),
    [onUpdate]
  );

  const editDeleteButtonsBodyTemplate = useCallback(
    (data: SindicanciaProfano) => {
      return (
        <>
          <Button
            type="button"
            className="p-ml-3 balandrau-exclude-button "
            icon="pi pi-trash"
            onClick={() => {
              confirmDialog({
                message: "Após a exclusão o item não poderá ser recuperado.",
                header: "Excluir sindicante?",
                icon: "pi pi-question-circle",
                focusOnShow: false,
                rejectLabel: "Manter",
                acceptLabel: "Excluir",
                accept: () => handleRemoveSindicancia(data.uuid),
                reject: () => {},
              });
            }}
          />
        </>
      );
    },
    [handleRemoveSindicancia]
  );

  const nomeBodyTemplate = useCallback((rowData: SindicanciaProfano) => {
    return rowData.sindicante ? <IrmaoComponent nome={rowData.sindicante.nome} foto={rowData.sindicante.foto} /> : "";
  }, []);

  const situacaoSindicanciaBodyTemplate = useCallback((rowData: SindicanciaProfano) => {
    if (rowData.situacaoSindicancia === "PENDENTE") {
      return <Tag className="situacao-sindicancia sindicancia-profano-aguardando">Aguardando</Tag>;
    }
    if (rowData.situacaoSindicancia === "REALIZADO") {
      return <Tag className="situacao-sindicancia sindicancia-profano-enviada">Enviada</Tag>;
    }
    return "";
  }, []);

  const favoravelBodyTemplate = useCallback((rowData: SindicanciaProfano) => {
    if (rowData.situacaoSindicancia !== "REALIZADO" || rowData.favoravel === undefined) {
      return "--";
    }
    return rowData.favoravel ? "Favorável" : "Desfavorável";
  }, []);

  const dtLimiteBodyTemplate = useCallback((rowData: SindicanciaProfano) => {
    return rowData.dtLimite && !_.isArray(rowData.dtLimite)
      ? new Date(rowData.dtLimite).toLocaleDateString("pt-BR", {
          timeZone: "UTC",
        })
      : "";
  }, []);
  // /pdf/fichaSindicancia/be1c4f96-a9df-45af-aa6e-fdf18cef8336
  const avaliacoesBodyTemplate = useCallback(
    (rowData: SindicanciaProfano) => {
      // return rowData.situacaoSindicancia !== "REALIZADO" ? "--" : "Ver ficha";
      if (rowData.id === null) return "--";
      const url =
        rowData.situacaoSindicancia === "REALIZADO"
          ? `${PATH_FICHA_SINDICANCIA_PREENCHIDA}${rowData.uuid}`
          : `${PATH_FICHA_SINDICANCIA}${profanoUuid}`;
      return (
        <a className="color-link" href={url} target="_blank" rel="noreferrer">
          Ver ficha
        </a>
      );
    },
    [profanoUuid]
  );

  const columnsSindicancias: ColumnProps[] = useMemo((): ColumnProps[] => {
    return [
      {
        field: "sindicante.nome",
        header: "Sindicante",
        sortable: false,
        style: { textAlign: "left", verticalAlign: "middle" },
        body: nomeBodyTemplate,
      },
      {
        field: "situacaoSindicancia",
        header: "Status da sindicância",
        sortable: false,
        align: "center",
        style: { verticalAlign: "middle", width: 150 },
        body: situacaoSindicanciaBodyTemplate,
      },
      {
        field: "favoravel",
        header: "Aval. final",
        sortable: false,
        align: "center",
        style: { verticalAlign: "middle", width: 120 },
        body: favoravelBodyTemplate,
      },
      {
        field: "dtLimite",
        header: "Data Limite",
        sortable: false,
        align: "center",
        style: { verticalAlign: "middle", width: 120 },
        body: dtLimiteBodyTemplate,
      },
      {
        field: "uuid",
        header: "Avaliações",
        sortable: false,
        align: "center",
        style: { verticalAlign: "middle", width: 120 },
        body: avaliacoesBodyTemplate,
      },
      {
        align: "center",
        body: editDeleteButtonsBodyTemplate,
        style: { width: "100px", verticalAlign: "middle" },
      },
    ];
  }, [
    avaliacoesBodyTemplate,
    dtLimiteBodyTemplate,
    editDeleteButtonsBodyTemplate,
    favoravelBodyTemplate,
    nomeBodyTemplate,
    situacaoSindicanciaBodyTemplate,
  ]);

  const yearNavigatorTemplate = useCallback((e: any) => {
    return (
      <Dropdown
        value={e.value}
        options={e.options}
        onChange={(event) => e.onChange(event.originalEvent, event.value)}
        className="p-ml-2"
        style={{ lineHeight: 1 }}
      />
    );
  }, []);

  const monthNavigatorTemplate = useCallback((e: any) => {
    return (
      <Dropdown
        value={e.value}
        options={e.options}
        onChange={(event) => e.onChange(event.originalEvent, event.value)}
        style={{ lineHeight: 1 }}
      />
    );
  }, []);

  const onHideNewSindicanciaDialog = () => {
    setSindicante(undefined);
    // setDtLimite(undefined);
    setDialogErrors({});
    setDisplayNewSindicanciaDialog(false);
  };

  const checkSindicancia = useCallback((): boolean => {
    const errors: { [name: string]: string } = {};
    if (!dtLimite) {
      // TODO: throw erro required
      errors.dtLimite = "Selecione uma data limite.";
    }
    if (!sindicante) {
      // TODO: throw erro required
      errors.sindicante = "Selecione um sindicante.";
    } else if (sindicante.uuid) {
      const indexFound = sindicancias.findIndex((sindicancia) => sindicancia.sindicante?.uuid === sindicante.uuid);
      if (indexFound >= 0) errors.sindicante = "O sindicante selecionado já está participando desta sindicância";
    }

    setDialogErrors(errors);
    return _.isEmpty(errors);
  }, [dtLimite, sindicancias, sindicante]);

  const newSindicanciaDialogFooter = () => {
    if (!loadingSindicantesOptions)
      return (
        <div className="p-mt-3">
          <Button
            label="Cancelar"
            icon="pi pi-times"
            onClick={() => onHideNewSindicanciaDialog()}
            className="p-button-text"
          />
          <Button
            label="Adicionar Sindicância"
            icon="pi pi-plus"
            onClick={() => {
              if (checkSindicancia()) {
                handleAddSindicancia({ id: null, uuid: `fake@${v4()}`, dtLimite, sindicante });
                onHideNewSindicanciaDialog();
              }
            }}
            style={{ width: "190px", marginRight: 32 }}
            autoFocus
          />
        </div>
      );
    return "";
  };

  const newSindicanciaDialogHeader = useCallback(() => {
    return (
      <h1 className="default-dialog-header" style={{ minWidth: 400 }}>
        Sindicância
      </h1>
    );
  }, []);

  const sindicantesDropdownItemTemplate = useCallback((option: IrmaoDTO) => {
    return (
      <div className="">
        <Tag
          icon={option.sindicante ? "pi pi-lock-open" : "pi pi-lock"}
          className={option.sindicante ? "situacao-profano-aprovado" : "situacao-profano-rejeitado"}
          style={{ color: "#4b4b4b", height: 20 }}
          value={option.sindicante ? "com acesso" : "sem acesso"}
        />
        <span className="p-ml-2"> {option.nome}</span>
      </div>
    );
  }, []);

  return (
    <SimpleEntityPage
      isFormPage
      showTopBar={false}
      showListHeader
      newButtonLabel="Nova sindicância"
      onNewButtonCLick={() => {
        setDisplayNewSindicanciaDialog(true);
      }}
      newButtonDisabled={sindicancias.length >= MAX_NUMBER_SINDICANCIAS}
      newButtonTooltip={
        sindicancias.length >= MAX_NUMBER_SINDICANCIAS ? "O número máximo de sindicâncias foi atingido" : undefined
      }
      loading={loading}
      datatableRef={dt}
      hidePagePadding
    >
      <Dialog
        header={newSindicanciaDialogHeader}
        visible={displayNewSindicanciaDialog}
        modal
        footer={newSindicanciaDialogFooter()}
        onHide={() => onHideNewSindicanciaDialog()}
        className="confirm-dialog-container"
        closable
        style={{ minWidth: "430px" }}
      >
        <div className="default-dialog-content">
          {loadingSindicantesOptions && (
            <div style={{ height: 100 }}>
              <Loading isLoading={loadingSindicantesOptions} />
            </div>
          )}

          {!loadingSindicantesOptions && (
            <div className="confirm-dialog-content">
              <CustomVerticalFormField
                // className="p-mt-2 debug-green"
                icon="fa-solid fa-user-tie"
                htmlForDescription="dtLimite"
                description="Sindicante"
                inputBody={
                  <Dropdown
                    value={sindicante}
                    options={sindicantesOptions}
                    dataKey="uuid"
                    optionLabel="nome"
                    placeholder="Selecione"
                    onChange={(e) => {
                      setSindicante(e.target.value);
                      const errors = { ...dialogErrors };
                      delete errors.sindicante;
                      setDialogErrors(errors);
                    }}
                    style={{ minWidth: "400px" }}
                    itemTemplate={sindicantesDropdownItemTemplate}
                    filter
                    filterBy="nome"
                    emptyMessage="Nenhum irmão encontrado"
                    emptyFilterMessage="Nenhum irmão encontrado"
                  />
                }
                errorMessage={dialogErrors.sindicante}
              />

              <CustomVerticalFormField
                className="p-mt-3 "
                icon="pi pi-calendar"
                htmlForDescription="dtLimite"
                description="Data limite para a sindicância"
                inputBody={
                  <Calendar
                    id="dtLimite"
                    // className="p-ml-2"
                    value={dtLimite}
                    onChange={(e) => {
                      setDtLimite(e.value);
                      const errors = { ...dialogErrors };
                      delete errors.dtLimite;
                      setDialogErrors(errors);
                    }}
                    mask="99/99/9999"
                    placeholder="Digite ou selecione"
                    monthNavigator
                    yearNavigator
                    yearRange="1800:2050"
                    monthNavigatorTemplate={monthNavigatorTemplate}
                    yearNavigatorTemplate={yearNavigatorTemplate}
                    locale="pt-br"
                    minDate={new Date()}
                    showIcon
                  />
                }
                errorMessage={dialogErrors.dtLimite}
              />

              {sindicante && !sindicante.sindicante && (
                <div className=" p-d-flex p-jc-start p-mt-2 p-ai-center" style={{ width: 400 }}>
                  <i style={{ fontSize: 20 }} className="fas fa-exclamation-triangle p-mr-2 p-warn" />
                  <small className="p-warn p-mt-0 custom-vertical-form-field-error" style={{ width: 320 }}>
                    O irmão selecionado não tem permissão de sindicante. Ao continuar e salvar, a permissão será
                    atribuída automaticamente e será possível conferir no Controle de Acesso.
                  </small>
                </div>
              )}
            </div>
          )}
        </div>
      </Dialog>
      <SimpleEntityDatatable
        datatableRef={dt}
        columns={columnsSindicancias}
        value={sindicancias}
        dataKey="uuid"
        paginator={false}
        emptyMessage="Nenhuma sindicância"
        rows={undefined}
      />
      <div className="p-mt-4 p-d-flex p-flex-row p-ai-center p-jc-end ">
        <Button
          label="Mesclar todos (pdf)"
          icon="fa-solid fa-file-pdf"
          onClick={() => openUrlNewTab(`${PATH_FICHAS_SINDICANCIAS_MERGE}`)}
        />
      </div>
    </SimpleEntityPage>
  );
};
export default SindicanciasProfano;
