import { IAppError } from "./AppErrorInterfaces";

export const emprestimoErrors: IAppError[] = [
  {
    code: "UUID inválido.",
    message: "Alteração inválida na url.",
  },
  {
    code: "Leitor não encontrado.",
    title: "Leitor não encontrado.",
    message: "Selecione outro leitor e tente novamente",
  },
  {
    code: "Exemplar não encontrado.",
    title: "Não foi possível encontrar este exemplar",
    message: "Tente novamente ou entre em contato com um administrador",
  },
  {
    code: "Exemplar Local não pode ser emprestado.",
    title: "Este é um livro local e não pode ser emprestado",
    message: "Insira outro código de exemplar",
  },
  {
    code: "Empréstimo não encontrado.",
    title: "Empréstimo não encontrado.",
    message: "Verifique corretamente os dados e tente novamente",
  },
  {
    code: "Exemplar já devolvido para biblioteca.",
    title: "Exemplar já devolvido para biblioteca.",
    message: "Verifique corretamente os dados e tente novamente",
  },
  {
    code: "Este exemplar já foi emprestado.",
    title: "Este exemplar já foi emprestado.",
    message: "Digite outro código ou faça a devolução deste exemplar",
  },
];
