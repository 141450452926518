import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { confirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { ColumnProps } from "primereact/column";
import { Button } from "primereact/button";
import { useHistory } from "react-router-dom";

import SimpleEntityListPage from "../../../components/SimpleEntityPage";
import SimpleEntityDatatable from "../../../components/SimpleEntityDatatable";
import { convertDateToStringBR } from "../../../utils/convertDates";
import { turmaErrors } from "../../../errors/turma";
import { useToast } from "../../../hooks/toast";
import { useError } from "../../../hooks/error";
import api from "../../../services/api";
import { TurmaDTO } from "../utils";

const TurmaPage: React.FC = () => {
  const dt = useRef<DataTable>(null);

  const [loading, setLoading] = useState(false);
  const [turmas, setTurmas] = useState<TurmaDTO[]>([]);
  const [globalFilter, setGlobalFilter] = useState("");

  const history = useHistory();
  const { showToast } = useToast();
  const { handleError } = useError();

  const loadData = useCallback(() => {
    setLoading(true);

    api
      .get("turmas")
      .then(({ data }) => {
        setTurmas(data);
      })
      .catch(() => {
        showToast({
          type: "error",
          title: "Não foi possível carregar as turmas",
          description: "Tente novamente ou contate nosso time de suporte para solicitar ajuda.",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [showToast]);

  const excluirTurma = useCallback(
    (data: TurmaDTO) => {
      api
        .delete(`turmas/${data.uuid}`)
        .then(() => {
          showToast({ title: "Sucesso!", type: "success", description: "A turma foi removida." });
          loadData();
        })
        .catch((err) => {
          handleError({ error: err, action: "remover turma", knownErrors: turmaErrors });
        });
    },
    [handleError, loadData, showToast]
  );

  const confirmExclusao = useCallback(
    (data: TurmaDTO) => {
      confirmDialog({
        header: "Remover turma?",
        message: "Após a remoção o item não poderá ser recuperado.",
        icon: "pi pi-question-circle",
        focusOnShow: false,
        rejectLabel: "Manter",
        acceptLabel: "Remover",
        accept: () => excluirTurma(data),
        reject: () => {},
      });
    },
    [excluirTurma]
  );

  const onGlobalFilterChange = useCallback((filterValue) => {
    setGlobalFilter(filterValue);
  }, []);

  const editDeleteButtonsBodyTemplate = useCallback(
    (data: TurmaDTO) => {
      return (
        <>
          <Button
            type="button"
            className="p-row-editor-init p-link "
            icon="pi pi-eye"
            tooltip="Detalhes da turma"
            tooltipOptions={{ position: "top", style: { width: 80, textAlign: "center" } }}
            onClick={() => {
              history.push(`/turma/${data.uuid}`);
            }}
          />
          <Button
            type="button"
            className="p-ml-3 balandrau-exclude-button"
            icon="pi pi-trash"
            onClick={() => {
              confirmExclusao(data);
            }}
          />
        </>
      );
    },
    [confirmExclusao, history]
  );

  const dtIniciacaoBodyTemplate = useCallback((data: TurmaDTO) => {
    return convertDateToStringBR(data.dtIniciacao);
  }, []);

  const columnsProfanos = useMemo(
    (): ColumnProps[] => [
      {
        field: "nome",
        header: "Nome",
        sortable: true,
        style: { verticalAlign: "middle" },
        align: "left",
      },
      {
        field: "dtIniciacao",
        header: "Data de iniciação",
        sortable: true,
        style: { verticalAlign: "middle", width: 180 },
        align: "center",
        body: dtIniciacaoBodyTemplate,
      },
      {
        body: editDeleteButtonsBodyTemplate,
        style: { width: "50px", textAlign: "center", verticalAlign: "middle" },
      },
    ],
    [dtIniciacaoBodyTemplate, editDeleteButtonsBodyTemplate]
  );

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <SimpleEntityListPage
      showListHeader
      onGlobalFilterChange={(value) => onGlobalFilterChange(value)}
      loading={loading}
      showExportCsvButton
      datatableRef={dt}
      newButtonLabel="Nova turma"
      onNewButtonCLick={() => {
        history.push("/turma/novo");
      }}
    >
      <SimpleEntityDatatable
        datatableRef={dt}
        value={turmas}
        columns={columnsProfanos}
        globalFilter={globalFilter}
        sortField="dtIniciacao"
        sortOrder={-1}
      />
    </SimpleEntityListPage>
  );
};

export default TurmaPage;
