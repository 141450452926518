import { IAppError } from "./AppErrorInterfaces";

export const publicacaoErrors: IAppError[] = [
  {
    code: "UUID inválido.",
    message: "Alteração inválida na url.",
  },
  {
    code: "Publicação não encontrada.",
    title: "Publicação não encontrada.",
    message: "Entre em contato com nosso time de suporte para solicitar ajuda.",
  },
  { code: "Data de publicação inválida.", title: "Data de publicação inválida.", message: "Selecione uma data válida" },
  {
    code: "Arquivo não encontrado ou inexistente.",
    title: "Arquivo não encontrado ou inexistente.",
    message: "Selecione um arquivo válido.",
  },
  {
    code: "Não foi possível salvar o arquivo.",
    title: "Não foi possível salvar o arquivo.",
    message: "Entre em contato com nosso time de suporte para solicitar ajuda.",
  },
];
