import { IAppError } from "./AppErrorInterfaces";

export const cobrancaErrors: IAppError[] = [
  {
    code: "UUID inválido.",
    message: "Alteração inválida na url.",
  },
  {
    code: "Cobranca não encontrada.",
    title: "Cobrança não encontrada.",
    message: "Entre em contato com nosso time de suporte para solicitar ajuda.",
  },
  {
    code: "Irmão não encontrado.",
    title: "Irmão não encontrado.",
    message: "Entre em contato com nosso time de suporte para solicitar ajuda.",
  },
  {
    code: "É necessário haver ao menos 1 irmão para ser realizado uma cobrança.",
    title: "Não é possível criar uma cobrança sem selecionar ao menos um irmão",
    message: "Selecione um irmão e tente novamente.",
  },
  {
    code: "Não pode mudar os dados de uma cobrança PAGA.",
    title: "Não é possível alterar esta cobrança",
    message: "Não é possível mudar os dados de uma cobrança paga",
  },
  {
    code: "Não é possível confirmar pagamento a uma cobrança PAGA.",
    title: "Não é possível confirmar o pagamento desta cobrança.",
    message: "A cobrança já foi paga.",
  },
  {
    code: "Não é possível cancelar uma cobrança PAGA.",
    title: "Não é possível cancelar esta cobrança.",
    message: "A cobrança já foi paga",
  },
  {
    code: "Não é possível reprocessar cobranças com o status diferente de ERRO ou GERADO.",
    title: "Não é possível reprocessar essa cobrança.",
    message: "É necessário que a cobrança esteja na situação de erro ou com a situação gerado",
  },
  {
    code: "Não é possível reprocessar uma cobrança que já existe na api de cobranças.",
    title: "Não é possível reprocessar essa cobrança.",
    message: "Entre em contato com nosso time de suporte para solicitar ajuda.",
  },
  {
    code: "Já existe uma url de pagamento para esta cobrança.",
    title: "Já existe um link associado a esta cobrança.",
    message: "Não é possível gerar um novo link de cobrança.",
  },
  {
    code: "Existe uma cobrança no asaas vinculada com esta cobrança.",
    title: "O link para esta cobrança existe mas foi perdido.",
    message: "Entre em contato com nosso time de suporte para solicitar ajuda.",
  },
  {
    code: "Erro de integração com API do ASAAS",
    title: "No momento, não foi possível realizar esta operação.",
    message: "Tente novamente mais tarde, ou entre em contato com nosso time de suporte para solicitar ajuda.",
  },

  {
    code: "O valor final com desconto precisa ser maior que 5 reais.",
    title: "O valor final com desconto precisa ser maior que R$5,00.",
    message: "Atualize o valor da cobrança ou do desconto e tente novamente.",
  },
  {
    code: "Não é possível alterar a cobrança com a data vencida.",
    title: "Não é possível atualizar uma cobrança vencida.",
    message: "Atualize o vencimento para prosseguir com as alterações.",
  },
];
