import React from "react";

// import "./styles.scss";
// import "../../scss/smallCss.scss";
import { useHistory } from "react-router-dom";

type TopbarProp = {
  saveCommand?: any;
  excludeCommand?: any;
  backMessage?: string;
  excludeMessage?: string;
  saveMessage?: string;
  routeBack?: string;
};

const ContentTopBar: React.FC<TopbarProp> = ({
  saveCommand,
  excludeCommand,
  backMessage = "Voltar",
  excludeMessage = "Excluir",
  saveMessage = "Salvar",
  routeBack,
}) => {
  const history = useHistory();
  return (
    <div className="content-topbar">
      <button
        type="button"
        className="p-button-text flex-row-center"
        onClick={() => {
          if (routeBack) history.push(routeBack);
          else history.goBack();
        }}
      >
        <div className="flex-row-center">
          <i className="pi pi-arrow-left p-mr-2" />
          <p>{backMessage}</p>
        </div>
      </button>
      <div className="flex-row-center">
        {excludeCommand && excludeMessage && (
          <button type="button" className="p-button-text flex-row-center" onClick={excludeCommand}>
            <i className="pi pi-trash p-mr-2" />
            <p>{excludeMessage}</p>
          </button>
        )}

        {saveCommand && saveMessage && (
          <button type="button" className="p-button-text flex-row-center" onClick={saveCommand}>
            <i className="pi pi-save p-mr-2" />
            <p>{saveMessage}</p>
          </button>
        )}
      </div>
    </div>
  );
};

export default ContentTopBar;
