import React, { useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { TabView, TabPanel } from "primereact/tabview";
import { useParams, useHistory, useLocation } from "react-router-dom";

import { confirmDialog } from "primereact/confirmdialog";
import SimpleEntityListPage from "../../../components/SimpleEntityPage";

import api from "../../../services/api";
import { useToast } from "../../../hooks/toast";
import { useError } from "../../../hooks/error";
import FloatingSave from "../../../components/FloatingSave";
import AnexosProfano, { AnexoProfano } from "../../../components/AnexosProfano";
import {
  SituacaoFiliando,
  SituacaoFiliandoElement,
  situacoesFiliandoMap,
} from "../../../components/FiliandoSituacaoComponent";
import InformacoesFiliando from "../../../components/InformacoesFiliando";
import { filiandoErrors } from "../../../errors/filiando";

interface Params {
  id: string;
}

interface PageParams {
  nome: string;
}

// export interface TurmaProfano {
//   uuid: string;
//   nome: string;
//   dtIniciacao: string;
// }

export interface FiliandoInfo {
  uuid: string;
  nome: string;
  foto: string;
  situacao: SituacaoFiliando;
}

export interface FiliandoForm {
  informacoes: FiliandoInfo;
  anexos: AnexoProfano[];
}

const ProfanoFormPage: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingSalvar, setLoadingSalvar] = useState(false);

  const [filiandoInicial, setFiliandoInicial] = useState<FiliandoForm>({} as FiliandoForm);

  const [nome, setNome] = useState<string>();
  const [informacoes, setInformacoes] = useState({} as FiliandoInfo);
  const [anexos, setAnexos] = useState<AnexoProfano[]>([]);

  const situacaoFiliandoOptions = useMemo(
    (): SituacaoFiliandoElement[] => [
      situacoesFiliandoMap.AGUARDANDO,
      situacoesFiliandoMap.LIDO,
      situacoesFiliandoMap.APROVADO,
      situacoesFiliandoMap.PAGO,
      situacoesFiliandoMap.FILIADO,
      situacoesFiliandoMap.REJEITADO,
      situacoesFiliandoMap.DESISTENCIA,
    ],
    []
  );

  const { showToast } = useToast();
  const { handleError } = useError();

  const { id } = useParams<Params>();
  const location = useLocation<PageParams>();
  const history = useHistory();

  const loadData = useCallback(() => {
    setLoading(true);
    api
      .get<FiliandoForm>(`filiandos/detalhes/${id}`)
      .then(({ data: filiando }) => {
        setFiliandoInicial(filiando);
        if (filiando.informacoes && filiando.informacoes.nome) setNome(filiando.informacoes.nome);
        if (filiando.informacoes) setInformacoes(filiando.informacoes);
        if (filiando.anexos) setAnexos(filiando.anexos);
      })
      .catch((error) => {
        showToast({
          type: "error",
          title: "Não foi possível carregar o filiando",
          description: "Tente novamente ou contate nosso time de suporte para solicitar ajuda.",
        });
        // eslint-disable-next-line no-console
        console.error(error);
      })
      .finally(() => setLoading(false));
  }, [id, showToast]);

  const redefinirFiliando = useCallback(() => {
    loadData();
  }, [loadData]);

  const onAnexoChange = useCallback(
    (anexoId: string, anexo: AnexoProfano) => {
      const newAnexos = _.cloneDeep(anexos);
      const indexFound = newAnexos.findIndex((anex) => anex.uuid === anexoId);
      if (indexFound >= 0) {
        newAnexos[indexFound] = anexo;
        setAnexos(newAnexos);
      }
    },
    [anexos]
  );

  const salvarFiliando = useCallback(() => {
    setLoadingSalvar(true);
    api
      .put(`filiandos/detalhes/${id}`, {
        informacoes: {
          situacao: informacoes.situacao,
        },
        anexos,
      })
      .then(() => {
        showToast({ title: "Sucesso!", type: "success", description: "O filiando foi editado e salvo." });
        history.push("/filiando");
      })
      .catch((err) => {
        handleError({ error: err, action: "atualizar filiando", knownErrors: filiandoErrors });
      })
      .finally(() => setLoadingSalvar(false));
  }, [anexos, handleError, history, id, informacoes.situacao, showToast]);

  const checkSituacao = useCallback(() => {
    if (informacoes.situacao === "FILIADO") {
      confirmDialog({
        message: "Após a mudança, a situação não poderá ser alterada.",
        header: "Mudar situação?",
        icon: "pi pi-question-circle",
        focusOnShow: false,
        rejectLabel: "Não",
        acceptLabel: "Sim",
        accept: () => salvarFiliando(),
        reject: () => redefinirFiliando(),
      });
    } else {
      salvarFiliando();
    }
  }, [informacoes.situacao, salvarFiliando, redefinirFiliando]);

  const isDirty = useCallback(() => {
    return (
      !_.isEmpty(filiandoInicial) &&
      (!_.isEqual(informacoes, filiandoInicial.informacoes) || !_.isEqual(anexos, filiandoInicial.anexos))
    );
  }, [anexos, informacoes, filiandoInicial]);

  useEffect(() => {
    if (location.state && location.state.nome) {
      setNome(location.state.nome);
    }
    loadData();
  }, [id, loadData, location.state]);

  return (
    <SimpleEntityListPage showTopBar routeBack="/filiando" isFormPage loading={loading}>
      {nome && <h1 className="header-h1-text p-mb-3 p-mt-1">Filiando {nome}</h1>}

      <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} className="modelo-container">
        <TabPanel header="Informações">
          <InformacoesFiliando
            loading={loading}
            informacoes={informacoes}
            dropdownSituacaoFiliandoOptions={situacaoFiliandoOptions}
            onSituacaoChange={(e) => {
              const cloneInformacao = _.cloneDeep(informacoes);
              cloneInformacao.situacao = e;
              setInformacoes(cloneInformacao);
            }}
          />
        </TabPanel>
        <TabPanel header="Anexos">
          <AnexosProfano loading={loading} anexos={anexos} onChange={onAnexoChange} profanoUuid={id} isFiliacao />
        </TabPanel>
      </TabView>
      {isDirty() && (
        <FloatingSave
          saveCommand={checkSituacao}
          resetCommand={redefinirFiliando}
          disabled={!isDirty()}
          loadingOnSave={loadingSalvar}
        />
      )}
    </SimpleEntityListPage>
  );
};

export default ProfanoFormPage;
