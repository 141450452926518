import React, { useCallback, useMemo } from "react";
import { FaturamentoProps } from "../../pages/DashboardPage";
import { numberToReal } from "../../utils/numbers";

interface Props {
  icon: string;
  title: string;
  value: FaturamentoProps | null;
}

const CardDashboardFaturamento: React.FC<Props> = ({ icon, title, value }) => {
  const valorTotalPrevisto = useMemo(() => {
    return value?.previsto ? value.previsto : 0;
  }, [value]);

  const valorTotalRecebido = useMemo(() => {
    return value?.recebido ? value.recebido : 0;
  }, [value]);

  const valorPorcentagem = useCallback(() => {
    if (value && value.cobrancasPrevistas && value.cobrancasPagas) {
      const teste = (value.cobrancasPagas * 100) / value.cobrancasPrevistas;
      return `${teste.toFixed(0)}%`;
    }
    return "0%";
  }, [value]);

  return (
    <div>
      <div className="p-mb-1 title-dashboard">
        <i className={`p-mr-2 ${icon}`} />
        {title}
      </div>
      <div className="dashboard-card-container-faturamento">
        <div className="p-text-nowrap p-text-truncate faturamento-card">
          <div className="faturamento-card-previsto">
            <div className="text-faturamento p-pb-2">Previsto</div>
            <div className="valores-faturamento">{value?.cobrancasPrevistas} cobranças</div>
            <div className="text-faturamento">no valor total de</div>
            <div className="valores-faturamento">{numberToReal(valorTotalPrevisto)}</div>
          </div>
          <div className="faturamento-card-recebido">
            <div className="text-faturamento p-pb-2">Recebido</div>
            <div className="valores-faturamento">{value?.cobrancasPagas} cobranças</div>
            <div className="text-faturamento">no valor total de</div>
            <div className="valores-faturamento">{numberToReal(valorTotalRecebido)}</div>
            <div className="text-faturamento">({valorPorcentagem()} do previsto)</div>
          </div>
          {/* <div
            className={`p-d-flex p-ai-center linha-card ${value. === "PREVISTO" && "p-pb-4"} ${
              cardDashboardFaturamentoMap[campo].class
            }`}
          >
            <div
              className="p-pb-1 dashboard-text-value"
              style={
                campo === "PREVISTO"
                  ? {
                      width: "45%",
                    }
                  : undefined
              }
            >
              {total}
            </div>
            {campo !== "PREVISTO" && <div className="p-pb-1 dashboard-text-porcentage">{total}</div>}
            <div className="dashboard-text-campo p-mr-5">{cardDashboardFaturamentoMap[campo].label}</div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default CardDashboardFaturamento;
