import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import React, { useCallback } from "react";
import { FiliandoInfo } from "../../pages/Filiando/FiliandoFormPage";
import CustomFormField from "../CustomFormField";
import { SituacaoFiliando, SituacaoFiliandoElement } from "../FiliandoSituacaoComponent";
import PreviewSelectAvatar from "../PreviewSelectAvatar";
// import { SituacaoProfano, SituacaoProfanoElement } from "../ProfanoSituacaoComponent";
import SimpleEntityPage from "../SimpleEntityPage";

interface Props {
  loading: boolean;
  informacoes: FiliandoInfo;
  dropdownSituacaoFiliandoOptions: SituacaoFiliandoElement[];
  // eslint-disable-next-line no-unused-vars
  onSituacaoChange: (situacao: SituacaoFiliando) => void;
}

const InformacoesFiliando: React.FC<Props> = ({
  loading,
  informacoes,
  dropdownSituacaoFiliandoOptions,
  onSituacaoChange,
}) => {
  const PATH_FICHA_FILIANDO = "/pdf/fichaFiliando/";

  const openUrlNewTab = useCallback((url: string) => {
    const opened = window.open(url, "_blank", "noopener,noreferrer");
    if (opened) {
      opened.opener = null;
    }
  }, []);

  return (
    <SimpleEntityPage showTopBar={false} loading={loading} hidePagePadding>
      <div className="profano-informacoes">
        <div className="profano-informacoes-left p-grid p-col-7">
          <div className="profano-informacoes-button">
            <Button
              icon="fa-solid fa-file-pdf"
              label="Ficha de Filiação"
              onClick={() => openUrlNewTab(`${PATH_FICHA_FILIANDO}${informacoes.uuid}?token=false`)}
            />
          </div>
          <div className="profano-informacoes-form p-fluid p-col-12">
            <CustomFormField
              icon="fa-solid fa-project-diagram"
              htmlForDescription="situacao"
              description="Situação"
              inputBody={
                <Dropdown
                  placeholder="Selecione"
                  options={dropdownSituacaoFiliandoOptions}
                  value={informacoes?.situacao}
                  optionValue="situacao"
                  disabled={informacoes.situacao === "FILIADO"}
                  onChange={(e) => {
                    onSituacaoChange(e.target.value);
                  }}
                  scrollHeight="500px"
                />
              }
            />
          </div>
        </div>
        <div className="profano-informacoes-right p-grid p-col-5">
          <PreviewSelectAvatar
            className="profano-informacoes-image"
            width="200px"
            height="200px"
            src={informacoes?.foto}
            editing={false}
          />
        </div>
      </div>
    </SimpleEntityPage>
  );
};

export default InformacoesFiliando;
