import { IAppError } from "./AppErrorInterfaces";

export const pecasArquiteturaErrors: IAppError[] = [
  {
    code: "UUID inválido.",
    message: "Alteração inválida na url.",
  },
  {
    code: "Irmão não encontrado.",
    title: "Irmão não encontrado.",
    message: "Entre em contato com nosso time de suporte para solicitar ajuda.",
  },
  {
    code: "Peça de Arquitetura não encontrada.",
    title: "Peça de Arquitetura não encontrado.",
    message: "Entre em contato com nosso time de suporte para solicitar ajuda.",
  },
  { code: "Data de publicação inválida.", title: "Data de publicação inválida.", message: "Selecione uma data válida" },
  {
    code: "Arquivo não encontrado ou inexistente.",
    title: "Arquivo não encontrado ou inexistente.",
    message: "Selecione um arquivo válido.",
  },
  {
    code: "Não foi possível salvar o arquivo.",
    title: "Não foi possível salvar o arquivo.",
    message: "Entre em contato com nosso time de suporte para solicitar ajuda.",
  },
];
