import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button } from "primereact/button";
import { ColumnProps } from "primereact/column";
import { MultiSelect } from "primereact/multiselect";

import { DataTable } from "primereact/datatable";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import api from "../../../services/api";
import { useToast } from "../../../hooks/toast";
import SimpleEntityDatatable from "../../../components/SimpleEntityDatatable";
import SimpleEntityListPage from "../../../components/SimpleEntityPage";
import IrmaoComponent from "../../../components/IrmaoComponent";
import FiliandoSituacaoComponent, {
  SituacaoFiliando,
  SituacaoFiliandoElement,
  situacoesFiliandoMap,
} from "../../../components/FiliandoSituacaoComponent";

export interface FiliandoList {
  id: number;
  uuid: string;
  nome: string;
  statusFiliando?: SituacaoFiliando;
  foto: string;
  cpf?: string;
}

const FiliandoPage: React.FC = () => {
  const situacaoFilterOptions: SituacaoFiliandoElement[] = useMemo((): SituacaoFiliandoElement[] => {
    return [
      situacoesFiliandoMap.AGUARDANDO,
      situacoesFiliandoMap.APROVADO,
      situacoesFiliandoMap.DESISTENCIA,
      situacoesFiliandoMap.FILIADO,
      situacoesFiliandoMap.LIDO,
      situacoesFiliandoMap.PAGO,
      situacoesFiliandoMap.REJEITADO,
    ];
  }, []);
  const [situacaoFilter, setSituacaoFilter] = useState<SituacaoFiliandoElement[]>([]);

  const dt = useRef<DataTable>(null);
  const [loading, setLoading] = useState(false);
  const [filiandos, setFiliandos] = useState<FiliandoList[]>([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const { showToast } = useToast();
  const history = useHistory();

  const onFilterSituacaoChange = useCallback((selectedList) => {
    dt.current?.filter(selectedList, "statusFiliando", "custom");
    setSituacaoFilter(selectedList);
  }, []);

  const onGlobalFilterChange = useCallback((filterValue) => {
    setGlobalFilter(filterValue);
  }, []);

  const loadData = useCallback(() => {
    setLoading(true);
    api
      .get<FiliandoList[]>(`filiandos`)
      .then(({ data }) => {
        setFiliandos(data);
      })
      .catch((err) => {
        showToast({
          type: "error",
          title: "Não foi possível carregar filiandos",
          description: "Tente novamente ou contate nosso time de suporte para solicitar ajuda.",
        });
        // eslint-disable-next-line no-console
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [showToast]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    onFilterSituacaoChange([
      situacoesFiliandoMap.AGUARDANDO,
      situacoesFiliandoMap.LIDO,
      situacoesFiliandoMap.APROVADO,
      situacoesFiliandoMap.PAGO,
    ]);
  }, [onFilterSituacaoChange, loading]);

  const editDeleteButtonsBodyTemplate = useCallback(
    (data: FiliandoList) => {
      return (
        <>
          <Button
            type="button"
            className="p-row-editor-init p-link "
            icon="pi pi-eye"
            tooltip="Detalhes do filiando"
            tooltipOptions={{ position: "top", style: { width: 80, textAlign: "center" } }}
            onClick={() => {
              history.push(`/filiando/${data.uuid}`, { nome: data.nome });
            }}
          />
        </>
      );
    },
    [history]
  );

  const nomeBodyTemplate = useCallback((rowData: FiliandoList) => {
    return <IrmaoComponent nome={rowData.nome} foto={rowData.foto} />;
  }, []);

  const situacaoFiliandoBodyTemplate = useCallback((rowData: FiliandoList) => {
    return <FiliandoSituacaoComponent situacao={rowData.statusFiliando} />;
  }, []);

  const filterBySituacao = useCallback((value: SituacaoFiliando, filter: SituacaoFiliandoElement[]) => {
    if (value) return filter == null || _.isEmpty(filter) || filter.filter((f) => f.situacao === value).length > 0;
    return false;
  }, []);

  const cpfBodyTemplate = useCallback((rowData: FiliandoList) => {
    if (rowData.cpf) {
      return `${rowData.cpf.substring(0, 3)}.${rowData.cpf.substring(3, 6)}.${rowData.cpf.substring(
        6,
        9
      )}-${rowData.cpf.substring(9, 11)}`;
    }
    return "";
  }, []);

  const columnsFiliandos = useMemo(
    (): ColumnProps[] => [
      {
        field: "nome",
        header: "Nome",
        sortable: true,
        style: { textAlign: "left", verticalAlign: "middle" },
        body: nomeBodyTemplate,
      },
      {
        field: "cpf",
        header: "CPF do filiando",
        sortable: true,
        style: { textAlign: "left", verticalAlign: "middle", width: "160px" },
        className: "p-text-nowrap p-text-truncate",
        body: cpfBodyTemplate,
      },
      {
        field: "statusFiliando",
        header: "Situação",
        sortable: true,
        style: { verticalAlign: "middle", width: "10%" },
        align: "center",
        body: situacaoFiliandoBodyTemplate,
        filterMatchMode: "custom",
        filterFunction: (value, filter) => filterBySituacao(value, filter),
      },
      {
        body: editDeleteButtonsBodyTemplate,
        style: { width: "80px", textAlign: "center", verticalAlign: "middle" },
      },
    ],
    [editDeleteButtonsBodyTemplate, filterBySituacao, nomeBodyTemplate, situacaoFiliandoBodyTemplate, cpfBodyTemplate]
  );

  const situacaoFilterBodyTemplate = (
    <MultiSelect
      showClear
      dataKey="situacao"
      optionLabel="label"
      options={situacaoFilterOptions}
      value={situacaoFilter}
      onChange={(e) => {
        onFilterSituacaoChange(e.target.value);
      }}
      placeholder="Filtrar por situação"
      selectedItemsLabel="{0} situações selecionadas"
      maxSelectedLabels={2}
      emptyFilterMessage="Nenhuma situação encontrada"
      style={{ width: "180px" }}
      scrollHeight="400px"
    />
  );

  const datatableFilters = <>{situacaoFilterBodyTemplate}</>;

  return (
    <SimpleEntityListPage
      showListHeader
      onGlobalFilterChange={(value) => onGlobalFilterChange(value)}
      loading={loading}
      listHeaderContentRight={datatableFilters}
      showExportCsvButton
      datatableRef={dt}
    >
      <SimpleEntityDatatable
        datatableRef={dt}
        value={filiandos}
        columns={columnsFiliandos}
        globalFilter={globalFilter}
        sortField="nome"
        sortOrder={1}
      />
    </SimpleEntityListPage>
  );
};

export default FiliandoPage;
