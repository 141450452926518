import React from "react";
import { EtapaProps } from "../../etapaProps";

const EtapaFinalizacao: React.FC<EtapaProps> = ({ isFormFiliacao }) => {
  return (
    <div className="profano-etapa-finalizacao">
      <i className="fa-solid fa-thumbs-up profano-etapa-finalizacao-icon" />
      <p className="profano-etapa-finalizacao-first-text">Tudo certo!</p>
      <p className="profano-etapa-finalizacao-second-text">
        {`Sua proposta de ${isFormFiliacao ? "filiação" : "iniciação"} foi enviada para análise. Mantenha contato com ${
          isFormFiliacao ? "a secretaria da loja" : "o seu padrinho"
        } para saber mais detalhes.`}
      </p>
    </div>
  );
};

export default EtapaFinalizacao;
