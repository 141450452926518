/* eslint-disable react/require-default-props */
/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { ColumnProps } from "primereact/column";

import { useHistory } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { confirmDialog } from "primereact/confirmdialog";
import { useToast } from "../../../hooks/toast";
import api from "../../../services/api";
import SimpleEntityDatatable from "../../../components/SimpleEntityDatatable";
import SimpleEntityListPage from "../../../components/SimpleEntityPage";
import { useError } from "../../../hooks/error";
import { livroErrors } from "../../../errors/livro";

export interface Livro {
  uuid: string;
  isbn: string;
  titulo: string;
  autor: string;
  edicao: string;
  editora: string;
  ano: number;
  exemplares: number;
}

const AcervoPage: React.FC = () => {
  const dt = useRef<DataTable>(null);
  const [loading, setLoading] = useState(false);
  const [acervo, setAcervo] = useState<Livro[]>([]);
  const [globalFilter, setGlobalFilter] = useState("");
  // const situacaoFilterOptions: SituacaoLivroElement[] = useMemo((): SituacaoLivroElement[] => {
  //   return [
  //     situacaoLivroMap.DISPONIVEL,
  //     situacaoLivroMap.PERDIDO,
  //     situacaoLivroMap.LOCAL,
  //     situacaoLivroMap.EMPRESTADO,
  //     situacaoLivroMap.REPARO,
  //   ];
  // }, []);
  // const [situacaoLivroFilter, setSituacaoLivroFilter] = useState<SituacaoLivroElement[]>([]);
  const { showToast } = useToast();
  const history = useHistory();
  const { handleError } = useError();

  const loadData = useCallback(() => {
    setLoading(true);
    api
      .get<Livro[]>(`biblioteca/livros`)
      .then(({ data }) => {
        setAcervo(data);
      })
      .catch((err) => {
        showToast({
          type: "error",
          title: "Não foi possível carregar o acervo",
          description: "Tente novamente ou contate nosso time de suporte para solicitar ajuda.",
        });
        // eslint-disable-next-line no-console
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [showToast]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  // const situacaoLivroBodyTemplate = useCallback((rowData: Livro) => {
  //   return <Tag className={situacaoLivroMap[rowData.situacao].class}>{situacaoLivroMap[rowData.situacao].label}</Tag>;
  // }, []);

  const excluirLivro = useCallback(
    (data: Livro) => {
      api
        .delete(`biblioteca/livros/${data.uuid}`)
        .then(() => {
          showToast({ title: "Sucesso!", type: "success", description: "O Livro foi removido do acervo." });
          loadData();
        })
        .catch((err) => {
          handleError({ error: err, action: "remover livro", knownErrors: livroErrors });
          // eslint-disable-next-line no-console
          console.log(err.response);
        });
    },
    [handleError, loadData, showToast]
  );

  const confirmExclusao = useCallback(
    (data: Livro) => {
      confirmDialog({
        message: "Após a remoção o item não poderá ser recuperado.",
        header: "Remover livro?",
        icon: "pi pi-question-circle",
        focusOnShow: false,
        rejectLabel: "Manter",
        acceptLabel: "Remover",
        accept: () => excluirLivro(data),
        reject: () => {},
      });
    },
    [excluirLivro]
  );

  const editDeleteButtonsBodyTemplate = useCallback(
    (data: Livro) => {
      return (
        <>
          <Button
            type="button"
            className="balandrau-action-button"
            icon="pi pi-pencil"
            onClick={() => {
              history.push({ pathname: `/acervo/${data.uuid}` });
            }}
          />
          <Button
            type="button"
            className="p-ml-3 balandrau-exclude-button"
            icon="pi pi-trash"
            onClick={() => {
              confirmExclusao(data);
            }}
          />
        </>
      );
    },
    [history, confirmExclusao]
  );

  const exemplarBodyTemplate = useCallback((data: Livro) => {
    return (
      <a className="color-link" href={`/acervo/${data.uuid}/exemplares`}>
        <span>{data.exemplares}</span>
      </a>
    );
  }, []);

  const columns = useMemo(
    (): ColumnProps[] => [
      {
        field: "isbn",
        header: "ISBN",
        align: "left",
        sortable: true,
        style: { verticalAlign: "middle", width: "115px" },
      },
      {
        field: "titulo",
        header: "Título",
        sortable: true,
        style: { textAlign: "left", verticalAlign: "middle" },
        className: "p-text-nowrap p-text-truncate",
      },
      {
        field: "autor",
        header: "Autor",
        sortable: true,
        style: { textAlign: "left", verticalAlign: "middle", width: "25%" },
        className: "p-text-nowrap p-text-truncate",
      },
      {
        field: "edicao",
        header: "Edição",
        sortable: true,
        style: { textAlign: "left", verticalAlign: "middle", width: "80px" },
        className: "p-text-nowrap p-text-truncate",
      },
      {
        field: "editora",
        header: "Editora",
        sortable: true,
        style: { textAlign: "left", verticalAlign: "middle", width: "150px" },
        className: "p-text-nowrap p-text-truncate",
      },
      {
        field: "ano",
        header: "Ano",
        sortable: true,
        align: "center",
        style: { textAlign: "center", verticalAlign: "middle", width: "90px" },
        className: "p-text-nowrap p-text-truncate",
      },
      {
        field: "exemplares",
        header: "Exemplares",
        sortable: true,
        align: "center",
        style: { verticalAlign: "middle", textAlign: "right", width: "90px" },
        body: exemplarBodyTemplate,
      },
      {
        align: "center",
        body: editDeleteButtonsBodyTemplate,
        style: { width: "100px", verticalAlign: "middle" },
      },
    ],
    [editDeleteButtonsBodyTemplate, exemplarBodyTemplate]
  );

  // const situacaoLivroFilterBodyTemplate = (
  //   <MultiSelect
  //     className="p-ml-2"
  //     showClear
  //     dataKey="tipo"
  //     optionLabel="label"
  //     options={situacaoFilterOptions}
  //     value={situacaoLivroFilter}
  //     onChange={(e) => {
  //       dt.current?.filter(e.value, "situacao", "custom");
  //       setSituacaoLivroFilter(e.target.value);
  //     }}
  //     placeholder="Filtrar por situação"
  //     selectedItemsLabel="{0} situações selecionadas"
  //     maxSelectedLabels={2}
  //     emptyFilterMessage="Nenhum tipo encontrado"
  //     style={{ width: "180px" }}
  //     scrollHeight="400px"
  //   />
  // );

  return (
    <SimpleEntityListPage
      showListHeader
      // listHeaderContentRight={situacaoLivroFilterBodyTemplate}
      newButtonLabel="Cadastrar livro"
      onNewButtonCLick={() => {
        history.push("/acervo/novo");
      }}
      onGlobalFilterChange={(value) => setGlobalFilter(value)}
      loading={loading}
      showExportCsvButton
      datatableRef={dt}
    >
      <SimpleEntityDatatable
        datatableRef={dt}
        value={acervo}
        columns={columns}
        globalFilter={globalFilter}
        sortField="titulo"
        sortOrder={1}
      />
    </SimpleEntityListPage>
  );
};

export default AcervoPage;
//
