import React from "react";

import { Provider } from "react-redux";
import { AuthProvider } from "./auth";
import { ToastProvider } from "./toast";
import { ErrorProvider } from "./error";
import { ProfanoProvider } from "./profano";
import { store } from "../redux/configStore";

const AppProvider: React.FC = ({ children }) => (
  <AuthProvider>
    <ToastProvider>
      <Provider store={store}>
        <ErrorProvider>
          <ProfanoProvider>{children}</ProfanoProvider>
        </ErrorProvider>
      </Provider>
    </ToastProvider>
  </AuthProvider>
);

export default AppProvider;
