import React from "react";

const ExcluirContaPage: React.FC = () => {
  return (
    <div className="excluir-conta">
      <h1>Deseja remover sua conta do Balandrau?</h1>
      <h3>
        Entre em contato pelo email: <a href="mailto:amazonas@glomam.org.br">amazonas@glomam.org.br</a> para remover sua
        conta.
      </h3>

      <div>Envie as seguintes informações:</div>
      <ul>
        <li>Nome completo;</li>
        <li>E-mail cadastrado.</li>
      </ul>
    </div>
  );
};

export default ExcluirContaPage;
