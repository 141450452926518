import { Button } from "primereact/button";
import React from "react";
import Loading from "../Loading";

interface Props {
  saveCommand: () => void;
  resetCommand: () => void;
  disabled: boolean;
  loadingOnSave?: boolean;
}

const FloatingSave: React.FC<Props> = ({ resetCommand, saveCommand, disabled, loadingOnSave = false }) => {
  return (
    <div className="container-floating-save p-d-flex p-ai-center">
      <div className="contain-floating-save p-d-flex p-ai-center p-jc-between">
        <div>
          <div className="p-ml-4 p-mr-6">Cuidado - você tem alterações não salvas!</div>
          {disabled && (
            <div className="p-mt-2 p-ml-4 p-mr-6">Preencha todos os campos obrigatórios (*) para salvar!</div>
          )}
        </div>

        <div className="p-ml-5 p-mr-4">
          <Button className="p-button-text" label="Redefinir" onClick={() => resetCommand()} />
        </div>
        <div>
          <Button
            label="Salvar alterações"
            style={{ width: "160px" }}
            disabled={disabled}
            onClick={() => saveCommand()}
          >
            <Loading isLoading={loadingOnSave} size={8} color="#fff" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FloatingSave;
