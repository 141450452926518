/* eslint-disable react/require-default-props */
/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { ColumnProps } from "primereact/column";

import { useHistory } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Tag } from "primereact/tag";
import { useToast } from "../../../../hooks/toast";
import api from "../../../../services/api";
import SimpleEntityDatatable from "../../../../components/SimpleEntityDatatable";
import SimpleEntityListPage from "../../../../components/SimpleEntityPage";
import IrmaoComponent from "../../../../components/IrmaoComponent";
import { Irmao } from "../../../Irmao/IrmaoPage";
import { EtapaFormProfano } from "../../FormProfanoPage";
import { convertStringBRToDate } from "../../../../utils/convertDates";

export interface FormProfano {
  uuid: string;
  padrinho: Irmao;
  profano: { nome: string; foto: string };
  token: string;
  url: string;
  etapa: EtapaFormProfano;
  cpf: string;
  criadoEm: string;
  dtCriadoEm: Date;
}

export interface EtapaFormProfanoElement {
  label: string;
  class: string;
  etapa: EtapaFormProfano;
}
type EtapaFormProfanoMap = {
  // eslint-disable-next-line no-unused-vars
  [name in EtapaFormProfano]: EtapaFormProfanoElement;
};

export const etapasFormProfanoMap: EtapaFormProfanoMap = {
  LEITURA: { label: "Termos", class: "tag-etapa etapa-leitura", etapa: "LEITURA" },
  FORM: {
    label: "Formulário",
    class: "tag-etapa etapa-form",
    etapa: "FORM",
  },
  ANEXO: { label: "Anexos", class: "tag-etapa etapa-anexo", etapa: "ANEXO" },
  FINALIZACAO: {
    label: "Submetido",
    class: "tag-etapa etapa-finalizacao",
    etapa: "FINALIZACAO",
  },
};

const ProfanoPage: React.FC = () => {
  const dt = useRef<DataTable>(null);
  const [loading, setLoading] = useState(false);
  const [forms, setForms] = useState<FormProfano[]>([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const { showToast } = useToast();
  const history = useHistory();

  const loadData = useCallback(() => {
    setLoading(true);
    api
      .get<FormProfano[]>(`formularios/profano`)
      .then(({ data }) => {
        setForms(
          data.map((ficha) => {
            return { ...ficha, dtCriadoEm: convertStringBRToDate(ficha.criadoEm) };
          })
        );
      })
      .catch((err) => {
        showToast({
          type: "error",
          title: "Não foi possível carregar os formulários",
          description: "Tente novamente ou contate nosso time de suporte para solicitar ajuda.",
        });
        // eslint-disable-next-line no-console
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [showToast]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const profanoBodyTemplate = useCallback((rowData: FormProfano) => {
    return (
      <>
        {rowData.profano && <IrmaoComponent nome={rowData.profano.nome} foto={rowData.profano.foto} />}
        {!rowData.profano && ""}
      </>
    );
  }, []);

  const padrinhoBodyTemplate = useCallback((rowData: FormProfano) => {
    return (
      <IrmaoComponent nome={rowData.padrinho.nome} apelido={rowData.padrinho.apelido} foto={rowData.padrinho.foto} />
    );
  }, []);

  const etapaBodyTemplate = useCallback((rowData: FormProfano) => {
    return (
      <>
        {rowData.etapa && (
          <Tag className={etapasFormProfanoMap[rowData.etapa].class}>{etapasFormProfanoMap[rowData.etapa].label}</Tag>
        )}
        {!rowData.etapa && <Tag className="tag-etapa etapa-nao-vinculado">Sem vínculo</Tag>}
      </>
    );
  }, []);

  const cpfBodyTemplate = useCallback((rowData: FormProfano) => {
    if (rowData.cpf) {
      return `${rowData.cpf.substring(0, 3)}.${rowData.cpf.substring(3, 6)}.${rowData.cpf.substring(
        6,
        9
      )}-${rowData.cpf.substring(9, 11)}`;
    }
    return "";
  }, []);

  const columnsForms = useMemo(
    (): ColumnProps[] => [
      {
        field: "etapa",
        header: "Etapa do Formulário",
        sortable: false,
        align: "center",
        style: { verticalAlign: "middle", width: "110px" },
        body: etapaBodyTemplate,
      },
      {
        field: "padrinho.nome",
        header: "Padrinho",
        sortable: true,
        style: { textAlign: "left", verticalAlign: "middle" },
        className: "p-text-nowrap p-text-truncate",
        body: padrinhoBodyTemplate,
      },
      {
        field: "profano.nome",
        header: "Profano",
        sortable: true,
        style: { textAlign: "left", verticalAlign: "middle" },
        className: "p-text-nowrap p-text-truncate",
        body: profanoBodyTemplate,
      },
      {
        field: "cpf",
        header: "CPF vinculado",
        sortable: true,
        style: { textAlign: "left", verticalAlign: "middle", width: "160px" },
        className: "p-text-nowrap p-text-truncate",
        body: cpfBodyTemplate,
      },
      {
        field: "criadoEm",
        sortField: "dtCriadoEm",
        header: "Data de criação",
        sortable: true,
        style: { textAlign: "left", verticalAlign: "middle", width: "160px" },
        className: "p-text-nowrap p-text-truncate",
      },
      {
        field: "url",
        header: "Link do Convite",
        sortable: false,
        style: { textAlign: "left", verticalAlign: "middle", width: "160px" },
        body: ({ url, token }: FormProfano) => {
          return (
            <a className="color-link" href={url} target="_blank" rel="noreferrer">
              {token}
            </a>
          );
        },
      },
    ],
    [etapaBodyTemplate, padrinhoBodyTemplate, profanoBodyTemplate, cpfBodyTemplate]
  );

  return (
    <SimpleEntityListPage
      showListHeader
      newButtonLabel="Novo convite"
      onNewButtonCLick={() => {
        history.push("/iniciacao/convite/novo");
      }}
      onGlobalFilterChange={(value) => setGlobalFilter(value)}
      loading={loading}
      showExportCsvButton
      datatableRef={dt}
    >
      <SimpleEntityDatatable
        datatableRef={dt}
        value={forms}
        columns={columnsForms}
        globalFilter={globalFilter}
        sortField="nome"
        sortOrder={1}
      />
    </SimpleEntityListPage>
  );
};

export default ProfanoPage;
