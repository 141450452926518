/* eslint-disable no-plusplus */
// import React, { useCallback, useEffect, useState } from "react";
// import { useParams } from "react-router-dom";
// import { useToast } from "../../hooks/toast";
// import publicApi from "../../services/public-api";
// import Loading from "../Loading";

// interface Params {
//   uuid: string;
// }

// const FilePageRecibo: React.FC = () => {
//   const { uuid } = useParams<Params>();
//   const { showToast } = useToast();

//   const [loading, setLoading] = useState(true);

//   const [pdf, setPdf] = useState<string>("");
//   const [mobile, setMobile] = useState<boolean>(false);
//   const [base64, setBase64] = useState<string>("");

//   const loadData = useCallback(() => {
//   if (/Android/i.test(navigator.userAgent)) {
//     setMobile(true);
//   }
//   publicApi
//     .get(`pdf/recibo/${uuid}`)
//     .then(({ data }) => {
//       const b64toBlob = (b64Data: string, contentType = "", sliceSize = 512) => {
//         const byteCharacters = atob(b64Data);
//         const byteArrays = [];
//         for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
//           const slice = byteCharacters.slice(offset, offset + sliceSize);
//           const byteNumbers = new Array(slice.length);
//           for (let i = 0; i < slice.length; i += 1) {
//             byteNumbers[i] = slice.charCodeAt(i);
//           }
//           const byteArray = new Uint8Array(byteNumbers);
//           byteArrays.push(byteArray);
//         }
//         const blob = new Blob(byteArrays, { type: contentType });
//         return blob;
//       };
//       const blob = b64toBlob(data, "application/pdf");
//       const blobUrl: any = URL.createObjectURL(blob);
//       setPdf(blobUrl);
//       setBase64(data);
//     })
//     .catch(() => {
//       showToast({
//         title: "Não foi possível carregar este PDF",
//         type: "error",
//         description: "Tente novamente mais tarde, ou entre em contato com nosso time de suporte para solicitar ajuda",
//       });
//     })
//     .finally(() => setLoading(false));
// }, [showToast, uuid]);
// useEffect(() => {
//   loadData();
// }, [loadData, showToast, uuid]);
// const printTrigger = (id: string) => {
//   const getMyFrameElement: HTMLElement | null = document.getElementById(id);
//   const getMyFrame = getMyFrameElement as unknown as HTMLIFrameElement;
//   if (getMyFrame && getMyFrame.contentWindow) {
//     getMyFrame.focus();
//     getMyFrame.contentWindow.print();
//   }
// };
// const celularFunction = useCallback(() => {
//   const linkSource = `data:application/pdf;base64,${base64}`;
//   const downloadLink = document.createElement("a");
//   const fileName = "recibo-cobranca.pdf";
//   downloadLink.href = linkSource;
//   downloadLink.download = fileName;
//   downloadLink.click();
// }, [base64]);
// useEffect(() => {
//   const checkPrintKey = (e: KeyboardEvent) => {
//     if (e.ctrlKey && e.key === "p") {
//       e.preventDefault();
//       e.stopPropagation();
//       printTrigger("pdf");
//     }
//   };
//     window.removeEventListener("keydown", checkPrintKey);
//     window.addEventListener("keydown", checkPrintKey);
//     return () => {
//       window.removeEventListener("keydown", checkPrintKey);
//     };
//   }, []);
//   return (
//     <div className="main" style={{ position: "absolute", top: 0, right: 0, left: 0, bottom: 0 }}>
//       {loading && <Loading isLoading />}
//       {!loading && !mobile ? window.open(`${pdf}`, "_self") : undefined}
//       {mobile && !loading ? celularFunction() : undefined}
//     </div>
//   );
// });
// };

// export default FilePageRecibo;

import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useToast } from "../../hooks/toast";
import api from "../../services/api";
import Loading from "../Loading";

interface Params {
  uuid: string;
}

const FilePageRecibo: React.FC = () => {
  const { uuid } = useParams<Params>();
  const { showToast } = useToast();

  const [loading, setLoading] = useState(true);
  const [pdfBlobUrl, setPdfBlobUrl] = useState<string | null>(null);

  // const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

  const arrayBufferToBase64 = (buffer: ArrayBuffer): string => {
    let binary = "";
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };

  const loadData = useCallback(async () => {
    try {
      const response = await api.post(
        "/relatorios",
        {
          nomeRelatorio: "relatorio_recibo_novo",
          parametroBusca: uuid,
        },
        {
          responseType: "arraybuffer",
        }
      );

      const base64 = arrayBufferToBase64(response.data);
      const blobUrl = `data:application/pdf;base64,${base64}`;
      setPdfBlobUrl(blobUrl);

      // Se for Android, inicia o download automaticamente
      if (/Android/i.test(navigator.userAgent)) {
        const link = document.createElement("a");
        link.href = blobUrl;
        link.setAttribute("download", "recibo.pdf");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      showToast({
        title: "Erro ao carregar o PDF",
        type: "error",
        description: "Não foi possível carregar o PDF. Tente novamente mais tarde ou entre em contato com o suporte.",
      });
    } finally {
      setLoading(false);
    }
  }, [uuid, showToast]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <div className="main">
      {loading && <Loading isLoading />}
      {!loading && pdfBlobUrl ? (
        <>
          {/* Exibição do PDF */}
          <iframe
            src={`${pdfBlobUrl}`}
            title="Visualizar PDF"
            style={{
              width: "100%",
              height: "100%",
              border: "none",
              overflow: "hidden",
            }}
          />{" "}
          {/* Botão de Download Flutuante
          {isIOS && (
            <a
              href={pdfBlobUrl}
              download="recibo.pdf"
              style={{
                position: "fixed",
                bottom: "20px",
                right: "20px",
                background: "linear-gradient(to top, #EECE96, #D2AE6D, #D2AE6D, #97763B)",
                color: "#000",
                padding: "10px 20px",
                borderRadius: "25px",
                textAlign: "center",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                fontSize: "14px",
                textDecoration: "none",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Baixar PDF
            </a>
          )} */}
        </>
      ) : (
        <p />
      )}
    </div>
  );
};

export default FilePageRecibo;
