import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { ColumnProps } from "primereact/column";

import SimpleEntityListPage from "../../../components/SimpleEntityPage";
import SimpleEntityDatatable from "../../../components/SimpleEntityDatatable";
import { useToast } from "../../../hooks/toast";
import api from "../../../services/api";
import { formatStringToCelular } from "../../../utils/formatStringToCelular";
import IrmaoComponent from "../../../components/IrmaoComponent";

export interface IrmaoDTO {
  uuid: string;
  nome: string;
  foto?: string;
}

interface RespostasSmsDTO {
  uuid: string;
  celular: string;
  resposta: string;
  irmao: IrmaoDTO;
  dtResposta: string;
}

const RespostaSMSPage: React.FC = () => {
  const dt = useRef<DataTable>(null);

  const [loading, setLoading] = useState(false);
  const [respostasSms, setRespostasSms] = useState<RespostasSmsDTO[]>([]);
  const [globalFilter, setGlobalFilter] = useState("");

  const { showToast } = useToast();

  const loadData = useCallback(() => {
    setLoading(true);

    api
      .get("respostas/sms")
      .then(({ data }) => {
        setRespostasSms(data);
      })
      .catch(() => {
        showToast({
          type: "error",
          title: "Não foi possível carregar as respostas do sms",
          description: "Tente novamente ou contate nosso time de suporte para solicitar ajuda.",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [showToast]);

  // const excluirRespostas = useCallback(
  //   (data: RespostasSmsDTO) => {
  //     api
  //       .delete(`turmas/${data.uuid}`)
  //       .then(() => {
  //         showToast({ title: "Sucesso!", type: "success", description: "A turma foi removida." });
  //         loadData();
  //       })
  //       .catch((err) => {
  //         handleError({ error: err, action: "remover turma", knownErrors: respostasSmsErrors });
  //       });
  //   },
  //   [handleError, loadData, showToast]
  // );

  // const confirmExclusao = useCallback(
  //   (data: RespostasSmsDTO) => {
  //     confirmDialog({
  //       header: "Remover resposta?",
  //       message: "Após a remoção o item não poderá ser recuperado.",
  //       icon: "pi pi-question-circle",
  //       focusOnShow: false,
  //       rejectLabel: "Manter",
  //       acceptLabel: "Remover",
  //       accept: () => excluirRespostas(data),
  //       reject: () => {},
  //     });
  //   },
  //   [excluirRespostas]
  // );

  const onGlobalFilterChange = useCallback((filterValue) => {
    setGlobalFilter(filterValue);
  }, []);

  // const editDeleteButtonsBodyTemplate = useCallback(
  //   (data: RespostasSmsDTO) => {
  //     return (
  //       <>
  //         <Button
  //           type="button"
  //           className="p-row-editor-init p-link "
  //           icon="pi pi-eye"
  //           tooltip="Detalhes da turma"
  //           tooltipOptions={{ position: "top", style: { width: 80, textAlign: "center" } }}
  //           onClick={() => {
  //             history.push(`/resposta/sms/${data.uuid}`);
  //           }}
  //         />
  //         <Button
  //           type="button"
  //           className="p-ml-3 balandrau-exclude-button"
  //           icon="pi pi-trash"
  //           onClick={() => {
  //             confirmExclusao(data);
  //           }}
  //         />
  //       </>
  //     );
  //   },
  //   [confirmExclusao, history]
  // );

  // const dtIniciacaoBodyTemplate = useCallback((data: TurmaDTO) => {
  //   return convertDateToStringBR(data.dtIniciacao);
  // }, []);

  const celularBodyTemplate = useCallback(({ celular }: RespostasSmsDTO) => {
    return formatStringToCelular(celular);
  }, []);

  const irmaoBodyTemplate = useCallback(({ irmao }: RespostasSmsDTO) => {
    if (irmao) {
      return <IrmaoComponent nome={irmao.nome} foto={irmao.foto} />;
    }
    return "aa";
  }, []);

  // const respostaBodyTemplate = useCallback(({ resposta }: RespostasSmsDTO) => {
  //   return resposta.substring(0, 55).concat("...");
  // }, []);

  const dtRespostaBodyTemplate = useCallback(({ dtResposta }: RespostasSmsDTO) => {
    const date = new Date(dtResposta);
    const dateWithDateAndTimeLocaleString = `${date.toLocaleDateString("pt-BR")} às ${date.toLocaleTimeString(
      "pt-BR"
    )}`;
    return dateWithDateAndTimeLocaleString;
  }, []);

  const columnsRespostasSms = useMemo(
    (): ColumnProps[] => [
      {
        field: "irmao.nome",
        header: "Irmao",
        sortable: true,
        style: { verticalAlign: "middle" },
        bodyStyle: { overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", width: "30%" },
        align: "left",
        body: irmaoBodyTemplate,
      },
      {
        field: "celular",
        header: "Celular do irmao",
        sortable: true,
        style: { verticalAlign: "middle", width: "145px" },
        align: "left",
        body: celularBodyTemplate,
      },
      {
        field: "resposta",
        header: "Resposta",
        sortable: true,
        style: { verticalAlign: "middle" },
        bodyStyle: { overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" },
        align: "left",
      },
      {
        field: "dtResposta",
        header: "Data da resposta",
        sortable: true,
        style: { verticalAlign: "middle", width: "180px" },
        align: "center",
        body: dtRespostaBodyTemplate,
      },
      // {
      //   field: "dtIniciacao",
      //   header: "Data de iniciação",
      //   sortable: true,
      //   style: { verticalAlign: "middle", width: 180 },
      //   align: "center",
      //   body: dtIniciacaoBodyTemplate,
      // },
      // {
      //   body: editDeleteButtonsBodyTemplate,
      //   style: { width: "50px", textAlign: "center", verticalAlign: "middle" },
      // },
    ],
    [celularBodyTemplate, dtRespostaBodyTemplate, irmaoBodyTemplate]
  );

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <SimpleEntityListPage
      showListHeader
      onGlobalFilterChange={(value) => onGlobalFilterChange(value)}
      loading={loading}
      showExportCsvButton
      datatableRef={dt}
    >
      <SimpleEntityDatatable
        datatableRef={dt}
        value={respostasSms}
        columns={columnsRespostasSms}
        globalFilter={globalFilter}
        sortField="dtResposta"
        sortOrder={-1}
      />
    </SimpleEntityListPage>
  );
};

export default RespostaSMSPage;
