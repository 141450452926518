import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useToast } from "../../hooks/toast";
import publicApi from "../../services/public-api";
import Loading from "../Loading";

interface Params {
  uuid: string;
}

const FilePageDetalhesProfanoSindicanciasMerge: React.FC = () => {
  const { uuid } = useParams<Params>();
  const { showToast } = useToast();

  const [loading, setLoading] = useState(true);
  const [pdf, setPdf] = useState<string>("");

  const loadData = useCallback(() => {
    publicApi
      .get(`pdf/sindicanciasProfano/${uuid}`, { responseType: "blob" })
      .then((response) => {
        setPdf(URL.createObjectURL(response.data));
      })
      .catch(() => {
        showToast({
          title: "Não foi possível carregar este PDF",
          type: "error",
          description: "Tente novamente mais tarde, ou entre em contato com nosso time de suporte para solicitar ajuda",
        });
      })
      .finally(() => setLoading(false));
  }, [showToast, uuid]);

  useEffect(() => {
    loadData();
  }, [loadData, showToast, uuid]);

  return (
    <div className="main" style={{ position: "absolute", top: 0, right: 0, left: 0, bottom: 0 }}>
      {loading && <Loading isLoading />}
      {!loading && <iframe title="PDF" src={pdf} width="100%" height={window.innerHeight} />}
    </div>
  );
};

export default FilePageDetalhesProfanoSindicanciasMerge;
