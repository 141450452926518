import { IAppError } from "./AppErrorInterfaces";

export const profanoErrors: IAppError[] = [
  {
    code: "Profano não encontrado.",
    title: "O profano não foi encontrado",
    message: "Entre em contato com nosso time de suporte para solicitar ajuda.",
  },
  {
    code: "Sindicante não encontrado.",
    title: "O irmão sindicante não foi encontrado.",
    message: "Entre em contato com nosso time de suporte para solicitar ajuda.",
  },
  {
    code: "Sindicancia não encontrada",
    title: "Uma das sindicâncias não foi encontrada.",
    message: "Entre em contato com nosso time de suporte para solicitar ajuda.",
  },
  {
    code: "Situacao inválida para o profano.",
    title: "Profano com situação inválida.",
    message: "Modifique a situação do profano e tente novamente.",
  },
  {
    code: "Turma não encontrada.",
    title: "Turma não encontrada",
    message: "Entre em contato com nosso time de suporte para solicitar ajuda.",
  },
  {
    code: "Não foi possível recuperar o CIM do Irmão. Será necessário aguardar a GLOMAM concluir o processo de Iniciação. Tente novamente mais tarde.",
    title: "Não foi possível recuperar o CIM do Irmão.",
    message: "Será necessário aguardar a GLOMAM concluir o processo de Iniciação. Tente novamente mais tarde.",
  },
];
