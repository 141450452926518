/* eslint-disable react/require-default-props */
/* eslint-disable no-unused-vars */
import _ from "lodash";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { ColumnProps } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Tag } from "primereact/tag";
import React, { useCallback, useMemo, useRef, useState } from "react";
import CustomVerticalFormField from "../CustomVerticalFormField";
import SimpleEntityDatatable from "../SimpleEntityDatatable";
import SimpleEntityPage from "../SimpleEntityPage";
import EmptyPageComponent from "../EmptyPageComponent";

export type SituacaoSindicancia = "PENDENTE" | "REALIZADO";

export interface IrmaoDTO {
  id: number;
  uuid: string;
  nome: string;
  foto?: string;
  sindicante?: boolean;
}

export type AnexoTipo =
  | "QUIT_PLACET"
  | "RESUMO_VIDA"
  | "ATESTADO_SAUDE"
  | "ATESTADO_SANIDADE"
  | "CN_CRIMINAL_FEDERAL"
  | "CN_CRIMINAL_ESTADUAL"
  | "DECLARACAO_SUBSISTENCIA"
  | "RG_CPF_TITULO_ELEITOR";

export type AnexoSituacao = "APROVADO" | "REJEITADO" | "SUBMETIDO";

export interface AnexoProfano {
  id?: number;
  uuid: string;
  tipo: AnexoTipo;
  situacao: AnexoSituacao;
  observacao?: string;
}

export interface SituacaoAnexoElement {
  label: string;
  class: string;
  situacao: AnexoSituacao;
  icon: string;
  order: number;
}

type SituacaoAnexoMap = {
  [name in AnexoSituacao]: SituacaoAnexoElement;
};

export const situacoesAnexoProfanoMap: SituacaoAnexoMap = {
  APROVADO: {
    label: "Aprovado",
    situacao: "APROVADO",
    class: "situacao-anexo situacao-anexo-aprovado",
    icon: "fa-solid fa-check",
    order: 2,
  },
  REJEITADO: {
    label: "Rejeitado",
    situacao: "REJEITADO",
    class: "situacao-anexo situacao-anexo-rejeitado",
    icon: "fa-solid fa-check",
    order: 1,
  },
  SUBMETIDO: {
    label: "Submetido",
    situacao: "SUBMETIDO",
    class: "situacao-anexo situacao-anexo-submetido",
    icon: "fa-solid fa-check",
    order: 3,
  },
};

export interface TipoAnexoProfanoElement {
  label: string;
  // class: string;
  tipo: AnexoTipo;
}
type TipoAnexoProfanoMap = {
  [name in AnexoTipo]: TipoAnexoProfanoElement;
};

export const tiposAnexoProfanoMap: TipoAnexoProfanoMap = {
  QUIT_PLACET: { label: "Quit Placet", tipo: "QUIT_PLACET" },
  RESUMO_VIDA: { label: "Resumo de vida feito de próprio punho", tipo: "RESUMO_VIDA" },
  ATESTADO_SAUDE: { label: "Atestado médico (doença infecto-contagiosa)", tipo: "ATESTADO_SAUDE" },
  ATESTADO_SANIDADE: { label: "Atestado médico (sanidade física e mental)", tipo: "ATESTADO_SANIDADE" },
  CN_CRIMINAL_FEDERAL: {
    label: "Certidão negativa criminal (Justiça Federal)",

    tipo: "CN_CRIMINAL_FEDERAL",
  },
  DECLARACAO_SUBSISTENCIA: { label: "Declaração de subsistência", tipo: "DECLARACAO_SUBSISTENCIA" },
  CN_CRIMINAL_ESTADUAL: { label: "Certidão negativa criminal (Justiça Estadual)", tipo: "CN_CRIMINAL_ESTADUAL" },
  RG_CPF_TITULO_ELEITOR: {
    label: "Cópias de RG, CPF e título de eleitor",

    tipo: "RG_CPF_TITULO_ELEITOR",
  },
};

interface AnexosProfanoProps {
  profanoUuid: string;
  anexos?: AnexoProfano[];
  loading?: boolean;
  onChange: (anexoId: string, anexo: AnexoProfano) => void;
  isFiliacao?: boolean;
}

const AnexosProfano: React.FC<AnexosProfanoProps> = ({
  profanoUuid,
  anexos = [],
  loading = false,
  onChange,
  isFiliacao = false,
}) => {
  const BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;
  const PATH_ANEXO = "/pdf/anexo/";
  const PATH_ANEXOS_DETALHES = `/${isFiliacao ? "filiandos" : "profanos"}/detalhes/${profanoUuid}`;
  const PATH_ANEXOS_MERGE = "/anexosMerge";
  const PATH_ANEXOS_ZIP = "/anexosZip";

  const [anexo, setAnexo] = useState<AnexoProfano>();

  const [displayJustificativaDialog, setDisplayJustificativaDialog] = useState(false);
  const [dialogErrors, setDialogErrors] = useState<{ [name: string]: string }>({});

  const dt = useRef<DataTable>(null);

  const handleUpdateAnexo = useCallback(
    (anexoId: string, anexoData: AnexoProfano) => onChange(anexoId, anexoData),
    [onChange]
  );

  const aproveRejectButtonsBodyTemplate = useCallback(
    (data: AnexoProfano) => {
      return (
        <>
          <Button
            type="button"
            className="anexo-action-button anexo-action-button-like"
            icon="fa-solid fa-thumbs-up"
            onClick={() => {
              const newAnexo = _.cloneDeep(data);
              if (newAnexo) {
                newAnexo.situacao = "APROVADO";
                newAnexo.observacao = undefined;
                onChange(newAnexo.uuid, newAnexo);
              }
            }}
          />
          <Button
            type="button"
            className="p-ml-3 anexo-action-button anexo-action-button-dislike"
            icon="fa-solid fa-thumbs-down"
            onClick={() => {
              setDisplayJustificativaDialog(true);
              setAnexo(data);
            }}
          />
        </>
      );
    },
    [onChange]
  );

  const documentoBodyTemplate = useCallback((rowData: AnexoProfano) => {
    const url = `${PATH_ANEXO}${rowData.uuid}`;

    return rowData.tipo ? (
      <a className="color-link" href={url} target="_blank" rel="noreferrer">
        <i className="fa-solid fa-file-alt p-mr-2" />
        {tiposAnexoProfanoMap[rowData.tipo].label}
      </a>
    ) : (
      ""
    );
  }, []);

  const situacaoAnexoBodyTemplate = useCallback((rowData: AnexoProfano) => {
    if (rowData.situacao) {
      return (
        <Tag className={situacoesAnexoProfanoMap[rowData.situacao].class}>
          {situacoesAnexoProfanoMap[rowData.situacao].label}
        </Tag>
      );
    }

    return "";
  }, []);

  const justificativaBodyTemplate = useCallback((rowData: AnexoProfano) => {
    if (rowData.situacao === "REJEITADO") {
      return (
        <InputTextarea
          style={{ width: "100%" }}
          rows={2}
          cols={10}
          contentEditable={false}
          autoResize
          value={rowData.observacao || ""}
          onClick={() => {
            setAnexo(rowData);
            setDisplayJustificativaDialog(true);
          }}
        />
      );
    }
    return "";
  }, []);

  const columnsAnexos: ColumnProps[] = useMemo((): ColumnProps[] => {
    return [
      {
        field: "tipo",
        header: "Documento",
        sortable: false,
        align: "left",
        style: { verticalAlign: "middle", width: "35%" },
        body: documentoBodyTemplate,
      },
      {
        field: "situacao",
        header: "Situação",
        sortable: false,
        align: "center",
        style: { verticalAlign: "middle", width: "15%" },
        body: situacaoAnexoBodyTemplate,
      },
      {
        field: "observacao",
        header: "Justificativa",
        sortable: false,
        // className: "p-text-nowrap p-text-truncate",
        style: { verticalAlign: "middle", width: "35%" },
        body: justificativaBodyTemplate,
      },
      {
        align: "center",
        header: "Revisão",
        body: aproveRejectButtonsBodyTemplate,
        style: { width: "15%", verticalAlign: "middle" },
      },
    ];
  }, [aproveRejectButtonsBodyTemplate, documentoBodyTemplate, justificativaBodyTemplate, situacaoAnexoBodyTemplate]);

  const onHideJustificativaDialog = () => {
    setAnexo(undefined);
    setDialogErrors({});
    setDisplayJustificativaDialog(false);
  };

  const checkJustificativaAnexo = useCallback((): boolean => {
    const errors: { [name: string]: string } = {};
    if (anexo && _.isEmpty(anexo.observacao?.trim())) {
      errors.observacao = "Preencha uma justificativa para a rejeição.";
    }

    setDialogErrors(errors);
    return _.isEmpty(errors);
  }, [anexo]);

  const justificativaDialogFooter = () => {
    return (
      <div className="p-mt-3">
        <Button
          label="Cancelar"
          icon="pi pi-times"
          onClick={() => onHideJustificativaDialog()}
          className="p-button-text"
        />
        <Button
          label="Confirmar"
          icon="pi pi-check"
          onClick={() => {
            if (checkJustificativaAnexo()) {
              const newAnexo = _.cloneDeep(anexo);

              if (newAnexo) {
                newAnexo.situacao = "REJEITADO";
                onChange(newAnexo.uuid, newAnexo);
              }
              onHideJustificativaDialog();
            }
          }}
          style={{ width: "120px", marginRight: 32 }}
          autoFocus
        />
      </div>
    );
  };

  const newSindicanciaDialogHeader = useCallback(() => {
    return (
      <h1 className="default-dialog-header" style={{ minWidth: 400 }}>
        Justificativa de reprovação
      </h1>
    );
  }, []);

  const openUrlNewTab = useCallback((url: string) => {
    const opened = window.open(url, "_blank", "noopener,noreferrer");
    if (opened) {
      opened.opener = null;
    }
  }, []);

  return (
    <SimpleEntityPage isFormPage showTopBar={false} showListHeader loading={loading} datatableRef={dt} hidePagePadding>
      <Dialog
        header={newSindicanciaDialogHeader}
        visible={displayJustificativaDialog}
        modal
        footer={justificativaDialogFooter()}
        onHide={() => onHideJustificativaDialog()}
        className="confirm-dialog-container"
        closable
        style={{ minWidth: "430px" }}
      >
        <div className="default-dialog-content">
          <div className="confirm-dialog-content p-d-flex p-flex-column " style={{ width: 400 }}>
            <span className="confirm-dialog-text">
              Apresente os motivos para a reprovação do documento:
              <br />
              <span className="confirm-dialog-text-bold">{anexo ? tiposAnexoProfanoMap[anexo.tipo].label : " "}</span>
            </span>

            <span className="confirm-dialog-text p-mt-2">
              Se possível, oriente quanto a forma de solucionar o problema.
            </span>
            <CustomVerticalFormField
              className="p-mt-3"
              icon="fa-solid fa-edit"
              htmlForDescription="dtLimite"
              description="Justificativa"
              inputBody={
                <InputTextarea
                  // className="p-mt-3"
                  value={anexo?.observacao || ""}
                  placeholder="Digite os motivos aqui"
                  autoResize
                  maxLength={100}
                  onChange={(e) => {
                    const newAnexo = _.cloneDeep(anexo);
                    if (newAnexo) {
                      newAnexo.observacao = e.target.value;
                      setAnexo(newAnexo);
                    }

                    const errors = { ...dialogErrors };
                    delete errors.observacao;
                    setDialogErrors(errors);
                  }}
                  style={{ width: 400 }}
                />
              }
              errorMessage={dialogErrors.observacao}
            />
          </div>
        </div>
      </Dialog>
      {_.isEmpty(anexos) && isFiliacao && (
        <EmptyPageComponent
          icon="fa-solid fa-edit"
          title="O filiando ainda não preencheu esta etapa da ficha."
          description="Quando o filiando enviar os documentos, eles serão listados aqui para avaliação."
        />
      )}
      {_.isEmpty(anexos) && !isFiliacao && (
        <EmptyPageComponent
          icon="fa-solid fa-edit"
          title="O profano ainda não preencheu esta etapa da ficha."
          description="Quando o profano enviar os documentos, eles serão listados aqui para avaliação."
        />
      )}
      {!_.isEmpty(anexos) && (
        <>
          <SimpleEntityDatatable
            datatableRef={dt}
            columns={columnsAnexos}
            value={anexos}
            dataKey="uuid"
            paginator={false}
            emptyMessage="Nenhum anexo encontrado"
            rows={undefined}
          />
          <div className="p-mt-4 p-d-flex p-flex-row p-ai-center p-jc-end ">
            <Button
              label="Mesclar todos (pdf)"
              icon="fa-solid fa-file-pdf"
              onClick={() => openUrlNewTab(`${PATH_ANEXOS_DETALHES}${PATH_ANEXOS_MERGE}`)}
            />
            <Button
              label="Baixar todos (zip)"
              icon="fa-solid fa-download"
              className="p-ml-3"
              onClick={() => openUrlNewTab(`${BASE_URL}${PATH_ANEXOS_DETALHES}${PATH_ANEXOS_ZIP}`)}
            />
          </div>
        </>
      )}
    </SimpleEntityPage>
  );
};

export default AnexosProfano;
