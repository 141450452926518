import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { ColumnProps } from "primereact/column";

import { useHistory } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { confirmDialog } from "primereact/confirmdialog";
import SimpleEntityListPage from "../../../components/SimpleEntityPage";
import SimpleEntityDatatable from "../../../components/SimpleEntityDatatable";
import { nominatasErrors } from "../../../errors/nominatas";
import { useToast } from "../../../hooks/toast";
import { useError } from "../../../hooks/error";
import api from "../../../services/api";
import IrmaoComponent from "../../../components/IrmaoComponent";

interface IrmaoDTO {
  id: number;
  uuid: string;
  nome: string;
  foto?: string;
}

interface PeriodoNominata {
  id: number;
  periodo: string;
  veneravel: IrmaoDTO;
}

const NominataPage: React.FC = () => {
  const dt = useRef<DataTable>(null);
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const { showToast } = useToast();
  const { handleError } = useError();

  const [globalFilter, setGlobalFilter] = useState("");
  const [nominatas, setNominatas] = useState<PeriodoNominata[]>([]);

  const loadData = useCallback(() => {
    setLoading(true);
    api
      .get<PeriodoNominata[]>(`nominatas`)
      .then(({ data }) => {
        setNominatas(data);
      })
      .catch((err) => {
        showToast({
          type: "error",
          title: "Não foi possível carregar as nominatas",
          description: "Tente novamente ou contate nosso time de suporte para solicitar ajuda.",
        });
        // eslint-disable-next-line no-console
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [showToast]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const excluirNominata = useCallback(
    (data: PeriodoNominata) => {
      api
        .delete(`nominata/${data.id}`)
        .then(() => {
          showToast({ title: "Sucesso!", type: "success", description: "A PeriodoNominata foi removido." });
          loadData();
        })
        .catch((err) => {
          handleError({ error: err, action: "remover nominata", knownErrors: nominatasErrors });
          // eslint-disable-next-line no-console
          console.log(err.response);
        });
    },
    [handleError, loadData, showToast]
  );

  const confirmExclusao = useCallback(
    (data: PeriodoNominata) => {
      confirmDialog({
        message: "Após a remoção o item não poderá ser recuperado.",
        header: "Remover nominata?",
        icon: "pi pi-question-circle",
        focusOnShow: false,
        rejectLabel: "Manter",
        acceptLabel: "Remover",
        accept: () => excluirNominata(data),
        reject: () => {},
      });
    },
    [excluirNominata]
  );

  const editDeleteButtonsBodyTemplate = useCallback(
    (data: PeriodoNominata) => {
      return (
        <>
          <Button
            type="button"
            className="balandrau-action-button"
            icon="pi pi-pencil"
            onClick={() => {
              history.push({ pathname: `/nominata/${data.id}` });
            }}
          />
          <Button
            type="button"
            className="p-ml-3 balandrau-exclude-button"
            icon="pi pi-trash"
            onClick={() => {
              confirmExclusao(data);
            }}
          />
        </>
      );
    },
    [history, confirmExclusao]
  );

  const veneravelBodyTemplate = useCallback((rowData: PeriodoNominata) => {
    if (rowData.veneravel) {
      return <IrmaoComponent nome={rowData.veneravel.nome} foto={rowData.veneravel.foto} />;
    }
    return "";
  }, []);

  const columns = useMemo(
    (): ColumnProps[] => [
      {
        field: "periodo",
        header: "Período Administrativo",
        align: "center",
        sortable: false,
        style: { verticalAlign: "middle", width: "115px" },
      },
      {
        field: "veneravel.nome",
        header: "Venerável",
        sortable: true,
        style: { textAlign: "left", verticalAlign: "middle" },
        className: "p-text-nowrap p-text-truncate",
        body: veneravelBodyTemplate,
      },
      {
        align: "center",
        body: editDeleteButtonsBodyTemplate,
        style: { width: "100px", verticalAlign: "middle" },
      },
    ],
    [editDeleteButtonsBodyTemplate, veneravelBodyTemplate]
  );

  return (
    <SimpleEntityListPage
      showListHeader
      newButtonLabel="Cadastrar nominata"
      onNewButtonCLick={() => {
        history.push("/nominata/novo");
      }}
      onGlobalFilterChange={(value) => setGlobalFilter(value)}
      loading={loading}
      showExportCsvButton
      datatableRef={dt}
    >
      <SimpleEntityDatatable
        datatableRef={dt}
        value={nominatas}
        columns={columns}
        globalFilter={globalFilter}
        sortField="nome"
        sortOrder={1}
      />
    </SimpleEntityListPage>
  );
};

export default NominataPage;
