/* eslint-disable no-unused-vars */
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { ColumnProps } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { v4 } from "uuid";
import React, { useCallback, useMemo, useRef } from "react";
import SimpleEntityDatatable from "../SimpleEntityDatatable";
import SimpleEntityListPage from "../SimpleEntityPage";

export interface Dependente {
  id: string;
  parentesco: string;
  nome: string;
  dtNascimento: Date | undefined;
}

interface ProfanoDependentesProps {
  dependentes?: Dependente[];
  onAdd: (dependente: Dependente) => void;
  onRemove: (dependenteId: string) => void;
  onUpdate: (dependenteId: string, dependente: Dependente) => void;
}

type Parentesco = "Filho" | "Filha" | "Cônjuge";

const ProfanoDependentes: React.FC<ProfanoDependentesProps> = ({ dependentes = [], onAdd, onRemove, onUpdate }) => {
  const dt = useRef<DataTable>(null);
  const parentescoOptions: Parentesco[] = useMemo(() => {
    return ["Filho", "Filha", "Cônjuge"];
  }, []);

  const handleAddDependente = useCallback((dependente: Dependente) => onAdd(dependente), [onAdd]);

  const handleRemoveDependente = useCallback((dependenteId: string) => onRemove(dependenteId), [onRemove]);

  const handleUpdateDependente = useCallback(
    (dependenteId: string, dependente: Dependente) => onUpdate(dependenteId, dependente),
    [onUpdate]
  );

  const yearNavigatorTemplate = (e: any) => {
    return (
      <Dropdown
        value={e.value}
        options={e.options}
        onChange={(event) => e.onChange(event.originalEvent, event.value)}
        className="p-ml-2"
        style={{ lineHeight: 1 }}
      />
    );
  };

  const monthNavigatorTemplate = (e: any) => {
    return (
      <Dropdown
        value={e.value}
        options={e.options}
        onChange={(event) => e.onChange(event.originalEvent, event.value)}
        style={{ lineHeight: 1 }}
      />
    );
  };

  const dtNascimentoBodyTemplate = useCallback(
    (rowData: Dependente) => {
      return (
        <Calendar
          key={rowData.id}
          placeholder="Selecione ou digite"
          value={rowData.dtNascimento}
          onChange={(e) => {
            const dependente = { ...rowData };
            if (e.value && e.value instanceof Date) {
              dependente.dtNascimento = e.value;
              handleUpdateDependente(rowData.id, dependente);
            }
          }}
          monthNavigator
          yearNavigator
          yearRange="1800:2050"
          monthNavigatorTemplate={monthNavigatorTemplate}
          yearNavigatorTemplate={yearNavigatorTemplate}
          locale="pt-br"
          showIcon
          maxDate={new Date(Date.now())}
          mask="99/99/9999"
        />
      );
    },
    [handleUpdateDependente]
  );

  const nomeBodyTemplate = useCallback(
    (rowData: Dependente) => {
      const index = dependentes.findIndex((dependente) => dependente.id === rowData.id);
      return (
        <InputText
          key={rowData.id}
          placeholder=""
          value={rowData.nome}
          autoFocus={index === 0}
          onChange={(e) => {
            const dependente = { ...rowData };
            if (e.target.value) {
              dependente.nome = e.target.value;
              handleUpdateDependente(rowData.id, dependente);
            }
          }}
        />
      );
    },
    [dependentes, handleUpdateDependente]
  );

  const parentescoBodyTemplate = useCallback(
    (rowData: Dependente) => {
      return (
        <Dropdown
          key={rowData.id}
          value={rowData.parentesco}
          emptyMessage="Nenhum parentesco encontrado."
          placeholder="Selecione"
          options={parentescoOptions}
          onChange={(e) => {
            const dependente = { ...rowData };
            if (e.target.value) {
              dependente.parentesco = e.target.value;
              handleUpdateDependente(rowData.id, dependente);
            }
          }}
        />
      );
    },
    [handleUpdateDependente, parentescoOptions]
  );

  const deleteButtonBodyTemplate = useCallback(
    (rowData: Dependente) => {
      return (
        <>
          <Button
            type="button"
            className="p-ml-3 balandrau-exclude-button"
            icon="pi pi-trash"
            onClick={() => {
              handleRemoveDependente(rowData.id);
            }}
          />
        </>
      );
    },
    [handleRemoveDependente]
  );

  const columnsDependentes = useMemo(
    (): ColumnProps[] => [
      {
        field: "parentesco",
        header: "Parentesco",
        body: parentescoBodyTemplate,
        bodyClassName: "p-fluid",
        headerStyle: { width: "20%" },
      },
      {
        field: "nome",
        header: "Nome completo",
        body: nomeBodyTemplate,
        bodyClassName: "p-fluid",
      },
      {
        field: "dtNascimento",
        header: "Nascimento",
        body: dtNascimentoBodyTemplate,
        bodyClassName: "p-fluid",
        headerStyle: { width: "20%" },
      },

      {
        body: deleteButtonBodyTemplate,
        align: "center",
        style: { width: "100px", verticalAlign: "middle" },
      },
    ],
    [deleteButtonBodyTemplate, dtNascimentoBodyTemplate, nomeBodyTemplate, parentescoBodyTemplate]
  );

  return (
    <SimpleEntityListPage
      showListHeader
      newButtonLabel="Adicionar dependente"
      newButtonIcon="fas fa-user-plus"
      onNewButtonCLick={() => {
        handleAddDependente({ id: v4(), nome: "", parentesco: "", dtNascimento: undefined });
      }}
      datatableRef={dt}
    >
      <SimpleEntityDatatable
        datatableRef={dt}
        value={dependentes}
        columns={columnsDependentes}
        paginator={false}
        emptyMessage="Nenhum dependente"
        rows={undefined}
      />
    </SimpleEntityListPage>
  );
};

export default ProfanoDependentes;
