import _ from "lodash";
import { Button } from "primereact/button";
import { InputMask } from "primereact/inputmask";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { cpf as cpfValidator } from "cpf-cnpj-validator";

import logo from "../../assets/img/balandrau-logo-nome-horizontal.svg";
import SimpleLabelAndValue from "../../components/SimpleLabelAndValue";
import { useToast } from "../../hooks/toast";
import publicApi from "../../services/public-api";

import { numberToReal } from "../../utils/numbers";
import { votacaoEscrutinio } from "../../errors/votacaoEscrutinio";
import { useError } from "../../hooks/error";
import Loading from "../../components/Loading";

interface Params {
  token: string;
}

interface EnderecoDTO {
  cep: string;
  logradouro: string;
  numeroLogradouro: string;
  complemento: string;
  bairro: string;
  cidade: string;
  estado: string;
  descricao: string;
  tipo: string;
}

interface Profano {
  ativo: boolean;
  foto: string;
  nome: string;
  trabEmpresaOrgao: string;
  trabCargoFuncao: string;
  trabProfissao: string;
  estadoCivil: string;
  idade: number;
  trabRendaMensal: number;
  enderecoPessoal: EnderecoDTO;
  votou: boolean;
}

const VotacaoEscrutinioPage: React.FC = () => {
  const profanoInicial = useMemo((): Profano => {
    return {
      nome: "",
      ativo: true,
      estadoCivil: "",
      foto: "",
      idade: 0,
      trabCargoFuncao: "",
      trabEmpresaOrgao: "",
      trabProfissao: "",
      trabRendaMensal: 0,
      enderecoPessoal: {} as EnderecoDTO,
      votou: false,
    };
  }, []);
  const { token } = useParams<Params>();
  const [profano, setProfano] = useState<Profano>(profanoInicial);
  const [buttonValue, setButtonValue] = useState<"branca" | "preto">();
  const [votado, setVotado] = useState<boolean>(false);
  const [votacaoEncerrada, setVotacaoEncerrada] = useState(false);
  const [cpf, setCpf] = useState<string>("");
  const [cpfToLoad, setCpfToLoad] = useState<string>(""); // usado para fazer o login automático caso tenha cpf no storage
  const [loading, setLoading] = useState(true);
  const [loadingData, setLoadingData] = useState(false);
  const [errors, setErrors] = useState<{ [name: string]: string }>({});

  const [loginStep, setLoginStep] = useState(true);

  const { showToast } = useToast();
  const { handleError } = useError();

  const parseEstadoCivil = useCallback(() => {
    switch (profano?.estadoCivil) {
      case "CASADO":
        return "Casado";
      case "SOLTEIRO":
        return "Solteiro";
      case "DIVORCIADO":
        return "Divorciado";
      case "UNIAO_ESTAVEL":
        return "União estável";
      case "VIUVO":
        return "Viúvo";
      default:
        return "";
    }
  }, [profano?.estadoCivil]);

  const montarEndereco = useCallback(() => {
    const endereco = profano?.enderecoPessoal;
    return `${endereco?.logradouro} nº ${endereco?.numeroLogradouro}, ${endereco?.bairro}`;
  }, [profano?.enderecoPessoal]);

  const sendVoto = useCallback(() => {
    publicApi
      .put(`escrutinio/voto/${token}`, {
        pretoOuBranco: buttonValue,
        cpf: cpf.replaceAll(".", "").replace("-", ""),
      })
      .then(() => {
        setVotado(true);
      })
      .catch((err) => {
        if (err.response.data.titulo === "Escrutinio apagado.") {
          showToast({ title: "Escrutínio apagado.", type: "error", description: "Solicite uma nova url." });
          setVotacaoEncerrada(true);
          return;
        }

        if (err.response.data.titulo === "Escrutinio está desativado.") {
          setVotacaoEncerrada(true);
          setCpfToLoad(cpf);
          localStorage.setItem(`@Balandrau:escrutinioCpfLogin`, cpf);
          return;
        }

        handleError({ error: err, action: "carregar escrutínio", knownErrors: votacaoEscrutinio });
      });
  }, [buttonValue, cpf, handleError, showToast, token]);

  const validarCPF = useCallback(() => {
    return !_.isEmpty(cpf) && cpfValidator.isValid(cpf);
  }, [cpf]);

  const dataLoad = useCallback(() => {
    setLoadingData(true);
    publicApi
      .get<Profano>(`escrutinio/voto/${token}?telaLogin=false&cpf=${cpf}`)
      .then(({ data }) => {
        setProfano(data);
        if (!data.ativo) {
          setVotacaoEncerrada(true);
        }

        setVotado(data.votou);
      })
      .catch((err) => {
        handleError({ error: err, action: "carregar escrutínio", knownErrors: votacaoEscrutinio });
      })
      .finally(() => setLoadingData(false));
  }, [cpf, handleError, token]);

  const loginLoad = useCallback(() => {
    setLoading(true);
    publicApi
      .get(`escrutinio/voto/${token}?telaLogin=true&cpf=`)
      .then(({ data }) => {
        setProfano(data);
      })
      .catch((err) => {
        handleError({ error: err, action: "carregar escrutínio", knownErrors: votacaoEscrutinio });
      })
      .finally(() => setLoading(false));
  }, [handleError, token]);

  const participarEscrutinio = useCallback(
    (cpfSend: string) => {
      setLoading(true);
      publicApi
        .post(`escrutinio/login/${token}`, {
          cpf: cpfSend.replaceAll(".", "").replace("-", ""),
        })
        .then(() => {
          localStorage.setItem(`@Balandrau:escrutinioCpfLogin`, cpfSend);
          setCpfToLoad(cpfSend);
          setLoginStep(false);
          dataLoad();
        })
        .catch((err) => {
          const errorsLocal: { [name: string]: string } = {};

          loginLoad();
          if (err.response.data.titulo === "Escrutinio está desativado.") {
            setVotacaoEncerrada(true);
            setCpfToLoad(cpf);
            localStorage.setItem(`@Balandrau:escrutinioCpfLogin`, cpf);
            return;
          }
          if (err.response.data.titulo === "Irmão não encontrado.") {
            showToast({
              title: "Irmão não encontrado.",
              type: "warn",
              description: "Insira um cpf existente em nossa base de dados.",
            });
            errorsLocal.cpf = "CPF não encontrado.";
            setErrors(errorsLocal);
            return;
          }

          handleError({ error: err, action: "fazer login", knownErrors: votacaoEscrutinio });
        })
        .finally(() => setLoading(false));
    },
    [cpf, dataLoad, handleError, loginLoad, showToast, token]
  );

  const handleLogoutButtonClick = useCallback(() => {
    localStorage.removeItem(`@Balandrau:escrutinioCpfLogin`);
    setCpfToLoad("");
    setCpf("");
    // setProfano(profanoInicial);
    setLoginStep(true);
    setVotado(false);
    setVotacaoEncerrada(false);
    // loginLoad();
  }, []);

  useEffect(() => {
    if (cpfToLoad) participarEscrutinio(cpfToLoad);
  }, [cpfToLoad, participarEscrutinio]);

  useEffect(() => {
    const cpfStorage = localStorage.getItem(`@Balandrau:escrutinioCpfLogin`);

    if (cpfStorage) {
      setCpf(cpfStorage);
      setCpfToLoad(cpfStorage);
    }
  }, [participarEscrutinio]);

  useEffect(() => {
    const cpfStorage = localStorage.getItem(`@Balandrau:escrutinioCpfLogin`);

    if (!cpfStorage) {
      loginLoad();
    }
  }, [loginLoad]);

  return (
    <div className="escrutinio-container">
      <Loading isLoading={loading || loadingData} />

      {!loading && !loadingData && (
        <>
          <div className="escrutinio-header">
            <div className="escrutinio-header-top">
              <img className="escrutinio-header-top-logo" alt="imagem do profano" src={logo} />
              {cpfToLoad && (
                <div className="escrutinio-header-top-logout-container">
                  <span className="escrutino-header-top-logout-label">{`${cpfToLoad.substring(
                    0,
                    3
                  )}.***.***-${cpfToLoad.substring(9, 11)}`}</span>
                  <span
                    className="escrutino-header-top-logout-button color-link"
                    onClickCapture={handleLogoutButtonClick}
                  >
                    Sair
                  </span>
                </div>
              )}
            </div>

            <p className="escrutinio-header-text">Escrutínio de {profano?.nome}</p>
          </div>
          {loginStep && !votado && !votacaoEncerrada && (
            <div className="escrutinio-login">
              <div className="escrutinio-login-input p-d-flex p-flex-column">
                <label htmlFor="cpf">Informe seu CPF:</label>
                <InputMask
                  id="cpf"
                  value={cpf}
                  onChange={(e) => {
                    setCpf(e.target.value);

                    const errorsLocal = _.cloneDeep(errors);
                    delete errorsLocal.cpf;
                    setErrors(errorsLocal);
                  }}
                  unmask
                  placeholder="XXX.XXX.XXX-XX"
                  mask="999.999.999-99"
                />
                {!_.isEmpty(errors.cpf) && (
                  <div className="custom-vertical-form-field-error-container p-d-flex p-jc-start p-mt-2 p-ai-center">
                    <i className="fas fa-exclamation-triangle p-mr-2 p-error" />
                    <small className="p-error p-mt-0 custom-vertical-form-field-error">{errors.cpf}</small>
                  </div>
                )}
              </div>

              <p className="escrutinio-login-voto-secreto">Seu voto é secreto.</p>
              <p className="escrutinio-login-simple-text">
                Seu CPF garante sua presença no escrutínio, mas não vinculará seu voto a você.
              </p>

              <Button
                label="Participar do escrutínio"
                disabled={!validarCPF()}
                onClick={() => participarEscrutinio(cpf)}
              />
            </div>
          )}
          {!loginStep && !votado && !votacaoEncerrada && (
            <div className="escrutinio-content">
              <div className="escrutinio-content-profano">
                <div className="escrutinio-content-profano-header">
                  {profano?.foto && (
                    <img
                      className="escrutinio-content-profano-header-image"
                      alt="logo balandrau"
                      src={`${profano?.foto}`}
                    />
                  )}
                  {/* <p className="escrutinio-content-profano-header-text">{profano?.nome}</p> */}
                </div>
                <div className="escrutinio-content-profano-content">
                  {/* <SimpleLabelAndValue label="Nome" value={`${profano?.nome} anos`} /> */}
                  <SimpleLabelAndValue label="Idade" value={`${profano?.idade} anos`} />
                  <SimpleLabelAndValue label="Estado Civil" value={`${parseEstadoCivil()}`} />
                  <SimpleLabelAndValue label="Profissão" value={`${profano?.trabProfissao}`} />
                  <SimpleLabelAndValue label="Salário" value={`${numberToReal(profano?.trabRendaMensal)}`} />
                  <SimpleLabelAndValue label="Cargo" value={`${profano?.trabCargoFuncao}`} />
                  <SimpleLabelAndValue label="Empresa" value={`${profano?.trabEmpresaOrgao}`} />
                  <SimpleLabelAndValue label="Endereço pessoal" value={`${montarEndereco()}`} />
                </div>
              </div>
              <hr />
              <div className="escrutinio-content-voto">
                <p className="escrutinio-content-voto-text-title">
                  Você é favorável à continuidade do processo de Iniciação do profano?
                </p>
                <div className="escrutinio-content-voto-buttons">
                  <div
                    className={`escrutinio-content-voto-buttons-sim ${buttonValue === "branca" && "selecionado-sim"}`}
                    onClickCapture={() => {
                      setButtonValue("branca");
                    }}
                  >
                    Sim
                  </div>
                  <div
                    className={`escrutinio-content-voto-buttons-nao ${buttonValue === "preto" && "selecionado-nao"}`}
                    onClickCapture={() => {
                      setButtonValue("preto");
                    }}
                  >
                    Não
                  </div>
                </div>
                <Button
                  type="button"
                  label="Enviar Escrutínio"
                  disabled={!buttonValue}
                  onClick={() => {
                    sendVoto();
                  }}
                />
              </div>
            </div>
          )}
          {votado && !votacaoEncerrada && (
            <div className="escrutinio-finalizacao">
              <i className="fa-solid fa-thumbs-up escrutinio-finalizacao-icon" />
              <p className="escrutinio-finalizacao-first-text">Tudo certo!</p>
              <p className="escrutinio-finalizacao-second-text">Você realizou o escrutínio desse profano.</p>
              <p className="p-mt-2 escrutinio-finalizacao-second-text">Aguarde instruções.</p>
            </div>
          )}
          {votacaoEncerrada && (
            <div className="escrutinio-finalizacao">
              <i className="fa-solid fa-hourglass-end escrutinio-finalizacao-icon" />
              <p className="escrutinio-finalizacao-first-text">Escrutínio Encerrado!</p>
              <p className="escrutinio-finalizacao-second-text">
                Não é mais possível realizar o escrutínio desse profano.
              </p>
              <p className="p-mt-2 escrutinio-finalizacao-second-text">Procure a secretaria para saber o resultado.</p>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default VotacaoEscrutinioPage;
