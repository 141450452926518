import { IAppError } from "./AppErrorInterfaces";

export const nominatasErrors: IAppError[] = [
  {
    code: "UUID inválido.",
    message: "Alteração inválida na url.",
  },
  {
    code: "Não foram encontradas nominatas para esta loja.",
    message: "Não foram encontradas nominatas para esta loja.",
  },
  {
    code: "Nominata não encontrada.",
    title: "Não foi possível carregar esta nominata",
    message: "Tente novamente ou contate um dos nossos administradores",
  },
  {
    code: "Loja não encontrada.",
    title: "Essa loja não existe em nossos dados",
    message: "Entre em contato com os administradores para averiguação do problema",
  },
];
