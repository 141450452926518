import React, { useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { Dropdown } from "primereact/dropdown";
import { useHistory, useParams } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { useToast } from "../../../hooks/toast";
import { useError } from "../../../hooks/error";
import api from "../../../services/api";
import CustomFormField from "../../../components/CustomFormField";
import FloatingSave from "../../../components/FloatingSave";
import SimpleEntityPage from "../../../components/SimpleEntityPage";
import { irmaoErrors } from "../../../errors/irmao";
import { PecaArquitetura, TiposPecasElement, tiposPecasMap } from "../PecasArquiteturaPage";
import { formatDateToStringToSend } from "../../../utils/formatDateToStringForSend";
import FileSelect from "../../../components/FileSelect";
import { pecasArquiteturaErrors } from "../../../errors/pecas-arquitetura";
import { GrauElement, grausMap } from "../../../components/IrmaoComponent";

interface Params {
  id: string;
}

export interface IrmaoAuthor {
  uuid: string;
  nome: string;
  id: string;
}

export interface PecaArquiteturaForm {
  author?: IrmaoAuthor;
  titulo: string;
  dtPublicacao: Date;
  tipoPublicacao: TiposPecasElement;
  arquivo?: File;
}

const PecasArquiteturaFormPage: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [loadingPeca, setLoadingPeca] = useState(false);
  const [isDirty, setIsDirty] = useState(false); // TODO: implementar o isDirty (indo e voltando)
  const [loadingSalvar, setLoadingSalvar] = useState(false);

  const [pecaArquiteturaInicial, setPecaArquiteturaInicial] = useState<PecaArquiteturaForm>({
    titulo: "",
    dtPublicacao: new Date(),
    author: undefined,
    tipoPublicacao: tiposPecasMap.NAO_PUBLICADO,
    arquivo: undefined,
  } as PecaArquiteturaForm);

  // estados dos campos do formulário
  const [author, setAuthor] = useState<IrmaoAuthor>();
  const [titulo, setTitulo] = useState<string>("");
  const [dtPublicacao, setDtPublicacao] = useState<Date>(new Date());
  const [tipoPublicacao, setTipoPublicacao] = useState<TiposPecasElement>(tiposPecasMap.NAO_PUBLICADO);
  const [grauPeca, setGrauPeca] = useState<GrauElement>(grausMap.APRENDIZ);
  const [arquivo, setArquivo] = useState<File>();

  const [fileToReset, setFileToReset] = useState<File | undefined>();

  const [irmaos, setIrmaos] = useState<IrmaoAuthor[]>();
  const tipoOptions = useMemo(
    (): TiposPecasElement[] => [
      tiposPecasMap.NAO_PUBLICADO,
      tiposPecasMap.PUBLICADO_LOJA,
      tiposPecasMap.PUBLICADO_POTENCIA,
    ],
    []
  );

  const tipoOptionsGrau = useMemo((): GrauElement[] => [grausMap.APRENDIZ, grausMap.COMPANHEIRO, grausMap.MESTRE], []);

  const { showToast } = useToast();
  const { handleError } = useError();
  const { id } = useParams<Params>();

  const [errors, setErrors] = useState<{ [campo: string]: string }>({});

  const history = useHistory();

  const isValidForm = useCallback(() => {
    const localErrors = {} as { [campo: string]: string };
    if (!titulo || _.isEmpty(titulo.trim())) {
      localErrors.titulo = "O campo título é obrigatório.";
    }
    if (!author || _.isEmpty(author)) {
      localErrors.author = "O campo autor é obrigatório.";
    }
    if (!tipoPublicacao || _.isEmpty(tipoPublicacao)) {
      localErrors.tipoPublicacao = "O campo tipo de publicação é obrigatório.";
    }
    if (!dtPublicacao || !_.isDate(dtPublicacao)) {
      localErrors.dtPublicacao = "O campo data de publicação é obrigatório.";
    }

    if (!id && !arquivo) {
      localErrors.arquivo = "O campo peça para publicação é obrigatório.";
    }

    setErrors(localErrors);

    if (!_.isEmpty(localErrors)) {
      return false;
    }
    return true;
  }, [arquivo, author, dtPublicacao, id, tipoPublicacao, titulo]);

  const salvarForm = useCallback(() => {
    if (isValidForm()) {
      setLoadingSalvar(true);
      const formData = new FormData();
      formData.append("titulo", titulo || "");
      formData.append("dtPublicacao", formatDateToStringToSend(dtPublicacao) || "");
      formData.append("tipoPublicacao", tipoPublicacao.tipo || "");
      formData.append("uuidIrmao", author ? author.uuid : "");
      formData.append("grauPeca", grauPeca.grau || "");
      if (arquivo) {
        formData.append("arquivo", arquivo);
      }

      if (!id) {
        api
          .post("pecas-arquitetura/adm", formData, { headers: { "Content-Type": "multipart/form-data" } })
          .then(() => {
            showToast({
              title: "Sucesso!",
              type: "success",
              description: "Uma nova peça de arquitetura foi criada.",
            });
            history.push("/pecas-arquitetura");
          })
          .catch((err: any) => {
            handleError({ error: err, action: "criar peça de arquitetura", knownErrors: irmaoErrors });
          })
          .finally(() => setLoadingSalvar(false));
      } else {
        api
          .put(`pecas-arquitetura/adm/${id}`, formData, { headers: { "Content-Type": "multipart/form-data" } })
          .then(() => {
            showToast({
              title: "Sucesso!",
              type: "success",
              description: "A peça de arquitetura foi editada.",
            });
            history.push("/pecas-arquitetura");
          })
          .catch((err: any) => {
            handleError({ error: err, action: "salvar peça de arquitetura", knownErrors: irmaoErrors });
          })
          .finally(() => setLoadingSalvar(false));
      }
    }
  }, [
    isValidForm,
    titulo,
    dtPublicacao,
    tipoPublicacao.tipo,
    author,
    arquivo,
    id,
    showToast,
    history,
    handleError,
    grauPeca.grau,
  ]);

  const loadPecaArquitetura = useCallback(() => {
    setLoadingPeca(true);
    api
      .get<PecaArquitetura>(`pecas-arquitetura/adm/${id}`)
      .then(({ data }) => {
        const authorFound = irmaos?.find((irmao) => irmao.uuid === data.authorUuid);

        setPecaArquiteturaInicial({
          titulo: data.titulo,
          tipoPublicacao: tiposPecasMap[data.tipoPublicacao],
          dtPublicacao: new Date(data.dtPublicacao),
          author: authorFound,
          arquivo: undefined,
        });
        setTitulo(data.titulo || "");
        setAuthor(authorFound);
        setTipoPublicacao(data.tipoPublicacao ? tiposPecasMap[data.tipoPublicacao] : tiposPecasMap.NAO_PUBLICADO);
        setGrauPeca(data.grauPeca ? grausMap[data.grauPeca] : grausMap.APRENDIZ);
        setDtPublicacao(new Date(data.dtPublicacao) || new Date());
        setArquivo(undefined);
      })
      .catch((err) => {
        handleError({ error: err, action: "carregar a peça", knownErrors: pecasArquiteturaErrors });
      })
      .finally(() => {
        setLoadingPeca(false);
      });
  }, [handleError, id, irmaos]);

  const loadIrmaos = useCallback(() => {
    setLoading(true);
    api
      .get<IrmaoAuthor[]>(`irmaos`)
      .then(({ data }) => {
        setIrmaos(data);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
        handleError({ error: err, action: "carregar irmãos", knownErrors: pecasArquiteturaErrors });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [handleError]);

  const yearNavigatorTemplate = useCallback((e: any) => {
    return (
      <Dropdown
        value={e.value}
        options={e.options}
        onChange={(event) => e.onChange(event.originalEvent, event.value)}
        className="p-ml-2"
        style={{ lineHeight: 1 }}
      />
    );
  }, []);

  const monthNavigatorTemplate = useCallback((e: any) => {
    return (
      <Dropdown
        value={e.value}
        options={e.options}
        onChange={(event) => e.onChange(event.originalEvent, event.value)}
        style={{ lineHeight: 1 }}
      />
    );
  }, []);

  useEffect(() => {
    loadIrmaos();
  }, [loadIrmaos]);

  useEffect(() => {
    if (!!id && irmaos !== undefined) {
      loadPecaArquitetura();
    }
  }, [id, irmaos, loadPecaArquitetura]);

  const checkIsDirty = useCallback((valueA: any, valueB: any) => {
    return !_.isEqual(valueA, valueB);
  }, []);

  const resetFormData = useCallback(() => {
    setAuthor(pecaArquiteturaInicial.author);
    setTitulo(pecaArquiteturaInicial.titulo);
    setDtPublicacao(pecaArquiteturaInicial.dtPublicacao);
    setTipoPublicacao(pecaArquiteturaInicial.tipoPublicacao);
    setArquivo(pecaArquiteturaInicial.arquivo);
    setFileToReset(undefined);
    setIsDirty(false);
    setErrors({});
  }, [pecaArquiteturaInicial]);

  useEffect(() => {
    const titleIsDirty = checkIsDirty(titulo, pecaArquiteturaInicial.titulo);
    const typeIsDirty = checkIsDirty(tipoPublicacao, pecaArquiteturaInicial.tipoPublicacao);
    const authorIsDirty = checkIsDirty(author, pecaArquiteturaInicial.author);
    const dateIsDirty = checkIsDirty(dtPublicacao, pecaArquiteturaInicial.dtPublicacao);
    const fileIsDirty = checkIsDirty(arquivo, pecaArquiteturaInicial.arquivo);
    const localIsDirty = titleIsDirty || typeIsDirty || authorIsDirty || dateIsDirty || fileIsDirty;
    setIsDirty(localIsDirty);
  }, [arquivo, author, checkIsDirty, dtPublicacao, isDirty, pecaArquiteturaInicial, tipoPublicacao, titulo]);

  return (
    <SimpleEntityPage showTopBar routeBack="/pecas-arquitetura" isFormPage loading={loading || loadingPeca}>
      <div className="p-grid p-flex-row p-jc-between">
        <div className="p-col-7 p-grid">
          <div className="p-fluid p-col-12">
            <CustomFormField
              icon="pi pi-star"
              htmlForDescription="titulo"
              description="Título"
              inputBody={
                <InputText
                  id="titulo"
                  value={titulo || ""}
                  onChange={(e) => {
                    setTitulo(e.target.value);

                    const errorsLocal = _.cloneDeep(errors);
                    delete errorsLocal.titulo;
                    setErrors(errorsLocal);
                  }}
                />
              }
              required
              errorMessage={errors.titulo}
            />
            <CustomFormField
              icon="pi pi-user-edit"
              htmlForDescription="author"
              description="Autor"
              inputBody={
                <Dropdown
                  placeholder="Selecione"
                  showClear
                  dataKey="uuid"
                  optionLabel="nome"
                  options={irmaos}
                  value={author}
                  onChange={(e) => {
                    setAuthor(e.target.value);

                    const errorsLocal = _.cloneDeep(errors);
                    delete errorsLocal.author;
                    setErrors(errorsLocal);
                  }}
                  emptyFilterMessage="Nenhum irmão encontrado"
                  filter
                  filterBy="nome"
                />
              }
              required
              errorMessage={errors.author}
            />

            <CustomFormField
              icon="pi pi-tag"
              htmlForDescription="tipoPublicacao"
              description="Tipo de publicação"
              inputBody={
                <Dropdown
                  placeholder="Selecione"
                  options={tipoOptions}
                  value={tipoPublicacao}
                  onChange={(e) => {
                    setTipoPublicacao(e.value);

                    const errorsLocal = _.cloneDeep(errors);
                    delete errorsLocal.tipoPublicacao;
                    setErrors(errorsLocal);
                  }}
                />
              }
              required
              errorMessage={errors.tipoPublicacao}
            />

            <CustomFormField
              icon="pi pi-tag"
              htmlForDescription="grauPeca"
              description="Grau da Peça"
              inputBody={
                <Dropdown
                  placeholder="Selecione"
                  options={tipoOptionsGrau}
                  value={grauPeca}
                  onChange={(e) => {
                    setGrauPeca(e.value);

                    const errorsLocal = _.cloneDeep(errors);
                    delete errorsLocal.grauPeca;
                    setErrors(errorsLocal);
                  }}
                />
              }
              required
              errorMessage={errors.grauPeca}
            />

            <CustomFormField
              icon="pi pi-calendar"
              htmlForDescription="dtPublicacao"
              description="Data de publicação"
              inputBody={
                <Calendar
                  id="dtPublicacao"
                  placeholder="DD/MM/AAAA"
                  value={dtPublicacao}
                  onChange={(e) => {
                    if (e.target && e.target.value) {
                      setDtPublicacao(new Date(e.target.value.toLocaleString("en-us")));
                    }
                    const errorsLocal = _.cloneDeep(errors);
                    delete errorsLocal.dtPublicacao;
                    setErrors(errorsLocal);
                  }}
                  monthNavigator
                  yearNavigator
                  yearRange="1800:2050"
                  monthNavigatorTemplate={monthNavigatorTemplate}
                  yearNavigatorTemplate={yearNavigatorTemplate}
                  locale="pt-br"
                  showIcon
                />
              }
              required
              errorMessage={errors.dtPublicacao}
            />
            <CustomFormField
              icon="pi pi-upload"
              htmlForDescription="arquivo"
              description="Peça para publicação"
              inputBody={
                <FileSelect
                  accept="application/pdf"
                  fileLabel="Selecionar arquivo"
                  maxFileSize={15}
                  initialFile={fileToReset}
                  onFileChange={(file) => {
                    setArquivo(file);
                    setFileToReset(file);

                    const errorsLocal = _.cloneDeep(errors);
                    delete errorsLocal.arquivo;
                    setErrors(errorsLocal);
                  }}
                />
              }
              required
              errorMessage={errors.arquivo}
            />
          </div>
        </div>
      </div>

      {isDirty && (
        <div>
          <FloatingSave
            resetCommand={resetFormData}
            disabled={
              !author ||
              _.isEmpty(author) ||
              !titulo ||
              !dtPublicacao ||
              !tipoPublicacao ||
              _.isEmpty(tipoPublicacao) ||
              (!id && !arquivo)
            }
            saveCommand={() => salvarForm()}
            loadingOnSave={loadingSalvar}
          />
        </div>
      )}
    </SimpleEntityPage>
  );
};

export default PecasArquiteturaFormPage;
