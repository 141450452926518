/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface DocState {
  nome: string;
  cpf: string;
  identidade: string;
}

const initialState: DocState = {
  nome: "",
  cpf: "",
  identidade: "",
};

export const decSubsisInfosSlice = createSlice({
  name: "declaracaoInfos",
  initialState,
  reducers: {
    saveCpf: (state, action: PayloadAction<DocState["cpf"]>) => {
      state.cpf = action.payload;
    },
    saveNome: (state, action: PayloadAction<DocState["nome"]>) => {
      state.nome = action.payload;
    },
    saveIdentidade: (state, action: PayloadAction<DocState["identidade"]>) => {
      state.identidade = action.payload;
    },
  },
});
export const { saveCpf, saveNome, saveIdentidade } = decSubsisInfosSlice.actions;
export default decSubsisInfosSlice.reducer;
