import { Button } from "primereact/button";
import { ColumnProps } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Tag } from "primereact/tag";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import SimpleEntityDatatable from "../../../components/SimpleEntityDatatable";
import SimpleEntityListPage from "../../../components/SimpleEntityPage";
import { useToast } from "../../../hooks/toast";
import api from "../../../services/api";
import { convertDateToStringBR } from "../../../utils/convertDates";

interface EscrutinioResponse {
  ativo: boolean;
  dtEscrutinio: string;
  faltamVotar: number;
  nomeProfano: string;
  quantidadeAcessos: number;
  quantidadeBrancas: number;
  quantidadeParticipantes: number;
  quantidadePretas: number;
  totalVotos: number;
  url: string;
  uuid: string;

  dtEscrutinioDate?: Date;
}

const EscrutinioPage: React.FC = () => {
  const dt = useRef<DataTable>(null);

  const [loading, setLoading] = useState<boolean>(false);
  const [escrutinios, setEscrutinios] = useState<EscrutinioResponse[]>();
  const [globalFilter, setGlobalFilter] = useState<string>("");

  const { showToast } = useToast();

  const history = useHistory();

  const handleCopyUrl = useCallback(
    (url: string) => {
      showToast({
        type: "info",
        title: "Ctrl+C",
        description: "Link de votação do escrutínio copiado para área de transferência",
      });
      if (url) navigator.clipboard.writeText(url);
    },
    [showToast]
  );

  const linkBodyTemplate = useCallback(
    (rowData: EscrutinioResponse) => {
      return (
        <div className="copiar-link-container" onClickCapture={() => handleCopyUrl(rowData.url)}>
          <i className="fa-solid fa-link" />
          <span className="p-ml-2 color-link">copiar link</span>
        </div>
      );
    },
    [handleCopyUrl]
  );

  const situacaoBodyTemplate = useCallback((rowData: EscrutinioResponse) => {
    return (
      <Tag
        style={{ width: 110 }}
        className={rowData.ativo ? "escrutinio-situacao-andamento" : "escrutinio-situacao-encerrado"}
        value={rowData.ativo ? "Em andamento" : "Encerrado"}
      />
    );
  }, []);

  const editDeleteButtonsBodyTemplate = useCallback(
    (data: EscrutinioResponse) => {
      return (
        <>
          <Button
            type="button"
            className="p-row-editor-init p-link "
            icon="pi pi-eye"
            tooltip="Acompanhar Escrutínio"
            tooltipOptions={{ position: "left" }}
            onClick={() => {
              history.push({ pathname: `/escrutinio/${data.uuid}` });
            }}
          />
        </>
      );
    },
    [history]
  );

  const dtEscrutinioBodyTemplate = useCallback((rowData: EscrutinioResponse) => {
    return rowData.dtEscrutinio ? convertDateToStringBR(rowData.dtEscrutinio) : "";
  }, []);

  const qtdPretasHeaderTemplate = useCallback(() => {
    return (
      <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
        <div
          style={{
            width: 20,
            height: 20,
            borderRadius: 10,
            background: "#000",
          }}
        />
      </div>
    );
  }, []);

  const qtdBrancasHeaderTemplate = useCallback(() => {
    return (
      <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
        <div
          style={{
            width: 20,
            height: 20,
            borderRadius: 10,
            background: "#fff",
          }}
        />
      </div>
    );
  }, []);

  const qtdEsferasBodyTemplate = useCallback(
    (cor: "quantidadeBrancas" | "quantidadePretas", rowData: EscrutinioResponse) => {
      if (rowData.ativo) {
        return "--";
      }

      return `${rowData[cor]}`;
    },
    []
  );

  const columnsEscrutinios = useMemo(
    (): ColumnProps[] => [
      {
        field: "ativo",
        header: "Situação",
        sortable: true,
        align: "center",
        style: { verticalAlign: "middle", width: 140 },
        className: "p-text-nowrap p-text-truncate",
        body: situacaoBodyTemplate,
      },
      {
        field: "nomeProfano",
        header: "Nome do profano",
        sortable: true,
        style: { textAlign: "left", verticalAlign: "middle", width: 250 },
      },
      {
        field: "nomeProfano",
        header: "Link do escrutínio",
        align: "center",
        sortable: false,
        style: { verticalAlign: "middle", width: 110 },
        body: linkBodyTemplate,
      },
      {
        field: "quantidadeAcessos",
        header: "Acessos",
        sortable: false,
        align: "center",
        style: { verticalAlign: "middle", width: 70 },
      },
      {
        field: "totalVotos",
        header: "Total de esferas",
        sortable: false,
        align: "center",
        style: { verticalAlign: "middle", width: 100 },
      },
      {
        field: "quantidadeBrancas",
        header: qtdBrancasHeaderTemplate(),
        sortable: false,
        align: "center",
        style: { verticalAlign: "middle", width: 40 },
        body: (rowData) => qtdEsferasBodyTemplate("quantidadeBrancas", rowData),
      },
      {
        field: "quantidadePretas",
        header: qtdPretasHeaderTemplate(),
        sortable: false,
        align: "center",
        style: { verticalAlign: "middle", width: 40 },
        body: (rowData) => qtdEsferasBodyTemplate("quantidadePretas", rowData),
      },
      {
        field: "dtEscrutinioDate",
        header: "Data",
        sortable: true,
        align: "center",
        style: { verticalAlign: "middle", width: 100 },
        body: dtEscrutinioBodyTemplate,
      },
      {
        align: "center",
        body: editDeleteButtonsBodyTemplate,
        style: { width: "70px", verticalAlign: "middle" },
      },
    ],
    [
      dtEscrutinioBodyTemplate,
      editDeleteButtonsBodyTemplate,
      linkBodyTemplate,
      qtdBrancasHeaderTemplate,
      qtdEsferasBodyTemplate,
      qtdPretasHeaderTemplate,
      situacaoBodyTemplate,
    ]
  );

  useEffect(() => {
    setLoading(true);
    api
      .get<EscrutinioResponse[]>("escrutinio")
      .then(({ data }) => {
        data.map((escrutinio) => {
          // eslint-disable-next-line no-param-reassign
          escrutinio.dtEscrutinioDate = new Date(Date.parse(escrutinio.dtEscrutinio));
          return escrutinio;
        });
        setEscrutinios(data);
      })
      .catch(() => {
        showToast({
          type: "error",
          title: "Não foi possível carregar os escrutínios",
          description: "Tente novamente ou contate nosso time de suporte para solicitar ajuda.",
        });
      })
      .finally(() => setLoading(false));
  }, [showToast]);

  return (
    <SimpleEntityListPage
      showListHeader
      newButtonLabel="Novo escrutínio"
      onNewButtonCLick={() => {
        history.push("/escrutinio/novo");
      }}
      onGlobalFilterChange={(value) => setGlobalFilter(value)}
      loading={loading}
      showExportCsvButton
      datatableRef={dt}
    >
      <SimpleEntityDatatable
        datatableRef={dt}
        value={escrutinios}
        columns={columnsEscrutinios}
        globalFilter={globalFilter}
        sortField="nomeProfano"
        sortOrder={1}
      />
    </SimpleEntityListPage>
  );
};

export default EscrutinioPage;
