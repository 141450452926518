import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import React, { useCallback, useMemo } from "react";
import { ProfanoInfo, TurmaProfano } from "../../pages/Profano/ProfanoFormPage";
import { convertDateToStringBR } from "../../utils/convertDates";
import CustomFormField from "../CustomFormField";
import PreviewSelectAvatar from "../PreviewSelectAvatar";
import { SituacaoProfano, SituacaoProfanoElement, situacoesProfanoMap } from "../ProfanoSituacaoComponent";
import SimpleEntityPage from "../SimpleEntityPage";

interface Props {
  loading: boolean;
  informacoes: ProfanoInfo;
  dropdownSituacaoProfanoOptions: SituacaoProfanoElement[];
  dropdownTurmaProfanoOptions: TurmaProfano[];
  // eslint-disable-next-line no-unused-vars
  onSituacaoChange: (situacao: SituacaoProfano) => void;
  // eslint-disable-next-line no-unused-vars
  onTurmaChange: (turma: TurmaProfano) => void;
}

const InformacoesProfano: React.FC<Props> = ({
  loading,
  informacoes,
  dropdownSituacaoProfanoOptions,
  dropdownTurmaProfanoOptions,
  onSituacaoChange,
  onTurmaChange,
}) => {
  // eslint-disable-next-line
  const situacaoInicial = useMemo(() => informacoes?.situacao, []);
  const PATH_FICHA_PROFANO = "/pdf/fichaIniciacao/";

  const openUrlNewTab = useCallback((url: string) => {
    const opened = window.open(url, "_blank", "noopener,noreferrer");
    if (opened) {
      opened.opener = null;
    }
  }, []);

  const disabledOptionsOnSituacaoAprovado = useCallback(
    (option: SituacaoProfanoElement) => {
      if (
        situacaoInicial === situacoesProfanoMap.APROVADO.situacao ||
        situacaoInicial === situacoesProfanoMap.PAGO.situacao
      )
        return (
          option === situacoesProfanoMap.AGUARDANDO ||
          option === situacoesProfanoMap.PRONTO_PARA_LEITURA ||
          option === situacoesProfanoMap.LIDO ||
          option === situacoesProfanoMap.SINDICANCIA ||
          option === situacoesProfanoMap.ESCRUTINIO ||
          option === situacoesProfanoMap.APROVADO ||
          option === situacoesProfanoMap.REJEITADO
        );
      return false;
    },
    [situacaoInicial]
  );

  return (
    <SimpleEntityPage showTopBar={false} loading={loading} hidePagePadding>
      <div className="profano-informacoes">
        <div className="profano-informacoes-left p-grid p-col-7">
          <div className="profano-informacoes-button">
            <Button
              icon="fa-solid fa-file-pdf"
              label="Ficha de iniciação"
              onClick={() => openUrlNewTab(`${PATH_FICHA_PROFANO}${informacoes.uuid}?token=false`)}
            />
          </div>
          <div className="profano-informacoes-form p-fluid p-col-12">
            <CustomFormField
              icon="fa-solid fa-users"
              htmlForDescription="turma"
              description="Turma"
              inputBody={
                <Dropdown
                  placeholder="Selecione"
                  options={dropdownTurmaProfanoOptions}
                  value={informacoes.turma}
                  optionLabel="nome"
                  itemTemplate={(turma) => (
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <div>{turma.nome}</div>
                      <div>{convertDateToStringBR(turma.dtIniciacao)}</div>
                    </div>
                  )}
                  onChange={(e) => {
                    onTurmaChange(e.target.value);
                  }}
                />
              }
            />
            <CustomFormField
              icon="fa-solid fa-project-diagram"
              htmlForDescription="situacao"
              description="Situação"
              inputBody={
                <Dropdown
                  placeholder="Selecione"
                  options={dropdownSituacaoProfanoOptions}
                  value={informacoes?.situacao}
                  optionValue="situacao"
                  optionDisabled={(option) => disabledOptionsOnSituacaoAprovado(option)}
                  disabled={situacaoInicial === "INICIADO"}
                  onChange={(e) => {
                    onSituacaoChange(e.target.value);
                  }}
                  scrollHeight="500px"
                />
              }
            />
          </div>
        </div>
        <div className="profano-informacoes-right p-grid p-col-5">
          <PreviewSelectAvatar
            className="profano-informacoes-image"
            width="200px"
            height="200px"
            src={informacoes?.foto}
            editing={false}
          />
        </div>
      </div>
    </SimpleEntityPage>
  );
};

export default InformacoesProfano;
