/* eslint-disable no-unused-vars */
import { Tag } from "primereact/tag";
import React from "react";

type ProfanoSituacaoComponentProps = {
  situacao?: SituacaoProfano;
};
export type SituacaoProfano =
  | "ANEXOS"
  | "AGUARDANDO"
  | "PRONTO_PARA_LEITURA"
  | "LIDO"
  | "SINDICANCIA"
  | "INICIADO"
  | "ESCRUTINIO"
  | "DESISTENCIA"
  | "APROVADO"
  | "PAGO"
  | "REJEITADO";

export interface SituacaoProfanoElement {
  label: string;
  class: string;
  situacao: SituacaoProfano;
}
type SituacaoProfanoMap = {
  [name in SituacaoProfano]: SituacaoProfanoElement;
};

export const situacoesProfanoMap: SituacaoProfanoMap = {
  AGUARDANDO: { label: "Aguardando", class: "situacao-profano-aguardando", situacao: "AGUARDANDO" },
  PRONTO_PARA_LEITURA: {
    label: "Pronto para leitura",
    class: "situacao-profano-aguardando",
    situacao: "PRONTO_PARA_LEITURA",
  },
  LIDO: { label: "Lido", class: "situacao-profano-lido", situacao: "LIDO" },
  SINDICANCIA: { label: "Sindicância", class: "situacao-profano-sindicancia", situacao: "SINDICANCIA" },
  INICIADO: { label: "Iniciado", class: "situacao-profano-iniciado", situacao: "INICIADO" },
  ESCRUTINIO: {
    label: "Escrutínio",
    class: "situacao-profano-escrutinio",
    situacao: "ESCRUTINIO",
  },
  DESISTENCIA: { label: "Desistência", class: "situacao-profano-desistencia", situacao: "DESISTENCIA" },
  APROVADO: {
    label: "Aprovado",
    class: "situacao-profano-aprovado",
    situacao: "APROVADO",
  },
  REJEITADO: {
    label: "Rejeitado",
    class: "situacao-profano-rejeitado",
    situacao: "REJEITADO",
  },
  PAGO: {
    label: "Jóia Paga",
    class: "situacao-profano-pago",
    situacao: "PAGO",
  },
  ANEXOS: {
    label: "Anexos",
    class: "situacao-profano-anexo",
    situacao: "ANEXOS",
  },
};

const ProfanoSituacaoComponent: React.FC<ProfanoSituacaoComponentProps> = ({ situacao }) => {
  return (
    <>
      {situacao && situacoesProfanoMap[situacao] && (
        <Tag
          className={`${situacoesProfanoMap[situacao].class}`}
          value={situacoesProfanoMap[situacao].label}
          style={{ width: "124px" }}
        />
      )}
    </>
  );
};

export default ProfanoSituacaoComponent;
