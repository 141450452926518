import React from "react";

interface Props {
  valueMessage: string;
}

const ObservacaoRevisaoDocumentos: React.FC<Props> = ({ valueMessage }) => {
  return (
    <div className="observacao-revisao-container">
      <div className="obvervacao-revisao-left-content">
        <i className="fas fa-info-circle" />
      </div>
      <div className="observacao-revisao-right-content">
        <div className="observacao-revisao-title-text">Observação do revisor:</div>
        <div className="observacao-revisao-value-text">&quot;{valueMessage}&quot;</div>
      </div>
    </div>
  );
};

export default ObservacaoRevisaoDocumentos;
