/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";

import background from "../../assets/img/signin-background.jpg";
import CardDashboard from "../../components/CardDashboard";
import api from "../../services/api";
import CardDashboardFaturamento from "../../components/CardDashboardFaturamento";
import { useToast } from "../../hooks/toast";
import { useAuth } from "../../hooks/auth";

export type CardProps<T> = { campo: T; total: number; valorTotal?: number; porcentagem?: string };
export type FaturamentoProps = {
  cobrancasPrevistas: number | null;
  previsto: number | null;
  cobrancasPagas: number | null;
  recebido: number | null;
};

export type IrmaoDashboardEnum = "REGULAR" | "IRREGULAR" | "REMIDO" | "DESLIGADO" | "ADORMECIDO";
export type CobrancaDashboardEnum = "PENDENTE" | "VENCIDO" | "PAGO" | "APROVADO";

interface DashboardResponse {
  irmaos: CardProps<IrmaoDashboardEnum>[];
  cobrancas: CardProps<CobrancaDashboardEnum>[];
  faturamentos: FaturamentoProps;
}

interface LojaInfosResponse {
  nomeLoja: string;
  numeroLoja: number;
  tituloLoja: string;
  tituloSiglaLoja: string;
  logoLoja: string;
}

export type IrmaoDashboardType = { [name in IrmaoDashboardEnum]: CardProps<IrmaoDashboardEnum> };
export type CobrancaDashboardType = { [name in CobrancaDashboardEnum]: CardProps<CobrancaDashboardEnum> };

const DashboardWithOutIrmao: React.FC = () => {
  const { potencia } = useAuth();

  return (
    <>
      <div className="main-dashboard">
        <div className="dashboard-container">
          <img src={background} alt="background dashboard" className="background-image" />

          <div className="p-mb-5 dashboard-header-infos">
            {!_.isEmpty(potencia) && potencia && (
              <img
                src={potencia.logo}
                alt={`Logo da potencia ${potencia.nome}`}
                style={{ width: "176px", marginRight: "16px" }}
              />
            )}
            <div className="dashboard-header-text">
              {!_.isEmpty(potencia) && potencia && potencia.nome}
              <br />
              <br />
            </div>
          </div>

          <div className="dashborad-bottom">
            <div>
              Desenvolvido pelo Instituto de Inovação, Pesquisa e Desenvolvimento Científico e Tecnológico do Amazonas
            </div>
            <div>Av. Eduardo Ribeiro, 520, Edifício Manaus Shopping Center, sala 1512, Manaus/AM</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardWithOutIrmao;
