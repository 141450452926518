import { Button } from "primereact/button";
import React from "react";

interface Props {
  command: () => void;
  label: string;
  icon: string;
}

const SimpleButton: React.FC<Props> = ({ command, label, icon }) => {
  return (
    <div className="p-mb-1 p-pl-1 simple-button">
      <Button className="p-d-flex p-jc-start p-button-text" onClick={() => command()}>
        <i className={`p-mr-3 ${icon}`} />
        <div>{label}</div>
      </Button>
    </div>
  );
};

export default SimpleButton;
