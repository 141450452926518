/* eslint-disable no-param-reassign */
/* eslint-disable no-useless-escape */
/* eslint-disable prettier/prettier */
import Decimal from "decimal.js";


// export const pattern = /^(\d{0,25}|-\d{0,25})(\,\d{0,25})?((e|E)(\+?|\-)\d{0,2})?$/; //aceita base decimal também
export const pattern = /^(\d{0,25}|-\d{0,25})(\,\d{0,25})?$/;

const dez = new Decimal("10");

// replace all dots (.) for commas (,)
export function replaceDotToComma(value: number | string): string {
  return value.toString().replace(".", ",");
}

// replace all commas (,) for dots (.)
export function replaceCommaToDot(value: number | string): string {
  return value.toString().replace(",", ".");
}

// generate a decimal string and replace . to ,
export function convertDecimalToString(number: Decimal, decimalPlaces?: number): string {
  const numberString = decimalPlaces ? number.toDecimalPlaces(decimalPlaces).toString() : number.toString();
  return replaceDotToComma(numberString);
}

// generate a decimal from a string and replace , to .
export function convertStringToDecimal(number: string): Decimal {
  try {
    return new Decimal(replaceCommaToDot(number));
  } catch {
    return new Decimal(NaN);
  }
}

export function checkNumberPattern(number: string): boolean {
  number = replaceDotToComma(number);
  return pattern.test(number);
}

export function formatStringNumber(number: string | number, decimalPlaces?: number): string {
  const decimalNumber = convertStringToDecimal(number.toString());
  return convertDecimalToString(decimalNumber, decimalPlaces);

}

export function getDecimalWithMultiplier(number:Decimal, multiplier: Decimal): Decimal {
  return number.times(dez.pow(multiplier));
}

export function decimalToNewMultiplierBase(number:Decimal, multiplier: Decimal=new Decimal("1")): Decimal {
  return number.dividedBy(dez.pow(multiplier));
}



export function numberToReal(valor: number): string {
  return `${new Intl.NumberFormat("pt-br", { style: "currency", currency: "BRL" }).format(valor)}`;
};