/* eslint-disable no-unused-vars */
import { Button } from "primereact/button";
import { ColumnProps } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { v4 } from "uuid";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { Calendar } from "primereact/calendar";
import _ from "lodash";
import SimpleEntityDatatable from "../../SimpleEntityDatatable";
import SimpleEntityListPage from "../SimpleEntityPage";
import { idiomasMap, SiglaIdioma } from "../../../pages/DELETAR/conviteGlomam/idiomas";

export interface Dependente {
  id: string;
  nome: string;
  descricao: string;
  numeroVoo: string;
  companhiaAerea: string;
  dtChegada?: Date | undefined;
}

interface ProfanoDependentesProps {
  dependentes?: Dependente[];
  selectedLanguage: SiglaIdioma;
  onAdd: (dependente: Dependente) => void;
  onRemove: (dependenteId: string) => void;
  onUpdate: (dependenteId: string, dependente: Dependente) => void;
}

const ProfanoDependentes: React.FC<ProfanoDependentesProps> = ({
  dependentes = [],
  onAdd,
  onRemove,
  onUpdate,
  selectedLanguage,
}) => {
  const dt = useRef<DataTable>(null);

  const handleAddDependente = useCallback((dependente: Dependente) => onAdd(dependente), [onAdd]);

  const handleRemoveDependente = useCallback((dependenteId: string) => onRemove(dependenteId), [onRemove]);

  const handleUpdateDependente = useCallback(
    (dependenteId: string, dependente: Dependente) => onUpdate(dependenteId, dependente),
    [onUpdate]
  );

  const nomeBodyTemplate = useCallback(
    (rowData: Dependente) => {
      const index = dependentes.findIndex((dependente) => dependente.id === rowData.id);
      return (
        <div key={rowData.id} className="p-d-flex p-flex-column inputs-convite-glomam-dependentes p-my-0">
          <input
            type="text"
            key={rowData.id}
            placeholder={`${idiomasMap[selectedLanguage].nomeCompleto}`}
            value={rowData.nome}
            className="p-fluid p-inputtext p-component"
            onChange={(e) => {
              const dependente = { ...rowData };
              dependente.nome = e.target.value;
              handleUpdateDependente(rowData.id, dependente);
            }}
          />
        </div>
      );
    },
    [dependentes, handleUpdateDependente, selectedLanguage]
  );

  const descricaoBodyTemplate = useCallback(
    (rowData: Dependente) => {
      return (
        <div key={rowData.id} className="p-d-flex p-flex-column inputs-convite-glomam-dependentes p-my-0">
          <input
            type="text"
            className="p-fluid p-inputtext p-component"
            key={rowData.id}
            placeholder={`${idiomasMap[selectedLanguage].placeholderDescricao}`}
            value={rowData.descricao}
            onChange={(e) => {
              const dependente = { ...rowData };
              dependente.descricao = e.target.value;
              handleUpdateDependente(rowData.id, dependente);
            }}
          />
        </div>
      );
    },
    [handleUpdateDependente, selectedLanguage]
  );

  const deleteButtonBodyTemplate = useCallback(
    (rowData: Dependente) => {
      return (
        <>
          <Button
            style={{ margin: 0 }}
            type="button"
            className="p-ml-3 balandrau-exclude-button"
            icon="pi pi-trash"
            onClick={() => {
              handleRemoveDependente(rowData.id);
            }}
          />
        </>
      );
    },
    [handleRemoveDependente]
  );

  const dateBodyTemplate = useCallback(
    (rowData: Dependente) => {
      return (
        <div key={rowData.id} className="p-d-flex p-flex-column inputs-convite-glomam-dependentes p-my-0">
          <Calendar
            id="date"
            showTime
            value={rowData.dtChegada}
            locale="pt-br"
            placeholder="07/06/2022 14:00"
            onChange={(e) => {
              if (!_.isArray(e.target.value)) {
                const dependente = { ...rowData };
                dependente.dtChegada = e.target.value != null ? e.target.value : undefined;
                handleUpdateDependente(rowData.id, dependente);
                // setDate(e.value);
              }
            }}
          />
        </div>
      );
    },
    [handleUpdateDependente]
  );

  const companhiaBodyTemplate = useCallback(
    (rowData: Dependente) => {
      return (
        <div key={rowData.id} className="p-d-flex p-flex-column inputs-convite-glomam-dependentes p-my-0">
          <input
            id="companhia"
            type="text"
            placeholder={`${idiomasMap[selectedLanguage].exemploCompanhia}`}
            className="p-fluid p-inputtext p-component"
            value={rowData.companhiaAerea}
            onChange={(e) => {
              const dependente = { ...rowData };
              dependente.companhiaAerea = e.target.value;
              handleUpdateDependente(rowData.id, dependente);
            }}
          />
        </div>
      );
    },
    [handleUpdateDependente, selectedLanguage]
  );

  const numeroVooBodyTemplate = useCallback(
    (rowData: Dependente) => {
      return (
        <div key={rowData.id} className="p-d-flex p-flex-column inputs-convite-glomam-dependentes p-my-0">
          <input
            id="voo"
            type="text"
            placeholder={`${idiomasMap[selectedLanguage].exemploVoo}`}
            className="p-fluid p-inputtext p-component"
            value={rowData.numeroVoo}
            onChange={(e) => {
              const dependente = { ...rowData };
              dependente.numeroVoo = e.target.value;
              handleUpdateDependente(rowData.id, dependente);
            }}
          />
        </div>
      );
    },
    [handleUpdateDependente, selectedLanguage]
  );

  const columnsDependentes = useMemo(
    (): ColumnProps[] => [
      {
        field: "nome",
        header: `${idiomasMap[selectedLanguage].nomeCompleto}`,
        body: nomeBodyTemplate,
        bodyClassName: "p-fluid",
        headerStyle: { width: "25%" },
      },
      {
        field: "descricao",
        header: `${idiomasMap[selectedLanguage].descricao}`,
        body: descricaoBodyTemplate,
        bodyClassName: "p-fluid",
        headerStyle: { width: "25%" },
      },
      {
        field: "companhiaAerea",
        header: `${idiomasMap[selectedLanguage].companhia}`,
        body: companhiaBodyTemplate,
        bodyClassName: "p-fluid",
        headerStyle: { width: "10%" },
      },
      {
        field: "numeroVoo",
        header: `${idiomasMap[selectedLanguage].voo}`,
        body: numeroVooBodyTemplate,
        bodyClassName: "p-fluid",
        headerStyle: { width: "10%" },
      },
      {
        field: "dtChegada",
        header: `${idiomasMap[selectedLanguage].date}`,
        body: dateBodyTemplate,
        bodyClassName: "p-fluid",
        headerStyle: { width: "170px" },
      },
      {
        body: deleteButtonBodyTemplate,
        align: "center",
        style: { width: "100px", verticalAlign: "middle" },
      },
    ],
    [
      nomeBodyTemplate,
      descricaoBodyTemplate,
      deleteButtonBodyTemplate,
      selectedLanguage,
      companhiaBodyTemplate,
      dateBodyTemplate,
      numeroVooBodyTemplate,
    ]
  );

  return (
    <SimpleEntityListPage
      showListHeader
      newButtonLabel={`${idiomasMap[selectedLanguage].addMembro}`}
      onNewButtonCLick={() => {
        handleAddDependente({
          id: v4(),
          descricao: "",
          nome: "",
          companhiaAerea: "",
          dtChegada: undefined,
          numeroVoo: "",
        });
      }}
      datatableRef={dt}
    >
      <SimpleEntityDatatable
        datatableRef={dt}
        value={dependentes}
        columns={columnsDependentes}
        paginator={false}
        emptyMessage="Nenhuma comitiva"
        rows={undefined}
      />
    </SimpleEntityListPage>
  );
};

export default ProfanoDependentes;
