import React, { useState, useCallback, useEffect, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import _ from "lodash";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { RadioButton } from "primereact/radiobutton";
import CustomFormField from "../../../components/CustomFormField";
import SimpleEntityPage from "../../../components/SimpleEntityPage";
import api from "../../../services/api";
import { useToast } from "../../../hooks/toast";
import FloatingSave from "../../../components/FloatingSave";

interface Params {
  id: string;
}

interface DataComemorativaResp {
  uuid: string;
  data: string;
  dataFormatada: string;
  descricao: string;
  idade: number;
  recorrente: boolean;
}

const DatasComemorativasFormPage: React.FC = () => {
  const { id } = useParams<Params>();
  const [loadingSalvar, setLoadingSalvar] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dtComemorativa, setDtComemorativa] = useState<Date>();
  const [descricao, setDescricao] = useState<string>("");
  const [recorrente, setRecorrente] = useState<"sim" | "nao">("nao");

  const [daySelected, setDaySelected] = useState<string>();
  const [monthSelected, setMonthSelected] = useState<string>();

  const monthOptions = useMemo(
    () => ["janeiro", "fevereiro", "março", "abril", "maio", "junho", "julho", "agosto", "setembro", "outubro"],
    []
  );

  const [datasComemorativasInitial, setDatasComemorativasInitial] = useState({} as DataComemorativaResp);

  const [errors, setErrors] = useState<{ [name: string]: string }>({});

  const { showToast } = useToast();

  const [isDirty, setIsDirty] = useState(false);

  const history = useHistory();

  const loadData = useCallback(() => {
    if (id) {
      setLoading(true);

      api
        .get<DataComemorativaResp>(`datas-comemorativas/${id}`)
        .then(({ data }) => {
          setDatasComemorativasInitial(data);
          setDtComemorativa(new Date(data.data));
          setDescricao(data.descricao);
        })
        .catch(() => {
          showToast({
            type: "error",
            title: "Não foi possível carregar data comemorativa",
            description: "Tente novamente, ou entre em contato com um administrador",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [showToast, id]);

  const redefinirDataComemorativa = useCallback(() => {
    setIsDirty(false);

    if (id === "novo") {
      setDtComemorativa(new Date());
      setDescricao("");
    } else {
      loadData();
    }
  }, [id, loadData]);

  const validarCampos = useCallback(() => {
    const errorsLocal: { [campo: string]: string } = {};

    if (_.isEmpty(descricao)) {
      errorsLocal.descricao = "Este é um campo obrigatório";
    }

    // if (_.isEmpty(dtComemorativa)) {
    //   errorsLocal.dtComemorativa = "Este é um campo obrigatório";
    // }

    if (recorrente === "nao") {
      if (!_.isDate(dtComemorativa)) {
        errorsLocal.dtComemorativa = "Insira uma data válida";
      }
    } else if (daySelected === undefined && monthSelected === undefined) {
      errorsLocal.dtComemorativa = "Preencha corretamente os campos.";
    }

    setErrors(errorsLocal);
    return _.isEmpty(errorsLocal);
  }, [daySelected, descricao, dtComemorativa, monthSelected, recorrente]);

  const salvarDataComemorativa = useCallback(() => {
    const valid = validarCampos();

    let date: string = dtComemorativa?.toISOString() || "";

    if (recorrente === "sim" && monthSelected && daySelected) {
      [date] = new Date(
        2000,
        monthOptions.findIndex((m) => m === monthSelected),
        parseInt(daySelected || "0", 10)
      )
        .toISOString()
        .split("T");
    }

    if (valid) {
      if (id) {
        setLoadingSalvar(true);
        api
          .put(`datas-comemorativas/${id}`, {
            data: date,
            descricao,
            recorrente: recorrente === "sim",
          })
          .then(() => {
            showToast({ title: "Sucesso!", type: "success", description: "A data comemorativa foi editada e salva." });
            history.push("/datas-comemorativas");
          })
          .catch(() => {
            showToast({
              title: "Não foi possível atualizar a data comemorativa",
              type: "error",
              description: "Tente novamente, ou entre em contato com um administrador",
            });

            // handleError({ error: err, action: "atualizar usuário", knownErrors: datasComemorativasErrors });
          })
          .finally(() => setLoadingSalvar(false));
      } else {
        api
          .post("datas-comemorativas", {
            data: date,
            descricao,
            recorrente: recorrente === "sim",
          })
          .then(() => {
            showToast({ title: "Sucesso!", type: "success", description: "A data comemorativa criada." });
            history.push("/datas-comemorativas");
          })
          .catch(() => {
            showToast({
              title: "Não foi possível criar a data comemorativa",
              type: "error",
              description: "Tente novamente, ou entre em contato com um administrador",
            });
          });
      }
    }
  }, [
    daySelected,
    descricao,
    dtComemorativa,
    history,
    id,
    monthOptions,
    monthSelected,
    recorrente,
    showToast,
    validarCampos,
  ]);

  const yearNavigatorTemplate = (e: any) => {
    return (
      <Dropdown
        value={e.value}
        options={e.options}
        onChange={(event) => e.onChange(event.originalEvent, event.value)}
        className="p-ml-2"
        style={{ lineHeight: 1 }}
      />
    );
  };

  const monthNavigatorTemplate = (e: any) => {
    return (
      <Dropdown
        value={e.value}
        options={e.options}
        onChange={(event) => e.onChange(event.originalEvent, event.value)}
        style={{ lineHeight: 1 }}
      />
    );
  };

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <SimpleEntityPage showTopBar routeBack="/datas-comemorativas" isFormPage loading={loading}>
      <div className="p-col-7 p-grid">
        <div className="p-fluid p-col-12">
          <CustomFormField
            htmlForDescription="recurrent"
            iconMaterial="sync"
            labelWidth="3"
            description="É um evento recorrente ?"
            inputBody={
              <div className="p-d-flex p-ai-center" style={{ paddingTop: 2, paddingBottom: 2 }} id="recurrent">
                <RadioButton
                  value="sim"
                  inputId="recurrentSim"
                  onChange={(e) => {
                    setRecorrente(e.value);

                    const errorsLocal = _.cloneDeep(errors);
                    delete errorsLocal.dtComemorativa;
                    setErrors(errorsLocal);

                    if (!_.isEqual(datasComemorativasInitial.recorrente, e.target.value === "sim")) {
                      setIsDirty(true);
                    } else {
                      setIsDirty(false);
                    }
                  }}
                  checked={recorrente === "sim"}
                />
                <label htmlFor="recurrentSim" className="p-ml-2">
                  Sim
                </label>
                <RadioButton
                  value="nao"
                  inputId="recurrentNão"
                  onChange={(e) => {
                    setRecorrente(e.value);

                    const errorsLocal = _.cloneDeep(errors);
                    delete errorsLocal.dtComemorativa;
                    setErrors(errorsLocal);

                    if (!_.isEqual(datasComemorativasInitial.recorrente, e.target.value === "sim")) {
                      setIsDirty(true);
                    } else {
                      setIsDirty(false);
                    }
                  }}
                  checked={recorrente === "nao"}
                  className="p-ml-4"
                />
                <label htmlFor="recurrentNão" className="p-ml-2">
                  Não
                </label>
              </div>
            }
            errorMessage={errors.recorrente}
          />

          <CustomFormField
            htmlForDescription="dtEvento"
            iconMaterial="calendar_month"
            labelWidth="3"
            description="Data do evento"
            inputBody={
              <>
                {recorrente === "nao" ? (
                  <Calendar
                    id="dtEvento"
                    placeholder="Selecione ou digite"
                    value={dtComemorativa}
                    onChange={(e) => {
                      const data = e.target.value;
                      if (!_.isArray(data) && !!data) {
                        const errorsLocal = _.cloneDeep(errors);
                        delete errorsLocal.dtComemorativa;
                        setErrors(errorsLocal);

                        setDtComemorativa(data);

                        if (!_.isEqual(datasComemorativasInitial.data, e.target.value)) {
                          setIsDirty(true);
                        } else {
                          setIsDirty(false);
                        }
                      }
                    }}
                    monthNavigator
                    yearNavigator
                    yearRange="1000:3000"
                    monthNavigatorTemplate={monthNavigatorTemplate}
                    yearNavigatorTemplate={yearNavigatorTemplate}
                    locale="pt-br"
                    showIcon
                    maxDate={new Date(Date.now())}
                    mask="99/99/9999"
                  />
                ) : (
                  <div className="p-d-flex">
                    <Dropdown
                      options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
                      value={daySelected}
                      onChange={(e) => {
                        setDaySelected(e.target.value);
                        setIsDirty(true);

                        const errorsLocal = _.cloneDeep(errors);
                        delete errorsLocal.dtComemorativa;
                        setErrors(errorsLocal);
                      }}
                      className="p-mr-2"
                    />
                    <Dropdown
                      options={monthOptions}
                      value={monthSelected}
                      onChange={(e) => {
                        setMonthSelected(e.target.value);

                        const errorsLocal = _.cloneDeep(errors);
                        delete errorsLocal.dtComemorativa;
                        setErrors(errorsLocal);
                      }}
                    />
                  </div>
                )}
              </>
            }
            errorMessage={errors.dtComemorativa}
          />

          <CustomFormField
            htmlForDescription="descricao"
            description="Descrição"
            iconMaterial="description"
            labelWidth="3"
            inputBody={
              <InputText
                id="descricao"
                value={descricao}
                placeholder="Digite a descrição do evento"
                onChange={(e) => {
                  setDescricao(e.target.value);

                  const errorsLocal = _.cloneDeep(errors);
                  delete errorsLocal.descricao;
                  setErrors(errorsLocal);

                  if (!_.isEqual(datasComemorativasInitial.descricao, e.target.value)) {
                    setIsDirty(true);
                  } else {
                    setIsDirty(false);
                  }
                }}
              />
            }
            errorMessage={errors.descricao}
          />
        </div>
      </div>
      <div>
        {isDirty ? (
          <FloatingSave
            resetCommand={() => redefinirDataComemorativa()}
            disabled={false}
            saveCommand={() => salvarDataComemorativa()}
            loadingOnSave={loadingSalvar}
          />
        ) : null}
      </div>
    </SimpleEntityPage>
  );
};

export default DatasComemorativasFormPage;
