import React, { useCallback, useEffect, useState } from "react";
import _ from "lodash";
import { Dropdown } from "primereact/dropdown";
import { useHistory } from "react-router-dom";
import { Irmao } from "../../../Irmao/IrmaoPage";
import { useToast } from "../../../../hooks/toast";
import { useError } from "../../../../hooks/error";
import api from "../../../../services/api";
import CustomFormField from "../../../../components/CustomFormField";
import FloatingSave from "../../../../components/FloatingSave";
import SimpleEntityPage from "../../../../components/SimpleEntityPage";
import { irmaoErrors } from "../../../../errors/irmao";

export interface Padrinho {
  uuid: string;
  nome: string;
  id: string;
}

export interface ProfanoForm {
  padrinho: Padrinho;
}

const ProfanoFormPage: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [loadingSalvar, setLoadingSalvar] = useState(false);

  const [profanoInicial, setProfanoInicial] = useState<ProfanoForm>({} as ProfanoForm);

  const [padrinho, setPadrinho] = useState<Padrinho>();
  const [irmaos, setIrmaos] = useState<Irmao[]>();

  const { showToast } = useToast();
  const { handleError } = useError();

  const [errors, setErrors] = useState<{ [campo: string]: string }>({});

  const history = useHistory();

  const salvarForm = useCallback(() => {
    if (padrinho) {
      setLoadingSalvar(true);
      api
        .post("formularios/profano", {
          padrinhoUuid: padrinho.uuid,
        })
        .then(() => {
          showToast({ title: "Sucesso!", type: "success", description: "Um novo formulário de iniciação foi criado." });
          history.push("/iniciacao/convite");
        })
        .catch((err: any) => {
          handleError({ error: err, action: "criar formulário de iniciação", knownErrors: irmaoErrors });
        })
        .finally(() => setLoadingSalvar(false));
    }
  }, [handleError, history, padrinho, showToast]);

  const loadIrmaos = useCallback(() => {
    setLoading(true);
    api
      .get<Irmao[]>(`dropdown/padrinhos`)
      .then(({ data }) => {
        setIrmaos(data);
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    setProfanoInicial({} as ProfanoForm);
    loadIrmaos();
  }, [loadIrmaos]);

  return (
    <SimpleEntityPage showTopBar routeBack="/iniciacao/convite" isFormPage loading={loading}>
      <div className="p-grid p-flex-row p-jc-between">
        <div className="p-col-7 p-grid">
          <div className="p-fluid p-col-12">
            <CustomFormField
              icon="pi pi-user"
              htmlForDescription="padrinho"
              description="Padrinho"
              info={{
                tooltip:
                  "Para ser padrinho o irmão precisa ter grau Mestre e estar ativo na loja (situação regular, irregular ou remido).",
                tooltipStyle: { maxWidth: "320px" },
              }}
              inputBody={
                <Dropdown
                  placeholder="Selecione"
                  showClear
                  dataKey="uuid"
                  optionLabel="nome"
                  options={irmaos}
                  value={padrinho}
                  onChange={(e) => {
                    setPadrinho(e.target.value);
                    const errorsLocal = _.cloneDeep(errors);
                    delete errorsLocal.irmao;
                    setErrors(errorsLocal);
                    if (!_.isEqual(profanoInicial.padrinho, e.target.value)) {
                      setIsDirty(true);
                    } else {
                      setIsDirty(false);
                    }
                  }}
                  emptyFilterMessage="Nenhum irmão encontrado"
                  filter
                  filterBy="nome"
                />
              }
              required
              errorMessage={errors.grau}
            />
          </div>
        </div>
      </div>

      {isDirty && (
        <div>
          <FloatingSave
            resetCommand={() => setPadrinho(profanoInicial.padrinho)}
            disabled={!padrinho}
            saveCommand={() => salvarForm()}
            loadingOnSave={loadingSalvar}
          />
        </div>
      )}
    </SimpleEntityPage>
  );
};

export default ProfanoFormPage;
