import React, { createContext, useCallback, useContext, useState } from "react";
import jwtDecode from "jwt-decode";
import _ from "lodash";
import api from "../services/api";
import { Grau } from "../pages/Irmao/IrmaoPage";

interface PotenciaLogin {
  id: number;
  uuid: string;
  sigla: string;
  nome: string;
  logo: string;
}

interface ResponseLogin {
  lojaId?: number;
  lojaUuid?: string;
  lojaNome?: string;
  lojaFoto?: string;
  irmaoId?: number;
  irmaoUuid: string;
  irmaoNome: string;
  irmaoEmail: string;
  irmaoFoto?: string;
  irmaoGrau?: Grau;
  potencia?: PotenciaLogin;
}

interface IrmaoLogin {
  id: number;
  uuid: string;
  nome: string;
  foto: string;
  grau: Grau;
}

interface UsuarioNotIrmao {
  uuid: string;
  nome: string;
  email: string;
}

interface LojaLogin {
  id: number;
  uuid: string;
  nome: string;
  foto: string;
}

interface User {
  id: string;
  nome: string;
  email: string;
}

interface AuthState {
  token: string;
  user: User;
  irmao: IrmaoLogin | null;
  loja: LojaLogin | null;
  usuario: UsuarioNotIrmao | null;
  potencia: PotenciaLogin | null;
  showValueInDashboard: boolean | null;
}

interface SingInCredentials {
  email: string;
  password: string;
}

interface AuthContextDate {
  user: User;
  irmao: IrmaoLogin | null;
  loja: LojaLogin | null;
  usuario: UsuarioNotIrmao | null;
  potencia: PotenciaLogin | null;
  showValueInDashboard: boolean | null;
  // eslint-disable-next-line no-unused-vars
  signIn(credentials: SingInCredentials): Promise<void>;
  signOut(): void;
  lastLoginCredential?: string;
}

interface TokenProps {
  auth: {
    usuario: User;
  };
}

const AuthContext = createContext<AuthContextDate>({} as AuthContextDate);

export const AuthProvider: React.FC = ({ children }) => {
  const [lastLoginCredential, setLastLoginCredential] = useState(() => {
    const lastCredential = localStorage.getItem("@Balandrau: lastLoginCredential");
    if (lastCredential) return lastCredential;
    return undefined;
  });

  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem("@Balandrau: token");
    const user = localStorage.getItem("@Balandrau: user");
    const irmao = localStorage.getItem("@Balandrau: irmao");
    const loja = localStorage.getItem("@Balandrau: loja");
    const potencia = localStorage.getItem("@Balandrau: potencia");
    const usuario = localStorage.getItem("@Balandrau: usuario");
    const showValueInDashboard = localStorage.getItem("@Balandrau: showValueInDashboard");

    if (token && user) {
      api.defaults.headers.authorization = `Bearer ${token}`;

      return {
        token,
        user: JSON.parse(user),
        irmao: JSON.parse(irmao || "{}"),
        loja: JSON.parse(loja || "{}"),
        potencia: JSON.parse(potencia || "{}"),
        usuario: JSON.parse(usuario || "{}"),
        showValueInDashboard: JSON.parse(showValueInDashboard || "false"),
      };
    }

    return {} as AuthState;
  });

  const updateLastLoginCredential = useCallback((credential) => {
    localStorage.setItem("@Balandrau: lastLoginCredential", credential);
    setLastLoginCredential(credential);
  }, []);

  const signIn = useCallback(
    async ({ email, password }) => {
      const response = await api.post<ResponseLogin>("autenticacao/login", {
        email,
        senha: password,
      });

      let irmao = {} as IrmaoLogin;
      let usuario = {} as UsuarioNotIrmao;
      let loja = {} as LojaLogin;
      let potencia = {} as PotenciaLogin;
      let showValueInDashboard = false;

      updateLastLoginCredential(email);

      const dataSignIn = response.data;

      if (dataSignIn.irmaoId) {
        if (
          dataSignIn.irmaoNome &&
          dataSignIn.irmaoUuid &&
          dataSignIn.irmaoGrau &&
          dataSignIn.lojaId &&
          dataSignIn.lojaNome &&
          dataSignIn.lojaUuid
        ) {
          irmao = {
            id: dataSignIn.irmaoId,
            nome: dataSignIn.irmaoNome,
            uuid: dataSignIn.irmaoUuid,
            foto: dataSignIn.irmaoFoto || "",
            grau: dataSignIn.irmaoGrau,
          };
          loja = {
            id: dataSignIn.lojaId,
            nome: dataSignIn.lojaNome,
            uuid: dataSignIn.lojaUuid,
            foto: dataSignIn.lojaFoto || "",
          };
          if (dataSignIn.lojaId === 2) showValueInDashboard = true;
          else showValueInDashboard = false;
        }
      } else {
        usuario = {
          uuid: dataSignIn.irmaoUuid,
          nome: dataSignIn.irmaoNome,
          email: dataSignIn.irmaoEmail,
        };
        showValueInDashboard = false;
      }

      if (dataSignIn.potencia) {
        potencia = dataSignIn.potencia;
      }

      const token = response.headers.authorization;
      const decoded: TokenProps = jwtDecode(token);

      const user = decoded.auth.usuario;

      if (!_.isEmpty(irmao) && !_.isEmpty(loja)) {
        localStorage.setItem("@Balandrau: irmao", JSON.stringify(irmao));
        localStorage.setItem("@Balandrau: loja", JSON.stringify(loja));
      }

      if (!_.isEmpty(potencia) && !_.isEmpty(usuario)) {
        localStorage.setItem("@Balandrau: potencia", JSON.stringify(potencia));
        localStorage.setItem("@Balandrau: usuario", JSON.stringify(usuario));
      }

      localStorage.setItem("@Balandrau: token", token);
      localStorage.setItem("@Balandrau: user", JSON.stringify(user));
      localStorage.setItem("@Balandrau: showValueInDashboard", JSON.stringify(showValueInDashboard));

      api.defaults.headers.authorization = `Bearer ${token}`;

      setData({ token, user, irmao, loja, potencia, usuario, showValueInDashboard });
    },
    [updateLastLoginCredential]
  );

  const signOut = useCallback(() => {
    localStorage.removeItem("@Balandrau: token");
    localStorage.removeItem("@Balandrau: user");
    localStorage.removeItem("@Balandrau: irmao");
    localStorage.removeItem("@Balandrau: loja");
    localStorage.removeItem("@Balandrau: potencia");
    localStorage.removeItem("@Balandrau: usuario");
    localStorage.removeItem("@Balandrau: showValueInDashboard");

    setData({} as AuthState);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        irmao: data.irmao,
        loja: data.loja,
        potencia: data.potencia,
        usuario: data.usuario,
        showValueInDashboard: data.showValueInDashboard,
        signIn,
        signOut,
        lastLoginCredential,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextDate {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  return context;
}
