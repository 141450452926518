import { Avatar } from "primereact/avatar";
import { Tooltip } from "primereact/tooltip";
import React from "react";

export interface InfoComponentProps {
  id?: string;
  tooltip?: string;
  tooltipStyle?: any;
  tooltipPosition?: "top" | "bottom" | "right" | "left";
  icon?: string;
  className?: string;
}

const InfoComponent: React.FC<InfoComponentProps> = ({
  id = "default",
  tooltip,
  tooltipPosition = "top",
  tooltipStyle,
  icon = "pi pi-info-circle",
  className = "",
}) => {
  return tooltip ? (
    <div className={`info-component-container ${className}`}>
      <Avatar className={`info-component-avatar info-component-id-${id}`} icon={icon} shape="circle" />
      <Tooltip
        target={`.info-component-id-${id}`}
        className="info-component-tooltip"
        style={tooltipStyle}
        content={tooltip}
        position={tooltipPosition}
      />
    </div>
  ) : (
    <></>
  );
};

export default InfoComponent;
