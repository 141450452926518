import { IAppError } from "./AppErrorInterfaces";

export const leitoresErrors: IAppError[] = [
  {
    code: "UUID inválido.",
    message: "Alteração inválida na url.",
  },
  {
    code: "Irmão já cadastrado no banco",
    title: "Irmão já cadastrado",
    message: "Não foi possível cadastrar o irmão",
  },
  {
    code: "Irmão não encontrado.",
    title: "Irmão não encontrado.",
    message: "Selecione outro irmão e tente novamente",
  },
  {
    code: "Leitor não encontrado.",
    title: "Leitor não encontrado.",
    message: "Selecione outro leitor e tente novamente",
  },
];
