/* eslint-disable no-unused-vars */
import React, { createContext, useCallback, useContext, useState } from "react";
import { Dependente } from "../components/ProfanoDependentes";

interface ProfanoFormData {
  // TODO: incluir todos os campos do formulário
  foto?: string;
  nome?: string;
  dtNascimento?: string;
  tipoSanguineo?: string;
  nacionalidade?: string;
  naturalidade?: string;
  naturalidadeUf?: string;
  nacionalidadePais?: string;
  estadoCivil?: string;
  escolaridade?: string;
  nomeMae?: string;
  nomePai?: string;
  cpf?: string;
  identidade?: string;
  identidadeOrgaoExp?: string;
  identidadeUf?: string;
  identidadeDtEmissao?: string;
  email?: string;
  celular?: string;
  telefone?: string;
  cep?: string;
  logradouro?: string;
  numeroLogradouro?: string;
  complemento?: string;
  bairro?: string;
  enderecoCidade?: string;
  enderecoUf?: string;

  // campos da aba de dados profissionais
  empresa?: string;
  telefoneEmpresa?: string;
  ramalEmpresa?: string;
  cargo?: string;
  tempoEmpresa?: string;
  profissao?: string;
  rendaMensal?: number;
  cepEmpresa?: string;
  logradouroEmpresa?: string;
  numeroLogradouroEmpresa?: string;
  complementoEmpresa?: string;
  bairroEmpresa?: string;
  cidadeEmpresa?: string;
  ufEmpresa?: string;

  // campos da aba de outras informações
  tempoCidade?: string;
  religiao?: string;
  principioCriador?: string;
  sobrevivenciaAlma?: string;
  compativelMaconaria?: string;
  conheceObrigacoes?: string;
  esposaConcorda?: string;
  tempoParaFrequentar?: string;
  submeteuAntes?: string;
  lojaOrigem?: string; // campo usado na ficha de filiação
  potenciaOrigem?: string; // campo usado na ficha de filiação

  // campos da aba de dependentes
  dependentes?: Dependente[];
}

interface ProfanoContextData {
  loadProfanoFromStorage: () => ProfanoFormData;
  removeProfanoFromStorage: () => void;
  saveField: (field: string, cpf: string) => void;
  setAToken: (token: string) => void;
}

const ProfanoContext = createContext<ProfanoContextData>({} as ProfanoContextData);

export const ProfanoProvider: React.FC = ({ children }) => {
  const [hasToken, setHasToken] = useState<boolean>(false);
  const [token, setToken] = useState<string | undefined>((): string | undefined => {
    return localStorage.getItem(`@Balandrau:tokenProfano`) || undefined;
  });

  const removeProfanoFromStorage = useCallback(() => {
    // eslint-disable-next-line no-console
    console.log("removing all data from token", token, hasToken);

    // TODO: incluir todos os campos do formulário
    localStorage.removeItem(`@Balandrau:${token}:foto`);
    localStorage.removeItem(`@Balandrau:${token}:cpf`);
    localStorage.removeItem(`@Balandrau:${token}:nome`);
    localStorage.removeItem(`@Balandrau:${token}:dtNascimento`);
    localStorage.removeItem(`@Balandrau:${token}:tipoSanguineo`);
    localStorage.removeItem(`@Balandrau:${token}:nacionalidade`);
    localStorage.removeItem(`@Balandrau:${token}:naturalidade`);
    localStorage.removeItem(`@Balandrau:${token}:naturalidadeUf`);
    localStorage.removeItem(`@Balandrau:${token}:nacionalidadePais`);
    localStorage.removeItem(`@Balandrau:${token}:estadoCivil`);
    localStorage.removeItem(`@Balandrau:${token}:escolaridade`);
    localStorage.removeItem(`@Balandrau:${token}:nomeMae`);
    localStorage.removeItem(`@Balandrau:${token}:nomePai`);
    localStorage.removeItem(`@Balandrau:${token}:cpf`);
    localStorage.removeItem(`@Balandrau:${token}:identidade`);
    localStorage.removeItem(`@Balandrau:${token}:identidadeOrgaoExp`);
    localStorage.removeItem(`@Balandrau:${token}:identidadeUf`);
    localStorage.removeItem(`@Balandrau:${token}:identidadeDtEmissao`);
    localStorage.removeItem(`@Balandrau:${token}:email`);
    localStorage.removeItem(`@Balandrau:${token}:celular`);
    localStorage.removeItem(`@Balandrau:${token}:telefone`);
    localStorage.removeItem(`@Balandrau:${token}:cep`);
    localStorage.removeItem(`@Balandrau:${token}:logradouro`);
    localStorage.removeItem(`@Balandrau:${token}:numeroLogradouro`);
    localStorage.removeItem(`@Balandrau:${token}:complemento`);
    localStorage.removeItem(`@Balandrau:${token}:bairro`);
    localStorage.removeItem(`@Balandrau:${token}:enderecoCidade`);
    localStorage.removeItem(`@Balandrau:${token}:enderecoUf`);

    // campos da aba de dados profissionais
    localStorage.removeItem(`@Balandrau:${token}:empresa`);
    localStorage.removeItem(`@Balandrau:${token}:telefoneEmpresa`);
    localStorage.removeItem(`@Balandrau:${token}:ramalEmpresa`);
    localStorage.removeItem(`@Balandrau:${token}:cargo`);
    localStorage.removeItem(`@Balandrau:${token}:tempoEmpresa`);
    localStorage.removeItem(`@Balandrau:${token}:profissao`);
    localStorage.removeItem(`@Balandrau:${token}:rendaMensal`);
    localStorage.removeItem(`@Balandrau:${token}:cepEmpresa`);
    localStorage.removeItem(`@Balandrau:${token}:logradouroEmpresa`);
    localStorage.removeItem(`@Balandrau:${token}:numeroLogradouroEmpresa`);
    localStorage.removeItem(`@Balandrau:${token}:complementoEmpresa`);
    localStorage.removeItem(`@Balandrau:${token}:bairroEmpresa`);
    localStorage.removeItem(`@Balandrau:${token}:cidadeEmpresa`);
    localStorage.removeItem(`@Balandrau:${token}:ufEmpresa`);

    // campos da aba de outras informações
    localStorage.removeItem(`@Balandrau:${token}:tempoCidade`);
    localStorage.removeItem(`@Balandrau:${token}:religiao`);
    localStorage.removeItem(`@Balandrau:${token}:principioCriador`);
    localStorage.removeItem(`@Balandrau:${token}:sobrevivenciaAlma`);
    localStorage.removeItem(`@Balandrau:${token}:compativelMaconaria`);
    localStorage.removeItem(`@Balandrau:${token}:conheceObrigacoes`);
    localStorage.removeItem(`@Balandrau:${token}:esposaConcorda`);
    localStorage.removeItem(`@Balandrau:${token}:tempoParaFrequentar`);
    localStorage.removeItem(`@Balandrau:${token}:submeteuAntes`);
    localStorage.removeItem(`@Balandrau:${token}:lojaOrigem`);
    localStorage.removeItem(`@Balandrau:${token}:potenciaOrigem`);

    // campos da aba de dependentes
    localStorage.removeItem(`@Balandrau:${token}:dependentes`);
  }, [hasToken, token]);

  const setAToken = useCallback(
    (atoken: string) => {
      // eslint-disable-next-line no-console
      // console.log("setting a token:", token, atoken, hasToken);
      if (token && token !== atoken) {
        removeProfanoFromStorage();
      }

      setToken(atoken);
      localStorage.setItem(`@Balandrau:tokenProfano`, atoken);
      setHasToken(true);
    },
    [removeProfanoFromStorage, token]
  );

  const loadProfanoFromStorage = useCallback((): ProfanoFormData => {
    if (!hasToken) {
      throw new Error("loadProfanoFromStorage must be used after setAToken");
    }
    const profano: ProfanoFormData = {};

    // TODO: incluir todos os campos do formulário
    profano.foto = localStorage.getItem(`@Balandrau:${token}:foto`) || undefined;
    profano.nome = localStorage.getItem(`@Balandrau:${token}:nome`) || undefined;
    profano.dtNascimento = localStorage.getItem(`@Balandrau:${token}:dtNascimento`) || undefined;
    profano.tipoSanguineo = localStorage.getItem(`@Balandrau:${token}:tipoSanguineo`) || undefined;
    profano.nacionalidade = localStorage.getItem(`@Balandrau:${token}:nacionalidade`) || undefined;
    profano.naturalidade = localStorage.getItem(`@Balandrau:${token}:naturalidade`) || undefined;
    profano.naturalidadeUf = localStorage.getItem(`@Balandrau:${token}:naturalidadeUf`) || undefined;
    profano.nacionalidadePais = localStorage.getItem(`@Balandrau:${token}:nacionalidadePais`) || undefined;
    profano.estadoCivil = localStorage.getItem(`@Balandrau:${token}:estadoCivil`) || undefined;
    profano.escolaridade = localStorage.getItem(`@Balandrau:${token}:escolaridade`) || undefined;
    profano.nomeMae = localStorage.getItem(`@Balandrau:${token}:nomeMae`) || undefined;
    profano.nomePai = localStorage.getItem(`@Balandrau:${token}:nomePai`) || undefined;
    profano.cpf = localStorage.getItem(`@Balandrau:${token}:cpf`) || undefined;
    profano.identidade = localStorage.getItem(`@Balandrau:${token}:identidade`) || undefined;
    profano.identidadeOrgaoExp = localStorage.getItem(`@Balandrau:${token}:identidadeOrgaoExp`) || undefined;
    profano.identidadeUf = localStorage.getItem(`@Balandrau:${token}:identidadeUf`) || undefined;
    profano.identidadeDtEmissao = localStorage.getItem(`@Balandrau:${token}:identidadeDtEmissao`) || undefined;
    profano.email = localStorage.getItem(`@Balandrau:${token}:email`) || undefined;
    profano.celular = localStorage.getItem(`@Balandrau:${token}:celular`) || undefined;
    profano.telefone = localStorage.getItem(`@Balandrau:${token}:telefone`) || undefined;
    profano.cep = localStorage.getItem(`@Balandrau:${token}:cep`) || undefined;
    profano.logradouro = localStorage.getItem(`@Balandrau:${token}:logradouro`) || undefined;
    profano.numeroLogradouro = localStorage.getItem(`@Balandrau:${token}:numeroLogradouro`) || undefined;
    profano.complemento = localStorage.getItem(`@Balandrau:${token}:complemento`) || undefined;
    profano.bairro = localStorage.getItem(`@Balandrau:${token}:bairro`) || undefined;
    profano.enderecoCidade = localStorage.getItem(`@Balandrau:${token}:enderecoCidade`) || undefined;
    profano.enderecoUf = localStorage.getItem(`@Balandrau:${token}:enderecoUf`) || undefined;

    // campos da aba de dados profissionais
    profano.empresa = localStorage.getItem(`@Balandrau:${token}:empresa`) || undefined;
    profano.telefoneEmpresa = localStorage.getItem(`@Balandrau:${token}:telefoneEmpresa`) || undefined;
    profano.ramalEmpresa = localStorage.getItem(`@Balandrau:${token}:ramalEmpresa`) || undefined;
    profano.cargo = localStorage.getItem(`@Balandrau:${token}:cargo`) || undefined;
    profano.tempoEmpresa = localStorage.getItem(`@Balandrau:${token}:tempoEmpresa`) || undefined;
    profano.profissao = localStorage.getItem(`@Balandrau:${token}:profissao`) || undefined;
    const renda = localStorage.getItem(`@Balandrau:${token}:rendaMensal`);
    profano.rendaMensal = renda ? Number.parseFloat(renda) : undefined;
    profano.cepEmpresa = localStorage.getItem(`@Balandrau:${token}:cepEmpresa`) || undefined;
    profano.logradouroEmpresa = localStorage.getItem(`@Balandrau:${token}:logradouroEmpresa`) || undefined;
    profano.numeroLogradouroEmpresa = localStorage.getItem(`@Balandrau:${token}:numeroLogradouroEmpresa`) || undefined;
    profano.complementoEmpresa = localStorage.getItem(`@Balandrau:${token}:complementoEmpresa`) || undefined;
    profano.bairroEmpresa = localStorage.getItem(`@Balandrau:${token}:bairroEmpresa`) || undefined;
    profano.cidadeEmpresa = localStorage.getItem(`@Balandrau:${token}:cidadeEmpresa`) || undefined;
    profano.ufEmpresa = localStorage.getItem(`@Balandrau:${token}:ufEmpresa`) || undefined;

    // campos da aba de outras informações
    profano.tempoCidade = localStorage.getItem(`@Balandrau:${token}:tempoCidade`) || undefined;
    profano.religiao = localStorage.getItem(`@Balandrau:${token}:religiao`) || undefined;
    profano.principioCriador = localStorage.getItem(`@Balandrau:${token}:principioCriador`) || undefined;
    profano.sobrevivenciaAlma = localStorage.getItem(`@Balandrau:${token}:sobrevivenciaAlma`) || undefined;
    profano.compativelMaconaria = localStorage.getItem(`@Balandrau:${token}:compativelMaconaria`) || undefined;
    profano.conheceObrigacoes = localStorage.getItem(`@Balandrau:${token}:conheceObrigacoes`) || undefined;
    profano.esposaConcorda = localStorage.getItem(`@Balandrau:${token}:esposaConcorda`) || undefined;
    profano.tempoParaFrequentar = localStorage.getItem(`@Balandrau:${token}:tempoParaFrequentar`) || undefined;
    profano.submeteuAntes = localStorage.getItem(`@Balandrau:${token}:submeteuAntes`) || undefined;
    profano.lojaOrigem = localStorage.getItem(`@Balandrau:${token}:lojaOrigem`) || undefined;
    profano.potenciaOrigem = localStorage.getItem(`@Balandrau:${token}:potenciaOrigem`) || undefined;

    // campos da aba de dependentes
    const dependentesStr = localStorage.getItem(`@Balandrau:${token}:dependentes`);
    if (dependentesStr !== null) {
      const json: { id: string; nome: string; parentesco: string; dtNascimento: string }[] = JSON.parse(dependentesStr);
      profano.dependentes = json.map((dependente) => {
        return {
          id: dependente.id,
          nome: dependente.nome,
          parentesco: dependente.parentesco,
          dtNascimento: new Date(Date.parse(dependente.dtNascimento)),
        } as Dependente;
      });
    }

    return profano;
  }, [hasToken, token]);

  const saveField = useCallback(
    (field: string, value: string) => {
      if (!hasToken) {
        throw new Error("saveField must be used after setAToken");
      }

      localStorage.setItem(`@Balandrau:${token}:${field}`, value);
    },
    [hasToken, token]
  );

  return (
    <ProfanoContext.Provider value={{ loadProfanoFromStorage, removeProfanoFromStorage, saveField, setAToken }}>
      {children}
    </ProfanoContext.Provider>
  );
};

export function useProfano(): ProfanoContextData {
  const context = useContext(ProfanoContext);

  if (!context) {
    throw new Error("useProfano must be used within an ProfanoProvider");
  }

  return context;
}
