import React from "react";

import logo from "../../assets/img/balandrau-logo-nome-horizontal.svg";

interface HeaderFormProfanoProps {
  isFormFiliacao?: boolean;
}

const HeaderFormProfano: React.FC<HeaderFormProfanoProps> = ({ isFormFiliacao = false }) => {
  return (
    <div className="header-form-profano">
      <img className="header-form-profano-logo" alt="logo balandrau" src={logo} />
      <p className="header-form-profano-text">{isFormFiliacao ? "Proposta de Filiação" : "Proposta de Iniciação"}</p>
    </div>
  );
};

export default HeaderFormProfano;
