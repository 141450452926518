import { Button } from "primereact/button";
import { ColumnProps } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import SimpleEntityDatatable from "../../../components/SimpleEntityDatatable";
import SimpleEntityListPage from "../../../components/SimpleEntityPage";
import { useToast } from "../../../hooks/toast";
import api from "../../../services/api";

interface Confirmacao {
  uuid: string;
  conviteGlomam: {
    pais: string;
    potencia: string;
  };
  descricao: string;
  nome: string;
  numeroVoo: string;
  companhiaAerea: string;
  dtChegada: Date;
  dtSaida: Date;
  telefone: string;
  email: string;
}

const ConfirmacaoParticipacaoPage: React.FC = () => {
  const { showToast } = useToast();
  const dt = useRef<DataTable>(null);
  const [confirmacaoParticipacao, setConfirmacaoParticipacao] = useState<Confirmacao[]>([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [loading, setLoading] = useState(false);

  const editDeleteButtonsBodyTemplate = useCallback((data: Confirmacao) => {
    return (
      <>
        <Button
          type="button"
          className="balandrau-action-button"
          icon="pi pi-pencil"
          onClick={() => {
            window.open(`https://maconarianoamazonas.com.br/${data.uuid}`, "_blank");
          }}
        />
      </>
    );
  }, []);

  const columnsConfirmacao = useMemo(
    (): ColumnProps[] => [
      {
        field: "criadoEmFormatted",
        header: "Criado em",
        bodyClassName: "p-fluid",
        headerStyle: { width: "200px" },
        sortable: true,
        sortField: "criadoEm",
      },
      {
        field: "conviteGlomam.potencia",
        header: "Potencia",
        sortable: true,
        style: { textAlign: "left", verticalAlign: "middle", paddingLeft: "20px", paddingRight: "20px" },
        headerStyle: { width: "20%" },
      },
      {
        field: "email",
        header: "E-mail",
        sortable: false,
        style: { textAlign: "left", verticalAlign: "middle", paddingLeft: "20px", paddingRight: "20px" },
        headerStyle: { width: "20%" },
      },
      {
        field: "conviteGlomam.pais",
        header: "País",
        sortable: true,
        style: { textAlign: "left", verticalAlign: "middle", paddingLeft: "20px", paddingRight: "20px" },
        headerStyle: { width: "100px" },
      },
      {
        field: "nome",
        header: "Nome",
        sortable: true,
        style: { textAlign: "left", verticalAlign: "middle", paddingLeft: "20px", paddingRight: "20px" },
        headerStyle: { width: "20%" },
      },
      {
        field: "descricao",
        header: "Descrição",
        sortable: true,
        style: { textAlign: "left", verticalAlign: "middle", paddingLeft: "20px", paddingRight: "20px" },
        headerStyle: { width: "20%" },
      },
      {
        field: "companhiaAerea",
        header: "Companhia Aérea",
        bodyClassName: "p-fluid",
        headerStyle: { width: "10%" },
      },
      {
        field: "numeroVoo",
        header: "Número do voo",
        bodyClassName: "p-fluid",
        headerStyle: { width: "8%" },
      },
      {
        field: "dtChegadaFormatted",
        header: "Chegada em Manaus",
        bodyClassName: "p-fluid",
        headerStyle: { width: "200px" },
        sortable: true,
        sortField: "dtChegada",
      },
      {
        field: "dtSaidaFormatted",
        header: "Saida de Manaus",
        bodyClassName: "p-fluid",
        headerStyle: { width: "200px" },
        sortable: true,
        sortField: "dtSaida",
      },
      {
        field: "telefone",
        header: "Número de contato",
        bodyClassName: "p-fluid",
        headerStyle: { width: "220px" },
      },
      {
        align: "center",
        body: editDeleteButtonsBodyTemplate,
        style: { width: "100px", verticalAlign: "middle" },
      },
    ],
    [editDeleteButtonsBodyTemplate]
  );

  const loadData = useCallback(() => {
    setLoading(true);
    api
      .get<Confirmacao[]>("registrar-resposta-convite-glomam/getAll")
      .then(({ data }) => {
        setConfirmacaoParticipacao(data);
      })
      .catch(() => {
        showToast({ title: "Não foi possível carregar listagem de confirmações", type: "error" });
      })
      .finally(() => setLoading(false));
  }, [showToast]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <SimpleEntityListPage
      onGlobalFilterChange={(value) => setGlobalFilter(value)}
      loading={loading}
      showExportCsvButton
      datatableRef={dt}
      showListHeader
      newButtonLabel="Nova Confirmação"
      onNewButtonCLick={() => {
        window.open("https://maconarianoamazonas.com.br/", "_blank");
      }}
    >
      <SimpleEntityDatatable
        datatableRef={dt}
        value={confirmacaoParticipacao}
        columns={columnsConfirmacao}
        globalFilter={globalFilter}
        sortField="criadoEm"
        sortOrder={-1}
        exportFilename="lista de confirmacoes"
      />
    </SimpleEntityListPage>
  );
};

export default ConfirmacaoParticipacaoPage;
