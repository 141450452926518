import { IAppError } from "./AppErrorInterfaces";

export const irmaoErrors: IAppError[] = [
  {
    code: "UUID inválido.",
    message: "Alteração inválida na url.",
  },
  {
    code: "Irmão não encontrado.",
    title: "Irmão não encontrado.",
    message: "Entre em contato com nosso time de suporte para solicitar ajuda.",
  },
  {
    code: "Loja não encontrada.",
    title: "Loja não encontrada.",
    message: "Entre em contato com nosso time de suporte para solicitar ajuda.",
  },
  {
    code: "Turma não encontrada.",
    title: "Turma não encontrada.",
    message: "Entre em contato com nosso time de suporte para solicitar ajuda.",
  },
  {
    code: "Endereço não encontrado.",
    title: "Endereço não encontrado.",
    message: "Entre em contato com nosso time de suporte para solicitar ajuda.",
  },
  {
    code: "CIM já cadastrado.",
    title: "Não é possível cadastrar o irmão.",
    message: "CIM já está cadastrado, altere o valor e tente novamente.",
  },
  {
    code: "CPF já cadastrado.",
    title: "Não é possível cadastrar o irmão.",
    message: "CPF já está cadastrado, altere o valor e tente novamente.",
  },
  {
    code: "Irmão está em uso por um usuário.",
    title: "Irmão está vinculado a uma conta de usuário.",
    message: "Entre em contato com nosso time de suporte para solicitar ajuda.",
  },
  {
    code: "Irmão está em uso por uma ou mais cobranças.",
    title: "Não é possível excluir o irmão",
    message: "Existem uma ou mais cobranças vinculadas a este Irmão.",
  },
];
