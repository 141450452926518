import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SignatureState {
  assinado: boolean;
}

const initialState: SignatureState = {
  assinado: false,
};

export const signatureSlice = createSlice({
  name: "signature",
  initialState,
  reducers: {
    saveAssinado: (state, action: PayloadAction<SignatureState["assinado"]>) => {
      // eslint-disable-next-line no-param-reassign
      state.assinado = action.payload;
    },
  },
});
export const { saveAssinado } = signatureSlice.actions;
export default signatureSlice.reducer;
