import React from "react";
import { useHistory } from "react-router-dom";

import ErrorPageComponent from "../../components/ErrorPageComponent";

const Page404: React.FC = () => {
  const history = useHistory();

  const handleHome = () => {
    history.push("/");
  };

  return (
    <ErrorPageComponent
      code="404"
      message="Desculpe, página não encontrada. :("
      backgroundImg=""
      showActionButton
      actionButtonLabel="Ir para a Home"
      actionButtionCallback={handleHome}
    />
  );
};

export default Page404;
