import React, { useCallback, useEffect, useState } from "react";

import { InputText } from "primereact/inputtext";
import _ from "lodash";
import { InputTextarea } from "primereact/inputtextarea";
import { useHistory, useParams } from "react-router-dom";
import SimpleEntityListPage from "../../../../components/SimpleEntityPage";
import CustomFormField from "../../../../components/CustomFormField";
import { Temporada } from "../../types";
import FloatingSave from "../../../../components/FloatingSave";
import api from "../../../../services/api";
import { useError } from "../../../../hooks/error";
import { temporadaErrors } from "../../../../errors/temporada";
import { useToast } from "../../../../hooks/toast";

interface Params {
  id: string;
  temporadaId: string;
}

const TemporadaFormPage: React.FC = () => {
  const history = useHistory();
  const { id, temporadaId } = useParams<Params>();
  const { showToast } = useToast();
  const { handleError } = useError();

  const [loading, setLoading] = useState(false);
  const [loadingSalvar, setLoadingSalvar] = useState(false);

  const [errors, setErrors] = useState<{ [name: string]: string }>({});

  const [nome, setNome] = useState<string>("");
  const [descricao, setDescricao] = useState<string>("");

  const [initialTemp, setInitialTemp] = useState({} as Temporada);
  const [isDirty, setIsDirty] = useState<boolean>(false);

  const redefinirTemporada = useCallback(() => {
    setIsDirty(false);

    if (temporadaId) {
      setNome(initialTemp.nome);
      setDescricao(initialTemp.descricao);
    } else {
      setNome("");
      setDescricao("");
    }
  }, [initialTemp.descricao, initialTemp.nome, temporadaId]);

  const temporadaValida = useCallback(() => {
    const errorsLocal: { [campo: string]: string } = {};

    if (_.isEmpty(nome)) errorsLocal.nome = "Este é um campo obrigatório";
    if (_.isEmpty(descricao)) errorsLocal.descricao = "Este é um campo obrigatório";

    setErrors(errorsLocal);
    return _.isEmpty(errorsLocal);
  }, [descricao, nome]);

  const salvarTemporada = useCallback(() => {
    if (temporadaValida()) {
      setLoadingSalvar(true);

      const objectToSend = {
        nome,
        descricao,
      };

      if (temporadaId) {
        api
          .put(`podcast/temporada/${temporadaId}`, objectToSend)
          .then(() => {
            showToast({
              title: "Sucesso!",
              type: "success",
              description: "A temporada foi atualizada.",
            });

            history.push(`/podcast/${id}/temporada`);
          })
          .catch((err) => {
            handleError({ error: err, action: "atualizar temporada", knownErrors: temporadaErrors });
          });
      } else {
        api
          .post("podcast/temporada", { ...objectToSend, uuidPrograma: id })
          .then(() => {
            showToast({
              title: "Sucesso!",
              type: "success",
              description: "Uma nova temporada foi adicionada ao programa.",
            });

            history.push(`/podcast/${id}/temporada`);
          })
          .catch((err) => {
            handleError({ error: err, action: "cadastrar temporada", knownErrors: temporadaErrors });
          });
      }
    }
  }, [descricao, handleError, history, id, nome, showToast, temporadaId, temporadaValida]);

  const loadData = useCallback(() => {
    api
      .get<Temporada>(`podcast/temporada/${temporadaId}`)
      .then(({ data }) => {
        setInitialTemp(data);

        setNome(data.nome);
        setDescricao(data.descricao);
      })
      .catch((err: any) => {
        handleError({ error: err, action: "cadastrar livro", knownErrors: temporadaErrors });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [handleError, temporadaId]);

  useEffect(() => {
    if (temporadaId) {
      setLoading(true);
      loadData();
    }
  }, [temporadaId, loadData]);

  return (
    <SimpleEntityListPage showTopBar routeBack={`/podcast/${id}/temporada`} isFormPage loading={loading}>
      <div className="p-grid p-flex-row p-jc-between">
        <div className="p-col-7 p-grid">
          <div className="p-fluid p-col-12">
            <CustomFormField
              htmlForDescription="nome"
              description="Nome"
              iconMaterial="mic"
              labelWidth="3"
              required
              inputBody={
                <InputText
                  id="nome"
                  value={nome}
                  placeholder="Digite o nome da temporada"
                  onChange={(e) => {
                    setNome(e.target.value);

                    const errorsLocal = _.cloneDeep(errors);
                    delete errorsLocal.nome;
                    setErrors(errorsLocal);

                    if (!_.isEqual(initialTemp.nome, e.target.value)) {
                      setIsDirty(true);
                    } else {
                      setIsDirty(false);
                    }
                  }}
                />
              }
              errorMessage={errors.nome}
            />

            <CustomFormField
              htmlForDescription="descricao"
              description="Descrição"
              iconMaterial="subject"
              labelWidth="3"
              required
              inputBody={
                <InputTextarea
                  id="descricao"
                  value={descricao}
                  placeholder="Digite a descrição da temporada"
                  rows={5}
                  autoResize
                  onChange={(e) => {
                    setDescricao(e.target.value);

                    const errorsLocal = _.cloneDeep(errors);
                    delete errorsLocal.descricao;
                    setErrors(errorsLocal);

                    if (!_.isEqual(initialTemp.descricao, e.target.value)) {
                      setIsDirty(true);
                    } else {
                      setIsDirty(false);
                    }
                  }}
                />
              }
              errorMessage={errors.descricao}
            />
          </div>
        </div>
      </div>
      {isDirty ? (
        <FloatingSave
          resetCommand={() => redefinirTemporada()}
          disabled={loadingSalvar || !_.isEmpty(errors)}
          saveCommand={() => salvarTemporada()}
          loadingOnSave={loadingSalvar}
        />
      ) : null}
    </SimpleEntityListPage>
  );
};

export default TemporadaFormPage;
