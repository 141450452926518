import React, { useMemo, useEffect, useCallback, useState, useRef } from "react";
import { ColumnProps } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { useHistory } from "react-router-dom";
import { confirmDialog } from "primereact/confirmdialog";
import SimpleEntityDatatable from "../../../components/SimpleEntityDatatable";
import SimpleEntityListPage from "../../../components/SimpleEntityPage";
import api from "../../../services/api";
import { useToast } from "../../../hooks/toast";

interface DataComemorativaDTO {
  uuid: string;
  data: string;
  dataFormatada: string;
  descricao: string;
  idade: number;
  recorrente: boolean;
}

const DatasComemorativasPage: React.FC = () => {
  const history = useHistory();
  const dt = useRef<DataTable>(null);

  const [datasComemorativas, setDatasComemorativas] = useState<DataComemorativaDTO[]>([]);
  const [loading, setLoading] = useState(true);
  const [globalFilter, setGlobalFilter] = useState<string>("");

  const { showToast } = useToast();

  const loadData = useCallback(() => {
    setLoading(true);

    api
      .get<DataComemorativaDTO[]>("datas-comemorativas")
      .then(({ data }) => {
        setDatasComemorativas(data);
      })
      .catch(() => {
        showToast({
          type: "error",
          title: "Não foi possível carregar datas comemorativas",
          description: "Tente novamente mais tarde, ou entre em contato com um administrador",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [showToast]);

  const onGlobalFilterChange = useCallback((filterValue) => {
    setGlobalFilter(filterValue);
  }, []);

  const excluirDataComemorativa = useCallback(
    (data: DataComemorativaDTO) => {
      api
        .delete(`datas-comemorativas/${data.uuid}`)
        .then(() => {
          showToast({ title: "Sucesso!", type: "success", description: "A data comemorativa foi removida." });
          loadData();
        })
        .catch((err) => {
          showToast({
            title: "Não foi possível remover data comemorativa",
            type: "error",
            description: "Tente novamente, ou entre em contato com um administrador",
          });
          // eslint-disable-next-line no-console
          console.log(err.response);
        });
    },
    [loadData, showToast]
  );

  const confirmExclusao = useCallback(
    (data: DataComemorativaDTO) => {
      confirmDialog({
        message: "Após a remoção o item não poderá ser recuperado.",
        header: "Remover data comemorativa?",
        icon: "pi pi-question-circle",
        focusOnShow: false,
        rejectLabel: "Manter",
        acceptLabel: "Remover",
        accept: () => excluirDataComemorativa(data),
        reject: () => {},
      });
    },
    [excluirDataComemorativa]
  );

  const editDeleteButtonsBodyTemplate = useCallback(
    (data: DataComemorativaDTO) => {
      return (
        <>
          <Button
            type="button"
            className="balandrau-action-button"
            icon="pi pi-pencil"
            onClick={() => {
              history.push({ pathname: `/datas-comemorativas/${data.uuid}` });
            }}
          />
          <Button
            type="button"
            className="p-ml-3 balandrau-exclude-button"
            icon="pi pi-trash"
            onClick={() => {
              confirmExclusao(data);
            }}
          />
        </>
      );
    },
    [history, confirmExclusao]
  );

  const dataBodyTemplate = useCallback((data: DataComemorativaDTO) => {
    if (data.recorrente) {
      return data.dataFormatada.substring(0, 5);
    }
    return data.dataFormatada;
  }, []);

  const idadeBodyTemplate = useCallback((data: DataComemorativaDTO) => {
    if (!data.recorrente) return data.idade;
    return "";
  }, []);

  const datasComemorativasColumns = useMemo(
    (): ColumnProps[] => [
      {
        field: "dataFormatada",
        header: "Data",
        style: { verticalAlign: "middle", width: 100 },
        sortable: true,
        sortField: "data",
        align: "center",
        body: dataBodyTemplate,
      },
      {
        field: "descricao",
        header: "Descrição",
        sortable: true,
        align: "left",
        style: { verticalAlign: "middle" },
        className: "p-text-nowrap p-text-truncate",
      },
      {
        field: "idade",
        header: "Idade",
        sortable: true,
        align: "center",
        style: { verticalAlign: "middle", width: 100, maxWidth: 100 },
        className: "p-text-nowrap p-text-truncate",
        body: idadeBodyTemplate,
      },
      {
        align: "center",
        body: editDeleteButtonsBodyTemplate,
        style: { width: "100px", verticalAlign: "middle" },
      },
    ],
    [dataBodyTemplate, editDeleteButtonsBodyTemplate, idadeBodyTemplate]
  );

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <SimpleEntityListPage
      showListHeader
      onGlobalFilterChange={(value) => onGlobalFilterChange(value)}
      loading={loading}
      showExportCsvButton
      datatableRef={dt}
      newButtonLabel="Nova data comemorativa"
      onNewButtonCLick={() => {
        history.push("/datas-comemorativas/novo");
      }}
    >
      <SimpleEntityDatatable
        datatableRef={dt}
        value={datasComemorativas}
        columns={datasComemorativasColumns}
        globalFilter={globalFilter}
        sortField="data"
        sortOrder={1}
      />
    </SimpleEntityListPage>
  );
};

export default DatasComemorativasPage;
