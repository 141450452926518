import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Tag } from "primereact/tag";
import { ColumnProps } from "primereact/column";
// import { useHistory } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { confirmDialog } from "primereact/confirmdialog";

import api from "../../../../services/api";
import { useToast } from "../../../../hooks/toast";
import { useError } from "../../../../hooks/error";
import { EtapaFormProfano } from "../../FormProfanoPage";
import { filiandoErrors } from "../../../../errors/filiando";
import IrmaoComponent from "../../../../components/IrmaoComponent";
import SimpleEntityListPage from "../../../../components/SimpleEntityPage";
import SimpleEntityDatatable from "../../../../components/SimpleEntityDatatable";
import { convertStringBRToDate } from "../../../../utils/convertDates";

export interface FormFiliando {
  uuid: string;
  // padrinho: Irmao;
  filiando: { nome: string; foto: string };
  token: string;
  url: string;
  etapa: EtapaFormProfano;
  cpf?: string;
  criadoEm: string;
  dtCriadoEm: Date;
}

export interface EtapaFormFiliandoElement {
  label: string;
  class: string;
  etapa: EtapaFormProfano;
}
type EtapaFormFiliandoMap = {
  // eslint-disable-next-line no-unused-vars
  [name in EtapaFormProfano]: EtapaFormFiliandoElement;
};

export const etapasFormFiliandoMap: EtapaFormFiliandoMap = {
  LEITURA: { label: "Termos", class: "tag-etapa etapa-leitura", etapa: "LEITURA" },
  FORM: {
    label: "Formulário",
    class: "tag-etapa etapa-form",
    etapa: "FORM",
  },
  ANEXO: { label: "Anexos", class: "tag-etapa etapa-anexo", etapa: "ANEXO" },
  FINALIZACAO: {
    label: "Submetido",
    class: "tag-etapa etapa-finalizacao",
    etapa: "FINALIZACAO",
  },
};

const ConviteFiliacaoPage: React.FC = () => {
  const dt = useRef<DataTable>(null);
  const [loading, setLoading] = useState(false);
  // const [loadingSalvar, setLoadingSalvar] = useState(false);
  const [forms, setForms] = useState<FormFiliando[]>([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const { showToast } = useToast();
  // const history = useHistory();

  const { handleError } = useError();

  const loadData = useCallback(() => {
    setLoading(true);
    api
      .get<FormFiliando[]>(`formularios/filiando`)
      .then(({ data }) => {
        setForms(
          data.map((ficha) => {
            return { ...ficha, dtCriadoEm: convertStringBRToDate(ficha.criadoEm) };
          })
        );
      })
      .catch((err) => {
        showToast({
          type: "error",
          title: "Não foi possível carregar os formulários",
          description: "Tente novamente ou contate nosso time de suporte para solicitar ajuda.",
        });
        // eslint-disable-next-line no-console
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [showToast]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const criarNovaFicha = useCallback(() => {
    // setLoadingSalvar(true);
    api
      .post("formularios/filiando", {
        // padrinhoUuid: padrinho.uuid,
      })
      .then(() => {
        showToast({ title: "Sucesso!", type: "success", description: "Um novo formulário de filiação foi criado." });
        loadData();
      })
      .catch((err: any) => {
        handleError({ error: err, action: "criar formulário de iniciação", knownErrors: filiandoErrors });
      });
    // .finally(() => setLoadingSalvar(false));
  }, [handleError, loadData, showToast]);

  const confirmarNovaFichaFiliacao = useCallback(() => {
    confirmDialog({
      header: "Criar nova ficha de filiação?",
      message: "Após a criação o item não poderá ser removido.",
      icon: "pi pi-question-circle",
      focusOnShow: false,
      rejectLabel: "Cancelar",
      acceptLabel: "Criar",
      accept: () => criarNovaFicha(),
      reject: () => {},
    });
  }, [criarNovaFicha]);

  const filiandoBodyTemplate = useCallback((rowData: FormFiliando) => {
    return (
      <>
        {rowData.filiando && <IrmaoComponent nome={rowData.filiando.nome} foto={rowData.filiando.foto} />}
        {!rowData.filiando && ""}
      </>
    );
  }, []);

  // const padrinhoBodyTemplate = useCallback((rowData: FormFiliando) => {
  //   return (
  //     <IrmaoComponent nome={rowData.padrinho.nome} apelido={rowData.padrinho.apelido} foto={rowData.padrinho.foto} />
  //   );
  // }, []);

  const etapaBodyTemplate = useCallback((rowData: FormFiliando) => {
    return (
      <>
        {rowData.etapa && (
          <Tag className={etapasFormFiliandoMap[rowData.etapa].class}>{etapasFormFiliandoMap[rowData.etapa].label}</Tag>
        )}
        {!rowData.etapa && <Tag className="tag-etapa etapa-nao-vinculado">Sem vínculo</Tag>}
      </>
    );
  }, []);

  const cpfBodyTemplate = useCallback((rowData: FormFiliando) => {
    if (rowData.cpf) {
      return `${rowData.cpf.substring(0, 3)}.${rowData.cpf.substring(3, 6)}.${rowData.cpf.substring(
        6,
        9
      )}-${rowData.cpf.substring(9, 11)}`;
    }
    return "";
  }, []);

  const columnsForms = useMemo(
    (): ColumnProps[] => [
      {
        field: "etapa",
        header: "Etapa do Formulário",
        sortable: false,
        align: "center",
        style: { verticalAlign: "middle", width: "110px" },
        body: etapaBodyTemplate,
      },
      // {
      //   field: "padrinho.nome",
      //   header: "Padrinho",
      //   sortable: true,
      //   style: { textAlign: "left", verticalAlign: "middle" },
      //   className: "p-text-nowrap p-text-truncate",
      //   body: padrinhoBodyTemplate,
      // },
      {
        field: "filiando.nome",
        header: "Filiando",
        sortable: true,
        style: { textAlign: "left", verticalAlign: "middle" },
        className: "p-text-nowrap p-text-truncate",
        body: filiandoBodyTemplate,
      },
      {
        field: "cpf",
        header: "CPF vinculado",
        sortable: true,
        style: { textAlign: "left", verticalAlign: "middle", width: "160px" },
        className: "p-text-nowrap p-text-truncate",
        body: cpfBodyTemplate,
      },
      {
        field: "criadoEm",
        sortField: "dtCriadoEm",
        header: "Data de criação",
        sortable: true,
        style: { textAlign: "left", verticalAlign: "middle", width: "160px" },
        className: "p-text-nowrap p-text-truncate",
      },
      {
        field: "url",
        header: "Link da Ficha",
        sortable: false,
        style: { textAlign: "left", verticalAlign: "middle", width: "160px" },
        body: ({ url, token }: FormFiliando) => {
          return (
            <a className="color-link" href={url} target="_blank" rel="noreferrer">
              {token}
            </a>
          );
        },
      },
    ],
    [etapaBodyTemplate, filiandoBodyTemplate, cpfBodyTemplate]
  );

  return (
    <SimpleEntityListPage
      showListHeader
      newButtonLabel="Nova ficha"
      onNewButtonCLick={() => {
        // history.push("/filiacao/convite/novo");
        confirmarNovaFichaFiliacao();
      }}
      onGlobalFilterChange={(value) => setGlobalFilter(value)}
      loading={loading}
      showExportCsvButton
      datatableRef={dt}
    >
      <SimpleEntityDatatable
        datatableRef={dt}
        value={forms}
        columns={columnsForms}
        globalFilter={globalFilter}
        sortField="filiando.nome"
        sortOrder={1}
      />
    </SimpleEntityListPage>
  );
};

export default ConviteFiliacaoPage;
