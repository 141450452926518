import React, { useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { Dropdown } from "primereact/dropdown";
import { useHistory, useParams } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { RadioButton } from "primereact/radiobutton";
import { Button } from "primereact/button";
import { InputMask } from "primereact/inputmask";
import { validate } from "email-validator";

import { useError } from "../../../../hooks/error";
import { useToast } from "../../../../hooks/toast";
import { Leitor, Vinculo, vinculoMap } from "../LeitoresPage";
import api from "../../../../services/api";
import { leitoresErrors } from "../../../../errors/leitores";
import SimpleEntityPage from "../../../../components/SimpleEntityPage";
import CustomVerticalFormField from "../../../../components/CustomVerticalFormField";
import { replaceCep } from "../../../../utils/replaceCep";
import { enderecoErrors } from "../../../../errors/endereco";
import { UF } from "../../../FormProfano/FormProfanoPage/EtapaForm";

interface Params {
  id: string;
}

interface DropdownIrmao {
  uuid?: string;
  cim: string;
  nome: string;
}

type Expansible = "DADOS_PESSOAIS" | "CONTATOS" | "ENDERECO";

interface ExpansibleProps {
  open: boolean;
}

const LeitoresFormPage: React.FC = () => {
  const { handleError } = useError();
  const { id } = useParams<Params>();

  const [errors, setErrors] = useState<{ [campo: string]: string }>({});

  const history = useHistory();
  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [loadingSalvar, setLoadingSalvar] = useState(false);

  // estados dos campos do formulário
  const [vinculo, setVinculo] = useState<Vinculo>(vinculoMap.IRMAO.vinculo);
  const [nome, setNome] = useState<string>("");
  const [cimIrmao, setCimIrmao] = useState<string>("");
  const [numeroDocumento, setNumeroDocumento] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [celular, setCelular] = useState<string>("");
  const [telefoneFixo, setTelefoneFixo] = useState<string>("");
  const [cep, setCep] = useState<string>("");
  const [logradouro, setLogradouro] = useState<string>("");
  const [numeroEndereco, setNumeroEndereco] = useState<string>("");
  const [bairro, setBairro] = useState<string>("");
  const [complemento, setComplemento] = useState<string>("");
  const [estado, setEstado] = useState<string>("");
  const [cidade, setCidade] = useState<string>("");

  const [dropdownIrmao, setDropdownIrmao] = useState<DropdownIrmao[]>([]);
  const [irmao, setIrmao] = useState({} as DropdownIrmao);

  // eslint-disable-next-line no-unused-vars
  const [expanded, setExpanded] = useState<{ [expanded in Expansible]: ExpansibleProps }>({
    DADOS_PESSOAIS: { open: true },
    CONTATOS: { open: true },
    ENDERECO: { open: true },
  });

  const ufOptions: UF[] = useMemo(() => {
    return [
      "AC",
      "AL",
      "AP",
      "AM",
      "BA",
      "CE",
      "DF",
      "ES",
      "GO",
      "MA",
      "MT",
      "MS",
      "MG",
      "PA",
      "PB",
      "PR",
      "PE",
      "PI",
      "RJ",
      "RN",
      "RS",
      "RO",
      "RR",
      "SC",
      "SP",
      "SE",
      "TO",
    ];
  }, []);

  const isValidForm = useCallback(() => {
    const localErrors = {} as { [campo: string]: string };

    if (_.isEmpty(vinculo)) {
      localErrors.vinculo = "O vínculo precisa ser selecionado";
    }

    if (vinculo === "EXTERNO") {
      if (_.isEmpty(nome)) {
        localErrors.nome = "O campo nome é obrigatório";
      }
    } else if (vinculo === "IRMAO") {
      if (_.isEmpty(irmao)) {
        localErrors.nome = "Selecione um irmão";
      }
    }

    if (_.isEmpty(numeroDocumento)) {
      localErrors.numeroDocumento = "O campo número do documento é obrigatório";
    }

    if (_.isEmpty(email)) {
      localErrors.email = "O campo e-mail é obrigatório";
    }

    if (!_.isEmpty(email)) {
      if (!validate(email)) {
        localErrors.email = "Insira um email válido ex.: exemplo@dominio.com";
      }
    }

    if (_.isEmpty(celular)) {
      localErrors.celular = "O campo celular é obrigatório";
    }

    if (_.isEmpty(cep)) {
      localErrors.cep = "o campo cep é obrigatório";
    }

    if (_.isEmpty(logradouro)) {
      localErrors.logradouro = "O campo logradouro é obrigatório";
    }

    if (_.isEmpty(numeroEndereco)) {
      localErrors.numeroEndereco = "O campo número é obrigatório";
    }

    if (_.isEmpty(bairro)) {
      localErrors.bairro = "O campo bairro é obrigatório";
    }

    if (_.isEmpty(estado)) {
      localErrors.estado = "O campo UF é obrigatório";
    }

    if (_.isEmpty(cidade)) {
      localErrors.cidade = "O campo cidade é obrigatório";
    }

    setErrors(localErrors);
    return _.isEmpty(localErrors);
  }, [bairro, celular, cep, cidade, email, estado, irmao, logradouro, nome, numeroDocumento, numeroEndereco, vinculo]);

  const confirmarCadastro = useCallback(() => {
    if (isValidForm()) {
      setLoadingSalvar(true);

      if (!id) {
        api
          .post("biblioteca/leitor", {
            vinculo,
            nome,
            documentoIdentificacao: numeroDocumento,
            email,
            celular,
            telefone: telefoneFixo,
            codigo: cimIrmao,
            endereco: {
              cep,
              rua: logradouro,
              numero: numeroEndereco,
              bairro,
              complemento,
              cidade,
              estado,
            },
          })
          .then(() => {
            showToast({
              title: "Sucesso!",
              type: "success",
              description: "Um novo leitor foi cadastrado.",
            });
            history.push("/leitor");
          })
          .catch((err: any) => {
            handleError({ error: err, action: "cadastrar leitor", knownErrors: leitoresErrors });
          })
          .finally(() => setLoadingSalvar(false));
      } else {
        api
          .put(`biblioteca/leitor/${id}`, {
            vinculo,
            nome,
            documentoIdentificacao: numeroDocumento,
            email,
            celular,
            telefone: telefoneFixo,
            codigo: cimIrmao,
            endereco: {
              cep,
              rua: logradouro,
              numero: numeroEndereco,
              bairro,
              complemento,
              cidade,
              estado,
            },
          })
          .then(() => {
            showToast({
              title: "Sucesso!",
              type: "success",
              description: "O Leitor foi editado.",
            });
            history.push("/leitor");
          })
          .catch((err: any) => {
            handleError({ error: err, action: "alterar leitor", knownErrors: leitoresErrors });
          })
          .finally(() => setLoadingSalvar(false));
      }
    }
  }, [
    bairro,
    celular,
    cep,
    cidade,
    cimIrmao,
    complemento,
    email,
    estado,
    handleError,
    history,
    id,
    isValidForm,
    logradouro,
    nome,
    numeroDocumento,
    numeroEndereco,
    showToast,
    telefoneFixo,
    vinculo,
  ]);

  const fillFields = useCallback((data: Leitor) => {
    setVinculo(data.vinculo);
    setNome(data.nome);
    setCimIrmao(data.codigo);
    setNumeroDocumento(data.documentoIdentificacao);
    setEmail(data.email);
    setCelular(data.celular || "");
    setTelefoneFixo(data.telefone || "");

    setIrmao({ cim: data.codigo, nome: data.nome });

    // if (!_.isEmpty(dropdownIrmao)) {
    //   const irmaoDropdown = dropdownIrmao.find((i) => i.cim === data.codigo);
    //   if (irmaoDropdown) setIrmao(irmaoDropdown);
    // }

    const enderecoValue = data.endereco;
    if (enderecoValue) {
      setCep(enderecoValue.cep);
      setLogradouro(enderecoValue.rua);
      setNumeroEndereco(enderecoValue.numero);
      setBairro(enderecoValue.bairro);
      setComplemento(enderecoValue.complemento);
      setEstado(enderecoValue.estado);
      setCidade(enderecoValue.cidade);
    }
  }, []);

  const loadLeitor = useCallback(
    (uuidSearch) => {
      setLoading(true);
      api
        .get<Leitor>(`biblioteca/leitor/${uuidSearch}`)
        .then(({ data }) => {
          fillFields(data);
        })
        .catch((err) => {
          handleError({ error: err, action: "carregar o leitor", knownErrors: leitoresErrors });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [handleError, fillFields]
  );

  const loadDropdowns = useCallback(() => {
    api
      .get<DropdownIrmao[]>(`biblioteca/leitor/irmao/dropdown`)
      .then(({ data }) => {
        setDropdownIrmao(data);
      })
      .catch((err) => {
        showToast({
          type: "error",
          title: "Não foi possível carregar informações do dropdown",
          description: "Tente novamente ou contate nosso time de suporte para solicitar ajuda.",
        });
        // eslint-disable-next-line no-console
        console.error(err);
      });
  }, [showToast]);

  const findIrmao = useCallback(
    (cim: string) => {
      api
        .get<Leitor>(`biblioteca/leitor/irmao/${cim}`)
        .then(({ data }) => {
          fillFields(data);
        })
        .catch((err) => {
          handleError({ error: err, action: "carregar o leitor", knownErrors: leitoresErrors });
        });
    },
    [fillFields, handleError]
  );

  const resetFields = useCallback(async () => {
    setVinculo(vinculoMap.IRMAO.vinculo);
    setNome("");
    setCimIrmao("");
    setNumeroDocumento("");
    setEmail("");
    setCelular("");
    setTelefoneFixo("");
    setCep("");
    setLogradouro("");
    setNumeroEndereco("");
    setBairro("");
    setComplemento("");
    setEstado("");
    setCidade("");
    setIrmao({} as DropdownIrmao);
  }, []);

  useEffect(() => {
    if (id) {
      loadLeitor(id);
    }
  }, [id, loadLeitor]);

  useEffect(() => {
    loadDropdowns();
  }, [loadDropdowns]);

  return (
    <SimpleEntityPage showTopBar routeBack="/leitor" isFormPage loading={loading}>
      <div className="acervo-form-container p-grid p-flex-column p-jc-between">
        <section id="dados-basicos" className="p-d-flex p-flex-column acervo-form">
          <div className="header-section dados-basicos p-d-flex p-ai-center p-jc-between">
            <span>Dados pessoais</span>
            <button
              type="button"
              onClick={() => {
                setExpanded({ ...expanded, DADOS_PESSOAIS: { open: !expanded.DADOS_PESSOAIS.open } });
              }}
            >
              {expanded.DADOS_PESSOAIS.open ? (
                <span className="material-symbols-rounded">expand_less</span>
              ) : (
                <span className="material-symbols-rounded">expand_more</span>
              )}
            </button>
          </div>

          {expanded.DADOS_PESSOAIS.open && (
            <div className="p-mt-1 p-col-7 p-grid">
              <div className="p-fluid p-col-12">
                <CustomVerticalFormField
                  className="color-branco"
                  iconMaterial="label"
                  htmlForDescription="vinculo"
                  description="Vínculo"
                  inputBody={
                    <div className="p-d-flex p-ai-center" style={{ paddingTop: 2, paddingBottom: 2 }}>
                      <RadioButton
                        value="IRMAO"
                        inputId="vinculo-irmao"
                        onChange={(e) => {
                          setVinculo(e.value);
                        }}
                        disabled={!!id}
                        checked={vinculo === vinculoMap.IRMAO.vinculo}
                      />
                      <label htmlFor="vinculo-irmao" className="p-ml-2">
                        {vinculoMap.IRMAO.label}
                      </label>
                      <RadioButton
                        value="EXTERNO"
                        inputId="vinculo-externo"
                        onChange={(e) => {
                          setVinculo(e.value);
                        }}
                        disabled={!!id}
                        checked={vinculo === vinculoMap.EXTERNO.vinculo}
                        className="p-ml-4"
                      />
                      <label htmlFor="vinculo-externo" className="p-ml-2">
                        {vinculoMap.EXTERNO.label}
                      </label>
                    </div>
                  }
                  errorMessage={errors.vinculo}
                />

                <CustomVerticalFormField
                  className="color-branco"
                  iconMaterial="person"
                  htmlForDescription="irmao-nome"
                  description="Nome"
                  required
                  inputBody={
                    vinculo === vinculoMap.IRMAO.vinculo ? (
                      <Dropdown
                        id="irmao-nome"
                        value={irmao}
                        options={dropdownIrmao}
                        dataKey="cim"
                        optionLabel="nome"
                        placeholder="Digite ou selecione o irmão"
                        onChange={(e: { value: DropdownIrmao }) => {
                          resetFields().finally(() => {
                            setIrmao(e.value);
                            findIrmao(e.value.cim);
                          });

                          const errorsLocal = _.cloneDeep(errors);
                          delete errorsLocal.nome;
                          setErrors(errorsLocal);
                        }}
                        style={{ minWidth: "400px" }}
                        itemTemplate={(o: DropdownIrmao) => `${o.cim} - ${o.nome}`}
                        filter
                        filterBy="nome"
                        emptyMessage="Nenhum irmão encontrado"
                        emptyFilterMessage="Nenhum irmão encontrado"
                      />
                    ) : (
                      <InputText
                        id="nome"
                        value={nome}
                        placeholder="Digite o nome do Leitor"
                        onChange={(e) => {
                          setNome(e.target.value);

                          const errorsLocal = _.cloneDeep(errors);
                          delete errorsLocal.nome;
                          setErrors(errorsLocal);
                        }}
                      />
                    )
                  }
                  errorMessage={errors.nome}
                />

                <CustomVerticalFormField
                  className="color-branco"
                  iconMaterial="key"
                  htmlForDescription="doc-identificacao"
                  description="N do documento de identificação"
                  required
                  inputBody={
                    <InputText
                      id="doc-identificacao"
                      value={numeroDocumento}
                      placeholder="Digite o número do documento escolhido"
                      onChange={(e) => {
                        setNumeroDocumento(e.target.value);

                        const errorsLocal = _.cloneDeep(errors);
                        delete errorsLocal.numeroDocumento;
                        setErrors(errorsLocal);
                      }}
                    />
                  }
                  errorMessage={errors.numeroDocumento}
                />
              </div>
            </div>
          )}
        </section>

        <section id="publicacao" className="p-d-flex p-flex-column acervo-form">
          <div className="header-section publicacao p-d-flex p-ai-center p-jc-between">
            <span>Contatos</span>
            <button
              type="button"
              onClick={() => {
                setExpanded({ ...expanded, CONTATOS: { open: !expanded.CONTATOS.open } });
              }}
            >
              {expanded.CONTATOS.open ? (
                <span className="material-symbols-rounded">expand_less</span>
              ) : (
                <span className="material-symbols-rounded">expand_more</span>
              )}
            </button>
          </div>

          {expanded.CONTATOS.open && (
            <div className="p-mt-1 p-col-7 p-grid">
              <div className="p-fluid p-col-12">
                <CustomVerticalFormField
                  className="color-branco"
                  iconMaterial="mail"
                  htmlForDescription="email"
                  description="E-mail"
                  required
                  inputBody={
                    <InputText
                      id="email"
                      value={email}
                      placeholder="Digite o e-mail"
                      onChange={(e) => {
                        setEmail(e.target.value);

                        const errorsLocal = _.cloneDeep(errors);
                        delete errorsLocal.email;
                        setErrors(errorsLocal);
                      }}
                    />
                  }
                  errorMessage={errors.email}
                />
                <CustomVerticalFormField
                  className="color-branco"
                  iconMaterial="phone"
                  htmlForDescription="celular"
                  description="Celular"
                  required
                  inputBody={
                    <InputMask
                      id="celular"
                      value={celular}
                      placeholder="(DDD) XXXXX-XXXX"
                      mask="(99) 99999-9999"
                      onChange={(e) => {
                        setCelular(e.target.value);

                        const errorsLocal = _.cloneDeep(errors);
                        delete errorsLocal.celular;
                        setErrors(errorsLocal);
                      }}
                    />
                  }
                  errorMessage={errors.celular}
                />
                <CustomVerticalFormField
                  className="color-branco"
                  iconMaterial="phone"
                  htmlForDescription="telefone"
                  description="Telefone fixo"
                  inputBody={
                    <InputMask
                      id="celular"
                      value={telefoneFixo}
                      placeholder="(DDD) XXXX-XXXX"
                      mask="(99) 9999-9999"
                      onChange={(e) => {
                        setTelefoneFixo(e.target.value);

                        const errorsLocal = _.cloneDeep(errors);
                        delete errorsLocal.telefone;
                        setErrors(errorsLocal);
                      }}
                    />
                  }
                  errorMessage={errors.telefone}
                />
              </div>
            </div>
          )}
        </section>

        <section id="exemplar" className="p-d-flex p-flex-column acervo-form">
          <div className="header-section exemplar p-d-flex p-ai-center p-jc-between">
            <span>Endereço</span>
            <button
              type="button"
              onClick={() => {
                setExpanded({ ...expanded, ENDERECO: { open: !expanded.ENDERECO.open } });
              }}
            >
              {expanded.ENDERECO.open ? (
                <span className="material-symbols-rounded">expand_less</span>
              ) : (
                <span className="material-symbols-rounded">expand_more</span>
              )}
            </button>
          </div>

          {expanded.ENDERECO.open && (
            <div className="p-mt-1 p-col-7 p-grid">
              <div className="p-fluid p-col-12">
                <CustomVerticalFormField
                  iconMaterial="map"
                  htmlForDescription="cep"
                  description="CEP"
                  className="p-mb-4"
                  inputBody={
                    <div className="p-d-flex">
                      <InputMask
                        style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                        id="cep"
                        placeholder="Digite o CEP do endereço residencial"
                        value={cep}
                        type="text"
                        onChange={(e) => {
                          setCep(e.target.value);
                          const errorsLocal = _.cloneDeep(errors);
                          delete errorsLocal.cep;
                          setErrors(errorsLocal);
                        }}
                        mask="99.999-999"
                        autoClear={false}
                      />
                      <Button
                        style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                        icon="pi pi-search"
                        onClick={() => {
                          const errorsLocal = _.cloneDeep(errors);
                          delete errorsLocal.cep;
                          delete errorsLocal.logradouro;
                          delete errorsLocal.numeroEndereco;
                          delete errorsLocal.bairro;
                          delete errorsLocal.complemento;
                          delete errorsLocal.estado;
                          delete errorsLocal.cidade;
                          setErrors(errorsLocal);

                          if (replaceCep(cep).length !== 8) {
                            // const errorsLocal: { [campo: string]: string } = {};
                            errorsLocal.cep = "O campo cep deve conter 8 dígitos";
                            setErrors(errorsLocal);
                            return;
                          }

                          api
                            .post(`enderecos/consultar-cep`, { cep: replaceCep(cep) })
                            .then((res) => {
                              showToast({
                                title: "A consulta foi feita com sucesso.",
                                type: "success",
                                description: "Prossiga com o preenchimento dos dados.",
                              });
                              setLogradouro(res.data.logradouro);
                              setNumeroEndereco("");
                              setComplemento(res.data.complemento);
                              setBairro(res.data.bairro);
                              setCidade(res.data.cidade);
                              setEstado(res.data.estado);
                            })
                            .catch((err) => {
                              handleError({ error: err, action: "consultar cep", knownErrors: enderecoErrors });
                            });
                        }}
                      />
                    </div>
                  }
                  required
                  errorMessage={errors.cep}
                />
                <CustomVerticalFormField
                  icon="fas fa-house-user"
                  htmlForDescription="logradouro"
                  description="Logradouro"
                  className="p-mb-4"
                  inputBody={
                    <InputText
                      id="logradouro"
                      placeholder="Digite o nome da rua ou avenida"
                      value={logradouro}
                      type="text"
                      onChange={(e) => {
                        setLogradouro(e.target.value);
                        const errorsLocal = _.cloneDeep(errors);
                        delete errorsLocal.logradouro;
                        setErrors(errorsLocal);
                      }}
                      maxLength={120}
                    />
                  }
                  required
                  errorMessage={errors.logradouro}
                />
                <CustomVerticalFormField
                  icon="fas fa-house-user"
                  htmlForDescription="numeroEndereco"
                  description="Número"
                  className="p-mb-4"
                  inputBody={
                    <InputText
                      id="numeroEndereco"
                      placeholder="Digite o número da casa, prédio ou condomínio"
                      value={numeroEndereco}
                      type="text"
                      onChange={(e) => {
                        setNumeroEndereco(e.target.value);
                        const errorsLocal = _.cloneDeep(errors);
                        delete errorsLocal.numeroEndereco;
                        setErrors(errorsLocal);
                      }}
                      maxLength={12}
                    />
                  }
                  required
                  errorMessage={errors.numeroEndereco}
                />
                <CustomVerticalFormField
                  icon="fas fa-route"
                  htmlForDescription="bairro"
                  description="Bairro"
                  className="p-mb-4"
                  inputBody={
                    <InputText
                      id="bairro"
                      placeholder="Digite o nome do bairro"
                      value={bairro}
                      type="text"
                      onChange={(e) => {
                        setBairro(e.target.value);
                        const errorsLocal = _.cloneDeep(errors);
                        delete errorsLocal.bairro;
                        setErrors(errorsLocal);
                      }}
                      maxLength={120}
                    />
                  }
                  required
                  errorMessage={errors.bairro}
                />
                <CustomVerticalFormField
                  icon="fas fa-house-user"
                  htmlForDescription="complemento"
                  description="Complemento"
                  className="p-mb-4"
                  inputBody={
                    <InputText
                      id="complemento"
                      placeholder="Ex.: Quadra, apartamento, etc."
                      value={complemento}
                      type="text"
                      onChange={(e) => {
                        setComplemento(e.target.value);
                        const errorsLocal = _.cloneDeep(errors);
                        delete errorsLocal.complemento;
                        setErrors(errorsLocal);
                      }}
                      maxLength={120}
                    />
                  }
                  errorMessage={errors.complemento}
                />
                <CustomVerticalFormField
                  icon="fas fa-map-marked-alt"
                  htmlForDescription="estado"
                  description="UF"
                  className="p-mb-4"
                  inputBody={
                    <Dropdown
                      id="estado"
                      value={estado}
                      emptyMessage="Nenhuma UF encontrada."
                      placeholder="Selecione uma UF"
                      options={ufOptions}
                      onChange={(e) => {
                        setEstado(e.target.value);
                        const errorsLocal = _.cloneDeep(errors);
                        delete errorsLocal.estado;
                        setErrors(errorsLocal);
                      }}
                    />
                  }
                  required
                  errorMessage={errors.estado}
                />
                <CustomVerticalFormField
                  icon="fas fa-map-marker-alt"
                  htmlForDescription="cidade"
                  description="Cidade"
                  className="p-mb-4"
                  inputBody={
                    <InputText
                      id="cidade"
                      placeholder="Digite o nome da cidade"
                      value={cidade}
                      type="text"
                      onChange={(e) => {
                        setCidade(e.target.value);
                        const errorsLocal = _.cloneDeep(errors);
                        delete errorsLocal.cidade;
                        setErrors(errorsLocal);
                      }}
                      maxLength={120}
                    />
                  }
                  required
                  errorMessage={errors.cidade}
                />
              </div>
            </div>
          )}
        </section>

        <div className="p-col-7 p-grid">
          <div className="p-fluid p-col-12">
            <div className="p-d-flex p-jc-between">
              <Button
                style={{ minWidth: "150px", maxWidth: "150px" }}
                onClick={() => confirmarCadastro()}
                disabled={loadingSalvar}
              >
                <span className="p-input-icon-left p-d-flex p-ai-center">
                  <span className="material-symbols-rounded p-mr-2">save</span>
                  <span>Salvar leitor</span>
                </span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </SimpleEntityPage>
  );
};

export default LeitoresFormPage;
