import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useHistory, useParams } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { confirmDialog } from "primereact/confirmdialog";
import { ColumnProps } from "primereact/column";
import SimpleEntityDatatable from "../../../../components/SimpleEntityDatatable";
import SimpleEntityListPage from "../../../../components/SimpleEntityPage";
import { useToast } from "../../../../hooks/toast";
import { Episodio, Temporada } from "../../types";
import api from "../../../../services/api";
import { convertStringBRToDate } from "../../../../utils/convertDates";

interface Params {
  id: string;
  temporadaId: string;
}

const EpisodioPage: React.FC = () => {
  const history = useHistory();
  const { id, temporadaId } = useParams<Params>();
  const { showToast } = useToast();

  const dt = useRef<DataTable>(null);

  const [loading, setLoading] = useState(false);
  const [globalFilter, setGlobalFilter] = useState<string>("");

  const [temporada, setTemporada] = useState({} as Temporada);
  const [episodios, setEpisodios] = useState<Episodio[]>([]);

  const loadData = useCallback(() => {
    api
      .get<Temporada>(`podcast/temporada/${temporadaId}`)
      .then(({ data }) => {
        setEpisodios(
          data.episodios?.map((ep) => {
            return { ...ep, publicationDate: convertStringBRToDate(ep.dataPublicacao) };
          }) || []
        );
        const temporadaFromRequest = { ...data, episodios: undefined };

        setTemporada(temporadaFromRequest);
      })
      .catch(() => {
        showToast({
          type: "error",
          title: "Não foi possível carregar os episódios",
          description: "Tente novamente ou contate nosso time de suporte para solicitar ajuda.",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [showToast, temporadaId]);

  const onGlobalFilterChange = useCallback((filterValue: string) => {
    setGlobalFilter(filterValue);
  }, []);

  const excluirEpisodio = useCallback(
    (data: Temporada) => {
      api
        .delete(`podcast/temporada/episodio/${data.uuid}`)
        .then(() => {
          showToast({ title: "Sucesso!", type: "success", description: "O episódio foi removido." });
          loadData();
        })
        .catch((err) => {
          showToast({
            title: "Não foi possível remover o episódio",
            type: "error",
            description: "Tente novamente, ou entre em contato com um administrador",
          });
          // eslint-disable-next-line no-console
          console.log(err.response);
        });
    },
    [loadData, showToast]
  );

  const confirmExclusao = useCallback(
    (data: Temporada) => {
      confirmDialog({
        message: "Após a remoção o item não poderá ser recuperado.",
        header: "Remover episódio?",
        icon: "pi pi-question-circle",
        focusOnShow: false,
        rejectLabel: "Manter",
        acceptLabel: "Remover",
        accept: () => excluirEpisodio(data),
        reject: () => {},
      });
    },
    [excluirEpisodio]
  );

  const editDeleteButtonsBodyTemplate = useCallback(
    (data: Temporada) => {
      return (
        <>
          <Button
            type="button"
            className="p-ml-2 balandrau-action-button"
            icon="pi pi-pencil"
            onClick={() => {
              history.push({ pathname: `/podcast/${id}/temporada/${temporadaId}/episodio/${data.uuid}` });
            }}
          />
          <Button
            type="button"
            className="p-ml-2 balandrau-exclude-button"
            icon="pi pi-trash"
            onClick={() => {
              confirmExclusao(data);
            }}
          />
        </>
      );
    },
    [confirmExclusao, history, id, temporadaId]
  );

  const episodiosColumns = useMemo(
    (): ColumnProps[] => [
      {
        field: "nome",
        header: "Nome",
        sortable: true,
        align: "left",
        style: { verticalAlign: "middle" },
        className: "p-text-nowrap p-text-truncate",
      },
      {
        field: "descricao",
        header: "Descrição",
        sortable: true,
        align: "left",
        style: { verticalAlign: "middle", maxWidth: "200px" },
        className: "p-text-nowrap p-text-truncate",
      },
      {
        field: "qtdVisualizacao",
        header: "Quantidade de vizualizações",
        sortable: true,
        align: "center",
        headerStyle: { maxWidth: "130px", textWrap: "wrap", lineHeight: "17px" },
        style: { verticalAlign: "middle", width: "130px" },
      },
      {
        field: "duracao",
        header: "Duração",
        sortable: true,
        align: "left",
        style: { verticalAlign: "middle", width: 100 },
        className: "p-text-nowrap p-text-truncate",
        body: ({ duracao }: Episodio) =>
          duracao
            .split(":")
            .map((d) => d.padStart(2, "0"))
            .join(":"),
      },
      {
        field: "dataPublicacao",
        header: "Data de publicação",
        sortable: true,
        sortField: "publicationDate",
        align: "center",
        style: { verticalAlign: "middle", width: 145, maxWidth: 145 },
      },
      {
        align: "center",
        header: "Ações",
        body: editDeleteButtonsBodyTemplate,
        style: { width: "100px", verticalAlign: "middle" },
      },
    ],
    [editDeleteButtonsBodyTemplate]
  );

  useEffect(() => {
    setLoading(true);
    loadData();
  }, [loadData]);

  return (
    <SimpleEntityListPage
      showListHeader
      onGlobalFilterChange={(value) => onGlobalFilterChange(value)}
      loading={loading}
      showExportCsvButton
      datatableRef={dt}
      newButtonLabel="Novo episódio"
      onNewButtonCLick={() => {
        history.push(`/podcast/${id}/temporada/${temporadaId}/episodio/novo`);
      }}
    >
      <div
        className="p-mb-4 p-grid"
        style={{ margin: 0, borderRadius: 7, minWidth: "100%", backgroundColor: "#404040", padding: 17 }}
      >
        <div className="p-col-2 p-d-flex p-flex-column">
          <span className="p-text-bold" style={{ color: "white" }}>
            Nº da temporada
          </span>
          <div className="p-mt-2">
            <span style={{ lineHeight: "16px" }}>{temporada.temporada?.toString().padStart(2, "0")}</span>
          </div>
        </div>

        <div className="p-col-2 p-d-flex p-flex-column">
          <span className="p-text-bold" style={{ color: "white" }}>
            Nome da temporada
          </span>
          <div className="p-mt-2">
            <span style={{ lineHeight: "16px" }}>{temporada.nome}</span>
          </div>
        </div>

        <div className="p-col p-d-flex p-flex-column">
          <span className="p-text-bold" style={{ color: "white" }}>
            Descrição da temporada
          </span>
          <div className="p-mt-2">
            <pre className="p-text-wrap" style={{ lineHeight: "16px", whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
              {temporada.descricao}
            </pre>
          </div>
        </div>
      </div>

      <SimpleEntityDatatable
        datatableRef={dt}
        value={episodios}
        columns={episodiosColumns}
        globalFilter={globalFilter}
        sortField="temporada"
        sortOrder={1}
      />
    </SimpleEntityListPage>
  );
};

export default EpisodioPage;
