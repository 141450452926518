/* eslint-disable no-unused-vars */
import { Button } from "primereact/button";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { useToast } from "../../hooks/toast";

export interface FileSelectProps {
  initialFile?: File;
  fileLabel?: string;
  accept: string;
  maxFileSize?: number; // em MB
  onFileChange: (file: File | undefined) => void;
}

const FileSelect: React.FC<FileSelectProps> = ({ initialFile, fileLabel, accept, maxFileSize = 10, onFileChange }) => {
  const [file, setFile] = useState<File | undefined>(initialFile);
  const inputRef = useRef<HTMLInputElement>(null);
  const { showToast } = useToast();

  const onButtonClick = () => {
    inputRef?.current?.click();
  };

  const onSelectedFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const fileSize = Number((e.target.files[0].size / 1024 / 1024).toFixed(4));
      if (fileSize < maxFileSize) {
        setFile(e.target.files[0]);
        onFileChange(e.target.files[0]);
      } else {
        showToast({
          type: "warn",
          title: "Ops...",
          description: `Arquivo excede o tamanho máximo permitido de ${maxFileSize}MB`,
        });
      }
    }
  };

  useEffect(() => {
    setFile(initialFile);
  }, [initialFile]);

  return (
    <div className="file-uploader-container">
      <input
        id="input-file"
        ref={inputRef}
        className="file-uploader-input-hidden"
        type="file"
        name="upload"
        accept={accept}
        onChange={onSelectedFileChange}
      />
      <Button
        type="button"
        label={file?.name || fileLabel || "Escolher"}
        icon={file ? "pi pi-upload" : "pi pi-plus"}
        onClick={onButtonClick}
      />
    </div>
  );
};

export default FileSelect;
