import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useHistory, useLocation, useParams } from "react-router-dom";
import HeaderFormProfano from "../../../components/HeaderFormProfano";
import FormProfanoEtapaSelecionada from "../../../components/FormProfanoEtapaSelecionada";
import EtapaAnexo from "./EtapaAnexo";
import EtapaFinalizacao from "./EtapaFinalizacao";
import EtapaForm from "./EtapaForm";
import EtapaLeitura from "./EtapaLeitura";
import { useProfano } from "../../../hooks/profano";

export type EtapaFormProfano = "LEITURA" | "FORM" | "ANEXO" | "FINALIZACAO";

export interface FormProfanoPageParams {
  etapa: EtapaFormProfano;
}

export interface FormProfanoPagePathParams {
  token: string;
}

const FormProfanoPage: React.FC = () => {
  const location = useLocation<FormProfanoPageParams>();
  const isFormFiliacao = useMemo(() => {
    return location.pathname.includes("proposta-filiacao");
  }, [location.pathname]);
  const history = useHistory();
  const { token } = useParams<FormProfanoPagePathParams>();

  const [etapa, setEtapa] = useState<EtapaFormProfano>();
  const { setAToken } = useProfano();

  const nextStepCallback = useCallback(() => {
    switch (etapa) {
      case "LEITURA": {
        setEtapa("FORM");
        window.scrollTo(0, 0);
        break;
      }
      case "FORM": {
        setEtapa("ANEXO");
        window.scrollTo(0, 0);
        break;
      }
      case "ANEXO": {
        setEtapa("FINALIZACAO");
        window.scrollTo(0, 0);
        break;
      }
      default: {
        break;
      }
    }
  }, [etapa]);

  useEffect(() => {
    setAToken(token);
    if (location.state && location.state.etapa) {
      setEtapa(location.state.etapa);
    } else if (isFormFiliacao) {
      history.push(`/convite/${token}`);
    } else {
      history.push(`/proposta-filiacao/${token}`);
    }
  }, [history, isFormFiliacao, location.state, setAToken, token]);

  return (
    <div className="profano-container">
      <HeaderFormProfano isFormFiliacao={isFormFiliacao} />
      {etapa && <FormProfanoEtapaSelecionada etapaSelecionada={etapa} />}

      {etapa === "LEITURA" && <EtapaLeitura nextStep={nextStepCallback} isFormFiliacao={isFormFiliacao} />}
      {etapa === "FORM" && <EtapaForm nextStep={nextStepCallback} isFormFiliacao={isFormFiliacao} />}
      {etapa === "ANEXO" && <EtapaAnexo nextStep={nextStepCallback} isFormFiliacao={isFormFiliacao} />}
      {etapa === "FINALIZACAO" && <EtapaFinalizacao nextStep={nextStepCallback} isFormFiliacao={isFormFiliacao} />}
    </div>
  );
};

export default FormProfanoPage;
