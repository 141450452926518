import { IAppError } from "./AppErrorInterfaces";

export const episodioErrors: IAppError[] = [
  {
    code: "Somente é aceito arquivos de audio e com suas devidas extensões",
    title: "Este arquivo não é do tipo 'áudio'.",
    message: "Selecione um arquivo de áudio e tente novamente.",
  },
  {
    code: "Temporada não encontrada",
    title: "A temporada não foi encontrada.",
    message: "Entre em contato com nosso time de suporte para solicitar ajuda.",
  },
  {
    code: "Episodio não encontrado",
    title: "O episódio não foi encontrado.",
    message: "Entre em contato com nosso time de suporte para solicitar ajuda.",
  },
];
