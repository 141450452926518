import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import _ from "lodash";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import SimpleEntityListPage from "../../../../components/SimpleEntityPage";
import CustomFormField from "../../../../components/CustomFormField";
import { Episodio, Temporada } from "../../types";
import FloatingSave from "../../../../components/FloatingSave";
import api from "../../../../services/api";
import { useToast } from "../../../../hooks/toast";
import { useError } from "../../../../hooks/error";
import { episodioErrors } from "../../../../errors/episodio";
import { formatDateToStringForSend } from "../../../../utils/formatDateToStringForSend";
import { convertStringBRToDate } from "../../../../utils/convertDates";
import FileSelect from "../../../../components/FileSelect";

interface Params {
  id: string;
  temporadaId: string;
  episodioId: string;
}

const EpisodioFormPage: React.FC = () => {
  const history = useHistory();

  const { id, temporadaId, episodioId } = useParams<Params>();

  const { handleError } = useError();
  const { showToast } = useToast();

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingSalvar, setLoadingSalvar] = useState(false);

  const [nome, setNome] = useState<string>("");
  const [descricao, setDescricao] = useState<string>("");
  const [dataPublicacaoFormatada, setDataPublicaoFormatada] = useState<string>(
    formatDateToStringForSend(new Date().toLocaleString("en-us")) || ""
  );
  const [dataPublicacao, setDataPublicacao] = useState<Date | Date[]>(new Date());
  const [file, setFile] = useState<File>();

  const [fileToReset, setFileToReset] = useState<File | undefined>();

  const [errors, setErrors] = useState<{ [name: string]: string }>({});

  const [initialEp, setInitialEp] = useState({} as Episodio);
  const [isDirty, setIsDirty] = useState<boolean>(false);

  const yearNavigatorTemplate = useCallback((e: any) => {
    return (
      <Dropdown
        value={e.value}
        options={e.options}
        onChange={(event) => e.onChange(event.originalEvent, event.value)}
        className="p-ml-2"
        style={{ lineHeight: 1 }}
      />
    );
  }, []);

  const monthNavigatorTemplate = useCallback((e: any) => {
    return (
      <Dropdown
        value={e.value}
        options={e.options}
        onChange={(event) => e.onChange(event.originalEvent, event.value)}
        style={{ lineHeight: 1 }}
      />
    );
  }, []);

  const redefinirEpisodio = useCallback(() => {
    setIsDirty(false);

    if (episodioId) {
      setNome(initialEp.nome);
      setDescricao(initialEp.descricao);
      setDataPublicacao(convertStringBRToDate(initialEp.dataPublicacao));
      setDataPublicaoFormatada(
        formatDateToStringForSend(convertStringBRToDate(initialEp.dataPublicacao).toLocaleString("en-us")) || ""
      );
    } else {
      setNome("");
      setDescricao("");
      setDataPublicacao(new Date());
      setDataPublicaoFormatada(formatDateToStringForSend(new Date().toLocaleString("en-us")) || "");
    }
    setFile({} as File);
    setFileToReset({} as File);
  }, [episodioId, initialEp.dataPublicacao, initialEp.descricao, initialEp.nome]);

  const episodioValido = useCallback(() => {
    const errorsLocal: { [campo: string]: string } = {};

    if (_.isEmpty(nome)) errorsLocal.nome = "Este é um campo obrigatório";
    if (_.isEmpty(descricao)) errorsLocal.descricao = "Este é um campo obrigatório";
    if (!dataPublicacao) errorsLocal.dataPublicacao = "Este é um campo obrigatório";
    if (!episodioId) if (!file) errorsLocal.file = "Este é um campo obrigatório";

    setErrors(errorsLocal);
    return _.isEmpty(errorsLocal);
  }, [dataPublicacao, descricao, episodioId, file, nome]);

  const salvarEpisodio = useCallback(() => {
    if (episodioValido()) {
      setLoadingSalvar(true);

      const formData = new FormData();

      formData.append("uuidTemporada", temporadaId);
      formData.append("dataPublicacao", dataPublicacaoFormatada);
      formData.append("nome", nome);
      formData.append("descricao", descricao);
      if (file) formData.append("file", file);

      if (episodioId) {
        api
          .put(`podcast/temporada/episodio/${episodioId}`, formData)
          .then(() => {
            showToast({
              title: "Sucesso!",
              type: "success",
              description: "O episódio foi atualizado.",
            });

            history.push(`/podcast/${id}/temporada/${temporadaId}/episodio`);
          })
          .catch((err) => {
            handleError({ error: err, action: "atualizar episodio", knownErrors: episodioErrors });
          })
          .finally(() => setLoadingSalvar(false));
      } else {
        api
          .post("podcast/temporada/episodio", formData)
          .then(() => {
            showToast({
              title: "Sucesso!",
              type: "success",
              description: "Um novo episódio foi adicionado à temporada.",
            });

            history.push(`/podcast/${id}/temporada/${temporadaId}/episodio`);
          })
          .catch((err) => {
            handleError({ error: err, action: "cadastrar episódio", knownErrors: episodioErrors });
          })
          .finally(() => setLoadingSalvar(false));
      }
    }
  }, [
    dataPublicacaoFormatada,
    descricao,
    episodioId,
    file,
    handleError,
    history,
    id,
    nome,
    showToast,
    temporadaId,
    episodioValido,
  ]);

  const loadData = useCallback(() => {
    api
      .get<Temporada>(`podcast/temporada/${temporadaId}`)
      .then(({ data }) => {
        const episodioFromRequest = data.episodios?.find((ep) => ep.uuid === episodioId);
        if (episodioFromRequest) {
          setInitialEp(episodioFromRequest);

          setNome(episodioFromRequest.nome);
          setDescricao(episodioFromRequest.descricao);
          setDataPublicacao(convertStringBRToDate(episodioFromRequest.dataPublicacao));
          setDataPublicaoFormatada(
            formatDateToStringForSend(
              convertStringBRToDate(episodioFromRequest.dataPublicacao).toLocaleString("en-us")
            ) || ""
          );
        }
      })
      .catch((err: any) => {
        handleError({ error: err, action: "cadastrar livro", knownErrors: episodioErrors });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [episodioId, handleError, temporadaId]);

  useEffect(() => {
    if (episodioId) {
      setLoading(true);
      loadData();
    }
  }, [temporadaId, loadData, episodioId]);

  return (
    <SimpleEntityListPage
      showTopBar
      routeBack={`/podcast/${id}/temporada/${temporadaId}/episodio`}
      isFormPage
      loading={loading}
    >
      <div className="p-grid p-flex-row p-jc-between">
        <div className="p-col-7 p-grid">
          <div className="p-fluid p-col-12">
            <CustomFormField
              htmlForDescription="nome"
              description="Nome"
              iconMaterial="mic"
              labelWidth="3"
              required
              inputBody={
                <InputText
                  id="nome"
                  value={nome}
                  placeholder="Digite o nome do episódio"
                  onChange={(e) => {
                    setNome(e.target.value);

                    const errorsLocal = _.cloneDeep(errors);
                    delete errorsLocal.nome;
                    setErrors(errorsLocal);

                    if (!_.isEqual(initialEp.nome, e.target.value)) {
                      setIsDirty(true);
                    } else {
                      setIsDirty(false);
                    }
                  }}
                />
              }
              errorMessage={errors.nome}
            />

            <CustomFormField
              htmlForDescription="descricao"
              description="Descrição"
              iconMaterial="subject"
              labelWidth="3"
              required
              inputBody={
                <InputTextarea
                  id="descricao"
                  value={descricao}
                  placeholder="Digite a descrição do episódio"
                  rows={5}
                  autoResize
                  onChange={(e) => {
                    setDescricao(e.target.value);

                    const errorsLocal = _.cloneDeep(errors);
                    delete errorsLocal.descricao;
                    setErrors(errorsLocal);

                    if (!_.isEqual(initialEp.descricao, e.target.value)) {
                      setIsDirty(true);
                    } else {
                      setIsDirty(false);
                    }
                  }}
                />
              }
              errorMessage={errors.descricao}
            />
            <CustomFormField
              iconMaterial="event"
              htmlForDescription="publicado-em"
              description="Data de publicação"
              inputBody={
                <Calendar
                  id="publicado-em"
                  placeholder="DD/MM/AAAA"
                  value={dataPublicacao}
                  onChange={(e) => {
                    if (e.target && e.target.value) {
                      setDataPublicaoFormatada(formatDateToStringForSend(e.target.value.toLocaleString("en-us")) || "");
                      setDataPublicacao(e.target.value);

                      if (episodioId) {
                        if (
                          !_.isEqual(
                            formatDateToStringForSend(
                              convertStringBRToDate(initialEp.dataPublicacao).toLocaleString("en-US")
                            ),
                            formatDateToStringForSend(e.target.value.toLocaleString("en-us"))
                          )
                        ) {
                          setIsDirty(true);
                        } else {
                          setIsDirty(false);
                        }
                      } else {
                        setIsDirty(true);
                      }
                    }
                    const errorsLocal = _.cloneDeep(errors);
                    delete errorsLocal.dataPublicacao;
                    setErrors(errorsLocal);
                  }}
                  monthNavigator
                  yearNavigator
                  yearRange="1800:2050"
                  monthNavigatorTemplate={monthNavigatorTemplate}
                  yearNavigatorTemplate={yearNavigatorTemplate}
                  locale="pt-br"
                  showIcon
                />
              }
              required
              errorMessage={errors.dataPublicacao}
            />
            <CustomFormField
              icon="pi pi-upload"
              htmlForDescription="file"
              description="Episódio"
              inputBody={
                <FileSelect
                  accept="audio/*"
                  fileLabel="Selecionar episódio"
                  maxFileSize={30}
                  initialFile={fileToReset}
                  onFileChange={(arquivo) => {
                    setFile(arquivo);
                    setFileToReset(arquivo);

                    const errorsLocal = _.cloneDeep(errors);
                    delete errorsLocal.file;
                    setErrors(errorsLocal);

                    setIsDirty(true);
                  }}
                />
              }
              required
              errorMessage={errors.file}
            />
          </div>
        </div>
      </div>
      {isDirty ? (
        <FloatingSave
          resetCommand={() => redefinirEpisodio()}
          disabled={loadingSalvar || !_.isEmpty(errors)}
          saveCommand={() => salvarEpisodio()}
          loadingOnSave={loadingSalvar}
        />
      ) : null}
    </SimpleEntityListPage>
  );
};

export default EpisodioFormPage;
