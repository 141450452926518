import { Button } from "primereact/button";
import React from "react";

interface ProfanoNextStepBarProps {
  message?: string;
  buttonLabel?: string;
  buttonIcon?: string;
  buttonDisabled?: boolean;
  loading?: boolean;
  onButtonClick?: () => void;
}

const ProfanoNextStepBar: React.FC<ProfanoNextStepBarProps> = ({
  // message = "",
  buttonLabel = "Próxima etapa",
  buttonIcon = "fas fa-arrow-right",
  buttonDisabled = false,
  loading = false,
  onButtonClick,
}) => {
  return (
    <div
      className="profano-next-step-container" // p-d-flex p-flex-row p-jc-end
    >
      <span className="profano-next-step-message">
        <p>Para avançar para a próxima etapa, preencha todos os campos da etapa atual.</p>
        {/* <p>(Lembre-se de conferir os dados que possam estar faltando em outras abas)</p> */}
      </span>
      <Button
        className="profano-next-step-button"
        label={buttonLabel}
        icon={buttonIcon}
        onClick={onButtonClick}
        loading={loading}
        disabled={buttonDisabled}
        iconPos="right"
      />
    </div>
  );
};

export default ProfanoNextStepBar;
