/* eslint-disable no-unused-vars */
import { Tag } from "primereact/tag";
import React from "react";

type FiliandoSituacaoComponentProps = {
  situacao?: SituacaoFiliando;
};
export type SituacaoFiliando =
  | "AGUARDANDO"
  | "LIDO"
  // | "SINDICANCIA"
  | "FILIADO"
  // | "ESCRUTINIO"
  | "DESISTENCIA"
  | "APROVADO"
  | "PAGO"
  | "REJEITADO";

export interface SituacaoFiliandoElement {
  label: string;
  class: string;
  situacao: SituacaoFiliando;
}
type SituacaoFiliandoMap = {
  [name in SituacaoFiliando]: SituacaoFiliandoElement;
};

export const situacoesFiliandoMap: SituacaoFiliandoMap = {
  AGUARDANDO: { label: "Aguardando", class: "situacao-filiando-aguardando", situacao: "AGUARDANDO" },
  LIDO: { label: "Lido", class: "situacao-filiando-lido", situacao: "LIDO" },
  // SINDICANCIA: { label: "Sindicância", class: "situacao-filiando-sindicancia", situacao: "SINDICANCIA" },
  FILIADO: { label: "Filiado", class: "situacao-filiando-iniciado", situacao: "FILIADO" },
  // ESCRUTINIO: {
  //   label: "Escrutínio",
  //   class: "situacao-filiando-escrutinio",
  //   situacao: "ESCRUTINIO",
  // },
  DESISTENCIA: { label: "Desistência", class: "situacao-filiando-desistencia", situacao: "DESISTENCIA" },
  APROVADO: {
    label: "Aprovado",
    class: "situacao-filiando-aprovado",
    situacao: "APROVADO",
  },
  REJEITADO: {
    label: "Rejeitado",
    class: "situacao-filiando-rejeitado",
    situacao: "REJEITADO",
  },
  PAGO: {
    label: "Jóia Paga",
    class: "situacao-filiando-pago",
    situacao: "PAGO",
  },
};

const FiliandoSituacaoComponent: React.FC<FiliandoSituacaoComponentProps> = ({ situacao }) => {
  return (
    <>
      {situacao && situacoesFiliandoMap[situacao] && (
        <Tag
          className={`${situacoesFiliandoMap[situacao].class}`}
          value={situacoesFiliandoMap[situacao].label}
          style={{ width: "100px" }}
        />
      )}
    </>
  );
};

export default FiliandoSituacaoComponent;
