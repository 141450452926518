import { IAppError } from "./AppErrorInterfaces";

export const iniciacaoProfanoErrors: IAppError[] = [
  {
    code: "Formulário vinculado com outro CPF.",
    title: "Formulário já está em uso.",
    message:
      "O CPF informado não pode ser utilizado neste formulário. Verifique o cpf ou solicite um novo link de proposta de iniciação.",
  },
  {
    code: "Formulário não encontrado.",
    title: "Formulário não existe ou expirou.",
    message: "Solicite um novo link de proposta de iniciação.",
  },
  {
    code: "É preciso informar um CPF.",
    title: "Informe um CPF válido.",
    message: "Verifique o CPF informado e tente novamente.",
  },
];
