import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { confirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { ColumnProps } from "primereact/column";
import { Button } from "primereact/button";
import { useHistory } from "react-router-dom";

import { Tag } from "primereact/tag";
import SimpleEntityListPage from "../../../components/SimpleEntityPage";
import SimpleEntityDatatable from "../../../components/SimpleEntityDatatable";
import { useToast } from "../../../hooks/toast";
import { useError } from "../../../hooks/error";
import api from "../../../services/api";
import { NotificacaoDTOList } from "../utils";
import { notificacaoErrors } from "../../../errors/notificacao";
import { convertDateToStringBR, removeOffsetFromDate } from "../../../utils/convertDates";

const NotificaoPage: React.FC = () => {
  const dt = useRef<DataTable>(null);

  const [loading, setLoading] = useState(false);

  const [notificacoes, setNotificacoes] = useState<NotificacaoDTOList[]>([]);
  const [globalFilter, setGlobalFilter] = useState("");

  const history = useHistory();
  const { showToast } = useToast();
  const { handleError } = useError();

  const loadData = useCallback(() => {
    setLoading(true);

    api
      .get<NotificacaoDTOList[]>("mensagens")
      .then(({ data }) => {
        data.map((notificacao) => {
          return {
            ...notificacao,
            dtConverted: convertDateToStringBR(`${removeOffsetFromDate(new Date(notificacao.dtAgendamento))}`),
          };
        });
        setNotificacoes(data);
      })
      .catch(() => {
        showToast({
          type: "error",
          title: "Não foi possível carregar as notificações",
          description: "Tente novamente ou contate nosso time de suporte para solicitar ajuda.",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [showToast]);

  const excluirNotificacao = useCallback(
    (data: NotificacaoDTOList) => {
      api
        .delete(`mensagens/${data.uuid}`)
        .then(() => {
          showToast({ title: "Sucesso!", type: "success", description: "A notificação foi removida." });
          loadData();
        })
        .catch((err) => {
          handleError({ error: err, action: "remover notificação", knownErrors: notificacaoErrors });
        });
    },
    [handleError, loadData, showToast]
  );

  const confirmExclusao = useCallback(
    (data: NotificacaoDTOList) => {
      confirmDialog({
        header: "Remover notificacao?",
        message: "Após a remoção o item não poderá ser recuperado.",
        icon: "pi pi-question-circle",
        focusOnShow: false,
        rejectLabel: "Manter",
        acceptLabel: "Remover",
        accept: () => excluirNotificacao(data),
        reject: () => {},
      });
    },
    [excluirNotificacao]
  );

  const onGlobalFilterChange = useCallback((filterValue) => {
    setGlobalFilter(filterValue);
  }, []);

  const dtAgendamentoBodyTemplate = useCallback((data: NotificacaoDTOList) => {
    const dateConverted = convertDateToStringBR(`${removeOffsetFromDate(new Date(data.dtAgendamento))}`);

    if (data.situacao === "PENDENTE") {
      return (
        <div className="p-d-flex p-jc-center date-converted-future">
          <i className="fa-solid fa-stopwatch p-mr-2 date-icon" />
          <p>{dateConverted}</p>
        </div>
      );
    }
    return <p className="date-converted-passed">{dateConverted}</p>;
  }, []);

  const situacaoNotificacaoBodyTemplate = useCallback((rowData: NotificacaoDTOList) => {
    if (rowData.situacao === "PENDENTE") {
      return <Tag className="situacao-notificacao notificacao-pendente">Pendente</Tag>;
    }
    if (rowData.situacao === "ENVIADO") {
      return <Tag className="situacao-notificacao notificacao-enviado">Enviado</Tag>;
    }
    return "";
  }, []);

  const editDeleteButtonsBodyTemplate = useCallback(
    (data: NotificacaoDTOList) => {
      return (
        <>
          <Button
            type="button"
            className="p-row-editor-init p-link "
            icon="pi pi-eye"
            tooltip="Detalhes da notificação"
            tooltipOptions={{ position: "left", style: { width: 100, textAlign: "center" } }}
            onClick={() => {
              history.push(`/notificacao/${data.uuid}`);
            }}
          />
          {data.situacao === "PENDENTE" && (
            <Button
              type="button"
              className="p-ml-3 balandrau-exclude-button"
              icon="pi pi-trash"
              tooltip="Remover notificação"
              tooltipOptions={{ position: "left", style: { width: 100, textAlign: "center" } }}
              onClick={() => {
                confirmExclusao(data);
              }}
            />
          )}
        </>
      );
    },
    [confirmExclusao, history]
  );

  const columnsNotificacoes = useMemo(
    (): ColumnProps[] => [
      {
        field: "situacao",
        header: "Situação",
        sortable: false,
        align: "center",
        style: { verticalAlign: "middle", width: 150 },
        body: situacaoNotificacaoBodyTemplate,
      },
      {
        field: "titulo",
        header: "Título",
        className: "p-text-nowrap p-text-truncate",
        style: { verticalAlign: "middle", width: 300, maxWidth: 300 },
        align: "left",
      },
      {
        field: "resumo",
        header: "Resumo",
        className: "p-text-nowrap p-text-truncate",
        style: { verticalAlign: "middle" },
        align: "left",
      },
      {
        field: "dtAgendamento",
        style: { verticalAlign: "middle", width: 150 },
        align: "center",
        body: dtAgendamentoBodyTemplate,
        exportable: false,
        hidden: true,
      },
      {
        field: "dtConverted",
        header: "Data",
        style: { verticalAlign: "middle", width: 150 },
        align: "center",
        body: dtAgendamentoBodyTemplate,
      },
      {
        body: editDeleteButtonsBodyTemplate,
        style: { width: "100px", textAlign: "center", verticalAlign: "middle" },
      },
    ],
    [dtAgendamentoBodyTemplate, editDeleteButtonsBodyTemplate, situacaoNotificacaoBodyTemplate]
  );

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <SimpleEntityListPage
      showListHeader
      onGlobalFilterChange={(value) => onGlobalFilterChange(value)}
      loading={loading}
      showExportCsvButton
      datatableRef={dt}
      newButtonLabel="Nova notificação"
      onNewButtonCLick={() => {
        history.push("/notificacao/novo");
      }}
    >
      <SimpleEntityDatatable
        datatableRef={dt}
        value={notificacoes}
        columns={columnsNotificacoes}
        globalFilter={globalFilter}
        sortField="dtAgendamento"
        sortOrder={-1}
      />
    </SimpleEntityListPage>
  );
};

export default NotificaoPage;
