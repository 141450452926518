/* eslint-disable no-unused-vars */
import { Button } from "primereact/button";
import React, { ChangeEvent, useRef, useState } from "react";
import { useToast } from "../../hooks/toast";

export interface EmptyPageComponentProps {
  icon?: string;
  title?: string;
  description?: string;
}

const EmptyPageComponent: React.FC<EmptyPageComponentProps> = ({
  icon = "pi pi-question",
  title = "Essa página está vazia.",
  description = "",
}) => {
  return (
    <div className="empty-page-container" style={{ height: "300px" }}>
      <i className={`empty-page-icon ${icon}`} />
      <span className="empty-page-title">{title}</span>
      <span className="empty-page-description">{description}</span>
    </div>
  );
};

export default EmptyPageComponent;
