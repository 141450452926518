export type SiglaIdioma = "br" | "en" | "es";

export interface IdiomaValue {
  placeholderSelecioneIdioma: string;
  tituloHomeAnos: string;
  tituloHomemMaconariaAmazonas: string;
  tituloHomemFuncacaoLoja: string;
  nomeImagemGlomam: string;
  siglaGlomam: string;
  nomeImagemLoja: string;
  homeConfirmacaoParticipacao: string;
  homeConviteParticipacao: string;
  homeTempoMaconariaAmazonas: string;
  homeLinkConfirmarPresenca: string;
  labelEmail: string;
  placeholderEmail: string;
  proximoPasso: string;
  programacao: string;
  recepcaoDelegacoes: string;
  palacioRioNegro: string;
  sessaoMagna: string;
  trajeMaconico: string;
  retornoHotel: string;
  passeioTuristico: string;
  teatroAmazonas: string;
  possePublicaGraoMestre: string;
  apresentacaoAutoridades: string;
  coquetel: string;
  hotelCassina: string;
  assembleiaZonaV: string;
  nomeLojaExtenso: string;
  caminhada: string;
  diamond: string;
  baileComemoracao: string;
  hospedagem: string;
  hotelQuality: string;
  enderecoHotelQuality: string;
  recomendacaoHotel: string;
  valorDiaria: string;
  superiorSingle: string;
  valorDiariaSingle: string;
  cortesia: string;
  superiorDuplo: string;
  valorDiariaDuplo: string;
  reserva: string;
  designacaoComitiva: string;
  formSiglaPotencia: string;
  formNomePotencia: string;
  exemploNomeCompleto: string;
  pais: string;
  exemploPais: string;
  indiqueComitiva: string;
  finalizar: string;
  nomeCompleto: string;
  placeholderDescricao: string;
  descricao: string;
  addMembro: string;
  obrigado: string;
  presencaComitivaConfirmada: string;
  possivelAlterarDados: string;
  confirmacaoAbrirComputador: string;
  erroEmailVinculadoOutroConvite: string;
  erroFacaLoginOutroEmail: string;
  erroConviteNaoEncontrado: string;
  erroConviteNaoEncontradoDescription: string;
  erroEmailEmUso: string;
  erroEmailEmUsoDescription: string;
  erroNaoFoiPossivelSubmeter: string;
  erroTenteNovamenteMaisTarde: string;
  erroNaoFoiPossivelCarregarInfos: string;
  voo: string;
  exemploVoo: string;
  companhia: string;
  exemploCompanhia: string;
  date: string;
}
type IdiomaMap = {
  // eslint-disable-next-line
  [sigla in SiglaIdioma]: IdiomaValue;
};

export const idiomasMap: IdiomaMap = {
  br: {
    placeholderSelecioneIdioma: "Selecione o Idioma",
    tituloHomeAnos: "150 ANOS",
    tituloHomemMaconariaAmazonas: "Maçonaria no Amazonas",
    tituloHomemFuncacaoLoja: "Fundação da Grande Benemérita Loja Simbólica Esperança e Porvir Nº 1",
    nomeImagemGlomam: "Grande Loja Maçônica do Amazonas",
    siglaGlomam: "GLOMAM",
    nomeImagemLoja: "Gr.`. Ben.`. Loj.`. Sim.`. Esperança e Porvir Nº 1",
    homeConfirmacaoParticipacao: "CONFIRMAÇÃO DE PARTICIPAÇÃO",
    homeConviteParticipacao: "Você foi convidado à participar do evento em comemoração aos",
    homeTempoMaconariaAmazonas: "150 anos da Maçonaria no Amazonas.",
    homeLinkConfirmarPresenca: "Por meio deste link você poderá confirmar a presença da comitiva da sua Potência.",
    labelEmail: "Insira seu e-mail",
    placeholderEmail: "Digite aqui o seu e-mail",
    proximoPasso: "Próximo passo",
    programacao: "PROGRAMAÇÃO",
    recepcaoDelegacoes: "Recepção das delegações e acomodação no Hotel Quality",
    palacioRioNegro: "PALÁCIO RIO NEGRO",
    sessaoMagna: "Sessão Magna de Aniversário da Loja Maçônica Esperança e Porvir Nº 1",
    trajeMaconico: "Traje Maçônico com Paramentos",
    retornoHotel: "Retorno ao Hotel",
    passeioTuristico: "Passeio turístico com as autoridades",
    teatroAmazonas: "TEATRO AMAZONAS",
    possePublicaGraoMestre: "Posse Pública do Grão-Mestre e Grão-Mestre Adjunto da GLOMAM",
    apresentacaoAutoridades: "Apresentações e Homenagens às autoridades presentes",
    coquetel: "Coquetel",
    hotelCassina: "HOTEL CASSINA",
    assembleiaZonaV: "Assembleia anual da Zona V da CMI",
    nomeLojaExtenso: "LOJA MAÇÔNICA ESPERANÇA E PORVIR Nº 1",
    caminhada: "Caminhada pelo Centro Histórico e Almoço",
    diamond: "DIAMOND CONVENTION CENTER",
    baileComemoracao:
      "Baile em comemoração aos 150 anos da Maçonaria no Amazonas e da fundação da Loja Maçônica Esperança e Provir Nº 1",
    hospedagem: "HOSPEDAGEM",
    hotelQuality: "HOTEL QUALITY MANAUS",
    enderecoHotelQuality: "Av. Mário Ypiranga, 1090 - Adrianópolis, Manaus - AM",
    recomendacaoHotel: "Recomendamos este hotel com conforto e ótima localização",
    valorDiaria: "VALOR DA DIÁRIA",
    superiorSingle: "SUPERIOR SINGLE",
    valorDiariaSingle: "R$280,00 + 12% de taxas",
    cortesia: "Cortesia: Café da Manhã servido no Restaurante",
    superiorDuplo: "SUPERIOR DUPLO",
    valorDiariaDuplo: "R$330,00 + 12% de taxas",
    reserva: "RESERVA",
    designacaoComitiva: "DESIGNAÇÃO DA COMITIVA",
    formSiglaPotencia: "Sigla da Potência",
    formNomePotencia: "Nome completo da Potência",
    exemploNomeCompleto: "Ex.: Grande Loja Maçônica do Amazonas",
    pais: "País",
    exemploPais: "Ex.: Brasil",
    indiqueComitiva: "INDIQUE A COMITIVA",
    finalizar: "Finalizar",
    nomeCompleto: "Nome completo",
    placeholderDescricao: "Ex.: Cargo, Esposa do Grão-Mestre, Grande Secretário Rel.`. Ext.`.",
    descricao: "Descrição",
    addMembro: "Adicionar membro",
    obrigado: "OBRIGADO!",
    presencaComitivaConfirmada:
      "A presença da comitiva da sua Potência foi confirmada para o evento em comemoração aos",
    possivelAlterarDados: "É possível alterar os dados acessando o link enviado no e-mail informado.",
    confirmacaoAbrirComputador: "Esta confirmação deve ser realizada a partir do computador",
    erroEmailVinculadoOutroConvite: "Este e-mail já está vinculado à outro convite.",
    erroFacaLoginOutroEmail: "Faça login com outro e-mail",
    erroConviteNaoEncontrado: "Convite não encontrado.",
    erroConviteNaoEncontradoDescription:
      "Convite não encontrado, recarregue a página e preencha o campo e-mail corretamente",
    erroEmailEmUso: "Este e-mail já está em uso.",
    erroEmailEmUsoDescription: "Convite vinculado à outro e-mail, tente modificar o e-mail e tentar novamente.",
    erroNaoFoiPossivelSubmeter: "Não foi possível submeter sua resposta.",
    erroTenteNovamenteMaisTarde: "Tente novamente mais tarde, ou entre em contato com os administradores",
    erroNaoFoiPossivelCarregarInfos: "Não foi possível carregar informações do convite",
    voo: "Número do voo",
    exemploVoo: "Ex.: GLO1743",
    companhia: "Companhia Aérea",
    exemploCompanhia: "Ex.: LATAM",
    date: "Chega em Manaus em",
  },
  en: {
    placeholderSelecioneIdioma: "Select the language",
    tituloHomeAnos: "150 YEARS",
    tituloHomemMaconariaAmazonas: "Freemasonry in Amazonas",
    tituloHomemFuncacaoLoja: "Founding of the Grande Benemérita Loja Simbólica Esperança e Porvir Lodge Nº 1",
    nomeImagemGlomam: "Grand Masonic Lodge of Amazonas",
    siglaGlomam: "GLOMAM",
    nomeImagemLoja: "Gr.`. Ben.`. Lod.`. Sim.`. Esperança e Porvir Nº 1",
    homeConfirmacaoParticipacao: "PRESENCE CONFIRMATION",
    homeConviteParticipacao: "You are invited to participate in the event in commemoration of the",
    homeTempoMaconariaAmazonas: "150 years of Freemasonry in Amazonas.",
    homeLinkConfirmarPresenca:
      "Through this link you can confirm the presence of your Grand Lodge delegation entourage.",
    labelEmail: "Enter your email",
    placeholderEmail: "Enter your email here",
    proximoPasso: "Next step",
    programacao: "SCHEDULE",
    recepcaoDelegacoes: "Reception of delegations and accommodation at the Hotel",
    palacioRioNegro: "PALÁCIO RIO NEGRO",
    sessaoMagna: "Magna Session of Anniversary of the Esperança e Porvir Masonic Lodge Nº 1",
    trajeMaconico: "Masonic costume and regalia",
    retornoHotel: "Return to the Hotel",
    passeioTuristico: "Sightseeing tour with the authorities",
    teatroAmazonas: "AMAZON OPERA HOUSE",
    possePublicaGraoMestre: "Grand Master's and Deputy Grand Master's Inauguration",
    apresentacaoAutoridades: "Presentations and Tributes to the authorities present",
    coquetel: "Cocktail",
    hotelCassina: "HOTEL CASSINA",
    assembleiaZonaV: "CMI Zone V Annual Assembly",
    nomeLojaExtenso: "ESPERANÇA E PORVIR MASONIC LODGE Nº 1",
    caminhada: "Walk through the Historic Center and Lunch",
    diamond: "DIAMOND CONVENTION CENTER",
    baileComemoracao:
      "Ball in celebration of the 150th anniversary of Freemasonry in Amazonas and the foundation of the Esperança e Provir Masonic Lodge Nº 1",
    hospedagem: "ACCOMMODATION",
    hotelQuality: "HOTEL QUALITY MANAUS",
    enderecoHotelQuality: "Av. Mário Ypiranga, 1090 – Adrianópolis, Manaus/AM",
    recomendacaoHotel: "We recommend this hotel with comfort and great location",
    valorDiaria: "DAILY VALUE",
    superiorSingle: "UPPER SINGLE",
    valorDiariaSingle: "BRL 280,00 + 12% of fees",
    cortesia: "Courtesy: Breakfast served at the Restaurant",
    superiorDuplo: "UPPER DOUBLE",
    valorDiariaDuplo: "BRL 330,00 + 12% of fees",
    reserva: "RESERVE",
    designacaoComitiva: "DESIGNATION OF THE COMMITTEE",
    formSiglaPotencia: "Acronym of Obedience",
    formNomePotencia: "Full name of Obedience",
    exemploNomeCompleto: "eg: Grand Masonic Lodge of the Amazonas",
    pais: "Country",
    exemploPais: "eg: Brazil",
    indiqueComitiva: "INDICATE THE COMMITTEE",
    finalizar: "Finalize",
    nomeCompleto: "Full name",
    placeholderDescricao: "eg: Position, Wife of the Grand Master, Grand Secretary For.`. Aff.`.",
    descricao: "Description",
    addMembro: "Add member",
    obrigado: "THANKS!",
    presencaComitivaConfirmada:
      "The presence of your Obedience entourage was confirmed for the event commemorating the",
    possivelAlterarDados: "It is possible to change the data by accessing the link sent in the email provided.",
    confirmacaoAbrirComputador: "This confirmation must be performed from the computer",
    erroEmailVinculadoOutroConvite: "This email is already linked to another invitation",
    erroFacaLoginOutroEmail: "Login with another email",
    erroConviteNaoEncontrado: "Invitation not found.",
    erroConviteNaoEncontradoDescription:
      "Invitation not found, please reload the page and fill in the email field correctly",
    erroEmailEmUso: "This email is already in use.",
    erroEmailEmUsoDescription: "Invitation linked to another email, try to modify the email and try again.",
    erroNaoFoiPossivelSubmeter: "Your response could not be submitted.",
    erroTenteNovamenteMaisTarde: "Please try again later, or contact the administrators",
    erroNaoFoiPossivelCarregarInfos: "Could not load invitation information",
    voo: "Flight number",
    exemploVoo: "eg: JBU2223",
    companhia: "Airline",
    exemploCompanhia: "eg.: LATAM",
    date: "Arrives in Manaus in",
  },
  es: {
    placeholderSelecioneIdioma: "Seleccione el idioma",
    tituloHomeAnos: "150 AÑOS",
    tituloHomemMaconariaAmazonas: "MASONERÍA EN AMAZONAS",
    tituloHomemFuncacaoLoja: "Fundación de la Gran Benemérita Logia Simbólica Esperança e Porvir Nº 1",
    nomeImagemGlomam: "Gran Logia Masónica del Amazonas",
    siglaGlomam: "GLOMAM",
    nomeImagemLoja: "Gr.`. Ben.`. Log.`. Sim.`. Esperança e Porvir Nº 1",
    homeConfirmacaoParticipacao: "CONFIRMACIÓN DE PARTICIPACIÓN",
    homeConviteParticipacao: "Usted fue invitado para participar del evento en conmemoración de los",
    homeTempoMaconariaAmazonas: "150 años de la Masonería en Amazonas.",
    homeLinkConfirmarPresenca:
      "A través de este link usted podrá confirmar la presencia de la comitiva de su Potencia.",
    labelEmail: "Insere su correo electrónico",
    placeholderEmail: "Introduzca su correo electrónico aquí",
    proximoPasso: "Próximo paso",
    programacao: "PROGRAMACIÓN",
    recepcaoDelegacoes: "Recepción de las delegaciones y alojamiento en el Hotel Quality",
    palacioRioNegro: "PALACIO RIO NEGRO",
    sessaoMagna: "Sesión Magna de Cumpleaños de la Logia Masonica Esperança e Porvir Nº 1 ",
    trajeMaconico: "Traje masónico con paramentos",
    retornoHotel: "Regreso al hotel",
    passeioTuristico: "Paseo turistico con las autoridades",
    teatroAmazonas: "TEATRO AMAZONAS",
    possePublicaGraoMestre: "Posesión pública de Gran Maestro y Gran Maestro Adjunto de la GLOMAM",
    apresentacaoAutoridades: "Presentaciones y homenajes a las autoridades presentes",
    coquetel: "Cóctel",
    hotelCassina: "HOTEL CASSINA",
    assembleiaZonaV: "Asemblea Anual de la Zona V de la CMI",
    nomeLojaExtenso: "LOGIA MASÓNICA ESPERANÇA E PORVIR Nº 1",
    caminhada: "Paseo por el Centro Histórico y Almuerzo",
    diamond: "DIAMOND CENTRO DE CONVENCIONES",
    baileComemoracao:
      "Baile conmemorativo de los 150 años de la Masonería en Amazonas y fundación de la Logia Masónica Esperança e Porvir Nº 1",
    hospedagem: "HOSPEDAJE",
    hotelQuality: "HOTEL QUALITY MANAUS",
    enderecoHotelQuality: "Av. Mário Ypiranga, 1090 – Adrianópolis, Manaus/AM",
    recomendacaoHotel: "Recomendamos este hotel con conforto y excelente ubicación",
    valorDiaria: "PRECIO DE LA DIARIA",
    superiorSingle: "SUPERIOR SINGLE",
    valorDiariaSingle: "BRL 280,00 + 12% de tasas",
    cortesia: "Cortesía: desayuno servido en el restaurante.",
    superiorDuplo: "SUPERIOR DUPLO",
    valorDiariaDuplo: "BRL 330,00 + 12% de tasas",
    reserva: "RESERVA",
    designacaoComitiva: "DESIGNACIÓN DEL COMITÉ",
    formSiglaPotencia: "acrónimo de la Potencia",
    formNomePotencia: "Nombre completo de la Potencia",
    exemploNomeCompleto: "Ej.: Gran Logia Masónica del Amazonas",
    pais: "País",
    exemploPais: "Ej.: Brasil",
    indiqueComitiva: "INDIQUE SU COMITIVA",
    finalizar: "Finalizar",
    nomeCompleto: "Nombre completo",
    placeholderDescricao: "Ej.: Cargo, Esposa del Gran Maestre, Gran Secretario de Rel.`. Ext.`.",
    descricao: "Descrición",
    addMembro: "Añadir miembro",
    obrigado: "¡GRACIAS!",
    presencaComitivaConfirmada: "Se confirmó la presencia de la comitiva de su Potencia para el acto conmemorativo del",
    possivelAlterarDados: "Es posible modificar los datos accediendo al enlace enviado en el correo facilitado.",
    confirmacaoAbrirComputador: "Esta confirmación debe realizarse desde el ordenador.",
    erroEmailVinculadoOutroConvite: "Este correo electrónico ya está vinculado a otra invitación.",
    erroFacaLoginOutroEmail: "Iniciar sesión con otro correo electrónico",
    erroConviteNaoEncontrado: "Invitación no encontrada.",
    erroConviteNaoEncontradoDescription:
      "No se encontró la invitación, vuelva a cargar la página y complete el campo de correo electrónico correctamente",
    erroEmailEmUso: "Este correo electrónico ya está en uso.",
    erroEmailEmUsoDescription:
      "Invitación vinculada a otro correo electrónico, intente modificar el correo electrónico y vuelva a intentarlo.",
    erroNaoFoiPossivelSubmeter: "No se pudo enviar su respuesta.",
    erroTenteNovamenteMaisTarde: "Vuelva a intentarlo más tarde o póngase en contacto con los administradores.",
    erroNaoFoiPossivelCarregarInfos: "No se pudo cargar la información de la invitación",
    voo: "Número de vuelo",
    exemploVoo: "Ej.: TAM8043",
    companhia: "Aerolínea",
    exemploCompanhia: "Ej.: LATAM",
    date: "Llega a Manaus en",
  },
};
