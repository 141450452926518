/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import _ from "lodash";
import { Button } from "primereact/button";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { validate } from "email-validator";
import { v4 } from "uuid";
import { Dropdown } from "primereact/dropdown";
import { useParams } from "react-router-dom";

import ProfanoDependentes from "../../../components/EXCLUIR/Dependentes";
import logoGlomam from "../../../assets/img/apagar/logo-glomam.png";
import logo150 from "../../../assets/img/apagar/logo-150-anos.png";
import Loading from "../../../components/Loading";
import publicApi from "../../../services/public-api";
import { useToast } from "../../../hooks/toast";

import background from "../../../assets/img/apagar/background.jpg";
import hotelQuality from "../../../assets/img/apagar/hotelQuality.png";
import wppSvg from "../../../assets/img/apagar/whatsApp.svg";
import br from "../../../assets/img/apagar/brasil.png";
import en from "../../../assets/img/apagar/english.png";
import es from "../../../assets/img/apagar/espanhol.png";
import { idiomasMap, SiglaIdioma } from "./idiomas";
import { removeOffsetFromDate } from "../../../utils/convertDates";

export interface Dependente {
  id: string;
  nome: string;
  descricao: string;
  numeroVoo: string;
  companhiaAerea: string;
  dtChegada?: Date | undefined;
}

interface InfoComitiva {
  email: string;
  potencia: string;
  siglaPotencia: string;
  pais: string;
  acompanhantes: Dependente[];
  idioma: SiglaIdioma;
}

interface Idiomas {
  bandeira: string;
  pais?: string;
  sigla: SiglaIdioma;
}

interface Params {
  uuid: string;
}

const ConviteGlomam: React.FC = () => {
  const [etapa, setEtapa] = useState<"HOME" | "PROGRAMACAO" | "HOSPEDAGEM" | "FORM" | "SUBMIT">("HOME");
  const [email, setEmail] = useState<string>("");

  const [lang, setLang] = useState<SiglaIdioma>("br");
  const idiomas = useMemo<Idiomas[]>(
    () => [
      { bandeira: br, pais: "Português", sigla: "br" },
      { bandeira: en, pais: "English", sigla: "en" },
      { bandeira: es, pais: "Español", sigla: "es" },
    ],
    []
  );
  const [idiomaSelecionado, setIdiomaSelecionado] = useState<Idiomas>({ bandeira: br, pais: "Português", sigla: "br" });

  const [loading, setLoading] = useState(false);
  const [loadingEdit, setLoadingEdit] = useState<boolean>(false);

  const [siglaPotencia, setSiglaPotencia] = useState<string>("");
  const [potencia, setPotencia] = useState<string>("");
  const [pais, setPais] = useState<string>("");

  const [acompanhantes, setAcompanhantes] = useState<Dependente[]>([
    { id: v4(), descricao: "", nome: "", companhiaAerea: "", dtChegada: undefined, numeroVoo: "" },
  ]);

  const { uuid } = useParams<Params>();

  const { showToast } = useToast();

  const replacePathRoute = useCallback(
    (sigla: SiglaIdioma) => {
      if (uuid) {
        window.history.replaceState({}, "", `/glomam/${uuid}?lang=${sigla}`);
      } else {
        window.history.replaceState({}, "", `/glomam?lang=${sigla}`);
      }
    },
    [uuid]
  );

  const onIdiomaChange = useCallback(
    (e: { value: Idiomas }) => {
      setLang(e.value.sigla);
      setIdiomaSelecionado(e.value);

      replacePathRoute(e.value.sigla);
    },
    [replacePathRoute]
  );

  const valueTemplate = (option: Idiomas, props: { placeholder: string }) => {
    if (option) {
      return (
        <div className="p-d-flex p-ai-center dropdown-option">
          <img alt={option.pais} src={option.bandeira} style={{ width: 32 }} className="p-mr-2" />
          <span>{option.sigla}</span>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const optionTemplate = (option: Idiomas) => {
    return (
      <div className="p-d-flex p-ai-center">
        <img alt={option.pais} src={option.bandeira} style={{ width: 32 }} className="p-mr-2" />
        <div>{option.pais}</div>
      </div>
    );
  };

  const onNewDependente = useCallback(
    (dependente: Dependente) => {
      const newDependentes = _.cloneDeep(acompanhantes);
      newDependentes.unshift(dependente);
      setAcompanhantes(newDependentes);
    },
    [acompanhantes]
  );

  const onDeleteDependente = useCallback(
    (dependenteId: string) => {
      const newDependentes = _.cloneDeep(acompanhantes);
      const indexFound = newDependentes.findIndex((dependente) => dependente.id === dependenteId);
      if (indexFound >= 0) {
        newDependentes.splice(indexFound, 1);
        setAcompanhantes(newDependentes);
      }
    },
    [acompanhantes]
  );

  const onUpdateDependente = useCallback(
    (dependenteId: string, dependente: Dependente) => {
      const newDependentes = _.cloneDeep(acompanhantes);
      const indexFound = newDependentes.findIndex((dep) => dep.id === dependenteId);
      if (indexFound >= 0) {
        newDependentes[indexFound] = dependente;
        setAcompanhantes(newDependentes);
      }
    },
    [acompanhantes]
  );

  const submitData = useCallback(() => {
    setLoading(true);
    acompanhantes.map((ac) => {
      // @ts-expect-error
      delete ac.id;
      return ac;
    });

    if (uuid) {
      publicApi
        .put(`registrar-resposta-convite-glomam/${uuid}`, {
          email,
          siglaPotencia,
          potencia,
          pais,
          acompanhantes,
          idioma: lang,
        })
        .then(() => {
          setEtapa("SUBMIT");
        })
        .catch((err) => {
          if (err.response) {
            showToast({
              title: idiomasMap[lang].erroNaoFoiPossivelSubmeter,
              description: idiomasMap[lang].erroTenteNovamenteMaisTarde,
              type: "error",
            });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      publicApi
        .post(`registrar-resposta-convite-glomam`, {
          email,
          siglaPotencia,
          potencia,
          pais,
          acompanhantes,
          idioma: lang,
        })
        .then(() => {
          setEtapa("SUBMIT");
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.data.titulo === "Este e-mail já está vinculado à outro convite.") {
              showToast({
                title: idiomasMap[lang].erroEmailVinculadoOutroConvite,
                description: idiomasMap[lang].erroFacaLoginOutroEmail,
                type: "warn",
              });
              return;
            }
            if (err.response.data.titulo === "Convite não encontrado.") {
              showToast({
                title: idiomasMap[lang].erroConviteNaoEncontrado,
                description: idiomasMap[lang].erroConviteNaoEncontradoDescription,
                type: "warn",
              });
              return;
            }
            if (err.response.data.titulo === "E-mail duplicado") {
              showToast({
                title: idiomasMap[lang].erroEmailEmUso,
                description: idiomasMap[lang].erroEmailEmUsoDescription,
                type: "warn",
              });
              return;
            }
            showToast({
              title: idiomasMap[lang].erroNaoFoiPossivelSubmeter,
              description: idiomasMap[lang].erroTenteNovamenteMaisTarde,
              type: "error",
            });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [showToast, email, siglaPotencia, potencia, pais, acompanhantes, lang, uuid]);

  const loadDataForEdit = useCallback(() => {
    setLoadingEdit(true);
    publicApi
      .get<InfoComitiva>(`registrar-resposta-convite-glomam/${uuid}`)
      .then(({ data }) => {
        setEmail(data.email);
        setSiglaPotencia(data.siglaPotencia);
        setPotencia(data.potencia);
        setPais(data.pais);
        data.acompanhantes.map((ac) => {
          if (ac.dtChegada) {
            // eslint-disable-next-line no-param-reassign
            ac.dtChegada = removeOffsetFromDate(new Date(`${ac.dtChegada}`));
          }

          return ac;
        });
        setAcompanhantes(data.acompanhantes);
        // setVoo(data.numeroVoo);
        // setCompanhia(data.companhiaAerea);
        // setDate(new Date(data.dtChegada));

        const idiomaEncontrado = idiomas.find((i) => i.sigla === data.idioma);
        if (idiomaEncontrado) setIdiomaSelecionado(idiomaEncontrado);
        if (data.idioma === "br" || data.idioma === "en" || data.idioma === "es") {
          setLang(data.idioma);
          replacePathRoute(data.idioma);
        }
      })
      .catch(() => {
        showToast({ title: idiomasMap[lang].erroNaoFoiPossivelCarregarInfos, type: "error" });
      })
      .finally(() => setLoadingEdit(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid, showToast, idiomas, replacePathRoute]);

  useEffect(() => {
    const root = document.getElementById("root");
    if (root) root.className = "background-color-convite-glomam";

    const sigla: string = window.location.search.replace("?lang=", "");
    const idiomaEncontrado = idiomas.find((i) => i.sigla === sigla);

    if (idiomaEncontrado) setIdiomaSelecionado(idiomaEncontrado);
    if (sigla === "br" || sigla === "en" || sigla === "es") setLang(sigla);

    if (uuid) {
      setEtapa("FORM");
      loadDataForEdit();
    }
  }, [idiomas, uuid, loadDataForEdit]);

  return (
    <div className="convite-glomam p-grid">
      <div className="info-nao-e-possivel-abrir p-offset-1 p-col-10">
        <div className="text-nao-e-possivel-abrir p-mb-3">
          <span>{idiomasMap[lang].tituloHomeAnos}</span>
          <span>{idiomasMap[lang].tituloHomemMaconariaAmazonas}</span>
        </div>
        <div className="text-sugestao-abrir">
          <span>{idiomasMap[lang].confirmacaoAbrirComputador}</span>
        </div>
        <div className="imagens-glomam-convite-glomam p-mt-auto p-d-flex p-flex-row">
          <div className="imagens-container-convite-glomam">
            <img src={logoGlomam} className="img-logo-convite" alt="Logo da GLOMAM" />
          </div>
          <div className="imagens-container-convite-glomam">
            <img src={logo150} className="img-logo-convite" alt="Logo de 150 anos da Loja número 1" />
          </div>
        </div>
      </div>
      <div style={{ position: "absolute", top: 20, right: 20, zIndex: 9999 }}>
        <Dropdown
          value={idiomaSelecionado}
          options={idiomas}
          onChange={onIdiomaChange}
          optionLabel="pais"
          placeholder={idiomasMap[lang].placeholderSelecioneIdioma}
          valueTemplate={valueTemplate}
          itemTemplate={optionTemplate}
        />
      </div>
      <img src={background} className="convite-glomam-background" alt="background" />
      {etapa === "HOME" && (
        <div className="content-page-convite-glomam p-col-11">
          <div className="content-page-left-convite-glomam p-col-5 p-offset-1">
            <div className="header-left-convite-glomam">
              <div className="header-top">
                <span className="header-title-convite-glomam">{idiomasMap[lang].tituloHomeAnos}</span>
                <span className="header-subtitle-convite-glomam">{idiomasMap[lang].tituloHomemMaconariaAmazonas}</span>
              </div>
              <div className="header-inferior">
                <span className="header-inferior-nome-loja">{idiomasMap[lang].tituloHomemFuncacaoLoja}</span>
              </div>
            </div>
            <div className="imagens-glomam-convite-glomam p-mt-auto">
              <div className="imagens-container-convite-glomam">
                <img src={logoGlomam} className="img-logo-convite" alt="Logo da GLOMAM" />
                <span>
                  {idiomasMap[lang].nomeImagemGlomam} <br /> {idiomasMap[lang].siglaGlomam}
                </span>
              </div>
              <div className="imagens-container-convite-glomam">
                <img src={logo150} className="img-logo-convite" alt="Logo de 150 anos da Loja número 1" />
                <span>{idiomasMap[lang].nomeImagemLoja}</span>
              </div>
            </div>
          </div>
          <div className="vl p-col-1" />
          <div className="content-page-right-convite-glomam p-col-5">
            <div className="top-right-content-convite-glomam">
              <div className="title-top-right-convite-glomam">
                <span className="color-amarelo">{idiomasMap[lang].homeConfirmacaoParticipacao}</span>
              </div>
              <div className="description-right-convite-glomam">
                {idiomasMap[lang].homeConviteParticipacao}{" "}
                <span className="color-amarelo">{idiomasMap[lang].homeTempoMaconariaAmazonas}</span>
              </div>
              <div className="description-right-convite-glomam">{idiomasMap[lang].homeLinkConfirmarPresenca}</div>
            </div>

            <div className="p-d-flex p-flex-column inputs-convite-glomam-horizontal p-my-auto">
              <label htmlFor="email">{idiomasMap[lang].labelEmail}</label>
              <input
                id="email"
                type="text"
                placeholder={`${idiomasMap[lang].placeholderEmail}`}
                className="p-fluid p-inputtext p-component"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </div>

            <div className="button-convite-glomam">
              <Button
                disabled={!validate(email)}
                label={`${idiomasMap[lang].proximoPasso}`}
                icon="pi pi-arrow-right"
                iconPos="right"
                onClick={() => {
                  setEtapa("PROGRAMACAO");
                }}
              />
            </div>
          </div>
        </div>
      )}
      {etapa === "PROGRAMACAO" && (
        <div className="container-form-etapa p-col-10 p-offset-1">
          <div className="convite-glomam etapa-programacao-convite-glomam p-pr-5 p-col-7">
            <div className="titulo-programacao-convite-glomam">
              <span>{idiomasMap[lang].programacao}</span>
            </div>

            <div className="container-atividade-convite-glomam">
              <div className="dt-atividade-convite-glomam">
                <span>05/10/2022</span>
              </div>
              <div className="container-horario-atividade">
                <span className="atividade-convite-glomam">{idiomasMap[lang].recepcaoDelegacoes}</span>
              </div>
            </div>

            <div className="container-atividade-convite-glomam">
              <div className="dt-atividade-convite-glomam">
                <span>06/10/2022</span>
              </div>
              <div className="local-atividade-convite-glomam p-mt-4">
                <span className="material-symbols-outlined p-mr-2">pin_drop</span>
                <span>{idiomasMap[lang].palacioRioNegro}</span>
              </div>
              <div className="container-horario-atividade">
                <span className="horario-convite-glomam">20:00h -</span>
                <span className="atividade-convite-glomam">
                  {idiomasMap[lang].sessaoMagna} <br /> {idiomasMap[lang].trajeMaconico}
                </span>
              </div>
              <div className="container-horario-atividade">
                <span className="horario-convite-glomam">23:30h -</span>
                <span className="atividade-convite-glomam">{idiomasMap[lang].retornoHotel}</span>
              </div>
            </div>

            <div className="container-atividade-convite-glomam">
              <div className="dt-atividade-convite-glomam">
                <span>07/10/2022</span>
              </div>
              <div className="container-horario-atividade">
                <span className="horario-convite-glomam">09:00h -</span>
                <span className="atividade-convite-glomam">{idiomasMap[lang].passeioTuristico}</span>
              </div>
              <div className="local-atividade-convite-glomam p-mt-4">
                <span className="material-symbols-outlined p-mr-2">pin_drop</span>
                <span>{idiomasMap[lang].teatroAmazonas}</span>
              </div>
              <div className="container-horario-atividade">
                <span className="horario-convite-glomam">20:00h -</span>
                <span className="atividade-convite-glomam">
                  {idiomasMap[lang].possePublicaGraoMestre} <br /> {idiomasMap[lang].apresentacaoAutoridades}
                </span>
              </div>
              <div className="container-horario-atividade">
                <span className="horario-convite-glomam">22:00h -</span>
                <span className="atividade-convite-glomam">{idiomasMap[lang].coquetel}</span>
              </div>
              <div className="container-horario-atividade">
                <span className="horario-convite-glomam">23:30h -</span>
                <span className="atividade-convite-glomam">{idiomasMap[lang].retornoHotel}</span>
              </div>
            </div>

            <div className="container-atividade-convite-glomam">
              <div className="dt-atividade-convite-glomam">
                <span>08/10/2022</span>
              </div>
              <div className="local-atividade-convite-glomam p-mt-4">
                <span className="material-symbols-outlined p-mr-2">pin_drop</span>
                <span>{idiomasMap[lang].hotelCassina}</span>
              </div>
              <div className="container-horario-atividade">
                <span className="horario-convite-glomam">09:00h -</span>
                <span className="atividade-convite-glomam">{idiomasMap[lang].assembleiaZonaV}</span>
              </div>
              <div className="local-atividade-convite-glomam p-mt-4">
                <span className="material-symbols-outlined p-mr-2">pin_drop</span>
                <span>{idiomasMap[lang].nomeLojaExtenso}</span>
              </div>
              <div className="container-horario-atividade">
                <span className="horario-convite-glomam">14:00h -</span>
                <span className="atividade-convite-glomam">{idiomasMap[lang].caminhada}</span>
              </div>
              <div className="local-atividade-convite-glomam p-mt-4">
                <span className="material-symbols-outlined p-mr-2">pin_drop</span>
                <span>{idiomasMap[lang].diamond}</span>
              </div>
              <div className="container-horario-atividade">
                <span className="horario-convite-glomam">20:00h -</span>
                <span className="atividade-convite-glomam">{idiomasMap[lang].baileComemoracao}</span>
              </div>
            </div>
          </div>
          <div className="button-convite-glomam" style={{ marginBottom: 50 }}>
            <Button
              label={`${idiomasMap[lang].proximoPasso}`}
              icon="pi pi-arrow-right"
              iconPos="right"
              onClick={() => setEtapa("HOSPEDAGEM")}
            />
          </div>
        </div>
      )}
      {etapa === "HOSPEDAGEM" && (
        <div className="container-form-etapa p-col-10 p-offset-1">
          <div className="convite-glomam etapa-hospedagem-convite-glomam p-pr-5 p-col-7">
            <div className="titulo-hospedagem-convite-glomam">
              <span>{idiomasMap[lang].hospedagem}</span>
            </div>

            <div className="hospedagem-container-atividade-convite-glomam">
              <div className="hospedagem-local-atividade-convite-glomam p-mt-4">
                <span className="material-symbols-outlined p-mr-2">pin_drop</span>
                <span>{idiomasMap[lang].hotelQuality}</span>
              </div>
            </div>

            <div className="hospedagem-endereco-convite-glomam p-my-2">
              <span>{idiomasMap[lang].enderecoHotelQuality}</span>
            </div>

            <div className="hospedagem-recomendacao-convite-glomam p-mb-2">
              <span>{idiomasMap[lang].recomendacaoHotel}</span>
            </div>

            <img className="p-mb-5" src={hotelQuality} alt="Fotos do Hotel Quality Manaus" />

            <div className="hospedagem-valores">
              <div className="hospedagem-header p-mb-3">
                <span>{idiomasMap[lang].valorDiaria}</span>
              </div>

              <div className="hospedagem-ofertas">
                <div className="hospedagem-column">
                  <span className="hospedagem-oferta-titulo p-mb-2">{idiomasMap[lang].superiorSingle}</span>
                  <span className="hospedagem-oferta-descricao">
                    {idiomasMap[lang].valorDiariaSingle} <br />
                    {idiomasMap[lang].cortesia}
                  </span>
                </div>
                <div className="hospedagem-column">
                  <span className="hospedagem-oferta-titulo p-mb-2">{idiomasMap[lang].superiorDuplo}</span>
                  <span className="hospedagem-oferta-descricao">
                    {idiomasMap[lang].valorDiariaDuplo} <br />
                    {idiomasMap[lang].cortesia}
                  </span>
                </div>
              </div>
            </div>

            <div className="hospedagem-reserva p-mt-4">
              <div className="hospedagem-reserva-header p-mb-2">
                <span>{idiomasMap[lang].reserva}</span>
              </div>

              <div
                className="hospedagem-reserva-container-whatsapp"
                onClick={() => {
                  window.open("https://api.whatsapp.com/send?phone=5592993506384", "_blank");
                }}
              >
                <img className="hospedagem-reserva-wpp" src={wppSvg} alt="logo do whatsApp" />

                <div className="hospedagem-reserva-numero-container">
                  <div className="hospedagem-reserva-numero p-mb-2">
                    <span>+55 (92) 99350-6384</span>
                  </div>
                  <div className="hospedagem-reserva-nome">
                    <span>MÁRCIA DUTRA</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="button-convite-glomam" style={{ marginBottom: 50 }}>
            <Button
              label={`${idiomasMap[lang].proximoPasso}`}
              icon="pi pi-arrow-right"
              iconPos="right"
              onClick={() => setEtapa("FORM")}
            />
          </div>
        </div>
      )}
      {etapa === "FORM" && (
        <div className="container-form-etapa p-col-10 p-offset-1" style={{ flex: 1 }}>
          {loadingEdit ? (
            <Loading isLoading={loadingEdit} size={24} color="#fff" />
          ) : (
            <div className="convite-glomam etapa-hospedagem-convite-glomam">
              <div className="p-fluid p-col-12">
                <div className="p-d-flex p-flex-column">
                  <div className="header-inputs">
                    <span>{idiomasMap[lang].designacaoComitiva}</span>
                  </div>

                  <div className="input-form-container">
                    <div className="p-d-flex p-flex-column inputs-convite-glomam-horizontal-label-branco">
                      <label htmlFor="potencia-sigla">{idiomasMap[lang].formSiglaPotencia}</label>
                      <input
                        id="potencia-sigla"
                        type="text"
                        placeholder="Ex.: GLOMAM"
                        className="p-fluid p-inputtext p-component"
                        style={{ maxWidth: 600 }}
                        value={siglaPotencia}
                        onChange={(e) => {
                          setSiglaPotencia(e.target.value);
                        }}
                      />
                    </div>

                    <div className="p-d-flex p-flex-column inputs-convite-glomam-horizontal-label-branco">
                      <label htmlFor="potencia-nome">{idiomasMap[lang].formNomePotencia}</label>
                      <input
                        id="potencia-nome"
                        type="text"
                        placeholder={`${idiomasMap[lang].exemploNomeCompleto}`}
                        className="p-fluid p-inputtext p-component"
                        style={{ maxWidth: 600 }}
                        value={potencia}
                        onChange={(e) => {
                          setPotencia(e.target.value);
                        }}
                      />
                    </div>

                    <div className="p-d-flex p-flex-column inputs-convite-glomam-horizontal-label-branco">
                      <label htmlFor="pais">{idiomasMap[lang].pais}</label>
                      <input
                        id="pais"
                        type="text"
                        placeholder={`${idiomasMap[lang].exemploPais}`}
                        className="p-fluid p-inputtext p-component"
                        style={{ maxWidth: 600 }}
                        value={pais}
                        onChange={(e) => {
                          setPais(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="header-inputs p-pt-5 p-mb-0">
                  <span>{idiomasMap[lang].indiqueComitiva}</span>
                </div>
                <div className="input-vertical-convite-glomam">
                  <ProfanoDependentes
                    selectedLanguage={lang}
                    dependentes={acompanhantes}
                    onAdd={onNewDependente}
                    onRemove={onDeleteDependente}
                    onUpdate={onUpdateDependente}
                  />
                </div>
              </div>
              <div className="button-convite-glomam">
                <Button
                  disabled={
                    loading ||
                    _.isEmpty(siglaPotencia) ||
                    _.isEmpty(potencia) ||
                    _.isEmpty(pais) ||
                    _.isEmpty(acompanhantes) ||
                    (acompanhantes.length > 0 && _.isEmpty(acompanhantes[0].descricao)) ||
                    (acompanhantes.length > 0 && _.isEmpty(acompanhantes[0].nome))
                  }
                  style={{ width: 165, height: 40 }}
                  onClick={() => submitData()}
                  iconPos="right"
                >
                  {!loading && (
                    <>
                      <span>{idiomasMap[lang].finalizar}</span>
                      <i className="p-ml-auto pi pi-arrow-right" />
                    </>
                  )}
                  {loading && <Loading isLoading={loading} size={10} color="#fff" />}
                </Button>
              </div>
            </div>
          )}
        </div>
      )}
      {etapa === "SUBMIT" && (
        <div className="container-form-etapa p-d-flex p-offset-1 p-col-7" style={{ flex: 1 }}>
          <div className="convite-glomam etapa-programacao-convite-glomam">
            <div className="titulo-programacao-convite-glomam">
              <span>{idiomasMap[lang].obrigado}</span>
            </div>
            <div className="agradecimento-convite-glomam">
              <span>
                {idiomasMap[lang].presencaComitivaConfirmada}{" "}
                <span className="color-amarelo">{`${idiomasMap[lang].homeTempoMaconariaAmazonas}`}</span>
              </span>
              <br />
              <br />
              <span>{`${idiomasMap[lang].possivelAlterarDados}`}</span>
            </div>
            <div className="imagens-glomam-convite-glomam p-mt-auto">
              <div className="imagens-container-convite-glomam">
                <img src={logoGlomam} className="img-logo-convite" alt="Logo da GLOMAM" />
                <span>
                  {idiomasMap[lang].nomeImagemGlomam} <br /> {idiomasMap[lang].siglaGlomam}
                </span>
              </div>
              <div className="imagens-container-convite-glomam">
                <img src={logo150} className="img-logo-convite" alt="Logo de 150 anos da Loja número 1" />
                <span>{idiomasMap[lang].nomeImagemLoja}</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ConviteGlomam;
