import { Avatar } from "primereact/avatar";
import _ from "lodash";
import { Button } from "primereact/button";
import { cpf as cpfValidator } from "cpf-cnpj-validator";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { InputMask } from "primereact/inputmask";
import CustomVerticalFormField from "../../../components/CustomVerticalFormField";
import HeaderFormProfano from "../../../components/HeaderFormProfano";
import Loading from "../../../components/Loading";
import api from "../../../services/api";
import { EtapaFormProfano } from "../FormProfanoPage";
import ErrorPageComponent from "../../../components/ErrorPageComponent";
import { useError } from "../../../hooks/error";
import { iniciacaoProfanoErrors } from "../../../errors/iniciacao-profano";
import { useProfano } from "../../../hooks/profano";
import { formFiliacaoErrors } from "../../../errors/form-filiacao";

export interface ProfanoPageParams {
  token: string;
}

interface LojaData {
  uuid: string;
  logo: string;
  nome: string;
  numero: string;
}

interface IrmaoLojaResponse {
  padrinho: {
    uuid: string;
    nome: string;
  };
  loja: LojaData;
}

interface LojaResponse {
  loja: LojaData;
}

interface IrmaoLojaLoginResponse {
  etapa: EtapaFormProfano;
}

const ProfanoSignInPage: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [loadingSignIn, setLoadingSignIn] = useState(false);
  const location = useLocation();
  const isFormFiliacao = useMemo(() => {
    return location.pathname.includes("proposta-filiacao");
  }, [location.pathname]);
  const { token } = useParams<ProfanoPageParams>();
  const [nomeIrmao, setNomeIrmao] = useState("");
  const [logoLoja, setLogoLoja] = useState("");
  const [dadosLoja, setDadosLoja] = useState<LojaData>();
  const [credential, setCredential] = useState("");
  const [cpfError, setCpfError] = useState("");
  const [pageError, setPageError] = useState(false);

  const { handleError } = useError();
  const history = useHistory();
  const { saveField, setAToken } = useProfano();

  const loadData = useCallback(() => {
    setLoading(true);
    api
      .get<IrmaoLojaResponse>(`formularios/profano/login/${token}`)
      .then(({ data }) => {
        setNomeIrmao(data.padrinho.nome);
        setLogoLoja(data.loja.logo);
        setDadosLoja(data.loja);
      })
      .catch((err) => {
        handleError({
          error: err,
          action: "acessar o formulário",
          knownErrors: iniciacaoProfanoErrors,
        });
        setPageError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [handleError, token]);

  const loadDataFiliacao = useCallback(() => {
    setLoading(true);
    api
      .get<LojaResponse>(`formularios/filiando/login/${token}`)
      .then(({ data }) => {
        setLogoLoja(data.loja.logo);
        setDadosLoja(data.loja);
      })
      .catch((err) => {
        handleError({
          error: err,
          action: "acessar o formulário",
          knownErrors: formFiliacaoErrors,
        });
        setPageError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [handleError, token]);

  const validForm = useCallback((): boolean => {
    if (!credential || _.isEmpty(credential.trim())) {
      setCpfError("Preencha todos os dígitos do CPF.");
      return false;
    }

    if (!cpfValidator.isValid(credential)) {
      setCpfError("Informe um CPF válido.");
      return false;
    }

    return true;
  }, [credential]);

  const handleSigninButtonClick = useCallback(() => {
    if (validForm()) {
      setLoadingSignIn(true);
      const postUrl = isFormFiliacao ? `formularios/filiando/login/${token}` : `formularios/profano/login/${token}`;
      api
        .post<IrmaoLojaLoginResponse>(postUrl, { cpf: credential })
        .then(({ data }) => {
          saveField("cpf", credential);
          const urlSignedIn = isFormFiliacao ? `/proposta-filiacao/${token}/form` : `/convite/${token}/form`;
          history.push(urlSignedIn, { etapa: data.etapa });
        })
        .catch((err) => {
          handleError({
            error: err,
            action: "acessar o formulário",
            knownErrors: iniciacaoProfanoErrors,
          });
        })
        .finally(() => {
          setLoadingSignIn(false);
        });
    }
  }, [credential, handleError, history, isFormFiliacao, saveField, token, validForm]);

  useEffect(() => {
    setAToken(token);
    if (isFormFiliacao) {
      loadDataFiliacao();
    } else {
      loadData();
    }
  }, [isFormFiliacao, loadData, loadDataFiliacao, setAToken, token]);

  return (
    <div className="profano-container">
      {loading ? (
        <Loading isLoading />
      ) : (
        <>
          {pageError && (
            <ErrorPageComponent
              code="#@$"
              message="URL inválida ou expirada. Solicite uma nova URL."
              backgroundImg=""
            />
          )}
          {!pageError && (
            <>
              <HeaderFormProfano isFormFiliacao={isFormFiliacao} />
              <div className="p-grid profano-signin-content">
                <div className="p-col-6 profano-signin-content-left">
                  <Avatar
                    className="p-mb-5"
                    shape="circle"
                    style={{ width: "150px", height: "auto" }}
                    size="xlarge"
                    image={logoLoja}
                  />
                  {!isFormFiliacao && !!nomeIrmao && (
                    <span className="profano-signin-text">
                      {/* Você foi indicado pelo irmão <span className="profano-signin-highlight-text">{nomeIrmao}</span>... */}
                      Você foi convidado por <span className="profano-signin-highlight-text">{nomeIrmao}</span> para
                      ingressar na Loja {dadosLoja?.nome}, jurisdicionada à Grande Loja Maçônica do Amazonas.
                    </span>
                  )}
                  <span className="profano-signin-text p-mt-3">
                    Para continuar você deverá preencher um formulário e anexar alguns documentos (confira na lista ao
                    lado).
                  </span>
                  <span className="profano-signin-highlight-text p-bold p-mt-3">
                    Para o preenchimento, utilize o computador.
                  </span>
                  <span className="profano-signin-highlight-text p-mt-5 p-mb-4 p-bold">
                    Informe seu CPF para começar.
                  </span>
                  <CustomVerticalFormField
                    description="CPF"
                    htmlForDescription="cpf"
                    errorMessage={cpfError}
                    required
                    icon="pi pi-user"
                    inputBody={
                      <InputMask
                        value={credential}
                        onChange={(e) => {
                          setCredential(e.target.value);
                          setCpfError("");
                        }}
                        placeholder="XXX.XXX.XXX-XX"
                        mask="999.999.999-99"
                      />
                    }
                  />
                  <Button
                    className="p-mt-5 profano-signin-button"
                    label="Começar a responder"
                    icon="pi pi-user"
                    onClick={() => handleSigninButtonClick()}
                    loading={loadingSignIn}
                  />
                </div>
                <div className="profano-signin-content-right">
                  <span className="profano-signin-highlight-text p-bold">
                    Documentos necessários para o procedimento:
                  </span>

                  <ul className="profano-signin-list">
                    {isFormFiliacao && <li className="profano-signin-list-item">Quit Placet da loja de Origem</li>}
                    <li className="profano-signin-list-item">
                      Cópias de cédula de identidade, CPF e título de eleitor. <br /> (Os três documentos devem estar no
                      mesmo arquivo PDF)
                    </li>
                    <li className="profano-signin-list-item">
                      Atestado médico, comprovando não ser portador de doença infecto-contagiosa
                    </li>
                    <li className="profano-signin-list-item">Atestado médico de sanidade física e mental</li>
                    <li className="profano-signin-list-item">Certidão negativa criminal (Justiça Federal)</li>
                    <li className="profano-signin-list-item">Certidão negativa criminal (Justiça Estadual)</li>
                    <li className="profano-signin-list-item">Foto 3x4 (paletó e gravata pretos, camisa branca)</li>
                    <li className="profano-signin-list-item">Declaração de subsistência</li>
                    <li className="profano-signin-list-item">
                      Um resumo da sua vida, de{" "}
                      <span className="profano-signin-highlight-text p-bold">próprio punho</span>, contando passagens
                      importantes, sua situação familiar, profissional
                      {isFormFiliacao
                        ? " e seus costumes. "
                        : ", seus costumes e as razões que o levaram a querer ingressar na maçonaria. "}
                      <br />{" "}
                      <span className="profano-signin-highlight-text p-bold">
                        Não são válidos documentos feitos no computador. O texto deve ter no máximo 1 página.
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default ProfanoSignInPage;
