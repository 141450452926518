import { Button } from "primereact/button";
import React, { ReactNode } from "react";
import { useHistory } from "react-router-dom";

export interface ErrorPageProps {
  code: string;
  message: string;
  backgroundImg: ReactNode;
  showActionButton?: boolean;
  actionButtonLabel?: string;
  actionButtionCallback?: () => void;
}

const ErrorPageComponent: React.FC<ErrorPageProps> = ({
  code,
  message,
  backgroundImg,
  actionButtonLabel,
  actionButtionCallback,
  showActionButton = false,
}) => {
  const { goBack } = useHistory();

  return (
    <div className="error-page">
      <div className="error-top-container">
        <div className="error-page-horizontal-line" />
      </div>
      <div className="error-status-background">{code}</div>
      <div className="error-modal-container">
        <div className="error-modal-horizontal-line" />
        <div className="error-card-container">
          <div className="error-message-container">
            <div className="error-message">{message}</div>
            {showActionButton && (
              <Button
                className="p-button-raised error-action-button"
                label={actionButtonLabel || "Voltar"}
                onClick={() => {
                  if (actionButtionCallback) {
                    actionButtionCallback();
                  } else if (!actionButtonLabel) {
                    goBack();
                  }
                }}
              />
            )}
          </div>
        </div>
      </div>
      <div className="error-background-img">{backgroundImg}</div>
    </div>
  );
};

export default ErrorPageComponent;
