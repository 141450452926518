import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Button } from "primereact/button";
import { ColumnProps } from "primereact/column";
import { confirmDialog } from "primereact/confirmdialog";
import { MultiSelect } from "primereact/multiselect";

import { useHistory } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import _ from "lodash";
import { Dropdown } from "primereact/dropdown";
import api from "../../../services/api";
import { useToast } from "../../../hooks/toast";
import { cobrancaErrors } from "../../../errors/cobranca";
import SimpleEntityDatatable from "../../../components/SimpleEntityDatatable";
import SimpleEntityListPage from "../../../components/SimpleEntityPage";
import { convertDateToStringBR } from "../../../utils/convertDates";
import SituacaoCobranca, {
  CobrancaSituacaoElement,
  SituacaoCobrancaTipo,
  situacoesCobrancaMap,
} from "../../../components/SituacaoCobranca";
import { useError } from "../../../hooks/error";
import { numberToReal } from "../../../utils/numbers";
import { FormaPagamento, formaPagamentoEParcelasString } from "../utils";
import { TipoCobranca, TipoCobrancaElement, tiposCobrancaMap } from "../CobrancaFormPage";
import { TurmaProfano } from "../../Profano/ProfanoFormPage";

export interface Cobranca {
  uuid: string;
  situacao: SituacaoCobrancaTipo;
  nome: string;
  turma: string;
  dtVencimento: number | string;
  valor: number;
  descricao?: string;
  tipo: TipoCobranca;
  dtVencimentoStr?: string;
  visualizado?: boolean;
  formaPagamento?: FormaPagamento;
  parcelas?: number;
}

const CobrancaPage: React.FC = () => {
  const dt = useRef<DataTable>(null);
  const [loading, setLoading] = useState(false);
  const situacaoFilterOptions: CobrancaSituacaoElement[] = useMemo((): CobrancaSituacaoElement[] => {
    return [
      situacoesCobrancaMap.PENDENTE,
      situacoesCobrancaMap.VENCIDO,
      situacoesCobrancaMap.ESTORNADO,
      // situacoesCobrancaMap.ERRO,
      // situacoesCobrancaMap.GERADO,
      situacoesCobrancaMap.PAGO,
      situacoesCobrancaMap.APROVADO,
      situacoesCobrancaMap.ANISTIADO,
    ];
  }, []);

  const tipoFilterOptions: TipoCobrancaElement[] = useMemo((): TipoCobrancaElement[] => {
    return [
      tiposCobrancaMap.ANUIDADE,
      tiposCobrancaMap.ANUIDADE_GLOMAM,
      tiposCobrancaMap.COTA,
      tiposCobrancaMap.GRANDE_TRONCO,
      tiposCobrancaMap.JOIA,
      tiposCobrancaMap.MENSALIDADE,
      tiposCobrancaMap.MENSALIDADE_GLOMAM,
    ];
  }, []);
  const [situacaoFilter, setSituacaoFilter] = useState<CobrancaSituacaoElement[]>([]);
  const [tipoFilter, setTipoFilter] = useState<TipoCobrancaElement[]>([]);
  const [turmaFilter, setTurmaFilter] = useState<string>("Todos");
  const [cobrancas, setCobrancas] = useState<Cobranca[]>([]);
  const [turmas, setTurmas] = useState<TurmaProfano[]>([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const { showToast } = useToast();
  const { handleError } = useError();
  const history = useHistory();

  const onFilterChange = useCallback((selectedList) => {
    dt.current?.filter(selectedList, "situacao", "custom");
    setSituacaoFilter(selectedList);
  }, []);

  const onFilterTipoChange = useCallback((selectedList) => {
    dt.current?.filter(selectedList, "tipo", "custom");
    setTipoFilter(selectedList);
  }, []);

  const loadTurmas = useCallback(() => {
    setLoading(true);
    api
      .get<TurmaProfano[]>(`dropdown/turmas`)
      .then(({ data }) => {
        const todosOption = { uuid: "", nome: "Todos", dtIniciacao: "" };
        const newData = [todosOption, ...data];
        setTurmas(newData);
      })
      .catch((err) => {
        showToast({
          type: "error",
          title: "Não foi possível carregar as turmas",
          description: "Tente novamente ou contate nosso time de suporte para solicitar ajuda.",
        });
        // eslint-disable-next-line no-console
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [showToast]);

  const loadData = useCallback(() => {
    setLoading(true);
    api
      .get<Cobranca[]>(`cobrancas`)
      .then(({ data }) => {
        data.forEach((cobranca) => {
          if (cobranca.dtVencimento) {
            // eslint-disable-next-line no-param-reassign
            cobranca.dtVencimentoStr = convertDateToStringBR(cobranca.dtVencimento);
          }
        });
        setCobrancas(data);
      })
      .catch((err) => {
        showToast({
          type: "error",
          title: "Não foi possível carregar as cobranças",
          description: "Tente novamente ou contate nosso time de suporte para solicitar ajuda.",
        });
        // eslint-disable-next-line no-console
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [showToast]);

  useEffect(() => {
    loadData();
    loadTurmas();
  }, [loadData, loadTurmas, onFilterChange]);

  useEffect(() => {
    onFilterChange([
      situacoesCobrancaMap.PENDENTE,
      situacoesCobrancaMap.VENCIDO,
      situacoesCobrancaMap.ESTORNADO,
      // situacoesCobrancaMap.ERRO,
      // situacoesCobrancaMap.GERADO,
      // situacoesCobrancaMap.PAGO,
      // situacoesCobrancaMap.CANCELADO,
    ]);
  }, [onFilterChange, loading]);

  useEffect(() => {
    onFilterTipoChange([
      // tiposCobrancaMap.ANUIDADE,
      // tiposCobrancaMap.ANUIDADE_GLOMAM,
      // tiposCobrancaMap.COTA,
      // tiposCobrancaMap.GRANDE_TRONCO,
      // tiposCobrancaMap.JOIA,
      // tiposCobrancaMap.MENSALIDADE,
      // tiposCobrancaMap.MENSALIDADE_GLOMAM,
    ]);
  }, [onFilterTipoChange, loading]);

  const excluirCobranca = useCallback(
    (data: Cobranca) => {
      api
        .delete(`cobrancas/${data.uuid}`)
        .then(() => {
          showToast({ title: "Sucesso!", type: "success", description: "A cobrança foi removida." });
          loadData();
        })
        .catch((err) => {
          handleError({ error: err, action: "remover cobranca", knownErrors: cobrancaErrors });
          // eslint-disable-next-line no-console
          console.log(err.response);
        });
    },
    [handleError, loadData, showToast]
  );

  const confirmExclusao = useCallback(
    (data: Cobranca) => {
      confirmDialog({
        message: "Após a remoção o item não poderá ser recuperado.",
        header: "Remover cobrança?",
        icon: "pi pi-question-circle",
        focusOnShow: false,
        rejectLabel: "Manter",
        acceptLabel: "Remover",
        accept: () => excluirCobranca(data),
        reject: () => {},
      });
    },
    [excluirCobranca]
  );

  const editDeleteButtonsBodyTemplate = useCallback(
    (data: Cobranca) => {
      return (
        <>
          <Button
            type="button"
            className="balandrau-action-button"
            icon="pi pi-pencil"
            onClick={() => {
              history.push({ pathname: `/cobranca/${data.uuid}` });
            }}
          />
          <Button
            type="button"
            className="p-ml-3 balandrau-exclude-button"
            icon="pi pi-trash"
            onClick={() => {
              confirmExclusao(data);
            }}
          />
        </>
      );
    },
    [confirmExclusao, history]
  );

  const valorBodyTemplate = useCallback(({ valor }: Cobranca) => {
    return numberToReal(valor);
  }, []);

  const situacaoBodyTemplate = useCallback(({ situacao, visualizado }: Cobranca) => {
    return <SituacaoCobranca situacao={situacao} visualizado={visualizado} />;
  }, []);

  const tipoBodyTemplate = useCallback(({ tipo }: Cobranca) => {
    return tiposCobrancaMap[tipo].label;
  }, []);

  const formaPagamentoBodyTemplate = useCallback(({ formaPagamento, parcelas }: Cobranca) => {
    return formaPagamentoEParcelasString(formaPagamento, parcelas);
  }, []);

  const vencimentoBodyTemplate = useCallback(({ dtVencimento }: Cobranca) => {
    return dtVencimento
      ? new Date(dtVencimento).toLocaleDateString("pt-BR", {
          timeZone: "UTC",
        })
      : "";
  }, []);

  const filterBySituacao = useCallback((value: SituacaoCobrancaTipo, filter: CobrancaSituacaoElement[]) => {
    if (value) return filter == null || _.isEmpty(filter) || filter.filter((f) => f.situacao === value).length > 0;
    return false;
  }, []);

  const filterByTipo = useCallback((value: TipoCobranca, filter: TipoCobrancaElement[]) => {
    if (value) return filter == null || _.isEmpty(filter) || filter.filter((f) => f.tipo === value).length > 0;
    return false;
  }, []);

  const columnsCobrancas = useMemo(
    (): ColumnProps[] => [
      {
        field: "situacao",
        header: "Situação",
        sortable: true,
        style: { verticalAlign: "middle", width: "5%" },
        body: situacaoBodyTemplate,
        filterMatchMode: "custom",
        filterFunction: (value, filter) => filterBySituacao(value, filter),
      },
      {
        field: "nome",
        header: "Irmão",
        sortable: true,
        style: { textAlign: "left", verticalAlign: "middle", width: "25%" },
      },
      {
        field: "dtVencimentoStr",
        sortField: "dtVencimento",
        header: "Vencimento",
        sortable: true,
        align: "center",
        style: { verticalAlign: "middle" },
        body: vencimentoBodyTemplate,
      },
      {
        field: "valor",
        header: "Valor",
        sortable: true,
        align: "right",
        style: { verticalAlign: "middle" },
        body: valorBodyTemplate,
      },
      {
        field: "descricao",
        header: "Descrição",
        sortable: true,
        style: { textAlign: "left", verticalAlign: "middle" },
      },
      {
        field: "tipo",
        header: "Tipo",
        sortable: true,
        style: { textAlign: "left", verticalAlign: "middle" },
        body: tipoBodyTemplate,
        filterFunction: (value, filter) => filterByTipo(value, filter),
      },
      {
        field: "formaPagamento",
        header: "Forma de Pagamento",
        sortable: true,
        style: { textAlign: "left", verticalAlign: "middle" },
        body: formaPagamentoBodyTemplate,
      },
      {
        align: "center",
        body: editDeleteButtonsBodyTemplate,
        style: { verticalAlign: "middle" },
      },
    ],
    [
      editDeleteButtonsBodyTemplate,
      filterBySituacao,
      filterByTipo,
      formaPagamentoBodyTemplate,
      situacaoBodyTemplate,
      tipoBodyTemplate,
      valorBodyTemplate,
      vencimentoBodyTemplate,
    ]
  );

  const situacaoFilterBodyTemplate = (
    <MultiSelect
      showClear
      dataKey="situacao"
      optionLabel="label"
      options={situacaoFilterOptions}
      value={situacaoFilter}
      onChange={(e) => {
        onFilterChange(e.target.value);
      }}
      placeholder="Filtrar por situação"
      selectedItemsLabel="{0} situações selecionadas"
      maxSelectedLabels={2}
      emptyFilterMessage="Nenhuma situação encontrada"
      style={{ width: "180px" }}
      scrollHeight="400px"
    />
  );

  const tipoFilterBodyTemplate = (
    <MultiSelect
      showClear
      dataKey="tipo"
      optionLabel="label"
      options={tipoFilterOptions}
      value={tipoFilter}
      onChange={(e) => {
        onFilterTipoChange(e.target.value);
      }}
      placeholder="Filtrar por tipo"
      maxSelectedLabels={2}
      selectedItemsLabel="{0} situações selecionadas"
      emptyFilterMessage="Nenhum tipo encontrado"
      style={{ width: "180px" }}
      scrollHeight="400px"
    />
  );

  const turmaFilterBodyTemplate = (
    <Dropdown
      // showClear
      optionLabel="nome"
      options={turmas}
      value={turmaFilter}
      optionValue="nome"
      onChange={(e) => {
        setTurmaFilter(e.target.value);
      }}
      placeholder="Filtrar por turma"
      emptyFilterMessage="Nenhuma turma encontrada"
      style={{ width: "180px" }}
      scrollHeight="400px"
    />
  );

  const filteredData = cobrancas.filter((cobranca) => {
    return turmaFilter === "Todos" || undefined ? cobrancas : turmaFilter?.includes(cobranca.turma);
  });

  return (
    <SimpleEntityListPage
      showListHeader
      newButtonLabel="Nova cobrança"
      onNewButtonCLick={() => {
        history.push("/cobranca/novo");
      }}
      onGlobalFilterChange={(value) => setGlobalFilter(value)}
      loading={loading}
      listHeaderContentRight={situacaoFilterBodyTemplate}
      secondFilterRight={turmaFilterBodyTemplate}
      thirdFilterRight={tipoFilterBodyTemplate}
      // listHeaderContentLeft={turmaFilterBodyTemplate}
      showExportCsvButton
      datatableRef={dt}
    >
      <SimpleEntityDatatable
        datatableRef={dt}
        value={filteredData}
        columns={columnsCobrancas}
        globalFilter={globalFilter}
        sortField="situacao"
        sortOrder={1}
      />
    </SimpleEntityListPage>
  );
};

export default CobrancaPage;
