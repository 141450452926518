import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { ColumnProps } from "primereact/column";

import { useHistory } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { confirmDialog } from "primereact/confirmdialog";
import { Tag } from "primereact/tag";
import { useToast } from "../../../../hooks/toast";
import { useError } from "../../../../hooks/error";
import api from "../../../../services/api";
import SimpleEntityListPage from "../../../../components/SimpleEntityPage";
import SimpleEntityDatatable from "../../../../components/SimpleEntityDatatable";
import { leitoresErrors } from "../../../../errors/leitores";
import { formatStringToCelular } from "../../../../utils/formatStringToCelular";

export interface VinculoElement {
  label: string;
  class: string;
  vinculo: Vinculo;
}
export type Vinculo = "IRMAO" | "EXTERNO";
type VinculoMap = {
  // eslint-disable-next-line no-unused-vars
  [name in Vinculo]: VinculoElement;
};

export const vinculoMap: VinculoMap = {
  IRMAO: {
    label: "Irmão",
    class: "tag-vinculo-leitor vinculo-irmao",
    vinculo: "IRMAO",
  },
  EXTERNO: {
    label: "Externo",
    class: "tag-vinculo-leitor vinculo-externo",
    vinculo: "EXTERNO",
  },
};

interface Endereco {
  cep: string;
  rua: string;
  numero: string;
  bairro: string;
  complemento: string;
  cidade: string;
  estado: string;
}

export interface Leitor {
  uuid: string;
  codigo: string;
  nome: string;
  documentoIdentificacao: string;
  email: string;
  vinculo: Vinculo;
  celular?: string;
  telefone?: string;
  endereco?: Endereco;
}

const LeitoresPage: React.FC = () => {
  const dt = useRef<DataTable>(null);
  const [loading, setLoading] = useState(false);
  const [leitores, setLeitores] = useState<Leitor[]>([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const { showToast } = useToast();
  const history = useHistory();
  const { handleError } = useError();

  const loadData = useCallback(() => {
    setLoading(true);
    api
      .get<Leitor[]>(`biblioteca/leitor`)
      .then(({ data }) => {
        setLeitores(data);
      })
      .catch((err) => {
        showToast({
          type: "error",
          title: "Não foi possível carregar os leitores",
          description: "Tente novamente ou contate nosso time de suporte para solicitar ajuda.",
        });
        // eslint-disable-next-line no-console
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [showToast]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const excluirLeitor = useCallback(
    (data: Leitor) => {
      api
        .delete(`biblioteca/leitor/${data.uuid}`)
        .then(() => {
          showToast({ title: "Sucesso!", type: "success", description: "O Leitor foi removido." });
          loadData();
        })
        .catch((err) => {
          handleError({ error: err, action: "remover leitor", knownErrors: leitoresErrors });
          // eslint-disable-next-line no-console
          console.log(err.response);
        });
    },
    [handleError, loadData, showToast]
  );

  const confirmExclusao = useCallback(
    (data: Leitor) => {
      confirmDialog({
        message: "Após a remoção o item não poderá ser recuperado.",
        header: "Remover leitor?",
        icon: "pi pi-question-circle",
        focusOnShow: false,
        rejectLabel: "Manter",
        acceptLabel: "Remover",
        accept: () => excluirLeitor(data),
        reject: () => {},
      });
    },
    [excluirLeitor]
  );

  const editDeleteButtonsBodyTemplate = useCallback(
    (data: Leitor) => {
      return (
        <>
          <Button
            type="button"
            className="balandrau-action-button"
            icon="pi pi-pencil"
            onClick={() => {
              history.push({ pathname: `/leitor/${data.uuid}` });
            }}
          />
          <Button
            type="button"
            className="p-ml-3 balandrau-exclude-button"
            icon="pi pi-trash"
            onClick={() => {
              confirmExclusao(data);
            }}
          />
        </>
      );
    },
    [history, confirmExclusao]
  );

  const vinculoBodyTemplate = useCallback((data: Leitor) => {
    if (data.vinculo) {
      return <Tag className={vinculoMap[data.vinculo].class}>{vinculoMap[data.vinculo].label}</Tag>;
    }
    return ``;
  }, []);

  const celularBodyTemplate = useCallback((data: Leitor) => {
    if (data.celular) {
      const cellWithOutFormatter = data.celular.replaceAll("(", "").replaceAll(")", "").replaceAll("-", "");
      return formatStringToCelular(cellWithOutFormatter);
    }
    return ``;
  }, []);

  const columns = useMemo(
    (): ColumnProps[] => [
      {
        field: "codigo",
        header: "Código",
        align: "center",
        sortable: false,
        style: { verticalAlign: "middle", width: "115px" },
      },
      {
        field: "nome",
        header: "Nome",
        sortable: true,
        style: { textAlign: "left", verticalAlign: "middle" },
        className: "p-text-nowrap p-text-truncate",
      },
      {
        field: "documentoIdentificacao",
        header: "Nº do Documento de Identificação",
        sortable: false,
        style: { textAlign: "left", verticalAlign: "middle", width: "18%" },
        className: "p-text-nowrap p-text-truncate",
      },
      {
        field: "email",
        header: "E-mail",
        sortable: false,
        style: { textAlign: "left", verticalAlign: "middle", width: "20%" },
        className: "p-text-nowrap p-text-truncate",
      },
      {
        field: "celular",
        header: "Celular",
        sortable: false,
        style: { textAlign: "left", verticalAlign: "middle", width: "130px" },
        className: "p-text-nowrap p-text-truncate",
        body: celularBodyTemplate,
      },
      {
        field: "vinculo",
        header: "Exemplares",
        sortable: false,
        align: "center",
        style: { verticalAlign: "middle", textAlign: "center", width: "120px" },
        body: vinculoBodyTemplate,
      },
      {
        align: "center",
        body: editDeleteButtonsBodyTemplate,
        style: { width: "100px", verticalAlign: "middle" },
      },
    ],
    [editDeleteButtonsBodyTemplate, vinculoBodyTemplate, celularBodyTemplate]
  );

  return (
    <SimpleEntityListPage
      showListHeader
      newButtonLabel="Cadastrar leitor"
      onNewButtonCLick={() => {
        history.push("/leitor/novo");
      }}
      onGlobalFilterChange={(value) => setGlobalFilter(value)}
      loading={loading}
      showExportCsvButton
      datatableRef={dt}
    >
      <SimpleEntityDatatable
        datatableRef={dt}
        value={leitores}
        columns={columns}
        globalFilter={globalFilter}
        sortField="nome"
        sortOrder={1}
      />
    </SimpleEntityListPage>
  );
};

export default LeitoresPage;
//
