import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import SignatureCanvas from "react-signature-canvas";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import SimpleEntityPage from "../../../components/SimpleEntityPage";
import { FormProfanoPagePathParams } from "../FormProfanoPage";
import api from "../../../services/api";
import Loading from "../../../components/Loading";

const SignDocumentPage: React.FC = () => {
  const [displaySignatureCanvas, setDisplaySignatureCanvas] = useState(false);
  const [pdfBlob, setPdfBlob] = useState<Blob | null>(null);
  const [pdfObjectURL, setPdfObjectURL] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const sigCanvasRef = useRef<SignatureCanvas | null>(null);

  const { token } = useParams<FormProfanoPagePathParams>();

  const getDeclaracaoSubsistencia = async () => {
    const isMobile = false;
    const downloadUrl = `/pdf/download/subsistencia/${token}?isMobile=${isMobile}`;
    try {
      const response = await api.get(downloadUrl, {
        responseType: "blob",
      });

      setPdfBlob(new Blob([response.data], { type: "application/pdf" }));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Erro ao buscar o documento:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDeclaracaoSubsistencia();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (pdfBlob) {
      // eslint-disable-next-line no-console
      setPdfObjectURL(URL.createObjectURL(pdfBlob));
    }
  }, [pdfBlob]);

  useEffect(() => {
    return () => {
      if (pdfObjectURL) {
        URL.revokeObjectURL(pdfObjectURL);
      }
    };
  }, [pdfObjectURL]);

  const signatureDialogHeader = useCallback(() => {
    return (
      <h1 className="default-dialog-header" style={{ minWidth: 400 }}>
        Assine aqui
      </h1>
    );
  }, []);

  const onHideSignatureDialog = () => {
    setDisplaySignatureCanvas(false);
  };

  const handleConfirmSignature = async () => {
    if (sigCanvasRef.current) {
      const signatureDataUrl = sigCanvasRef.current.getTrimmedCanvas().toDataURL("image/png");
      localStorage.setItem("@Assinado", "true");

      interface Body {
        assinatura: string;
        tipo: string;
        isMobile: boolean;
        situacao: string;
      }

      const body: Body = {
        assinatura: signatureDataUrl,
        tipo: "DECLARACAO_SUBSISTENCIA",
        situacao: "SUBMETIDO",
        isMobile: false,
      };

      try {
        setLoading(true);

        const url = `/formularios/profano/assinatura?token=${token}`;

        const response = await api.post(url, body);
        setLoading(false);
        return response;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error("Erro ao enviar a requisição:", error);
        setLoading(false);
      } finally {
        setDisplaySignatureCanvas(false);
        window.close();
      }
    }
    return null;
  };

  const handleClearSignature = () => {
    if (sigCanvasRef.current) {
      sigCanvasRef.current.clear();
    }
  };

  const signatureDialogFooter = () => {
    return (
      <div className="p-mt-3">
        <Button label="Cancelar" icon="pi pi-times" onClick={onHideSignatureDialog} className="p-button-text" />
        <Button
          label="Limpar"
          icon="pi pi-sync"
          onClick={handleClearSignature}
          style={{ width: "120px", marginRight: 32 }}
        />

        <Button
          label="Confirmar"
          icon="pi pi-check"
          onClick={handleConfirmSignature}
          style={{ width: "120px", marginRight: 32 }}
          autoFocus
        />
      </div>
    );
  };

  useEffect(() => {
    // eslint-disable-next-line no-console
  }, [pdfObjectURL]);

  if (loading) {
    return <Loading isLoading />;
  }

  return (
    <SimpleEntityPage showTopBar showListHeader hidePagePadding>
      <Dialog
        header={signatureDialogHeader}
        visible={displaySignatureCanvas}
        modal
        footer={signatureDialogFooter()}
        onHide={onHideSignatureDialog}
        className="confirm-dialog-container"
        closable
        style={{ minWidth: "600px" }}
      >
        <div className="signature-container">
          <SignatureCanvas
            ref={sigCanvasRef}
            penColor="black"
            canvasProps={{ width: 600, height: 300, className: "sigCanvas" }}
            backgroundColor="white"
          />
        </div>
      </Dialog>
      <Button
        label="Assinar Documento"
        onClick={(event) => {
          event.preventDefault();
          setDisplaySignatureCanvas(true);
        }}
        style={{ width: "500px", marginRight: 32, alignSelf: "center" }}
        autoFocus
      />
      {pdfObjectURL && (
        <iframe src={pdfObjectURL} style={{ width: "100%", height: "100%", marginTop: "20px" }} title="Documento PDF" />
      )}
    </SimpleEntityPage>
  );
};

export default SignDocumentPage;
