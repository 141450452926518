import { Tag } from "primereact/tag";
import React, { useState } from "react";
import { v4 } from "uuid";
import { AnexoSituacao, situacoesAnexoProfanoMap } from "../AnexosProfano";
import FileSelect from "../FileSelect";
import ObservacaoRevisaoDocumentos from "../ObservacaoRevisaoDocumentos";

interface Props {
  labelField: string;
  typeAcceptFileSelect: string;
  // eslint-disable-next-line no-unused-vars
  onChangeFile: (file: File | undefined) => void;
  fileReviewed?: boolean;
  fileOnError?: boolean;
  body?: React.ReactNode | (() => React.ReactNode);
  errorMessage?: string;
  revisaoMessage?: string;
  situacao?: AnexoSituacao;
  hideButton?: boolean;
}

const InputAnexo: React.FC<Props> = ({
  labelField,
  body = <></>,
  typeAcceptFileSelect,
  onChangeFile,
  errorMessage = "",
  revisaoMessage = "",
  situacao,
  hideButton = false,
}) => {
  const [fileKey, setFileKey] = useState<string>(`file-key-${v4()}`);

  return (
    <section className="input-anexo">
      {(situacao === "APROVADO" || situacao === "SUBMETIDO") && (
        <div className="input-anexo-content-reviewed">
          <Tag className={situacoesAnexoProfanoMap[situacao].class} value={situacoesAnexoProfanoMap[situacao].label} />
          <p className="input-anexo-label-text-title" style={{ marginLeft: 12 }}>
            {labelField}
          </p>
        </div>
      )}
      {!(situacao === "APROVADO" || situacao === "SUBMETIDO") && (
        <>
          <div className="input-anexo-label">
            <i className="fa-solid fa-file-lines input-anexo-label-icon" />
            <div>
              <p className="input-anexo-label-text-title">{labelField}</p>
              {body}
            </div>
          </div>

          {situacao === "REJEITADO" && <ObservacaoRevisaoDocumentos valueMessage={revisaoMessage} />}

          {!hideButton && (
            <FileSelect
              key={fileKey}
              accept={typeAcceptFileSelect}
              maxFileSize={5}
              fileLabel="Escolher"
              onFileChange={(f: File | undefined) => {
                onChangeFile(f);
                if (!(f && f.type === "application/pdf")) {
                  setFileKey(`file-key-${v4()}`);
                }
              }}
            />
          )}
          <small className="p-error p-mt-0 p-ml-1 custom-vertical-form-field-error">{errorMessage}</small>
        </>
      )}
    </section>
  );
};

export default InputAnexo;
