// converte uma data no formato brasileiro (e.g.: 25/12/2020) em uma data (com zero horas, minutos e segundos)
export function convertStringBRToDate(dateStr: string): Date {
  const datePieces = dateStr.trim().split("/");
  return new Date(Number(datePieces[2]), Number(datePieces[1]) - 1, Number(datePieces[0]));
}

export function convertDateToStringBR(date: string | number): string {
  return new Date(date).toLocaleDateString("pt-BR", {
    timeZone: "UTC",
  });
}

export function removeOffsetFromDate(date: Date): Date {
  const offsetInMinutes = date.getTimezoneOffset();
  const offsetInMillis = offsetInMinutes * 60 * 1000;
  const dateInMillis = date.getTime();

  return new Date(dateInMillis + offsetInMillis);
}
