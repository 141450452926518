import React from "react";

interface Props {
  label: string;
  value: string;
}

const SimpleLabelAndValue: React.FC<Props> = ({ label, value }) => {
  return (
    <div className="p-grid simple-label">
      <p className="p-col-4 simple-label-field">{label}:</p>
      <p className="p-col-7 simple-label-value">{value}</p>
    </div>
  );
};

export default SimpleLabelAndValue;
