import { Button } from "primereact/button";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { ColumnProps } from "primereact/column";
import { confirmDialog } from "primereact/confirmdialog";
import SimpleEntityPage from "../../../components/SimpleEntityPage";
import { useToast } from "../../../hooks/toast";
import api from "../../../services/api";
import { convertDateToStringBR } from "../../../utils/convertDates";
import FormHeader from "../../../components/FormHeader";
import SimpleEntityDatatable from "../../../components/SimpleEntityDatatable";
import IrmaoComponent from "../../../components/IrmaoComponent";

interface VotanteEscrutinio {
  id: number;
  nome: string;
  votou?: boolean;
  horaVoto?: string;
  foto: string;
}

interface EscrutinioData {
  uuid: string;
  nomeProfano: string;
  quantidadeAcessos: number;
  totalVotos: number;
  dtEscrutinio: string;
  votantes: VotanteEscrutinio[];

  url: string;

  // !encerrado
  ativo: boolean;

  // resultados
  quantidadePretas: number;
  quantidadeBrancas: number;
}

interface Params {
  id: string;
}

const EscrutinioDetails: React.FC = () => {
  const REFRESH_DELAY_IN_SECONDS = 1;
  const TABLE_FONT_SIZE = "18px";

  const [escrutinio, setEscrutinio] = useState({
    nomeProfano: "",
    dtEscrutinio: "",
    quantidadeAcessos: 0,
    totalVotos: 0,
    quantidadeBrancas: 0,
    quantidadePretas: 0,
    votantes: [] as VotanteEscrutinio[],
  } as EscrutinioData);
  const [loading, setLoading] = useState<boolean>(true);

  const { showToast } = useToast();

  const [encerrado, setEncerrado] = useState(false);
  const { id } = useParams<Params>();

  const loadData = useCallback(() => {
    // setLoading(true);
    api
      .get<EscrutinioData>(`escrutinio/${id}`)
      .then(({ data }) => {
        setEscrutinio(data);
        setEncerrado(!data.ativo);
      })
      .catch(() => {
        showToast({
          type: "error",
          title: "Não foi possível carregar o escrutínio",
          description: "Tente novamente ou contate nosso time de suporte para solicitar ajuda.",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id, showToast]);

  const encerrarEscrutinio = useCallback(() => {
    api
      .delete(`escrutinio/${id}?disableReset=disable`)
      .then(() => {
        setEncerrado(true);
        loadData();
      })
      .catch(() =>
        showToast({
          type: "error",
          title: "Não foi possível encerrar o escrutínio",
          description: "Tente novamente ou contate nosso time de suporte para solicitar ajuda.",
        })
      );
  }, [id, loadData, showToast]);

  const reiniciarEscrutinio = useCallback(() => {
    api
      .delete(`escrutinio/${id}?disableReset=reset`)
      .then(() => {
        setEncerrado(false);
        loadData();
      })
      .catch(() =>
        showToast({
          type: "error",
          title: "Não foi possível encerrar o escrutínio",
          description: "Tente novamente ou contate nosso time de suporte para solicitar ajuda.",
        })
      );
  }, [id, loadData, showToast]);

  const irmaoBodyTemplate = useCallback((rowData: VotanteEscrutinio) => {
    return <IrmaoComponent nome={rowData.nome} foto={rowData.foto} fontSize={TABLE_FONT_SIZE} />;
  }, []);

  const jaVotouBodyTemplate = useCallback((rowData: VotanteEscrutinio) => {
    return <>{rowData.votou ? <i className="fa-solid fa-check escrutinio-votante-icon-votou" /> : "--"}</>;
  }, []);

  const horaVotoBodyTemplate = useCallback((rowData: VotanteEscrutinio) => {
    return <>{rowData.horaVoto ? `${rowData.horaVoto} h` : "--"}</>;
  }, []);

  const columnsVotantes = useMemo(
    (): ColumnProps[] => [
      {
        field: "nome",
        header: "Irmão",
        headerStyle: { fontSize: TABLE_FONT_SIZE },
        sortable: true,
        style: { textAlign: "left", verticalAlign: "middle" },
        className: "p-text-nowrap p-text-truncate",
        body: irmaoBodyTemplate,
      },
      {
        field: "votou",
        header: "Já votou?",
        sortable: true,
        align: "center",
        style: { verticalAlign: "middle", width: "20%", fontSize: TABLE_FONT_SIZE },
        className: "p-text-nowrap p-text-truncate",
        body: jaVotouBodyTemplate,
      },
      {
        field: "horaVoto",
        header: "Hora do voto",
        sortable: true,
        align: "center",
        style: { verticalAlign: "middle", width: "20%", fontSize: TABLE_FONT_SIZE },
        className: "p-text-nowrap p-text-truncate",
        body: horaVotoBodyTemplate,
      },
    ],
    [horaVotoBodyTemplate, irmaoBodyTemplate, jaVotouBodyTemplate]
  );

  const confirmEncerrar = useCallback(() => {
    confirmDialog({
      style: { width: 380, lineHeight: "18px" },
      message: `Você realmente deseja encerrar o escrutínio de ${escrutinio.nomeProfano}`,
      header: "Encerrar votação?",
      icon: "pi pi-question-circle",
      acceptLabel: "Encerrar",
      rejectLabel: "Não encerrar",
      accept: encerrarEscrutinio,
      reject: () => {},
    });
  }, [encerrarEscrutinio, escrutinio.nomeProfano]);

  const confirmReiniciar = useCallback(() => {
    confirmDialog({
      style: { width: 380, lineHeight: "18px" },
      message: `Você realmente deseja reiniciar o escrutínio de ${escrutinio.nomeProfano}. Os totais de votos computados serão zerados e a lista de participantes apagada.`,
      header: "Reiniciar votação?",
      icon: "pi pi-question-circle",
      acceptLabel: "Reiniciar",
      rejectLabel: "Não reiniciar",
      accept: reiniciarEscrutinio,
      reject: () => {},
    });
  }, [escrutinio.nomeProfano, reiniciarEscrutinio]);

  useEffect(() => {
    if (!encerrado) {
      const timer = setInterval(loadData, REFRESH_DELAY_IN_SECONDS * 1000);
      return () => {
        clearInterval(timer);
      };
    }
    return () => {};
  }, [encerrado, loadData]);

  return (
    <SimpleEntityPage showTopBar routeBack="/escrutinio" isFormPage loading={loading} hidePagePadding>
      {!escrutinio.nomeProfano && "Não foi possĩvel carregar o escrutínio"}
      {escrutinio.nomeProfano && (
        <div className="escrutinio-details-container">
          <div className="escrutinio-header">
            <p className="escrutinio-header-text">Escrutínio de {escrutinio.nomeProfano}</p>
          </div>
          <div
            className={`escrutinio-situacao-container ${
              escrutinio.ativo ? "escrutinio-situacao-andamento" : "escrutinio-situacao-encerrado"
            }`}
          >
            <span className="escrutinio-situacao-label">{escrutinio.ativo ? "Em andamento" : "Encerrado"}</span>
            {escrutinio.dtEscrutinio && (
              <span className="escrutinio-situacao-date">
                {escrutinio.ativo
                  ? `Sendo realizado em ${convertDateToStringBR(escrutinio.dtEscrutinio)}`
                  : `Realizado em ${convertDateToStringBR(escrutinio.dtEscrutinio)}`}
              </span>
            )}
          </div>
          <div className="escrutinio-details-body">
            <div className="escrutinio-totais-panel">
              <div className="indicador-card-container">
                <div className="indicador-card-left-content">
                  <span className="indicador-card-title">Total de Acessos</span>
                  <span className="indicador-card-number">{escrutinio.quantidadeAcessos}</span>
                </div>
                <div className="indicador-card-right-content">
                  <i className="fa-solid fa-eye" />
                </div>
              </div>
              <div className="indicador-card-container p-ml-5">
                <div className="indicador-card-left-content">
                  <span className="indicador-card-title">Total de Votos</span>
                  <span className="indicador-card-number">{escrutinio.totalVotos}</span>
                </div>
                <div className="indicador-card-right-content">
                  <i className="fa-solid fa-vote-yea" />
                </div>
              </div>
              {!encerrado && (
                <Button
                  className="indicador-card-container escrutinio-button p-ml-5"
                  label="Encerrar Escrutínio"
                  icon="fa-solid fa-hourglass-end"
                  onClick={confirmEncerrar}
                />
              )}
              {encerrado && (
                <Button
                  className="indicador-card-container escrutinio-button p-ml-5"
                  label="Reiniciar Escrutínio"
                  icon="fa-solid fa-hourglass-start"
                  onClick={confirmReiniciar}
                />
              )}
            </div>
            {/* {escrutinio.dtEscrutinio && (
            <span className="escrutinio-details-date">
              Escrutínio realizado dia {convertDateToStringBR(escrutinio.dtEscrutinio)}.
            </span>
          )} */}
            {encerrado && (
              <div className="escrutinio-resultados-container">
                <FormHeader headerMessage="Resultado" />
                <div className="escrutinio-resultados-content">
                  <div className="escrutinio-resultados-left-content">
                    <div className="escrutinio-details-votos ">
                      <div className="escrutinio-details-votos-sim">{escrutinio.quantidadeBrancas}</div>
                      <div className="escrutinio-details-votos-nao">{escrutinio.quantidadePretas}</div>
                    </div>
                  </div>
                  <div className="escrutinio-resultados-separator" />
                  <div className="escrutinio-resultados-right-content">
                    {escrutinio.quantidadePretas === 0 && escrutinio.quantidadeBrancas > 0 && (
                      <span className="escrutinio-resultados-text">O profano foi considerado limpo e puro.</span>
                    )}
                    {escrutinio.quantidadePretas > 0 && (
                      <span className="escrutinio-resultados-text">O profano foi considerado impuro.</span>
                    )}
                  </div>
                </div>
              </div>
            )}

            <FormHeader headerMessage="Votantes" />
            <SimpleEntityDatatable
              columns={columnsVotantes}
              value={escrutinio.votantes}
              emptyMessage="Nenhum irmão acessou a votação até o momento"
              rows={0}
              paginator={false}
            />
          </div>
        </div>
      )}
    </SimpleEntityPage>
  );
};

export default EscrutinioDetails;
