/* eslint-disable no-param-reassign */
/* eslint-disable react/require-default-props */
/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Avatar } from "primereact/avatar";
import { Button } from "primereact/button";
import { ColumnProps } from "primereact/column";
import { MultiSelect } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";

import { DataTable } from "primereact/datatable";
import { useHistory } from "react-router-dom";
import { Tooltip } from "primereact/tooltip";
import _ from "lodash";
import { Badge } from "primereact/badge";
import api from "../../../services/api";
import { useToast } from "../../../hooks/toast";
import SimpleEntityDatatable from "../../../components/SimpleEntityDatatable";
import SimpleEntityListPage from "../../../components/SimpleEntityPage";
import IrmaoComponent from "../../../components/IrmaoComponent";
import ProfanoSituacaoComponent, {
  SituacaoProfano,
  SituacaoProfanoElement,
  situacoesProfanoMap,
} from "../../../components/ProfanoSituacaoComponent";
import { SituacaoSindicancia } from "../../../components/SindicanciasProfano";
import { TurmaProfano } from "../ProfanoFormPage";
import { convertDateToStringBR } from "../../../utils/convertDates";

interface SindicantDTO {
  id: number;
  uuid: string;
  nome: string;
  foto?: string;
}

interface SindicanciasDTO {
  uuid: string;
  id: number;
  sindicante: SindicantDTO;
  situacaoSindicancia?: SituacaoSindicancia;
  dtLimite?: string;
  favoravel?: boolean;
}

export interface ProfanoList {
  id: number;
  uuid: string;
  nome: string;
  nomePadrinho: string;
  statusProfano?: SituacaoProfano;
  foto: string;
  // sindicantes: SindicanteDTO[];
  sindicante1?: string;
  sindicante2?: string;
  sindicante3?: string;
  uuidTurma?: string;
  cpf?: string;
  sindicancias: SindicanciasDTO[];
  dtCadastro: string;
}

const ProfanoPage: React.FC = () => {
  const situacaoFilterOptions: SituacaoProfanoElement[] = useMemo((): SituacaoProfanoElement[] => {
    return [
      situacoesProfanoMap.AGUARDANDO,
      situacoesProfanoMap.PRONTO_PARA_LEITURA,
      situacoesProfanoMap.LIDO,
      situacoesProfanoMap.SINDICANCIA,
      situacoesProfanoMap.ESCRUTINIO,
      situacoesProfanoMap.APROVADO,
      situacoesProfanoMap.PAGO,
      situacoesProfanoMap.ANEXOS,
      situacoesProfanoMap.INICIADO,
      situacoesProfanoMap.REJEITADO,
      situacoesProfanoMap.DESISTENCIA,
    ];
  }, []);
  const [situacaoFilter, setSituacaoFilter] = useState<SituacaoProfanoElement[]>([]);
  const [turmaFilterOptions, setTurmaFilterOptions] = useState<TurmaProfano[]>([]);
  const [turmaFilter, setTurmaFilter] = useState<TurmaProfano>();

  const dt = useRef<DataTable>(null);
  const [loading, setLoading] = useState(false);
  const [profanos, setProfanos] = useState<ProfanoList[]>([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const { showToast } = useToast();
  const history = useHistory();

  const onFilterSituacaoChange = useCallback((selectedList) => {
    dt.current?.filter(selectedList, "statusProfano", "custom");
    setSituacaoFilter(selectedList);
  }, []);

  const onTurmaFilterChange = useCallback((turma) => {
    dt.current?.filter(turma, "uuidTurma", "custom");
    setTurmaFilter(turma);
  }, []);

  const onGlobalFilterChange = useCallback((filterValue) => {
    setGlobalFilter(filterValue);
  }, []);

  const loadData = useCallback(() => {
    setLoading(true);
    api
      .get<ProfanoList[]>(`profanos`)
      .then(({ data }) => {
        data.forEach((profano) => {
          const [sindicante1, sindicante2, sindicante3] = profano.sindicancias.map((s) => {
            return { nome: s.sindicante.nome };
          });
          profano.sindicante1 = sindicante1 ? sindicante1.nome : undefined;
          profano.sindicante2 = sindicante2 ? sindicante2.nome : undefined;
          profano.sindicante3 = sindicante3 ? sindicante3.nome : undefined;
        });
        setProfanos(data);
      })
      .catch((err) => {
        showToast({
          type: "error",
          title: "Não foi possível carregar profanos",
          description: "Tente novamente ou contate nosso time de suporte para solicitar ajuda.",
        });
        // eslint-disable-next-line no-console
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [showToast]);

  const loadTurmaOptions = useCallback(() => {
    api
      .get<TurmaProfano[]>(`dropdown/turmas`)
      .then(({ data: turmas }) => {
        setTurmaFilterOptions([{ uuid: "", dtIniciacao: "", nome: "Sem turma definida" }, ...turmas]);
        setTurmaFilter(turmas[0]);
      })
      .catch(() => {
        showToast({
          type: "error",
          title: "Não foi possível carregar as opções de turmas.",
          description: "Tente novamente ou contate nosso time de suporte para solicitar ajuda.",
        });
      });
  }, [showToast]);

  useEffect(() => {
    loadData();
    loadTurmaOptions();
  }, [loadData, loadTurmaOptions]);

  useEffect(() => {
    onFilterSituacaoChange([
      situacoesProfanoMap.AGUARDANDO,
      situacoesProfanoMap.PRONTO_PARA_LEITURA,
      situacoesProfanoMap.LIDO,
      situacoesProfanoMap.SINDICANCIA,
      situacoesProfanoMap.ESCRUTINIO,
      situacoesProfanoMap.APROVADO,
      situacoesProfanoMap.PAGO,
      situacoesProfanoMap.ANEXOS,
      // situacoesProfanoMap.INICIADO,
      // situacoesProfanoMap.REJEITADO,
      // situacoesProfanoMap.DESISTENCIA,
    ]);
  }, [onFilterSituacaoChange, loading]);

  const editDeleteButtonsBodyTemplate = useCallback(
    (data: ProfanoList) => {
      return (
        <>
          <Button
            type="button"
            className="p-row-editor-init p-link "
            icon="pi pi-eye"
            tooltip="Detalhes do profano"
            tooltipOptions={{ position: "top", style: { width: 80, textAlign: "center" } }}
            onClick={() => {
              history.push(`/profano/${data.uuid}`, { nome: data.nome });
            }}
          />
        </>
      );
    },
    [history]
  );

  const nomeBodyTemplate = useCallback((rowData: ProfanoList) => {
    return <IrmaoComponent nome={rowData.nome} foto={rowData.foto} />;
  }, []);

  const situacaoProfanoBodyTemplate = useCallback((rowData: ProfanoList) => {
    return <ProfanoSituacaoComponent situacao={rowData.statusProfano} />;
  }, []);

  const filterBySituacao = useCallback((value: SituacaoProfano, filter: SituacaoProfanoElement[]) => {
    if (value) return filter == null || _.isEmpty(filter) || filter.filter((f) => f.situacao === value).length > 0;
    return false;
  }, []);

  const filterByTurma = useCallback(
    (value: string, filter: TurmaProfano) => {
      if (value === undefined && filter && filter.uuid === "") return profanos.map((profano) => !profano.uuidTurma);
      if (value === undefined && filter === undefined) return true;
      if (value) return filter == null || _.isEmpty(filter) || filter.uuid === value;
      return false;
    },
    [profanos]
  );

  const sindicantesBodyTemplate = useCallback(({ uuid, sindicancias }: ProfanoList) => {
    return (
      <div className="irmao-avatar-grupo">
        {sindicancias.map((sindicancia) => (
          <Avatar
            key={sindicancia.sindicante.uuid}
            icon={sindicancia.sindicante.foto ? undefined : "pi pi-user"}
            image={sindicancia.sindicante.foto || undefined}
            imageAlt={sindicancia.sindicante.foto ? sindicancia.sindicante.nome : undefined}
            shape="circle"
            className={`p-overlay-badge p-mr-2 p-shadow-2 irmao-avatar-foto avatar-${uuid}-${sindicancia.sindicante.uuid}`}
          >
            <Badge
              style={{ background: "transparent", transform: "translate(50%,90%)" }}
              value={
                <span
                  className={`material-symbols-rounded svg-icon status-sindicancia ${
                    sindicancia.situacaoSindicancia === "REALIZADO" && "accomplished"
                  }`}
                  style={{ fontSize: 18 }}
                >
                  {sindicancia.situacaoSindicancia === "REALIZADO" && "thumb_up"}
                </span>
              }
              size="normal"
            />
          </Avatar>
        ))}

        {sindicancias.map((sindicancia) => (
          <Tooltip
            key={`${uuid}-${sindicancia.sindicante.uuid}-tooltip`}
            content={sindicancia.sindicante.nome}
            target={`.avatar-${uuid}-${sindicancia.sindicante.uuid}`}
            position="top"
          />
        ))}
      </div>
    );
  }, []);

  const cpfBodyTemplate = useCallback((rowData: ProfanoList) => {
    if (rowData.cpf) {
      return `${rowData.cpf.substring(0, 3)}.${rowData.cpf.substring(3, 6)}.${rowData.cpf.substring(
        6,
        9
      )}-${rowData.cpf.substring(9, 11)}`;
    }
    return "";
  }, []);

  const columnsProfanos = useMemo(
    (): ColumnProps[] => [
      {
        field: "nome",
        header: "Nome",
        sortable: true,
        style: { textAlign: "left", verticalAlign: "middle" },
        body: nomeBodyTemplate,
      },
      {
        field: "cpf",
        header: "CPF do profano",
        sortable: true,
        style: { textAlign: "left", verticalAlign: "middle", width: "160px" },
        className: "p-text-nowrap p-text-truncate",
        body: cpfBodyTemplate,
      },
      {
        field: "statusProfano",
        header: "Situação",
        sortable: true,
        style: { verticalAlign: "middle", width: "10%" },
        align: "center",
        body: situacaoProfanoBodyTemplate,
        filterMatchMode: "custom",
        filterFunction: (value, filter) => filterBySituacao(value, filter),
      },
      {
        field: "nomePadrinho",
        header: "Padrinho",
        sortable: true,
        style: { textAlign: "left", verticalAlign: "middle" },
      },
      {
        field: "dtCadastro",
        header: "Data de cadastro",
        sortable: true,
        style: { verticalAlign: "middle" },
        align: "center",
      },
      {
        field: "sindicante1",
        header: "Sindicante 1",
        hidden: true,
      },
      {
        field: "sindicante2",
        header: "Sindicante 2",
        hidden: true,
      },
      {
        field: "sindicante3",
        header: "Sindicante 3",
        hidden: true,
      },
      {
        field: "uuidTurma",
        header: "Turma",
        hidden: true,
        filterMatchMode: "custom",
        filterFunction: (value, filter) => filterByTurma(value, filter),
      },
      {
        exportable: false,
        field: "sindicantes",
        header: "Sindicantes",
        sortable: false,
        align: "left",
        style: { verticalAlign: "middle", width: "12%", minWidth: 110 },
        body: sindicantesBodyTemplate,
        // filterMatchMode: "custom",
        // filterFunction: (value, filter) => filterBySindicantes(value, filter),
      },

      {
        body: editDeleteButtonsBodyTemplate,
        style: { width: "80px", textAlign: "center", verticalAlign: "middle" },
      },
    ],
    [
      cpfBodyTemplate,
      editDeleteButtonsBodyTemplate,
      filterBySituacao,
      filterByTurma,
      nomeBodyTemplate,
      sindicantesBodyTemplate,
      situacaoProfanoBodyTemplate,
    ]
  );

  const situacaoFilterBodyTemplate = (
    <MultiSelect
      showClear
      dataKey="situacao"
      optionLabel="label"
      options={situacaoFilterOptions}
      value={situacaoFilter}
      onChange={(e) => {
        onFilterSituacaoChange(e.target.value);
      }}
      placeholder="Filtrar por situação"
      selectedItemsLabel="{0} situações selecionadas"
      maxSelectedLabels={2}
      emptyFilterMessage="Nenhuma situação encontrada"
      style={{ width: "180px" }}
      scrollHeight="400px"
    />
  );

  const turmaFilterBodyTemplate = (
    <Dropdown
      showClear
      dataKey="uuid"
      optionLabel="nome"
      options={turmaFilterOptions}
      value={turmaFilter}
      onChange={(e) => {
        onTurmaFilterChange(e.target.value);
      }}
      placeholder="Filtrar por turma"
      emptyFilterMessage="Nenhuma turma encontrada"
      style={{ width: "200px", marginRight: 10 }}
      itemTemplate={(turma: TurmaProfano) => (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ marginRight: 25 }}>{turma.nome}</div>
          <div>{turma.uuid && convertDateToStringBR(turma.dtIniciacao)}</div>
        </div>
      )}
    />
  );

  const datatableFilters = (
    <>
      {turmaFilterBodyTemplate}
      {situacaoFilterBodyTemplate}
    </>
  );

  return (
    <SimpleEntityListPage
      showListHeader
      onGlobalFilterChange={(value) => onGlobalFilterChange(value)}
      loading={loading}
      listHeaderContentRight={datatableFilters}
      showExportCsvButton
      datatableRef={dt}
    >
      <SimpleEntityDatatable
        datatableRef={dt}
        value={profanos}
        columns={columnsProfanos}
        globalFilter={globalFilter}
        sortField="nome"
        sortOrder={1}
      />
    </SimpleEntityListPage>
  );
};

export default ProfanoPage;
