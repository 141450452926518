/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { PanelMenu } from "primereact/panelmenu";
import { MenuItem } from "primereact/menuitem";
import classNames from "classnames";

import { useAuth } from "../../hooks/auth";
import NotAuthorizedContainer from "../NotAuthorizedContainer";
import { store } from "../../redux/configStore";

import TopMenuBar from "../TopMenuBar";
import logo from "../../assets/img/balandrau-logo.svg";
import logoNome from "../../assets/img/balandrau-logo-nome.svg";
import api from "../../services/api";

interface ItemsMenu {
  titulo: string;
  url: string;
  icone: string;
}

export interface Menu {
  titulo: string;
  icone: string;
  url?: string;
  menu?: ItemsMenu[];
}

const MainContainer: React.FC = ({ children }) => {
  const { user } = useAuth();
  const history = useHistory();

  const [isToggled, setIsToggled] = useState(false);
  const [isError, setIsError] = useState(false);
  const [hiddenMenu, setHiddenMenu] = useState(Date.now());

  const sideMenuRef = useRef(null);

  const menuMode = isToggled ? "sidebar-closed" : "sidebar-open";

  const home = {
    icon: "pi pi-home",
    command: () => {
      history.push("/");
    },
  };

  const [menuItems, setMenuItems] = useState<MenuItem[]>([
    {
      label: "Início",
      icon: "pi pi-home",
      command: () => history.push("/"),
    },
  ]);

  const onToggleMenuClick = () => setIsToggled(!isToggled);

  useEffect(() => {
    setMenuItems([
      {
        label: "Início",
        icon: "pi pi-home",
        command: () => history.push("/"),
      },
    ]);
    if (user) {
      const getMenu = () => {
        api.get<Menu[]>("/menu").then(({ data }) => {
          const menus = data.map((item) => {
            const subItens = item.menu?.map((menu) => {
              return {
                label: menu.titulo,
                command: () => history.push(menu.url),
                icon: menu.icone,
                template: ({ label, target }: any, options: any) => {
                  return (
                    <div>
                      <a className={`${options.className} p-py-3`} target={target} onClickCapture={options.onClick}>
                        <span className={classNames(options.iconClassName)} />
                        <span className={options.labelClassName}>{label}</span>
                      </a>
                    </div>
                  );
                },
              };
            });

            const menuItem: MenuItem = {
              icon: item.icone,
              label: item.titulo,
              items: subItens,
            };

            return menuItem;
          });
          setMenuItems([...menuItems, ...menus]);
        });
      };

      getMenu();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!user]);

  useEffect(() => {
    store.subscribe(() => {
      setIsError(store.getState().errorState.isError);
    });
  }, []);

  if (isError) {
    return (
      <div className="main-content">
        <NotAuthorizedContainer />
      </div>
    );
  }

  return (
    <>
      {user ? (
        <div className="main text-font-family">
          <div ref={sideMenuRef} className={menuMode}>
            {!isToggled && (
              <div className="sidebar-background">
                <div className="sidebar-logo-area">
                  <img className="logo" alt="logo balandrau" src={logo} />
                  <img className="logo-nome" alt="logo balandrau" src={logoNome} />
                </div>

                <div className="sidebar-content">
                  <PanelMenu model={menuItems} multiple className="p-panelmenu menu-prime" />
                </div>
              </div>
            )}
          </div>
          <div className="right-content">
            <TopMenuBar home={home} onMenuToggleClick={onToggleMenuClick} hiddenMenu={hiddenMenu} />
            <div className="main-content p-component" onClickCapture={() => setHiddenMenu(Date.now())}>
              {children}
            </div>
          </div>
        </div>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

export default MainContainer;
