import React, { useEffect, useState } from "react";
import { BreadCrumb } from "primereact/breadcrumb";
import { confirmDialog } from "primereact/confirmdialog";
import { matchPath, useLocation } from "react-router-dom";
import { Avatar } from "primereact/avatar";
import _ from "lodash";
import { useAuth } from "../../hooks/auth";
import { AppRoutes } from "../../routes/index";
import MenuLogOut from "../MenuLogOut";

type functionProps = {
  home?: { icon: string; command: any };
  onMenuToggleClick: any;
  hiddenMenu: number;
};

const TopMenuBar: React.FC<functionProps> = ({ home, onMenuToggleClick, hiddenMenu }) => {
  const [blMenu, setBlMenu] = useState(false);
  const { signOut, user, irmao, loja, potencia, usuario } = useAuth();

  const handleSignOut = () => {
    signOut();
  };

  const confirmLogOut = () => {
    confirmDialog({
      message: "Será necessário fazer login novamente para acessar o sistema.",
      header: "Deseja sair?",
      icon: "pi pi-question-circle",
      acceptLabel: "Sair",
      rejectLabel: "Cancelar",
      accept: handleSignOut,
      reject: () => {},
    });
  };

  const location = useLocation();

  const showMenu = () => {
    setBlMenu(!blMenu);
  };

  useEffect(() => {
    setBlMenu(false);
  }, [hiddenMenu]);

  return (
    <div className="topmenu">
      <div className="row-topmenu">
        <button type="button" onClick={onMenuToggleClick} className="button-toggle">
          <i className="pi pi-bars" />
        </button>

        <div className="column">
          <p className="title-page">
            {AppRoutes.find((item) => !!matchPath(location.pathname, { path: item.url, exact: true }))?.label}
          </p>
          <BreadCrumb
            model={
              AppRoutes.find((item) => !!matchPath(location.pathname, { path: item.url, exact: true }))?.breadcrumb
            }
            home={home}
            className="p-breadcrumb p-breadcrumb-chevron breadcrumb"
          />
        </div>
      </div>

      <div className="right-info">
        <button
          type="button"
          onClick={() => {
            showMenu();
          }}
          className="button-perfil irmao-container"
        >
          {!_.isEmpty(irmao) && !_.isEmpty(loja) && irmao && loja && (
            <>
              <Avatar
                label={irmao.foto ? undefined : user.nome[0]}
                image={irmao.foto || undefined}
                className="p-mr-3 irmao-avatar avatar-container"
                size="large"
                shape="circle"
              />
              <Avatar
                label={loja.foto ? undefined : loja.nome[0]}
                image={loja.foto || undefined}
                className="image-loja-pequeno irmao-avatar avatar-container"
                shape="circle"
              />
            </>
          )}
          {!_.isEmpty(potencia) && !_.isEmpty(usuario) && potencia && usuario && (
            <>
              <Avatar
                label={usuario.nome[0]}
                className="p-mr-3 irmao-avatar avatar-container"
                size="large"
                shape="circle"
              />
              <Avatar
                label={potencia.logo ? undefined : potencia.nome[0]}
                image={potencia.logo || undefined}
                className="image-loja-pequeno irmao-avatar avatar-container"
                shape="circle"
              />
            </>
          )}
        </button>
        {blMenu && <MenuLogOut logoutCommand={confirmLogOut} />}
      </div>
    </div>
  );
};

export default TopMenuBar;
