import React from "react";

interface Props {
  icon?: string;
  iconMaterial?: string;
  description: string;
  htmlForDescription: string;
  required?: boolean;
  inputBody?: React.ReactNode;
  // labelWidth?: "2" | "3" | "4" | "5" | "6" | "7" | "8" | "9" | "10";
  errorMessage?: string;
  className?: string;
}

const CustomVerticalFormField: React.FC<Props> = ({
  icon = "pi pi-question",
  iconMaterial = undefined,
  description,
  inputBody,
  required = false,
  htmlForDescription,
  // labelWidth = "3",
  errorMessage,
  className = "",
}) => {
  return (
    <div className={`custom-vertical-form-field-container ${className || ""}`}>
      <div className="custom-vertical-form-field-label-container">
        {/* <i className={`custom-vertical-form-field-icon ${icon}`} /> */}
        {!iconMaterial ? (
          <i className={`custom-vertical-form-field-icon ${icon}`} />
        ) : (
          <span className="material-symbols-rounded svg-icon">{iconMaterial}</span>
        )}
        <label className="custom-vertical-form-field-label" htmlFor={htmlForDescription}>
          {description}
          {required && " *"}
        </label>
      </div>
      {inputBody}
      {/* {errorMessage && ( */}
      <div className="custom-vertical-form-field-error-container p-d-flex p-jc-start p-mt-2 p-ai-center">
        {errorMessage && <i className="fas fa-exclamation-triangle p-mr-2 p-error" />}
        <small className="p-error p-mt-0 custom-vertical-form-field-error">{errorMessage || ""}</small>
      </div>
      {/* )} */}
    </div>
  );
};

export default CustomVerticalFormField;
