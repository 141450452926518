/* eslint-disable react/require-default-props */
/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Button } from "primereact/button";
import { ColumnProps } from "primereact/column";
import { Tag } from "primereact/tag";

import { useHistory } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { MultiSelect } from "primereact/multiselect";
import _ from "lodash";
import api from "../../../../services/api";
import { useToast } from "../../../../hooks/toast";
import SimpleEntityDatatable from "../../../../components/SimpleEntityDatatable";
import SimpleEntityListPage from "../../../../components/SimpleEntityPage";
import IrmaoComponent from "../../../../components/IrmaoComponent";

export interface GrupoUsuario {
  id: number;
  uuid: string;
  nome: string;
}

export interface Usuario {
  id: number;
  uuid: string;
  nome: string;
  email: string;
  foto?: string;
  grupos: GrupoUsuario[];
}

const UsuarioPage: React.FC = () => {
  const dt = useRef<DataTable>(null);

  const [grupoFilterOptions, setGrupoFilterOptions] = useState<GrupoUsuario[]>([]);
  const [grupoFilter, setGrupoFilter] = useState<GrupoUsuario[]>([]);
  const [loading, setLoading] = useState(false);
  const [loadingGrupos, setLoadingGrupos] = useState(false);
  const [usuarios, setUsuarios] = useState<Usuario[]>([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const { showToast } = useToast();
  const history = useHistory();

  const loadData = useCallback(() => {
    setLoading(true);
    api
      .get<Usuario[]>(`controle/usuarios`)
      .then(({ data }) => {
        setUsuarios(data);
      })
      .catch((err) => {
        showToast({
          type: "error",
          title: "Não foi possível carregar usuários",
          description: "Tente novamente ou contate nosso time de suporte para solicitar ajuda.",
        });
        // eslint-disable-next-line no-console
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [showToast]);

  const loadGruposData = useCallback(() => {
    setLoadingGrupos(true);
    api
      .get<GrupoUsuario[]>(`controle/grupos`)
      .then(({ data }) => {
        setGrupoFilterOptions(data);
      })
      .catch((err) => {
        showToast({
          type: "error",
          title: "Não foi possível carregar grupos",
          description: "Tente novamente ou contate nosso time de suporte para solicitar ajuda.",
        });
        // eslint-disable-next-line no-console
        console.error(err);
      })
      .finally(() => {
        setLoadingGrupos(false);
      });
  }, [showToast]);

  useEffect(() => {
    loadData();
    loadGruposData();
  }, [loadData, loadGruposData]);

  const editDeleteButtonsBodyTemplate = useCallback(
    (data: Usuario) => {
      return (
        <>
          <Button
            type="button"
            className="p-row-editor-init p-link "
            icon="pi pi-pencil"
            onClick={() => {
              history.push({ pathname: `/controle/usuario/${data.uuid}` });
            }}
          />
        </>
      );
    },
    [history]
  );

  const gruposFilterBodyTemplate = (
    <>
      {!loadingGrupos && (
        <MultiSelect
          className="p-ml-2"
          showClear
          dataKey="id"
          optionLabel="nome"
          options={grupoFilterOptions}
          value={grupoFilter}
          onChange={(e) => {
            dt.current?.filter(e.value, "grupos", "custom");
            setGrupoFilter(e.target.value);
          }}
          placeholder="Filtrar por grupo"
          selectedItemsLabel="{0} grupos selecionados"
          maxSelectedLabels={2}
          emptyFilterMessage="Nenhum grupo encontrado"
          style={{ width: "180px" }}
          scrollHeight="400px"
        />
      )}
    </>
  );

  const filterByGrupos = useCallback((value: GrupoUsuario[], filter: GrupoUsuario[]) => {
    if (value) return filter == null || _.isEmpty(filter) || filter.some((f) => value.some((v) => v.id === f.id));
    return false;
  }, []);

  const nomeBodyTemplate = useCallback((rowData: Usuario) => {
    return <IrmaoComponent nome={rowData.nome} foto={rowData.foto} />;
  }, []);

  const gruposBodyTemplate = useCallback((rowData: Usuario) => {
    return (
      <div className="usuario-grupo-list">
        {rowData.grupos.map((grupo) => (
          <Tag className="usuario-grupo-item" key={grupo.id}>
            {grupo.nome}
          </Tag>
        ))}
      </div>
    );
  }, []);

  const columnsUsuarios = useMemo(
    (): ColumnProps[] => [
      {
        field: "nome",
        header: "Nome do Irmão",
        sortable: true,
        style: { textAlign: "left", verticalAlign: "middle", width: "25%" },
        body: nomeBodyTemplate,
      },

      {
        field: "email",
        header: "E-mail",
        sortable: true,
        style: { textAlign: "left", verticalAlign: "middle", width: "25%" },
        className: "p-text-nowrap p-text-truncate",
      },
      {
        field: "grupos",
        header: "Grupos de Acesso",
        sortable: false,
        style: { textAlign: "left", verticalAlign: "middle" },
        className: "p-text-nowrap p-text-truncate",
        body: gruposBodyTemplate,
        filterMatchMode: "custom",
        filterFunction: (value, filter) => filterByGrupos(value, filter),
      },

      {
        align: "center",
        body: editDeleteButtonsBodyTemplate,
        style: { width: "100px", verticalAlign: "middle" },
      },
    ],
    [editDeleteButtonsBodyTemplate, filterByGrupos, gruposBodyTemplate, nomeBodyTemplate]
  );

  const datatableFilters = <>{gruposFilterBodyTemplate}</>;

  return (
    <SimpleEntityListPage
      showListHeader
      // newButtonLabel="Novo usuário"
      // onNewButtonCLick={() => {
      //   history.push("/controle/usuario/novo");
      // }}
      onGlobalFilterChange={(value) => setGlobalFilter(value)}
      loading={loading}
      showExportCsvButton
      listHeaderContentRight={datatableFilters}
      datatableRef={dt}
    >
      <SimpleEntityDatatable
        datatableRef={dt}
        value={usuarios}
        columns={columnsUsuarios}
        globalFilter={globalFilter}
        sortField="nome"
        sortOrder={1}
      />
    </SimpleEntityListPage>
  );
};

export default UsuarioPage;
