import React, { useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { Dropdown } from "primereact/dropdown";
import { useHistory, useParams } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { useToast } from "../../../hooks/toast";
import { useError } from "../../../hooks/error";
import api from "../../../services/api";
import CustomFormField from "../../../components/CustomFormField";
import FloatingSave from "../../../components/FloatingSave";
import SimpleEntityPage from "../../../components/SimpleEntityPage";
import { Publicacao, TipoPublicacaoElement, tiposPublicacaoMap } from "../PublicacaoPage";
import { formatDateToStringToSend } from "../../../utils/formatDateToStringForSend";
import FileSelect from "../../../components/FileSelect";
import { publicacaoErrors } from "../../../errors/publicacao";

interface Params {
  id: string;
}

export interface PublicacaoForm {
  titulo: string;
  dtPublicacao: Date;
  tipo: TipoPublicacaoElement;

  autor?: string;
  arquivo?: File;
  edicao?: string;
}

const PublicacaoFormPage: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [loadingSalvar, setLoadingSalvar] = useState(false);

  const [publicacaoInicial, setPublicacao] = useState<PublicacaoForm>({
    titulo: "",
    autor: "",
    dtPublicacao: new Date(),
    tipo: tiposPublicacaoMap.LIVRO,
    arquivo: undefined,
  } as PublicacaoForm);

  // estados dos campos do formulário
  const [titulo, setTitulo] = useState<string>("");
  const [autor, setAutor] = useState<string>("");
  const [dtPublicacao, setDtPublicacao] = useState<Date>(new Date());
  const [tipo, setTipo] = useState<TipoPublicacaoElement>(tiposPublicacaoMap.LIVRO);
  const [arquivo, setArquivo] = useState<File>();
  const [edicao, setEdicao] = useState<string>("");

  const [fileToReset, setFileToReset] = useState<File | undefined>();

  const tipoOptions = useMemo(
    (): TipoPublicacaoElement[] => [tiposPublicacaoMap.LIVRO, tiposPublicacaoMap.REVISTA, tiposPublicacaoMap.OUTROS],
    []
  );

  const { showToast } = useToast();
  const { handleError } = useError();
  const { id } = useParams<Params>();

  const [errors, setErrors] = useState<{ [campo: string]: string }>({});

  const history = useHistory();

  const isValidForm = useCallback(() => {
    const localErrors = {} as { [campo: string]: string };
    if (!titulo || _.isEmpty(titulo.trim())) {
      localErrors.titulo = "O campo título é obrigatório.";
    }
    if (!tipo || _.isEmpty(tipo)) {
      localErrors.tipo = "O campo tipo de publicação é obrigatório.";
    }
    if (!dtPublicacao || !_.isDate(dtPublicacao)) {
      localErrors.dtPublicacao = "O campo data de publicação é obrigatório.";
    }
    if (!id && !arquivo) {
      localErrors.arquivo = "O campo aquivo para publicação é obrigatório.";
    }

    setErrors(localErrors);

    if (!_.isEmpty(localErrors)) {
      return false;
    }
    return true;
  }, [arquivo, dtPublicacao, id, tipo, titulo]);

  const salvarForm = useCallback(() => {
    if (isValidForm()) {
      setLoadingSalvar(true);
      const formData = new FormData();
      formData.append("titulo", titulo || "");
      formData.append("dtPublicacao", formatDateToStringToSend(dtPublicacao) || "");
      formData.append("tipo", tipo.tipo || "");
      formData.append("autor", autor || "");
      formData.append("edicao", edicao || "");
      if (arquivo) {
        formData.append("arquivo", arquivo);
      }

      if (!id) {
        api
          .post("publicacao", formData, { headers: { "Content-Type": "multipart/form-data" } })
          .then(() => {
            showToast({
              title: "Sucesso!",
              type: "success",
              description: "Uma nova publicação foi criada.",
            });
            history.push("/publicacoes");
          })
          .catch((err: any) => {
            handleError({ error: err, action: "criar publicação", knownErrors: publicacaoErrors });
          })
          .finally(() => setLoadingSalvar(false));
      } else {
        api
          .put(`publicacao/${id}`, formData, { headers: { "Content-Type": "multipart/form-data" } })
          .then(() => {
            showToast({
              title: "Sucesso!",
              type: "success",
              description: "A publicação foi editada.",
            });
            history.push("/publicacoes");
          })
          .catch((err: any) => {
            handleError({ error: err, action: "salvar publicação", knownErrors: publicacaoErrors });
          })
          .finally(() => setLoadingSalvar(false));
      }
    }
  }, [arquivo, autor, dtPublicacao, edicao, handleError, history, id, isValidForm, showToast, tipo.tipo, titulo]);

  const loadPublicacao = useCallback(() => {
    setLoading(true);
    api
      .get<Publicacao>(`publicacao/${id}`)
      .then(({ data }) => {
        setPublicacao({
          titulo: data.titulo,
          tipo: tiposPublicacaoMap[data.tipo],
          dtPublicacao: new Date(data.dtPublicacao),
          autor: data.autor || "",
          arquivo: undefined,
          edicao: data.edicao || "",
        });
        setTitulo(data.titulo || "");
        setAutor(data.autor || "");
        setTipo(data.tipo ? tiposPublicacaoMap[data.tipo] : tiposPublicacaoMap.LIVRO);
        setDtPublicacao(new Date(data.dtPublicacao) || new Date());
        setArquivo(undefined);
        setEdicao(data.edicao || "");
      })
      .catch((err) => {
        handleError({ error: err, action: "carregar a publicação", knownErrors: publicacaoErrors });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [handleError, id]);

  const yearNavigatorTemplate = useCallback((e: any) => {
    return (
      <Dropdown
        value={e.value}
        options={e.options}
        onChange={(event) => e.onChange(event.originalEvent, event.value)}
        className="p-ml-2"
        style={{ lineHeight: 1 }}
      />
    );
  }, []);

  const monthNavigatorTemplate = useCallback((e: any) => {
    return (
      <Dropdown
        value={e.value}
        options={e.options}
        onChange={(event) => e.onChange(event.originalEvent, event.value)}
        style={{ lineHeight: 1 }}
      />
    );
  }, []);

  useEffect(() => {
    if (id) {
      loadPublicacao();
    }
  }, [id, loadPublicacao]);

  const checkIsDirty = useCallback((valueA: any, valueB: any) => {
    return !_.isEqual(valueA, valueB);
  }, []);

  const resetFormData = useCallback(() => {
    setAutor(publicacaoInicial.autor || "");
    setTitulo(publicacaoInicial.titulo);
    setDtPublicacao(publicacaoInicial.dtPublicacao);
    setTipo(publicacaoInicial.tipo);
    setArquivo(publicacaoInicial.arquivo);
    setFileToReset(undefined);
    setIsDirty(false);
    setErrors({});
  }, [publicacaoInicial]);

  useEffect(() => {
    const titleIsDirty = checkIsDirty(titulo, publicacaoInicial.titulo);
    const authorIsDirty = checkIsDirty(autor, publicacaoInicial.autor);
    const editionIsDirty = checkIsDirty(edicao, publicacaoInicial.edicao);
    const typeIsDirty = checkIsDirty(tipo, publicacaoInicial.tipo);
    const dateIsDirty = checkIsDirty(dtPublicacao, publicacaoInicial.dtPublicacao);
    const fileIsDirty = checkIsDirty(arquivo, publicacaoInicial.arquivo);
    const localIsDirty = titleIsDirty || authorIsDirty || editionIsDirty || typeIsDirty || dateIsDirty || fileIsDirty;
    setIsDirty(localIsDirty);
  }, [arquivo, autor, checkIsDirty, dtPublicacao, edicao, publicacaoInicial, tipo, titulo]);

  return (
    <SimpleEntityPage showTopBar routeBack="/publicacoes" isFormPage loading={loading}>
      <div className="p-grid p-flex-row p-jc-between">
        <div className="p-col-7 p-grid">
          <div className="p-fluid p-col-12">
            <CustomFormField
              icon="pi pi-star"
              htmlForDescription="titulo"
              description="Título"
              inputBody={
                <InputText
                  id="titulo"
                  value={titulo || ""}
                  onChange={(e) => {
                    setTitulo(e.target.value);

                    const errorsLocal = _.cloneDeep(errors);
                    delete errorsLocal.titulo;
                    setErrors(errorsLocal);
                  }}
                />
              }
              required
              errorMessage={errors.titulo}
            />
            <CustomFormField
              icon="pi pi-user-edit"
              htmlForDescription="autor"
              description="Autor"
              inputBody={
                <InputText
                  id="autor"
                  value={autor || ""}
                  onChange={(e) => {
                    setAutor(e.target.value);

                    const errorsLocal = _.cloneDeep(errors);
                    delete errorsLocal.autor;
                    setErrors(errorsLocal);
                  }}
                />
              }
              errorMessage={errors.autor}
            />
            <CustomFormField
              icon="fa-solid fa-book-open"
              htmlForDescription="edicao"
              description="Nº da edição"
              inputBody={
                <InputText
                  id="edicao"
                  value={edicao || ""}
                  onChange={(e) => {
                    setEdicao(e.target.value);

                    const errorsLocal = _.cloneDeep(errors);
                    delete errorsLocal.edicao;
                    setErrors(errorsLocal);
                  }}
                />
              }
              errorMessage={errors.edicao}
            />
            <CustomFormField
              icon="pi pi-tag"
              htmlForDescription="tipo"
              description="Tipo do arquivo"
              inputBody={
                <Dropdown
                  placeholder="Selecione"
                  options={tipoOptions}
                  value={tipo}
                  onChange={(e) => {
                    setTipo(e.value);

                    const errorsLocal = _.cloneDeep(errors);
                    delete errorsLocal.tipo;
                    setErrors(errorsLocal);
                  }}
                />
              }
              required
              errorMessage={errors.tipo}
            />

            <CustomFormField
              icon="pi pi-calendar"
              htmlForDescription="dtPublicacao"
              description="Data de publicação"
              inputBody={
                <Calendar
                  id="dtPublicacao"
                  placeholder="DD/MM/AAAA"
                  value={dtPublicacao}
                  onChange={(e) => {
                    if (e.target && e.target.value) {
                      setDtPublicacao(new Date(e.target.value.toLocaleString("en-us")));
                    }
                    const errorsLocal = _.cloneDeep(errors);
                    delete errorsLocal.dtPublicacao;
                    setErrors(errorsLocal);
                  }}
                  monthNavigator
                  yearNavigator
                  yearRange="1500:2050"
                  monthNavigatorTemplate={monthNavigatorTemplate}
                  yearNavigatorTemplate={yearNavigatorTemplate}
                  maxDate={new Date()}
                  locale="pt-br"
                  showIcon
                />
              }
              required
              errorMessage={errors.dtPublicacao}
            />
            <CustomFormField
              icon="pi pi-upload"
              htmlForDescription="arquivo"
              description="Arquivo para publicação"
              inputBody={
                <FileSelect
                  accept="application/pdf"
                  fileLabel="Selecionar arquivo"
                  maxFileSize={15}
                  initialFile={fileToReset}
                  onFileChange={(file) => {
                    setArquivo(file);
                    setFileToReset(file);

                    const errorsLocal = _.cloneDeep(errors);
                    delete errorsLocal.arquivo;
                    setErrors(errorsLocal);
                  }}
                />
              }
              required
              errorMessage={errors.arquivo}
            />
          </div>
        </div>
      </div>

      {isDirty && (
        <div>
          <FloatingSave
            resetCommand={resetFormData}
            disabled={!titulo || _.isEmpty(titulo) || !tipo || _.isEmpty(tipo) || !dtPublicacao || (!id && !arquivo)}
            saveCommand={() => salvarForm()}
            loadingOnSave={loadingSalvar}
          />
        </div>
      )}
    </SimpleEntityPage>
  );
};

export default PublicacaoFormPage;
