/* eslint-disable react/require-default-props */
/* eslint-disable no-unused-vars */
import { Avatar } from "primereact/avatar";
import { Badge } from "primereact/badge";
import { Tooltip } from "primereact/tooltip";
import React, { useMemo } from "react";
import { v4 } from "uuid";
import { Grau } from "../../pages/Irmao/IrmaoPage";

type IrmaoComponentProps = {
  // uuid: string;
  nome: string;
  foto?: string;
  grau?: Grau;
  apelido?: string;
  fontSize?: string;
  iconePadrao?: string;
};

export interface GrauElement {
  nivel: string;
  nivelStr: string;
  label: string;
  class: string;
  grau: Grau;
}
type GrauMap = {
  [name in Grau]: GrauElement;
};

export const grausMap: GrauMap = {
  APRENDIZ: { nivel: "1", nivelStr: "Apr∴M∴", label: "Aprendiz", class: "irmao-aprendiz", grau: "APRENDIZ" },
  COMPANHEIRO: {
    nivel: "2",
    nivelStr: "Comp∴M∴",
    label: "Companheiro",
    class: "irmao-companheiro",
    grau: "COMPANHEIRO",
  },
  MESTRE: { nivel: "3", nivelStr: "M∴M∴", label: "Mestre", class: "irmao-mestre", grau: "MESTRE" },
  MESTRE_INSTALADO: {
    nivel: "3",
    nivelStr: "M∴I∴",
    label: "Mestre Instalado",
    class: "irmao-mestre-instalado",
    grau: "MESTRE_INSTALADO",
  },
};

const IrmaoComponent: React.FC<IrmaoComponentProps> = ({
  nome,
  foto,
  grau,
  apelido,
  fontSize,
  iconePadrao = "pi pi-user",
}) => {
  const componentId = useMemo(() => v4(), []);

  const badgeBodyTemplate = () => {
    if (!grau) {
      return <></>;
    }
    return (
      <>
        <Badge
          value={grausMap[grau].nivelStr}
          className={`irmao-avatar-badge ${grausMap[grau].class} badge-id-${componentId}`}
        />
        <Tooltip target={`.badge-id-${componentId}`}>{grausMap[grau].label}</Tooltip>
      </>
    );
  };

  const nomeApelido = apelido ? `${nome} (${apelido})` : `${nome}`;

  return (
    <div className="irmao-container p-text-nowrap p-text-truncate">
      <Avatar
        icon={foto ? undefined : iconePadrao}
        image={foto || undefined}
        imageAlt={foto ? nome : undefined}
        shape="circle"
        className="p-overlay-badge p-mr-2 irmao-avatar"
      >
        {/* <Badge value="4" className="irmao-avatar-badge" /> */}
        {/* {badgeBodyTemplate()} */}
      </Avatar>
      {badgeBodyTemplate()}
      <span className="irmao-nome-text p-ml-2" style={fontSize ? { fontSize } : {}}>
        {nomeApelido}
      </span>
    </div>
  );
};

export default IrmaoComponent;
