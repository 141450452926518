import React, { useCallback, useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { useParams, useHistory } from "react-router-dom";
import _ from "lodash";

import { MultiSelect } from "primereact/multiselect";
import FloatingSave from "../../../../components/FloatingSave";
import { usuarioErrors } from "../../../../errors/usuario";
import SimpleEntityListPage from "../../../../components/SimpleEntityPage";
import CustomFormField from "../../../../components/CustomFormField";

import api from "../../../../services/api";
import { useToast } from "../../../../hooks/toast";

import { useError } from "../../../../hooks/error";
import { GrupoUsuario, Usuario } from "../UsuarioPage";

interface Params {
  id: string;
}

const UsuarioFormPage: React.FC = () => {
  const [loading, setLoading] = useState(false);
  // const [loadingGrupos, setLoadingGrupos] = useState(false);
  const [loadingSalvar, setLoadingSalvar] = useState(false);

  const [usuario, setUsuario] = useState<Usuario>({} as Usuario);

  const [nome, setNome] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [grupos, setGrupos] = useState<GrupoUsuario[]>([]);

  const [gruposOptions, setGruposOptions] = useState<GrupoUsuario[]>([]);

  const [isDirty, setIsDirty] = useState(false);

  const { showToast } = useToast();
  const { handleError } = useError();

  const [errors, setErrors] = useState<{ [campo: string]: string }>({});

  const { id } = useParams<Params>();
  const history = useHistory();

  const loadUsuario = useCallback(() => {
    setLoading(true);
    api
      .get<Usuario>(`controle/usuarios/${id}`)
      .then(({ data: user }) => {
        setUsuario(user);
        setNome(user.nome);
        setEmail(user.email);
        setGrupos(user.grupos);
      })
      .catch((error) => {
        showToast({
          type: "error",
          title: "Não foi possível carregar o usuário",
          description: "Tente novamente ou contate nosso time de suporte para solicitar ajuda.",
        });
        // eslint-disable-next-line no-console
        console.error(error);
      })
      .finally(() => setLoading(false));
  }, [id, showToast]);

  const loadGrupos = useCallback(() => {
    // setLoadingGrupos(true);
    api
      .get<GrupoUsuario[]>(`controle/grupos`)
      .then(({ data }) => {
        setGruposOptions(data);
      })
      .catch((err) => {
        showToast({
          type: "error",
          title: "Não foi possível carregar os grupos de acesso",
          description: "Tente novamente ou contate nosso time de suporte para solicitar ajuda.",
        });
        // eslint-disable-next-line no-console
        console.error(err);
      })
      .finally(() => {
        // setLoadingGrupos(false);
      });
  }, [showToast]);

  const redefinirUsuario = useCallback(() => {
    setIsDirty(false);

    if (id === "novo") {
      setGrupos([]);
    } else {
      loadUsuario();
    }
  }, [id, loadUsuario]);

  const validarCampos = useCallback(() => {
    const errorsLocal: { [campo: string]: string } = {};

    // if (_.isEmpty(nome)) {
    //   errorsLocal.nome = "Este é um campo obrigatório";
    // }

    setErrors(errorsLocal);
    return _.isEmpty(errorsLocal);
  }, []);

  const floatingSaveEnabled = useCallback(() => {
    return !_.isEqual(usuario.grupos, grupos);
  }, [grupos, usuario.grupos]);

  const salvarUsuario = useCallback(() => {
    const valid = validarCampos();

    if (valid) {
      if (id === "novo") {
        // TODO: não existe rota para a criação de um usuário com grupos
        // setLoadingSalvar(true);
        // api
        //   .post("controle/usuarios", {
        //     // idTurma: 2,
        //     nome,
        //     email,
        //     grupos,
        //   })
        //   .then(() => {
        //     showToast({ title: "Sucesso!", type: "success", description: "Um novo usuário foi criado." });
        //     history.push("/controle/usuario");
        //   })
        //   .catch((err) => {
        //     handleError({ error: err, action: "criar usuário", knownErrors: usuarioErrors });
        //   })
        //   .finally(() => setLoadingSalvar(false));
      } else {
        setLoadingSalvar(true);
        api
          .put(`controle/usuarios/${id}`, {
            idsGrupos: !_.isEmpty(grupos) ? grupos.map((grupo) => grupo.id) : [],
          })
          .then(() => {
            showToast({ title: "Sucesso!", type: "success", description: "O usuário foi editado e salvo." });
            history.push("/controle/usuario");
          })
          .catch((err) => {
            handleError({ error: err, action: "atualizar usuário", knownErrors: usuarioErrors });
          })
          .finally(() => setLoadingSalvar(false));
      }
    }
  }, [grupos, handleError, history, id, showToast, validarCampos]);

  useEffect(() => {
    loadGrupos();
    if (id !== "novo") loadUsuario();
  }, [id, loadGrupos, loadUsuario]);

  return (
    <SimpleEntityListPage showTopBar routeBack="/controle/usuario" isFormPage loading={loading}>
      <div className="p-grid p-flex-row p-jc-between">
        <div className="p-col-7 p-grid">
          <div className="p-fluid p-col-12">
            <CustomFormField
              icon="pi pi-user"
              htmlForDescription="nome"
              description="Irmão"
              inputBody={
                <InputText
                  id="Irmão"
                  value={nome}
                  type="text"
                  placeholder="Nome e sobrenome"
                  onChange={(e) => {
                    setNome(e.target.value);
                    const errorsLocal = _.cloneDeep(errors);
                    delete errorsLocal.nome;
                    setErrors(errorsLocal);
                    if (!_.isEqual(usuario.nome, e.target.value)) {
                      setIsDirty(true);
                    } else {
                      setIsDirty(false);
                    }
                  }}
                  disabled
                  maxLength={100}
                />
              }
              // required
              errorMessage={errors.nome}
            />
            <CustomFormField
              icon="ba ba-email"
              htmlForDescription="email"
              description="E-mail"
              inputBody={
                <InputText
                  id="email"
                  value={email}
                  type="text"
                  placeholder="exemplo@email.com"
                  onChange={(e) => {
                    setEmail(e.target.value);
                    const errorsLocal = _.cloneDeep(errors);
                    delete errorsLocal.email;
                    setErrors(errorsLocal);
                    if (!_.isEqual(usuario.email, e.target.value)) {
                      setIsDirty(true);
                    } else {
                      setIsDirty(false);
                    }
                  }}
                  maxLength={100}
                  disabled
                />
              }
              // required
              errorMessage={errors.nome}
            />

            <CustomFormField
              icon="ba ba-usuario"
              htmlForDescription="grupos"
              description="Grupos de Acesso"
              inputBody={
                <MultiSelect
                  placeholder="Selecione"
                  showClear
                  dataKey="uuid"
                  optionLabel="nome"
                  options={gruposOptions}
                  value={grupos}
                  onChange={(e) => {
                    setGrupos(e.target.value);
                    const errorsLocal = _.cloneDeep(errors);
                    delete errorsLocal.grupos;
                    setErrors(errorsLocal);
                    if (!_.isEqual(usuario.grupos, e.target.value)) {
                      setIsDirty(true);
                    } else {
                      setIsDirty(false);
                    }
                  }}
                  showSelectAll={false}
                  maxSelectedLabels={3}
                  selectedItemsLabel="{0} grupos selecionados"
                  emptyFilterMessage="Nenhum grupo encontrado"
                  filter
                  filterBy="nome"
                />
              }
              required
              errorMessage={errors.irmao}
            />
          </div>
        </div>
      </div>

      <div>
        {isDirty ? (
          <FloatingSave
            resetCommand={() => redefinirUsuario()}
            disabled={!floatingSaveEnabled()}
            saveCommand={() => salvarUsuario()}
            loadingOnSave={loadingSalvar}
          />
        ) : null}
      </div>
    </SimpleEntityListPage>
  );
};

export default UsuarioFormPage;
