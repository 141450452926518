import React from "react";
import { Avatar } from "primereact/avatar";
import _ from "lodash";
import { useAuth } from "../../hooks/auth";
import SimpleButton from "../SimpleButton";
import { getFirstCharactersFirstWord, getFirstCharactersSecondWord } from "../../utils/getFirstCharacters";
import { grausMap } from "../IrmaoComponent";

interface Props {
  logoutCommand: () => void;
}

const MenuLogOut: React.FC<Props> = ({ logoutCommand }) => {
  const { user, irmao, loja, potencia, usuario } = useAuth();
  let firstCharacterFirstName = "";
  let firstCharacterLastName = "";

  const firstName = user.nome.split(" ")[0];
  const charactersFirstName = getFirstCharactersFirstWord(user.nome);
  const charactersLastName = getFirstCharactersSecondWord(user.nome);

  if (charactersLastName && charactersFirstName) {
    [firstCharacterFirstName] = charactersFirstName;
    [firstCharacterLastName] = charactersLastName;
  }

  return (
    <div className="menu-logout-container">
      {!_.isEmpty(irmao) && !_.isEmpty(loja) && irmao && loja && (
        <>
          <div className="p-mb-3 p-d-flex p-ai-center irmao-container">
            <Avatar
              label={irmao.foto ? undefined : firstCharacterFirstName}
              image={irmao.foto || undefined}
              className="p-mr-3 irmao-avatar avatar-container"
              size="large"
              shape="circle"
            />
            <div className="p-d-flex p-flex-column">
              <span>
                {firstName} {firstCharacterLastName}.
              </span>
              <span className="p-mt-1 menu-logout-grau-irmao">{grausMap[irmao.grau].label}</span>
            </div>
          </div>
          <div className="p-mb-2 p-d-flex p-ai-center">
            <div className="irmao-container">
              <Avatar
                label={loja.foto ? undefined : loja.nome[0]}
                image={loja.foto || undefined}
                className="p-mr-3 irmao-avatar avatar-container"
                size="large"
                shape="circle"
              />
            </div>
            <div style={{ lineHeight: 1.8 }}>Loja {loja.nome}</div>
          </div>
          <hr className="p-my-3 linha-horizontal" />
          <SimpleButton command={() => logoutCommand()} label="Sair" icon="pi pi-sign-out" />
        </>
      )}
      {!_.isEmpty(potencia) && !_.isEmpty(usuario) && potencia && usuario && (
        <>
          <div className="p-mb-3 p-d-flex p-ai-center irmao-container">
            <Avatar
              label={firstCharacterFirstName}
              className="p-mr-3 irmao-avatar avatar-container"
              size="large"
              shape="circle"
            />
            <div className="p-d-flex p-flex-column">
              <span>
                {firstName} {firstCharacterLastName}.
              </span>
              {/* <span className="p-mt-1 menu-logout-grau-irmao">{grausMap[irmao.grau].label}</span> */}
            </div>
          </div>
          <div className="p-mb-2 p-d-flex p-ai-center">
            <div className="irmao-container">
              <Avatar
                label={potencia.logo ? undefined : potencia.nome[0]}
                image={potencia.logo || undefined}
                className="p-mr-3 irmao-avatar avatar-container"
                size="large"
                shape="circle"
              />
            </div>
            <div style={{ lineHeight: 1.8 }}>{potencia.nome}</div>
          </div>
          <hr className="p-my-3 linha-horizontal" />
          <SimpleButton command={() => logoutCommand()} label="Sair" icon="pi pi-sign-out" />
        </>
      )}
    </div>
  );
};

export default MenuLogOut;
