/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Tooltip } from "primereact/tooltip";
import React, { ReactNode, RefObject, useCallback, useState } from "react";
import ContentTopBar from "../../ContentTopBar";
import Loading from "../../Loading";

type SimpleEntityPageProps = {
  loading?: boolean;
  globalFilter?: string;
  onGlobalFilterChange?: (value: string) => void;
  showTopBar?: boolean;
  showListHeader?: boolean;
  onNewButtonCLick?: () => void;
  newButtonLabel?: string;
  newButtonIcon?: string;
  newButtonTooltip?: string;
  newButtonDisabled?: boolean;
  filterPlaceholder?: string;
  routeBack?: string;
  saveLabel?: string;
  excludeLabel?: string;
  saveCommand?: (event: any) => void;
  excludeCommand?: (event: any) => void;
  listHeaderContentRight?: ReactNode;
  listHeaderContentLeft?: ReactNode;
  hidePagePadding?: boolean;
  isFormPage?: boolean;
  showExportCsvButton?: boolean;
  datatableRef?: RefObject<DataTable>;
};

const SimpleEntityPage: React.FC<SimpleEntityPageProps> = ({
  children,
  loading = false,
  showTopBar = false,
  showListHeader = false,
  onNewButtonCLick,
  newButtonLabel = "Novo",
  newButtonIcon = "pi pi-plus",
  newButtonDisabled = false,
  newButtonTooltip,
  globalFilter = "",
  filterPlaceholder = "Buscar",
  onGlobalFilterChange,
  saveLabel,
  excludeLabel,
  saveCommand,
  excludeCommand,
  routeBack,
  listHeaderContentLeft,
  listHeaderContentRight,
  hidePagePadding = false,
  isFormPage = false,
  showExportCsvButton = false,
  datatableRef,
}) => {
  const [filter, setFilter] = useState<string>(globalFilter);

  const exportCSV = useCallback(
    (selectionOnly = false) => {
      if (datatableRef && datatableRef.current) datatableRef.current.exportCSV({ selectionOnly });
    },
    [datatableRef]
  );

  return (
    <div className="simple-entity-page-container">
      {showTopBar && (
        <div>
          <ContentTopBar
            routeBack={routeBack}
            saveCommand={saveCommand}
            saveMessage={saveLabel}
            excludeMessage={excludeLabel}
            excludeCommand={excludeCommand}
          />
        </div>
      )}

      <Loading isLoading={loading} />
      {!loading && (
        <div className={hidePagePadding ? `simple-entity-page-content` : `simple-entity-page-content padding-16`}>
          {showListHeader && (
            <div className="simple-entity-page-header p-mb-2" style={{ justifyContent: "flex-end" }}>
              {onNewButtonCLick && (
                <>
                  {newButtonTooltip && <Tooltip target=".entity-page-new-button" />}

                  <span className="entity-page-new-button" data-pr-tooltip={newButtonTooltip}>
                    <Button
                      className="datatable-filter-field button-transparent-convite-glomam"
                      label={newButtonLabel}
                      onClick={onNewButtonCLick}
                      disabled={newButtonDisabled}
                      tooltip={newButtonTooltip}
                      tooltipOptions={{ position: "right" }}
                      style={{ marginBottom: -20, marginTop: 0 }}
                    />
                  </span>
                </>
              )}
              {onGlobalFilterChange && (
                <span className="p-input-icon-left simple-entity-page-filter">
                  <i className="pi pi-search" />
                  <InputText
                    value={filter}
                    onChange={(e) => {
                      setFilter(e.currentTarget.value);
                      if (onGlobalFilterChange) {
                        onGlobalFilterChange(e.currentTarget.value);
                      }
                    }}
                    placeholder={filterPlaceholder}
                    className="filter-datatable-search"
                  />
                </span>
              )}
              {showExportCsvButton && (
                <Button
                  type="button"
                  icon="pi pi-file-excel"
                  style={{
                    height: "30px",
                    width: "30px",
                    border: "1px solid white",
                    color: "white",
                    backgroundColor: "#689F38",
                  }}
                  onClick={() => exportCSV(false)}
                  className="p-button-success p-ml-2 p-mr-2"
                  tooltip="Exportar tabela (.csv)"
                  tooltipOptions={{ position: "left" }}
                />
              )}
            </div>
          )}
          {!isFormPage ? children : <div className="simple-entity-form-page">{children}</div>}
        </div>
      )}
    </div>
  );
};

export default SimpleEntityPage;
